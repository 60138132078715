export const RATE_DESIGNER = 'CLICK_STAR_RATING';

export const rateDesigner = ({ designerId, rating }) => {
	return {
		type: RATE_DESIGNER,
		payload: {
			designerId,
			rating,
		},
	};
};