import * as actions from './actions';

import {
	call,
	put,
    takeLatest,
    select
} from 'redux-saga/effects';
import {cl} from 'utils'
 
//set chips according to the set filters
function* updateChipsByFilters(action) {
    
    cl('chips saga: updateChipsByFilters')
    let stateFilters = yield select( state => state.filters.filters.terms)
    cl(stateFilters, 'stateFilters: ')
    // const filters = yield select(state => lodash.cloneDeep(state.filters.filters));
	try{
		
        yield put(actions.updateChips(stateFilters));
        
	} catch(e) {
        cl(e, 'filterchipsbar saga error: ')
		// yield put(fetchTermsFailed(e));
	}
}

export default function* listenerSaga() {
	yield takeLatest(actions.GET_FILTERS_TO_UPDATE_CHIPS, updateChipsByFilters); 
}
