import { call, put, takeLatest } from 'redux-saga/effects';
import { types, actions } from './actions';
import api, { fetchProducts } from 'data/api';

function* fetchShippingProfile(action) {
	try{
		const result = yield call(api.fetchShippingProfile, action.payload.shippingProfileId);
		yield put(actions.fetchShippingProfileSuccess(result));
	} catch(error) {
		yield put(actions.fetchShippingProfileFailure(error));
	}
}

function* createShippingProfile(action) {
	const { formData } = action.payload;
	try{
		const result = yield call(api.createShippingProfile, formData);
		yield put(actions.createShippingProfileSuccess(result));
	} catch(error) {
		yield put(actions.createShippingProfileFailure(error));
	}
}

function* updateShippingProfile(action) {
	const { shippingProfileId, formData } = action.payload;
	try{
		const result = yield call(api.updateShippingProfile, shippingProfileId, formData);
		yield put(actions.updateShippingProfileSuccess(result));
	} catch(error) {
		yield put(actions.updateShippingProfileFailure(error));
	}
}

function* getProducts(action) {
	const args = {
		designers: [action.payload.designerId],
	};
	try{
		const result = yield call(fetchProducts, args);
		yield put(actions.fetchProductsSuccess(result));
	} catch(error) {
		yield put(actions.fetchProductsFailure(error));
	}
}

export default function* listenerSaga() {
	yield takeLatest(types.FETCH_SHIPPING_PROFILE, fetchShippingProfile);
	yield takeLatest(types.CREATE_SHIPPING_PROFILE, createShippingProfile);
	yield takeLatest(types.UPDATE_SHIPPING_PROFILE, updateShippingProfile);
	yield takeLatest(types.FETCH_PRODUCTS, getProducts);
}
