import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactRating from 'react-rating';
import { rateDesigner } from './actions';
import { selectSession } from 'data/selectors';

class Rating extends Component {

	constructor(props) {
		super(props);

		this.handleRate = this.handleRate.bind(this);
	}

	handleRate(rate, event) {
		this.props.rateDesigner({
			designerId: 1,
			rating: rate,
		});
	}

	render() {
		return (
			<div>
				<ReactRating 
					empty="fa fa-star-o fa-2x rating__star"
					full="fa fa-star fa-2x rating__star"
					initialRate={this.props.rating}
					readonly={!this.props.session.signedIn}
					onClick={this.handleRate}
				/> {this.props.rating} ({this.props.numRatings || 0})
			</div>
		);
	}

};

Rating.propTypes = {
	rating: PropTypes.number.isRequired,
	session: PropTypes.object.isRequired,
	rateDesigner: PropTypes.func.isRequired,
	numRatings: PropTypes.number,
};

export default connect(
	state => ({
		session: selectSession(state),
	}),
	{ rateDesigner }
)(Rating);