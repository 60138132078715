import React, {Component } from 'react';
import {connect} from 'react-redux'
import { withRouter } from 'react-router';

import {  changeCountry, requestData, changeCurrency } from 'containers/Session/actionCreators';
  

import Popover from '@material-ui/core/Popover';
// import Paper from 'material-ui-old/Paper';
//import { MenuItem } from 'material-ui-next/Menu';
import MenuItem from '@material-ui/core/MenuItem';
// import {Select} from 'redux-form-material-ui' 

import Select from '@material-ui/core/Select';
import ReactSelect from 'react-select'
import Button from  '@material-ui/core/Button';

import { DaylicacyFlagIcon } from 'components/Icon';
import { DaylicacyTitle, DaylicacyText } from 'components/Text';

// import List from '@material-ui/core/List';
// import ListItem from '@material-ui/core/ListItem';
import ListItemIcon  from '@material-ui/core/ListItemIcon';
import ListItemText  from '@material-ui/core/ListItemText';

import Typography from '@material-ui/core/Typography'; 
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';  
import CircularProgress from  '@material-ui/core/CircularProgress';
import {cl} from 'utils';


const initialStyles = {
	popover: {
		overflowY: 'hidden',
		width: '650px',
		marginLeft: '-8px',
		marginTop: '3px',
	},
	title: {
		margin: 0,
	}
 
};

//(scroll down for LanguagePickerPopover)

/* =============== React-select custom components (adapted to mui) ===============
			see: https://material-ui.com/demos/autocomplete/
			and: https://react-select.com/components

	Control, (+ mui inputComponent), Option, Menu, ValueContainer
*/ 
function Control(props) {
	return (
	  <TextField
		fullWidth
		InputProps={{
		  inputComponent,
		  inputProps: {
			className: "mui-reactselect-input",
			inputRef: props.innerRef,
			children: props.children,
			...props.innerProps,
		  },
		}}
		// {...props.selectProps.textFieldProps}
	   
	  />
	);
  }

  function inputComponent({ inputRef, ...props }) {
	return <div ref={inputRef} {...props} />;
  }

  function Option(props) {
	return (
	  <MenuItem 
		buttonRef={props.innerRef}
		selected={props.isFocused}
		component="div"
		className="mui-reactselect-options-menuitem"
		{...props.innerProps}
	  >
		{props.children}
	  </MenuItem>
	);
  }

//   function Menu(props) {
// 	return (
// 	  <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
// 		{props.children}
// 	  </Paper>
// 	);
//   }

function ValueContainer(props) {
	return <div className="mui-reactselect-valuecontainer">{props.children}</div>;
  } 

function Menu(props) {
	return (
		<Paper square className="mui-reactselect-paper" {...props.innerProps}>
		{props.children}
		</Paper>
	);
}

function Placeholder(props) {
	return (
		<Typography  
			className="mui-reactselect-placeholder"
			{...props.innerProps}
			>
			{props.children}
		</Typography>
	);
}

{/* <span>{props.typeMsg}</span> */}
function SingleValue(props) {
	return (
	  <Typography{...props.innerProps}>
			{props.children} 
	  </Typography>
	);
  }
  function NoOptionsMessage(props) {
	return (
	  <Typography
		className="mui-reactselect-nooptionsmessage"
		{...props.innerProps}
	  >
		{props.children}
	  </Typography>
	);
  }

const components = {
	Option,
	Control,
	NoOptionsMessage,
	Placeholder,
 	SingleValue,
	// MultiValue,
	ValueContainer,
	Menu,
};

// function Placeholder(props) {

// 	return (
// 		<Typography
// 			color="textSecondary"
// 			className="mui-reactselect-placeholder"
// 			{...props.innerProps}
// 			>
// 			{props.children}
// 		</Typography>
// 	);
// }

/* ================== LanguagePickerPopover ================== */
class LanguagePickerPopover extends Component {

	constructor(props){
		super(props)

		this.state = {
			single: ({ 
				value: this.props.deliveryCountry,
				label: this.props.deliveryCountry
			}),
			// multi: null,
			selectedCountry: null,
			userCurrency: this.props.userCurrency	,	//'code' property
			
		}

		// this.handleDeliveryCountryBlur = this.handleDeliveryCountryBlur.bind(this);
		// this.handleDeliveryCountryFocus = this.handleDeliveryCountryFocus.bind(this);
		this.onCurrencyChange = this.onCurrencyChange.bind(this);
	}
  
	componentWillReceiveProps(nextProps) {
		 
		if(nextProps.deliveryCountry !== this.state.single.value){
				cl('nextProps.deliveryCountry.value !== this.props.single.value - setting new state');

				this.setState({
					...this.state, 
					single: ({ 
							value: nextProps.deliveryCountry,
							label: nextProps.deliveryCountry
						}) 
				}, () => {
					// cl(this.state, 'new local state is ', true);
				})
		}
	
	}
	 
// 	handleDeliveryCountryBlur(){
// 		this.setState({
// 				typeMsg: 'something2'
// 		})
//  }

// 	handleDeliveryCountryFocus(){
// 		 this.setState({
// 			 	typeMsg: '(type to change)'
// 		 })
// 	}
	handleChange = name => object => {

		cl(object, 'handlechange')
		//  alert(name);
		const { requestData, changeCountry } = this.props;


        changeCountry(object.value, object.label, object.code);
//        requestData({args: {country:value}, type: 'products' });

		this.setState({
		  [name]: object,
		  selectedCountry: object.value
		});

	};

	onCurrencyChange(event) {
		const { changeCurrency } = this.props;
			// alert('onCurrencyChange to: ' + event.target.value)

			this.setState({
				userCurrency: event.target.value, 
			});	
		changeCurrency( event.target.value);
	}

	createConfirmMessageMarkup(){
			return {__html: this.props.confirmMessage};		
	}
	render(){

		const {
			onClose,
			anchorEl,
			open,
			languages,
			countries,
			currencies,
			onLanguageChange,
			userLanguage,
			deliveryCountry, 
			userCurrency,
			onCountryChange,
			onCurrencyChange,
			confirmMessage ,
			tr
		}  = this.props;
	 

	if(!tr) {
		return (<div>Loading..</div>);
	}

	
	return(
		<div>
			<Popover
				className="language-picker-popover"
				open={open}
				anchorEl={anchorEl}
				onClose={onClose}
				style={initialStyles.popover}
			>
				<Paper className="language-picker-paper">
				<div className="container-fluid">

				{confirmMessage &&
					<div className="row message-sign">
						<div className="cx12"><p className="note"dangerouslySetInnerHTML={this.createConfirmMessageMarkup()}/></div>
					</div>
				 }
					{/* ============= Language ============= */}
					<div className="row">
						<div className="cx4 language-picker-popover-column">
							<DaylicacyTitle title={tr.language} />
							<div> 
								<DaylicacyText>
									{tr.choose_site_language}
								</DaylicacyText>
							</div>
							<Select
								className="mui-select-bottom-border"  
								onChange={ onLanguageChange }
								value={userLanguage}
							>
								{ languages.map((language, i) => {
									//todo: flag icons should be set to their country code
									//however the language code is not always the same as country code  
									//workaourend for sweden - 'sv' is set to 'se' 
									var languageCode = language.code;

									if(language.code == 'sv'){
										// alert('languageCode is ' + languageCode)
										languageCode = 'SE';
									}
								  
									return (
									<MenuItem
										key={i}
										value={languageCode}   	
									>
										<ListItemText inset primary={language.name} />
										<ListItemIcon>
											<DaylicacyFlagIcon
												countryCode={languageCode}
												mode="list"
											/>
										</ListItemIcon>
										 
									</MenuItem>
									)
								}
								) }
							</Select>
						</div>

						{/* ============= Delivery country ============= */}

						<div className="cx4 language-picker-popover-column">
							<DaylicacyTitle title={tr.delivery_country} />
							<div>
								<DaylicacyText>
									{tr.delivery_country_text || 'Pick your country where products should ship to'}
								</DaylicacyText>
							</div>
							<div className="form">
								<div className="form-select react-select-container">
									<ReactSelect
										className="react-select mui-select-bottom-border" 
										onChange={onCountryChange}
										options={countries} 
										// typeMsg={this.state.typeMsg}
										components={components}
										value={this.state.single}
										onChange={this.handleChange('single')}
										onFocus={this.handleDeliveryCountryFocus}
										onBlur={this.handleDeliveryCountryBlur}
										placeholder="(type to search)"
									/> 
								</div>
							</div>
							<DaylicacyText type="note">
								Note: You will only be able to see creations available that can be delivered to the country you choose.
							</DaylicacyText>
						</div>

						{/* ============= Currency ============= */}
						<div className="cx4 language-picker-popover-column">
							<DaylicacyTitle title={tr.currency} />
							<div>
								<DaylicacyText>
									{tr.choose_site_currency}
								</DaylicacyText>
							</div>
							<div className="form">
								<div className="form-select">
									<Select
										className="mui-select-bottom-border" 
										onChange={this.onCurrencyChange}
										value={this.state.userCurrency}
										
									>
										{ currencies.map((currency, i) => (
											<MenuItem
												key={i}
												value={currency.code} 
											>
											{`${currency.name}`}
											</MenuItem>
										))
										}
									</Select>
								</div>
								

							</div>
							<Button 
									type="button" 
									onClick={onClose}
									variant="raised" 
									color="secondary"
									className="daylicacy-button" 
									>
									Close
									
									</Button>
						</div>
								 
					</div>
				</div>
				</Paper>
			</Popover>
		</div>
	);
}
};
 
LanguagePickerPopover = withRouter(connect(
	state => ({
		
		deliveryCountry: state.session.delivery_country_name, 
	}), 
	{  changeCountry, requestData,changeCurrency}
)(LanguagePickerPopover))



export default LanguagePickerPopover;