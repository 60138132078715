import * as types from './actionTypes';
import api, { fetchProduct } from 'data/api';
import { call, put, takeLatest } from 'redux-saga/effects';
import {cl} from 'utils'

function* getProduct(action) {
	try{
		const result = yield call(fetchProduct, action.id);
		yield put({ type: types.EDIT_PRODUCT_FETCH_PRODUCT_SUCCESS, result });
	} catch(e) {
		yield put({ type: types.EDIT_PRODUCT_FETCH_PRODUCT_FAILED, error: e });
	}
}

function* updateProduct(action) {
	try{
		const result = yield call(api.updateProduct, action.id, action.data);
		cl(result, 'saga, updateProduct response: ')
		yield put({ type: types.EDIT_PRODUCT_UPDATE_PRODUCT_SUCCESS, payload: result.data });

	} catch(e) {
		cl(e, 'editproduct saga error: ')
		yield put({ type: types.EDIT_PRODUCT_UPDATE_PRODUCT_FAILED, error: e });
	}
}

export default function* listenerSaga() {
	yield takeLatest(types.EDIT_PRODUCT_FETCH_PRODUCT, getProduct);
	yield takeLatest(types.EDIT_PRODUCT_UPDATE_PRODUCT, updateProduct);
}
