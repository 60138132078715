export default [
	{ label: 'Setup', 
		value: 'shop-setup'},
	{ label: 'Creations',
		value: 'creations', },
	{ label: 'Collections',
		value: 'collections', },
	{ label: 'Profile',
		value: 'shop-profile', },
	{ label: 'Imprint',
		value: 'imprint', },
	{ label: 'FAQ',
		value: 'faq', },
	{ label: 'T&Cs',
		value: 'terms-and-conditions', },
	{ label: 'Shipping',
		value: 'payment-and-shipping', },
	{ label: 'Leave',
		value: 'leave', },
	{ label: 'Sales',
		value: 'sales', },
	{ label: 'Advertise',
		value: 'advertise', }
];