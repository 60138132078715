import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
//import CircularProgress from 'material-ui-next/CircularProgress';
import CircularProgress from '@material-ui/core/CircularProgress';

import Sidebar from './Sidebar';
import axios from 'axios';
import Gallery from 'react-photo-gallery';

import {selectUserLanguage} from 'containers/Session/selectors';
import {cl} from 'utils';
import PageNotFound from 'views/PageNotFound';


class Page extends Component {

	constructor(props) {
		console.log('page constructor');
		super(props);

		const { params: { slug } } = this.props.match;

		this.state = {
			content: null,
			title: null,
			slug,
			requestStatus: {
				requestStatus: null, 
				fetchError: {
					errorCode: null,
					errorMessage: null
				}
			}
		};

		this.getContent = this.getContent.bind(this);
	}

	getPhotos() {
        let arrPhotos = [ 'a.jpg', 'b.jpg', 'c.jpg', 'd.jpg', 'e.jpg', 'f.jpg'];

        let photos = arrPhotos.map(function (photo) {
            return {
                src: '/gallery/'+photo,
                srcset: [
                    '/gallery/'+photo
                ],
                sizes:[
                    '(min-width: 480px) 50vw',
                    '(min-width: 1024px) 33.3vw',
                    '100vw'
                ],
                width: 681,
                height: 1024,
                alt: 'image 1',
            }
        });

		return photos;
	}

    openLightBox() {

	}

	/* Fetches text content by current slug
			backend function "getPageBySlug"
		sets slug and content in local state
	*/
	getContent(slug, language) {
		axios(`/api/page/${language}/${slug}`)
			.then(res => {

				cl(res, 'result of get page by slug: ')
					if(res.data){
 
					this.setState({
						content: res.data.content,
						title: res.data.title,
						slug: slug,
						requestStatus: {
							requestStatus: 'success', 
							fetchError: {
								errorCode: null,
								errorMessage: null
							}
						}
					});
					let pageContent = this.refs.pageContent;
					pageContent.innerHTML = res.data.content;
				}
			})
			.catch(err => {
				cl(err.response.status, 'error code: ') 
				this.setState({
					requestStatus: {
						requestStatus: 'failed', 
						fetchError: {
							errorCode: err.response.status || null,
							errorMessage: err.response.data.message || null
						}
					}
				})
			})
	}

	componentWillMount(){
		const {slug} = this.props.match.params;

		if (slug !== this.state.slug || !this.state.content) {
			console.log('calling getContent, slug is ' + slug);
			this.getContent(slug, this.props.language);
		}
	}
	componentDidUpdate(prevProps ){
		const {slug} = this.props.match.params;

		//fetch if url changed
		if (this.props.location.pathname !== prevProps.location.pathname) {
			console.log('componentDidUpdate: calling getContent, slug is ' + slug);
			this.getContent(slug, this.props.language);
		}
	 
			
		 
	}

	render() {
		console.log('rendering');
		const { params: { slug } } = this.props.match;
		const { footer_menus } = window.d_store;

		if(this.state.requestStatus.requestStatus == 'failed' && this.state.requestStatus.fetchError.errorCode == 404){
				
			return <PageNotFound />
		} 

		return (
			<div className="container page">
				<div className="row">
					<Sidebar 
						menus={footer_menus}
						lang={this.props.language} 
					/>
					{ !this.state.content
						? <div className="mui-circular-container"><CircularProgress /></div>
						: 
							<div className="cx12 cs9 page-content">
								<h2>{this.state.title}</h2>
								<div className="content" ref="pageContent">

								</div>
								{ this.state.title == 'Photo gallery'
									? <Gallery photos={this.getPhotos()} onClickPhoto={this.openLightBox} />
									: ''
								}

							</div>
					}
				</div>
			</div>
		);
	}
}

Page.PropTypes = {
	match: PropTypes.object.isRequired,
};

export default connect(
	state => ({
		 
		language: selectUserLanguage(state),
	})
)(Page);