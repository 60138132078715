import { put, call } from 'redux-saga/effects';
import { normalize, denormalize } from 'normalizr';
import { createSelector } from 'reselect';
import {selectReturnSomething} from 'data/selectors';

import {
	shippingZoneListSchema,
} from 'data/schemas';
import { 
	ADD_ENTITIES, 
	addEntities, 
	requestDataFailure,
} from 'data/actions';
import api from 'data/api';
import {cl} from 'utils'


export const FETCH_DESIGNER_SHIPPING_ZONES = 'FETCH_DESIGNER_SHIPPING_ZONES';
export const ADD_DESIGNER_SHIPPING_ZONES = 'ADD_DESIGNER_SHIPPING_ZONES';
export const DELETE_SHIPPING_ZONE = 'DELETE_SHIPPING_ZONE';


//Actions
export const fetchDesignerShippingZones = ({ id }) => ({
	type: FETCH_DESIGNER_SHIPPING_ZONES,
	payload: {id}
});
export const addDesignerShippingZones = ( result ) => ({
	type: ADD_DESIGNER_SHIPPING_ZONES,
	payload: result
});
// export const fetchDesignerShippingZonesFailure = ({ error }) => ({
// 	type: FETCH_DESIGNER_SHIPPING_ZONES_FAILURE,
// 	payload: {error}
// });
export const deleteShippingZone = ( zoneId ) => ({		
	type: DELETE_SHIPPING_ZONE,
	zoneId
});

export const STATE_KEY = 'shippingZones';

const subject = 'shippingZonesModule';

// ======= NOTE!! Zones reducer is currently in ShippingProfiles reducers . Saga stuff is still here.


// =============== Reducer ================ 

//state.data.shippingZones
const initialState = {
	shippingZones: [],
	requesting:false,
	fetchResultStatus:null
};
 
export default function reducer(state = initialState, action) {

	switch (action.type) {
		// case ADD_ENTITIES:
        //             console.log('modules-shippingZones reducer: ADD_ENTITIES');
		// 	return {
		// 		...state,
		// 		...action.payload.shippingZones,
		// 	};

		// case ADD_DESIGNER_SHIPPING_ZONES:
		// 	console.log('shippingZOnes reducer: ADD_DESIGNER_SHIPPING_ZONES, action.payload: ',action.payload);
		// 	return Object.assign({}, state, {
		// 		shippingZones: action.payload,
		// 		fetchResultStatus: 'success'
		// 	});

		// case FETCH_DESIGNER_SHIPPING_ZONES_FAILURE:
		// 	return Object.assign({}, state, {
		// 		fetchResultStatus: 'failed',
		// 	});
		// 	break;

		default:
			return state; 

	}
}


//not in use???
export function* getShippingZones(action) {
	try {
		const response = yield call(
			api.fetchShippingZones,
			action.payload.args.designerId
		);
		
		const data = normalize(response.data, shippingZoneListSchema);
		yield put(addEntities(data.entities));
	} catch (e) {
		console.log('getShippingZones failed');
		yield put(requestDataFailure(subject, e, e.response.status));
	}
}
 

export function* callDeleteShippingZone(action) {
	cl('shippingZones - deleteShippingZone');
	cl(action);
	try {
		//result should contain all shipping zones
		const response = yield call( api.deleteShippingZone, action.payload.args );
 
		const data = normalize(response.data, shippingZoneListSchema);
		
		//replace existing zones with new data
		yield put(addDesignerShippingZones(data.entities));
		//call action to remove zone from redux
		// deleteShippingZone(action.args.zoneId)

	} catch (e) {
		console.log('getUserDesigner failed');
		yield put(requestDataFailure(subject, e, e.response.status));
	}
}
 

/**
 * Selectors
 */

export const selectAll = state => state.data.shippingZones;
 
export const selectDesignerShippingZones = state => state.data.shippingZones.shippingZones;
export const selectContinents = state =>  state.editShippingZone.countries.continents;

export const selectShippingZoneCountriesExceptCurrent = createSelector(
	[selectReturnSomething,selectDesignerShippingZones],
	(id, designerShippingZones) => {

		console.log('selectShippingZoneCountriesExceptCurrent selector is running');
		//see if there are more than 1 zone 
		if(designerShippingZones.length > 1) {
			//get list of country Ids of the other zones

			var zones = designerShippingZones.filter( zone => zone.id !== id)
			
			var countryIdArray = [];

			zones.map( zone => {

				let idArray = zone.countries.map( country => {
					return country.id
				})
				console.log('idArray: ', idArray)
				countryIdArray = countryIdArray.concat(idArray);
			})

			//(remove duplicates?)
			console.log('countryIdArray')
			return countryIdArray;
			
			  
		}else{
			console.log('designerShippingZones.length is ' + designerShippingZones.length)
		}
 
	}
	  
)

	/*		expects argument - array of otherZoneCountries

			Disabled countries are determined by the otherZoneCountries (countries
			in the users' other zones, which are available )
	*/
 
// export const selectDisabledCountryIds = createSelector(
// 	[selectReturnSomething,selectContinents],
// 	(otherZoneCountries, continents) => {
 
// 		let disabledCountries = [];
		  
// 			//set disabled on taken countries
// 			Object.values(continents).forEach( continent => 

// 				Object.values(continent).map( country => {

// 					//see if matching any taken country ids
// 					otherZoneCountries.map( takenCountryId => {

// 						if(takenCountryId == country.id) {
// 							disabledCountries.push(takenCountryId);
// 							return ;
// 						}
// 					})
// 				})
// 			)
				
		
		
// 	}
// )
 