import * as types from './actionTypes';

export const openLoginModal = () => ({
	type: types.MODAL_OPEN_LOGIN,
});

export const closeLoginModal = () => {
	return({
		type: types.MODAL_CLOSE_LOGIN,
	});
};
 
export const toggleSearchField = () => ({
	type: types.TOGGLE_SEARCH_FIELD,
});