import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ReactDropzone from 'react-dropzone';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';

//see https://react-dropzone.js.org/



class DaylicacyDropzone extends Component {

	constructor(props){
		super()

		
	}
	 
	// onCrop = (file) => {
	// 	const newFiles = [...files, file]
	// 	field.input.onChange(newFiles)
	// }
	

	render(){

		const { 
			name, 
			maxFileSize, 
			acceptedFileTypesArray,
			rejectedFilesErrors, 
			fileFieldError, 
			onDrop,
			filenames,
			dropZoneErrorMessage,
			maxNumFiles
		} = this.props;
	 
		const dropZoneMessage = maxNumFiles > 1 ? 'Drop files here or click to upload.' :  'Drop a file here or click to upload.';

		console.log('Dropzone: error is: ' + fileFieldError);
		return(
			<ReactDropzone 
				maxSize={maxFileSize}
				className="dropzone-style"
				multiple={true}
				name={name}
				onDrop={onDrop}
				accept={acceptedFileTypesArray}
				// style={{ 
				// 	border: '2px dashed rgb(255, 64, 129)',
				// 	textAlign: 'center',
				// 	padding: '24px',
				// 	marginBottom: '16px',
				// 	backgroundColor: 'white',
				// }}
				activeStyle={{
					backgroundColor: 'rgb(208, 113, 145)',
					opacity: '0.6',
				}}
			>
				<h4 style={{ 
					marginTop: 0,
					marginBottom: '8px',
				}}>
					{dropZoneMessage &&
					<p>{dropZoneMessage}</p>
					}
				</h4>
				{maxNumFiles && (maxNumFiles > 1) &&
					<div>Max {maxNumFiles} files</div>
				}
				
					{fileFieldError && <p className="formalert">{fileFieldError}</p>}
					{dropZoneErrorMessage && <p> {dropZoneErrorMessage}</p> }
				
					{rejectedFilesErrors && rejectedFilesErrors.map( (fileError, i) => 
						<p key={i} className="formalert">{fileError}</p>)}
				
			</ReactDropzone>
		);
	}
};

		// <ImagePreview 
		// 	src={ file.preview }
		// 	crop={ true }
		// 	onCrop={ onCrop }
		// />

DaylicacyDropzone.propTypes = {
	onDrop: PropTypes.func.isRequired,
	name: PropTypes.string
};

//connect to the form to be able to get form values
DaylicacyDropzone = reduxForm( {
	form: 'createProductForm'
} )( DaylicacyDropzone )

const selector = formValueSelector( 'createProductForm' ) 

DaylicacyDropzone = connect(
	state => ({
		formImages: selector(state, 'images')
	})
)( DaylicacyDropzone )

export default DaylicacyDropzone;