import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import T, { Table } from 'components/Table';
import { selectCustomerOrderLines as selectOrderLines } from 'data/modules/orderLines';
import Image from 'components/Image';
import { Link } from 'react-router-dom';
import { requestData } from 'data/actions';

const tableHeaders = [
	'Date',
	'Order line nb',
	'Invoice',
	'Designer',
	'Product Description',
	'Estimated Delivery Date',
	'Status',
];

class ListOrders extends Component {

	constructor(props) {
		super(props);

		this.props.requestData({
			type: 'customerOrders',
			args: {
				customerId: this.props.customerId,
			},
		});
	}

	render() {
		return (
			<Table>
				<T.Head>
					<T.TR>
						{tableHeaders.map((header, i) => (
							<T.TH key={i}>
								{header}
							</T.TH>
						))}
					</T.TR>
				</T.Head>
				<T.Body>
					{this.props.orderLines.map(orderLine =>
						<T.TR key={orderLine.id}>
							<T.TD>
                                {orderLine.order.date}
							</T.TD>
							<T.TD>
                                {orderLine.id}
							</T.TD>
							<T.TD>
                                {orderLine.id}
							</T.TD>
							<T.TD>
                                {orderLine.order.designer.shop_name}
							</T.TD>
							<T.TD>
                                {orderLine.product.description.substring(0, 20)}
							</T.TD>
							<T.TD>
                                {orderLine.estimated_delivery}
							</T.TD>
							<T.TD>
                                {orderLine.order.lines.map((line, i) => <span key={i}>{line.delivery_status},</span>)}
							</T.TD>
						</T.TR>
					)}
				</T.Body>
			</Table>
		);
	}

}

export default connect(
	state => ({
		customerId: state.session.userId,
        orderLines: selectOrderLines(state, state.session.userId),
	}),
	{ requestData }
)(ListOrders);
