


/* get 'state' item in localStorage
        Is run in store.js
*/
export const loadState = () => {

    try{ 
        const serializedState = localStorage.getItem('state');
        if(serializedState === null){
            return undefined;
        }
        
        return JSON.parse(serializedState);
    }catch(err){
        return undefined;
    }

}
 
//run in store.js
export const loadFromSessionStorage = () => {

    try{

        const serializedProductData = sessionStorage.getItem('products');
        if(serializedProductData === null){
            return undefined;
        }

        return JSON.parse(serializedProductData);

    }catch(err){
        return undefined;
    }
}
 
export const saveToSessionStorage = (stateData) => {

    try{
        const serializedSessionStorage = JSON.stringify(stateData);
        sessionStorage.setItem('products', serializedSessionStorage);

    }catch(err){
        console.log('error in saveState: ' + err);
    }
}


export const saveState = (state) => {

    try{
        const serializedState = JSON.stringify(state);
        localStorage.setItem('state', serializedState);
        // console.log('saveState: new state saved to localStorage')
    }catch(err){
        console.log('error in saveState: ' + err);
    }
}

 