export const OPEN_SNACKBAR = 'OPEN_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';

export const openSnackbar = ({ message, autoHideDuration = 5000}) => ({
	type: OPEN_SNACKBAR,
	payload: {
		message,
		autoHideDuration,
	}
});

export const closeSnackbar = () => ({
	type: CLOSE_SNACKBAR,
});