import React from 'react';
import DaylicacyFavoriteButton from './DaylicacyFavoriteButton';
import DaylicacyUploadButton from './DaylicacyUploadButton';
import NextButton from './NextButton';
import PrevButton from './PrevButton';
import Button from '@material-ui/core/Button';
//import Button from 'material-ui-next/Button';
import styled from 'styled-components';

export {
	DaylicacyFavoriteButton,
	DaylicacyUploadButton,
	NextButton,
	PrevButton,
};

 
const DaylicacyButton = (props) => {
	const { buttonType } = props;
	const { label, type, onClick, href, children } = props;

	if(buttonType === 'flat') {
		return(
			<Button 
				type={type}
				
			>
			{label}
			</Button>
		);
	} else {
		return(
			<Button 
				style={{borderRadius: '2px'}}
				variant="raised"
				type={type}
				color="secondary" 
				href={href} 
				{...props}
			>
				{ children }
			</Button>
		);
	}
};

export default DaylicacyButton;
 