import React from 'react';
//import { MenuItem } from 'material-ui-next/Menu';
import  MenuItem  from '@material-ui/core/MenuItem';

//import Select from 'material-ui-next/Select';
import Select from '@material-ui/core/Select';

/*
value="nooption"

*/

/*
sortOptions = [
        {
                value="nooption",
                lanel="",
                disabled="disabled"
        }
]
*/
const renderSortOptions = (option,index) => {
         
        return(
                <MenuItem 
                        key={`${option.value_}${index}`}
                        value={option.value}  
                        disabled={option.disabled ? true : false }  
                >
                        {option.label}
                </MenuItem>  
        )
}


const DaylicacySort = ({ value, onChange, sortOptions }) => {
	return(
                <Select 
                className="mui-select-bottom-border"
                value={value} 
                onChange={(e) => onChange(e, value)}
		>
                {sortOptions &&
                        sortOptions.map(renderSortOptions )
                } 
		</Select>
	);
};

export default DaylicacySort;