import localStorage from 'localStorage';
import moment from 'moment';
import * as types from './actionTypes';
import { REQUEST_UPDATE_DESIGNER_SUCCESS } from 'views/Account/MyShop/ShopSetup/actionTypes';
import {SET_LANGUAGE_POPOVER_CONFIRMED} from 'containers/Session/actionTypes';

import {normalize} from 'normalizr';
import {addressSchema} from 'data/schemas';
import {cl} from 'utils'
import {types as actionTypes} from 'views/Account/MyShop/Shipping/containers/CreateEditShippingGeneral/actions';



//state.session
const initialState = {
	language: 'en',
	delivery_country_id: null,
	delivery_country_name: null,
	delivery_country_code:null,
	languagePopoverConfirmed: false,
	currency: 'EUR',
	signedIn: false,
	user: {
		designer: {
			account: {},
			shop_currency:{}
		},
		addresses: []
	}
	
}
//    !!(session && session.token && moment().isBefore(moment.unix(session.token.expires))),
// 	...session, 
//(JSON.parse(localStorage.getItem('session')));

const reducer = (state = initialState, action) => {

	switch(action.type) {

		case types.REQUEST_USER_SIGN_IN:
			return Object.assign({}, state, {
				isLoading: true,
			});

		case types.REQUEST_USER_SIGN_IN_SUCCESS:
 
			console.log('the response (action.result): ', action.result);
			//normalize addersses
			var normalizedUserAddresses = normalize(action.result.data.user.addresses, [addressSchema]);
			console.log('normalized user result:', normalizedUserAddresses);
			console.log('state ');
			console.log(JSON.stringify(state));
			var stored = {

				...state,

				userId: action.result.data.user.id, 
				user: {
					...state.user,

					...action.result.data.user,
					addresses: normalizedUserAddresses.result,

					designer: {
						// ...state.user.designer,

						...action.result.data.user.designer 
					}
				},
				addresses: normalizedUserAddresses.entities.addresses,
				addressTypes: normalizedUserAddresses.entities.addressTypes,
				designerId: action.result.data.user.designer ?
					action.result.data.user.designer.id : null,
				
				token: {
					value:  action.result.data.token,
					expires: action.result.data.expires,
				},
			};
			console.log('stored ');
			console.log(JSON.stringify(stored));

			localStorage.setItem('session', JSON.stringify({token: stored.token}));
			
			return Object.assign({}, state, {
				...stored,
				signedIn: true
			});

		case types.REQUEST_USER_SIGN_IN_FAILED:
			return Object.assign({}, state, {
				signedIn: false,
				// errorMsg: action.error.response.data.message,
				 
			});

		case types.USER_SIGN_OUT:
			console.log('sign out - session is deleted');
			localStorage.removeItem('session');
			
			return Object.assign({}, state, { 
				...initialState,
				signedIn: false,
				user: undefined,
				designerId: undefined,
				designer: undefined,
				token: undefined,
				userId:undefined,
				languagePopoverConfirmed:true		//keep it true (prevent popup)
			});
		case types.LANGUAGE_CHANGE:
			console.log('Language changed');
			return Object.assign({}, state, {
				language: action.language,
			});

		case types.COUNTRY_CHANGE:
			return Object.assign({}, state, {
				delivery_country_id: action.payload.countryId,
				delivery_country_name: action.payload.countryName,
				delivery_country_code: action.payload.countryCode,
			});
 
        /* case types.REQUEST_DATA2:
            return Object.assign({}, state, {
                country: action.country,
            }); */

		case types.CURRENCY_CHANGE:
        	console.log('Currency changed');

			return Object.assign({}, state, {
				currency: action.currency,
			});
            
		case types.UPDATE_USER_SUCCESS:
			cl('UPDATE_USER_SUCCESS')
			cl(action.result)
			cl(action.result.data.avatar , 'avatar is : ')

			var formData = { 

				...state,

				user: {

						...state.user,

						name: 						action.result.data.name,
						email:						action.result.data.email ,
						company_org_number:			action.result.data.company_org_number,
						company_name:				action.result.data.company_name,
						title_id: 					action.result.data.title_id,
						first_name:  				action.result.data.first_name,
						last_name: 					action.result.data.last_name ,
						birth_date: 				action.result.data.birth_date , 
						phone_numbers:				action.result.data.phone_numbers,
						avatar_upload_id: 					action.result.data.avatar_upload_id,
						phone: 						action.result.data.phone,
						full_name: 					action.result.data.full_name,
						avatar:		{
							...action.result.data.avatar
						}				
				}
			}
			// let currentSessionStorage = JSON.parse(localStorage.getItem("session"));

			//overwrite existing values
			// for (var k in formData) {
			// 	currentSessionStorage.user[k] = formData[k];
			// }
			//update localStorage - not necessary??
			// localStorage.setItem('session', JSON.stringify(currentSessionStorage));

			return Object.assign({}, state, formData);
	 
		case types.UPDATE_USER_TOKEN: 
			cl('reducer - updating user token')

			return  Object.assign({}, state, {
				...state, 
				user: {
					...state.user,
					token: {
						value: action.data.value,
						expires: action.data.expires
					} 
				}
				
			});

		case types.UPDATE_USER_ADDRESSES_SUCCESS:
			
			console.log('session reducer is running for UPDATE_USER_ADDRESSES_SUCCESS');
			 
			return  Object.assign({}, state, {

				user: {
					...state.user,

					addresses: [
						...state.user.addresses,
						...action.result[0]
					]
				}
			});


		//action.result is the designer object 
		case REQUEST_UPDATE_DESIGNER_SUCCESS:
		console.log('REQUEST_UPDATE_DESIGNER_SUCCESS, here is action.resylt: ',action.result )

			const {shop_currency, account} = action.result;

			var updatedDesigner = {

				...state,

				user: {

					...state.user,

					designer: {

						...state.user.designer,
	
						account: {
							
							...state.user.designer.account,
							...account
							// id: account.id || state.user.designer.account.id ,
							// bank: account.bank || state.user.designer.account.bank ,
							// clearing: account.clearing || state.user.designer.account.clearing,
							// country_id: account.country_id || state.user.designer.account.country_id,
							// currency_id: account.currency_id || state.user.designer.account.currency_id,
						},
						shop_slug: action.result.shop_slug || state.user.designer.shop_slug,
						shop_name:  action.result.shop_name || state.user.designer.shop_name,
						shop_banner: {
							...action.result.shop_banner
						},
						shop_banner_id:  action.result.shop_banner_id || state.user.designer.shop_banner_id,
						shop_currency_id: action.result.shop_currency_id || state.user.designer.shop_currency_id,
						shop_notice:  action.result.shop_notice || state.user.designer.shop_notice,
						permalink:  action.result.permalink || state.user.designer.permalink,
						shop_currency: {
	
							...state.user.designer.shop_currency,
	
							id: shop_currency.id || state.user.designer.shop_currency.id, 
							name: shop_currency.name || state.user.designer.shop_currency.name,
							code: shop_currency.code || state.user.designer.shop_currency.code,
							symbol: shop_currency.symbol || state.user.designer.shop_currency.symbol,
							format: shop_currency.format || state.user.designer.shop_currency.format,
							visible: shop_currency.visible || state.user.designer.shop_currency.visible
						}
					}
				}
				
				
				//??? commenting out this:
				// userId: state.userId,
				// designerId: action.result.data.id,
				// token: state.token,
			}
			console.log('this will be added: ', updatedDesigner)
			//update localStorage
			// localStorage.setItem('session', JSON.stringify(updatedDesigner));

			//update state
			return Object.assign({}, state, {
				...updatedDesigner,
			});


		// Action from CreateEditShippingGeneral. 
		// result contains general shipping methods (after update)
		case actionTypes.UPDATE_SHIPPING_GENERAL_SUCCESS:
			console.log('session reducer: UPDATE_SHIPPING_GENERAL_SUCCESS');

			return Object.assign({}, state, {
				...state,
				user: {
					...state.user,
					designer: {
						...state.user.designer,
						shipping_general_methods : action.payload.result.data
					}
				} 
			});

		case SET_LANGUAGE_POPOVER_CONFIRMED:
			cl('setting SET_LANGUAGE_POPOVER_CONFIRMED to true')
			return {
				...state,
				languagePopoverConfirmed: true
			}
		default:
			return state;
	}
};

export default reducer;

/*
     'name',
		'email',
		'password',
		'company_org_number',
		'company_name',
		'title_id',
		'first_name',
		'last_name',
		'birth_date',
		'email',
		'phone_numbers',
		'phone',
		'avatar_upload_id', 

		*/