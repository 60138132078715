import React from 'react';
import PropTypes from 'prop-types';
import DaylicacyProductTable from './DaylicacyProductTable';
import {
	Table as MaterialTable,
	TableBody as MaterialTableBody,
	TableHeader as MaterialTableHeader,
	TableHeaderColumn as MaterialTableHeaderColumn,
	TableRow as MaterialTableRow,
	TableRowColumn as MaterialTableRowColumn,
} from 'material-ui-old/Table';

export const Table = props => {
	return (
		<table>
			{props.children}
		</table>
	);
};

export const Head = props => {
	return (
		<MaterialTableHeader>
			{props.children}
		</MaterialTableHeader>
	);
};

export const Body = props => {
	return (
		<MaterialTableBody>
			{props.children}
		</MaterialTableBody>
	);
};

export const TH = props => {
	return (
		<MaterialTableHeaderColumn
			style={Object.assign({},
				{
					paddingLeft: '1em',
					paddingRight: '1em',
				},
				props.style
			)}
		>
			{props.children}
		</MaterialTableHeaderColumn>
	);
};

export const TR = props => {
	return (
		<MaterialTableRow>
			{props.children}
		</MaterialTableRow>
	);
};

export const TD = props => {
	return (
		<MaterialTableRowColumn>
			{props.children}
		</MaterialTableRowColumn>
	);
};

Table.PropTypes = {
	sortable: PropTypes.bool,
	children: PropTypes.node.isRequired,
};

export {
	DaylicacyProductTable
};

export default {
	Table,
	Body,
	Head,
	TH,
	TR,
	TD,
};