import { createSelector } from 'reselect';

const getTable = state => state.productTable;

export const getProducts = createSelector(
	getTable,
	table => table.products
);

export const getVisibleProducts = createSelector(
	getProducts,
	products => products
);

export const getIsLoading = createSelector(
	getTable,
	table => table.isLoading
);

export const getSelectedProduct = createSelector(
	getTable,
	table => table.selectedProduct
);
