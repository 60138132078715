import { call, put, takeLatest } from 'redux-saga/effects';
import api from 'data/api';
import { types, actions } from './actions';

function* fetchShippingProfiles(action) {
	try{
		const result = yield call(api.fetchShippingProfiles, action.payload.designerId);
		yield put(actions.fetchShippingProfilesSuccess(result));
	} catch(error) {
		yield put(actions.fetchShippingProfilesFailure(error));
	}
}

export default function* listenerSaga() {
	yield takeLatest(types.FETCH_SHIPPING_PROFILES, fetchShippingProfiles);
}
