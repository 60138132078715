import React from 'react';
import PropTypes from 'prop-types';

const getClassNames = props => {
	let classNames = '';

	if (props.xs) {
		classNames += 'cx' + props.xs;
	}

	if (props.sm) {
		classNames += ' cs' + props.sm;
	}

	if (props.md) {
		classNames += ' cm' + props.md;
	}

	if (props.lg) {
		classNames += ' cl' + props.lg;
	}

	if (props.xl) {
		classNames += ' cxl' + props.xl;
	}

	if (!props.xs && !props.sm && !props.md && !props.lg && !props.xl) {
		classNames += 'cx';
	}

	return `${props.className && props.className} ${classNames}`;
};

const getStyles = props => {
	const styles = {};

	switch (props.align) {
		case 'left':
			styles.textAlign = 'left';
			break;
		case 'right':
			styles.textAlign = 'right';
			break;
		case 'center':
			styles.textAlign = 'center';
			break;
	}

	if (props.gutter) {
		styles.paddingLeft = props.gutter + 'em';
		styles.paddingRight = props.gutter + 'em';
	}

	return styles;
};

const Col = props => {
	return (
		<div 
			className={getClassNames(props)}
			style={Object.assign({}, 
				getStyles(props),
				props.style
			)}>
			{props.children}
		</div>
	);
};

Col.propTypes = {
	children: PropTypes.node.isRequired,
	xs: PropTypes.number,
	sm: PropTypes.number,
	md: PropTypes.number,
	lg: PropTypes.number,
	xl: PropTypes.number,
	gutter: PropTypes.number,
};

export default Col;