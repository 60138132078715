import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import StarRating from 'containers/StarRating';
import Image from 'components/Image';
import ActionBar from 'containers/ActionBar';
import ProductCard from 'containers/ProductCard';

const DaylicacyProductCard = () => <div>card</div>;

const DesignerListItem = props => {

	const sliderSettings = {
		dots: false,
		speed: 500,
		slidesToShow: 4,
		slidesToScroll: 4,
		arrows: false,
	};

	const cardStyles = {
		paddingLeft: '10px',
		paddingRight: '10px',
		paddingTop: '5px',
		paddingBottom: '5px',
	};

	
	return(
		<li className="list-item cx12 cm6">
		
				<div className="designer-card cx12">
					<div className="row">
						<div className="cx4">
							<div className="avatar">
								<Link to={props.permalink}>
									<Image src={props.avatar} />
								</Link>
							</div>
						</div>
						<div className="cx8">
							<div className="designer-card__details">
								<div className="designer-name">
									<Link to={{
										pathname: `${props.permalink}`,
										state: {
											designerId: props.designerId
										}
									}}>
										{props.name}
									</Link>
								</div>
								<div className="designer-rating">
									<StarRating 
										rating={props.rating}
										numRatings={props.numRatings}
									/>
								</div>
								<div className="designer-country">
									{props.country}
								</div>
								<div className="designer-num-creations">
									{props.numCreations} creations in the shop
								</div>
								<div>
									{props.numCreationsSold} sold creations
								</div>
								<div className="designer-actions">
									<ActionBar />
								</div>
							</div>
						</div>
					</div>
				</div>
			
		</li>
	);
};

DesignerListItem.propTypes = {
	name: PropTypes.string.isRequired,
	rating: PropTypes.number.isRequired,
	numRatings: PropTypes.number.isRequired,
	country: PropTypes.string.isRequired,
	numCreations: PropTypes.number.isRequired,
	numCreationsSold: PropTypes.number.isRequired,
	numFavorites: PropTypes.number.isRequired,
	permalink: PropTypes.string.isRequired,
	products: PropTypes.arrayOf(PropTypes.shape({
		featured_image: PropTypes.object.isRequired,
		price: PropTypes.string.isRequired,
		reserved: PropTypes.bool.isRequired,
		permalink: PropTypes.string.isRequired,
		sale: PropTypes.number.isRequired,
	})),
};

export default DesignerListItem;
