import { createSelector } from 'reselect'

export const getErrorMsg = state => state.creations.createProduct.errorMsg;
export const getStatus = state => state.creations.createProduct.status;
export const getIsLoading = state => state.creations.createProduct.isLoading;
 
export const getTerms = state => state.creations.createProduct.terms;

// const getTermNodeId = props => props.termNodeId;
const getTermNodes = state => state.data.terms.termNodes;

const getTermNode = (state, termNodeId) => state.data.terms.termNodes[termNodeId] || '';

 
// export const rootChildren = (termNodeId) => createSelector(
//     getTermNode(termNodeId),
//     termNodeId,
// 	termNode => {

//         if (termNodeId in getTermNodes) {

//             return termNode.children.map(id => {            //mappar termens children . returnerar array med children

//             const node = termNode.termNodes[id];
//                 const term = termNode.terms[node.term];
            
//             return {
//                 id,
//                 term,
//             };
//             });
//         }else{
//             return "no termNodeId in getTermNodes";
//         }
        
//     }
// ); 


/* previously in CreationForm 

const rootChildren = (state, context) => {
	const module = state.data.terms;
	if (context in module.termNodes) {
		return module.termNodes[context].children.map(id => {
			const node = module.termNodes[id];
			const term = module.terms[node.term];
			
			return {
				id,
				term,
			};
		});
	}
	console.log('categories retrieved from state');
	return [];
};

*/
 