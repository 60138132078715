import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';

import {Select} from 'redux-form-material-ui';
import MenuItem from '@material-ui/core/MenuItem';

import DaylicacyTextField from 'components/Form/DaylicacyTextField';
import DaylicacyButton from 'components/DaylicacyButton';
import { connect } from 'react-redux';

const months = [
    'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
]
var d = new Date();
var startYear = d.getFullYear() -1;
 

class PaymentForm extends Component {

	constructor(props) {
		super(props);
	}

	render() {
		const { handleSubmit, onSubmit, tr } = this.props;
		
		return(
                <div className="row margin-bottom1">
                    <div className="cx12 mui-bottom-border">
                        <Field 
                            className="cx12 mui-field-bottomspace" 
                            type="text"
                            component={DaylicacyTextField}
                            label= {tr.card_number || 'Card number'}
                            name="card_number"
                            number={true}
                        />
                    </div>
                    <div className="cx12">
                        <Field
                            className="mui-select-bottom-border mui-field-bottomspace"
                            component={Select}
                            label={tr.expiration_month}
                            placeholder={tr.expiration_month}
                            name="card_expiry_month"    
                        >
                             <MenuItem
                                    className="mui-greyed"
                                    key="card_expiry_month_placeholder"
                                    value="exp_month_placeholder"
                                    disabled
                                    >
                                    {tr.expiration_month}
                                </MenuItem>
                          {
                                months.map( (month, index) => 
                                <MenuItem
                                    key={`card_expiry_month_${month}`}
                                    value={String(index +1)} 
                                    >
                                    {month}
                                </MenuItem>
                                )
                            }
                        </Field>
                    </div>
                    <div className="cx12">
                        <Field
                            className="mui-select-bottom-border mui-field-bottomspace"
                            placeholder={tr.expiration_year}
                            component={Select}
                            label={tr.expiration_year}
                            name="card_expiry_year"
                        >
                                <MenuItem
                                    className="mui-greyed"
                                    key="card_expiry_year_placeholder"
                                    value="exp_year_placeholder"
                                    disabled
                                    >
                                    {tr.expiration_year}
                                </MenuItem>

                            {
                                [1,2,3,4,5,6,7,8,9,10].map( num => 
                                <MenuItem
                                    key={`card_expiry_year_${startYear + num}`}
                                    value={String(startYear + num)} 
                                    >
                                    {startYear + num}
                                </MenuItem>
                                )
                            }
                        </Field>
                    </div>
                    <div className="cx12 mui-field-bottomspace">
                        <Field 
                            className="mui-bottom-border"
                            type="text"
                            component={DaylicacyTextField}
                            label={tr.security_code}
                            name="card_cvc"
                            maxLength="3"
                            number={true}
                        />
                    </div>
                    <div className="cx12">
                        <DaylicacyButton className="cx12" type="submit">
                            {tr.pay || 'Pay'}
                        </DaylicacyButton>
                    </div>
                </div>
		);
	}

}


PaymentForm = connect(
    state => ({
      
        tr: state.translations,
    })
)(PaymentForm);

export default PaymentForm;
