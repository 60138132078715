import sessionSaga from 'containers/Session/sagas';
import productSaga from 'hoc/product/sagas';
import checkoutSaga from 'views/Checkout/sagas';
import translationsSaga from 'containers/LanguageProvider/sagas';
import formActionSaga from 'redux-form-saga';
import shopSetupSaga from 'views/Account/MyShop/ShopSetup/sagas';
import imprintSaga from 'views/Account/MyShop/Imprint/sagas';
import updateUserSaga from 'views/Account/MyAccount/sagas';

import createProductSaga from 'containers/CreateProduct/sagas';
import editProductSaga from 'containers/EditProduct/sagas';
//import designerSaga from 'hoc/designer/sagas'
import dataSaga from 'data/sagas';
import createEditShippingZone from 'views/Account/MyShop/Shipping/containers/CreateEditShippingZone/sagas';
import ShippingZones from 'views/Account/MyShop/Shipping/containers/ShippingZones/sagas';
import ShippingZonesModule from 'data/modules/ShippingZones';
import editShippingProfile from 'views/Account/MyShop/Shipping/containers/EditShippingProfile/sagas';
import listShippingProfiles from 'views/Account/MyShop/Shipping/containers/ListShippingProfiles/sagas';
import editShippingGeneral from 'views/Account/MyShop/Shipping/containers/CreateEditShippingGeneral/sagas';
import customProfiles from 'views/Account/MyShop/Shipping/containers/CustomProfiles/sagas';
import filtersSaga from 'containers/Filters/sagas'; 
import filterMenuSaga from 'containers/Filters/components/sagas'; 
import chipsSaga from 'containers/FilterChipsBar/sagas';
import cartSaga from 'containers/Cart/sagas';

export default function* rootSaga() {
	yield [
		sessionSaga(),
		//productSaga(),
		formActionSaga(),
		checkoutSaga(),
		translationsSaga(),
		shopSetupSaga(),
		imprintSaga(),
		updateUserSaga(),
		createProductSaga(),
		editProductSaga(),
		//designerSaga(),
		dataSaga(),
		createEditShippingZone(),
		ShippingZones(),
		editShippingProfile(),
		listShippingProfiles(),
		editShippingGeneral(),
		customProfiles(),
		filtersSaga(), 
		filterMenuSaga(),
		chipsSaga(),
		cartSaga()
		
	];
}
