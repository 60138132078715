import React, { Component } from 'react';
import { Field, 
	reduxForm, 
	getFormValues, 
	SubmissionError, 
	isValid, 
	FormSection,
	formValueSelector,
	submit, 
	change,
	unregisterField
 } from 'redux-form';
import { Link } from 'react-router-dom';
//import { MenuItem } from 'material-ui-next/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { connect } from 'react-redux';
import Paper from 'material-ui-old/Paper';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DaylicacyButton from 'components/DaylicacyButton';
import { removeFromCart } from 'containers/Cart/actionCreators';
import {Select} from 'redux-form-material-ui';
import InputLabel from '@material-ui/core/InputLabel'; 
import FormControl from '@material-ui/core/FormControl';
import CircularProgress from '@material-ui/core/CircularProgress';
import { requestData } from 'data/actions';
import orderBy from 'lodash/orderBy';
import isEqual from 'lodash/isEqual';

import {getCartItemsByDesigner} from 'containers/Cart/selectors';
import {selectUserDeliveryAddress} from 'containers/Session/selectors'
import {selectCountryIdByName} from 'data/modules/countries';

import {cl} from 'utils';

// import PaymentForm from './PaymentForm';

const required = value => (value ? undefined : 'Required')


const validate = values => { 
       
    const errors = {};
    if (!values.shipping_method) {
        errors.shipping_method = 'Required'
      }
      if (!values.shipping_destination) {
        errors.shipping_destination = 'Required'
      }
    // const requiredFields = [
    //     'delivery_title',
    //     'delivery_first_name',
    //     'delivery_zip_code',
    //     'shipping_method',
    //     'shipping_destination'
    // ];
    // console.log('validate', values);

    // requiredFields.forEach(field => {
    //     if(!values[ field ]) {
    //         errors[ field ] = 'Required';
    //     }
    // });
    if (!values.username) {
        errors.username = 'Required'
      }

    return errors;

};

const initialStyles = {
	// gridHeader: {
	// 	background:'#D0BBD3',
	// 	padding: '16px',
	// },
	// gridBody: {
	// 	padding: '16px',
	// }
};

const EmptyMessage = styled.div`
	padding: 1em 2em;
`;

const isInArray = (id, array) => {
	// console.log('isInArray');
return array.indexOf(id) > -1;
}



/*
	- ShoppingBasket has some workarounds :
		The form is stored in local state and being compared to the form values.
		Most things happen in "componentDidUpdate"
		(The workaround possibly could have been avoided if the methods "change" or "onChange" had been used originally).

	When cart is completely filled, shipping costs are automatically retrieved:
		with action 'prepare_checkout' (modules/checkout)
		api method 'prepareCheckout'
		route: 		'checkout'
		function: 	'getCheckout'
*/
class ShoppingBasket extends Component {

	constructor(props) {
		super(props);

		this.state = {
			shippingProfileMethodTypes: [],
			locallyStoredFilledGroupMethods: [],
			filled:false,
			productRemoved: false,
			countryName: this.props.sessionDeliveryCountry || '',
			shippingCountryId: this.props.formValues && this.props.formValues.shipping_destination ? 
										this.props.formValues.shipping_destination : this.props.initialValues.shipping_destination || null,
			currentChosenShippingMethodName: null
			 
		}    
		//this.handleRemove = this.handleRemove.bind(this);
	}

	handleRemove(e, designerId, itemId) {
		cl('handleRemove!')
		 
		//name={`shipping_method[${currentDesigner.id}][${item.id}]`}

    	e.preventDefault();
    	e.stopPropagation();
		console.log('Shopping Basket handleremove: Unregister field + Remove from basket inside: designer ' + designerId + ' , item ' + itemId);
		
		/* Unregister the field for the shipping method id. 
			If it is the only item for this designer, unregister shipping_method[${designerId}] , 
			otherwise unregister the specific item -  shipping_method[${designerId}[${itemId}]]
		*/
		 //find designer object
		var targetDesigner = this.props.cartItemsByDesigner.filter( designerObj =>  designerObj.designer.id == designerId  );
		
		cl('here is targetdesigner ')
		cl(targetDesigner[0])

		cl( this.props.stateCart, ' this.props.stateCart: ')
			var methodsNum = Object.keys(this.props.stateCart.items).length;
	
			cl( methodsNum, 'Methodsnum IS ' );

		if(targetDesigner[0].items.length > 1){
			cl('designer will still have 1 or more items after removing this item. ')
			this.props.change( `shipping_method[${designerId}][${itemId}]`, null)
			// unregisterField('checkout', `shipping_method[${designerId}][${itemId}]`);
		}else{
			cl('designer will have no items after removing this item')
  
			if(methodsNum == 1){
				cl('Removing last item - cart will be empty')
				this.props.change( `shipping_method`, null)
			}else{
				cl('remove designer. ')
				this.props.change( `shipping_method[${designerId}]`, null)
				// unregisterField('checkout', `shipping_method[${designerId}]`);
			}
			
		}
		

		this.props.removeFromCart(itemId);
		
		//set productRemoved to true (causes checkCurrentFormUpdateStoredForm in componentDidUpdate)
		cl('setting productRemoved to true')
		this.setState({
			productRemoved:true
		})
	 
 
 
	};


	/*
		onSelectShippingCountry:
			- since the countries can belong to different profiles,
			the "shipping methods" options will be populated when country is selected.
			If a method is selected, and then a different country, the method might be cleared
	*/
	onSelectShippingCountry(e, countryId, countries) {

		const {cartItemsByDesigner} = this.props;

		// alert('on onSelectShippingCountry');
		 
		//get name from id
		let targetCountryName = countries.filter(country => {
			if(country.id == countryId){
				return country.name;
			}
		}); 
			 
		//See each item's "countries" array (countries collected from its zonemethods) if it has the countryId
		//If not, we need to clear the  shipping method input field for that item.
		//Form name:	name={`shipping_method[${currentDesigner.id}][${item.id}]`}
		 
		cl('onSelectShippingCountry Looping items to see if each have the country id: ' + countryId)
		cartItemsByDesigner.map( group => 
			group.items.map( item => {

				let targetCountry = false;
				item.countries.forEach( country => {
					if(country.id == countryId){
						cl('country.id ' + countryId + ' is available to item id ' + item.id)
						targetCountry = country.id;
					}
				}) 
				if(!targetCountry) {
					console.log('country.id ' + countryId + ' is NOT available to the item. ' +
						'setting select input shipping method for item id ' + item.id + ' to empty');

					//if there were missing countries
					this.props.dispatch(change(
						'checkout',
						`shipping_method[${group.designer.id}][${item.id}]`
						,'' )								// <-- set to empty
					)
 
				}

			} )
		)	 
		 
		// SET STATE: add country name + country id
		//setting country id means that Method Select dropdown is enabled
		
		cl('calling setState, setting new country name and id');
		this.setState(
			{
				countryName: targetCountryName[0].name,
				shippingCountryId: countryId
			},
			() => {
				console.log('setState callback: countryName is ', this.state.countryName)
				console.log('After setState the countryName is ' + this.state.countryName + ' and shippingCountryId ' + this.state.shippingCountryId)
			
		
		/* ====================== setState callback ====================== */
		//set value manually
		//loop designer groups and items
		 
		//LOOP GROUPS
		Object.keys(cartItemsByDesigner).map((groupId, index) => {
				 
			//LOOP GROUP ITEMS
			cartItemsByDesigner[groupId].items.map((item, index) => {
				
				const currentDesigner = cartItemsByDesigner[groupId].designer; 
				var methodsAvailable = true;

				const {shippingCountryId} = this.state;

				cl(item.name, 'rendering item: ')
				
				//return object with zonemethodtypes (note - must send country id since setState has not updated yet)
				var shippingProfileMethodTypes = this.returnProfileZoneMethodsThatMatchesCountry(item, countryId);
			

				if(shippingCountryId && !shippingProfileMethodTypes.length) {
					// Current item has no available shipping option for the chosen country - other country
					//has to be chosen , or item be removed
					console.log('item \'' + item.name + '\' does NOT ship to country id ' + shippingCountryId)
					methodsAvailable = false;

				} else {

					//sort shippingProfileMethodTypes based on lowest cost first
					console.log( item.name + 'has one or more zones containing chosen country id ' + shippingCountryId)
					
					shippingProfileMethodTypes = shippingProfileMethodTypes.sort( (a, b) => (a.charge_single - b.charge_single)   );
					
					/*  SET FORM VALUES for methods. If country has been set, set redux form value for methods to cheapest one */
					cl('SET FORM VALUES for methods: ')	

					if(shippingCountryId && this.props.initialized) { 

						cl('CHANGE: setting form value: shipping_method[' + currentDesigner.id + 
							'][' + item.id + '] with value: ' + shippingProfileMethodTypes[0].id);
						
						//shipping_method property is not set - set it
						
						this.props.change(`shipping_method[${currentDesigner.id}][${item.id}]`, shippingProfileMethodTypes[0].id);
						
					}else{
						cl('form\' not initialized - no setting new method values.')
					}
				}
			})
 
		}); // end loop groups cartItemsByDesigner

		} ); //end of setState callback

	}


	//
	// componentWillMount(){
	// 	alert('componentWillMount this.state.shippingCountryId: ' + this.state.shippingCountryId)
	// }

	componentDidMount(){
		// alert('componentDidMount. this.state.shippingCountryId: ' + this.state.shippingCountryId)
	}


	/* 
		componentDidUpdate:

		1. check if stored form values (in local state) are different than current redux form values
			 if different, step 2,3,4 will be done, otherwise none.
		2. check redux formvalues to see which designer groups are filled 
				- returns 'filled' (complete), false (none filled), array of designer ids (individual groups filled)
		3. 		- update local state with form values
		4.		- perform request of shipping costs

	*/
	componentDidUpdate() {
		//   alert('componentDidUpdate');

		//get formValues shipping_method
		let formValuesShippingMethodInputs = this.props.formValues ? this.props.formValues.shipping_method : undefined;

		if(!formValuesShippingMethodInputs){
			return;
		}
		cl('==================== componentDidUpdate ====================')
		console.log('here is formvalues shipping_method (formValuesShippingMethodInputs): ', JSON.stringify(formValuesShippingMethodInputs));

		const {cartItemsByDesigner, shippingMethodByDesignerId } = this.props;

		/*	1. When methods for one shop is filled,  request calculated cost for that order.
			If different methods from the same shop are chosen,  they will be shipped separately 
			Otherwise, 'charge_combined' cost will be used. 
		 
			Structure of this.state.locallyStoredFilledGroupMethods:

				 {
					designerId : group.designer.id,
					itemMethods: {
						[
							productId: [ formValuesShippingMethodInputs[item.designer.id][item.id] ] : 	
							methodId:	formValuesShippingMethodInputs[item.designer.id][item.id]	
						] 	
					}
				}	 

		*/
		 
		let locallyStoredFilledGroupMethods = this.state.locallyStoredFilledGroupMethods;
		var skip = false;
		var formResult = false;
		/* Exception 1:
				- If locallyStoredFilledGroupMethods is empty, do checkCurrentFormUpdateStoredForm before the state comparison below.
				If any groups are filled, the state will be identical to form values - but a request 
					still needs to be made. Workaround : set a variable "skip" to true, to skip the below state comparison
					below. Also the "check group" method will be skipped 
				If no groups are filled, it will just return (componentDidUpdate should not run again)	
				
			Exception 2: If a product has been removed ("productRemoved" is true),it needs to go through the same 
			procedure as exception 1
		*/
		cl('here is locallyStoredFilledGroupMethods: ')
		cl(locallyStoredFilledGroupMethods);

		if(!locallyStoredFilledGroupMethods.length || (Object.keys(locallyStoredFilledGroupMethods[0]).length === 0 && locallyStoredFilledGroupMethods[0].constructor === Object) || this.state.productRemoved ) {
			cl(' locallyStoredFilledGroupMethods is empty, or has empty object, OR a product has just been removed - checkCurrentFormUpdateStoredForm will be run')
			if(this.state.productRemoved){
				cl('productRemoved - product was removed - checking form result')
			}else{
				cl('Product was NOT removed')
			}
			//Local methods are empty - check current form values
			formResult = this.checkCurrentFormUpdateStoredForm(formValuesShippingMethodInputs);
			console.log('result of formResult: ' + !formResult ? 'false' : formResult)

			 if(formResult == false){
				 cl('check formResult was false - nothing filled')
				 return;
			 }else {
				 
				 var skip = true; 
			 }

		}else{
			console.log('this.state.productRemoved is not set, locallyStoredFilledGroupMethods is NOT empty: ',JSON.stringify(locallyStoredFilledGroupMethods));
		}
		 
		/* ================ Compare stored form values to current form values =================== */
		
		var identicalFormValues = true;
	
		if(!skip) {

			//first get designers from locallyStoredFilledGroupMethods and see if any new designers have been added
			var designerIds = this.state.locallyStoredFilledGroupMethods.map( storedDesigner => 
				String(storedDesigner.designerId)
			)
			//From form values, get designers where at least 1 method have been filled
			cl('From form values, get designers whose shipping methods form inputs have been filled.')
			var currentFilledOrPartlyFilledDesignerIds = [];

			Object.keys(formValuesShippingMethodInputs).map( designerIndex => {
				 console.log('looping designer ' + designerIndex)
				 
				if(formValuesShippingMethodInputs[designerIndex] !== null  
					&& formValuesShippingMethodInputs[designerIndex]  !== undefined 
					&& formValuesShippingMethodInputs[designerIndex].length) {

					var fieldsCount = 0;
					//get number of unique products for the designer ( same number of shipping method inputs)
					cartItemsByDesigner.map( group => {
						if (group.designer.id == designerIndex){
							console.log('Number of unique products for designer ' + designerIndex + ' is ' + group.items.length)
							fieldsCount = group.items.length;
							
						}
					})
					 
					//get number of filled shipping method inputs
					var existingValuesCount = 0;
					formValuesShippingMethodInputs[designerIndex].map( methodIndex => {
						cl(methodIndex, 'methodIndex is ')

						//console log:
						if(methodIndex  !== '') {
							cl('methodIndex is not empty string')
						}else{
							cl('methodIndex IS EMPTY STRING')
						}
						//addition - methodIndex must not be ''
						if(methodIndex !== null  && methodIndex !== undefined && methodIndex !== '') { 
							cl(methodIndex, 'One shipping method was found with a set form value: ')
							existingValuesCount += 1
								
						}
					})
					cl(existingValuesCount, ' number of filled shipping method inputs: ')
					
					if(fieldsCount == existingValuesCount && fieldsCount !== 0) {
						 
						//since there was a value for one or more methods, add the designer id
						console.log('all ' + fieldsCount + ' method fields for this designer was filled - adding designer id ' + designerIndex);
						currentFilledOrPartlyFilledDesignerIds.push(designerIndex);
					}else{
						console.log('Out of ' + fieldsCount + ' shipping method inputs, only ' + existingValuesCount + ' were filled - skipping designer.');
						if(fieldsCount == 0 ){
							cl(' fieldsCount match existingValuesCount but fieldsCount IS 0')
						}
					}
				}else{
					cl('formValuesShippingMethodInputs[designerIndex] was null or undefined');
				}
				 
			
			}
		
		)
		console.log(' the locally stored designer ids: ', designerIds);
		console.log('currently filled DesignerIds in form values: ', currentFilledOrPartlyFilledDesignerIds);
		//compare the arrays
		if( isEqual(currentFilledOrPartlyFilledDesignerIds.sort(), designerIds.sort() )) {
			cl('isEqual: designers is equal ');
			//designers is equal - check items
			this.state.locallyStoredFilledGroupMethods.map( storedDesigner => {		
		
				console.log('current designer is ' + storedDesigner.designerId);
		
					//match stored designer id with designer id the form values array
					
				if(typeof formValuesShippingMethodInputs[storedDesigner.designerId] !== 'undefined' ) {
		
					console.log('the designer' + storedDesigner.designerId + ' is set')
					var targetDesigner = formValuesShippingMethodInputs[storedDesigner.designerId];
					//the designer is set. Check if items are set, and see if value have changed

					/* Designer object structure:
						{
							"designerId":6,
							"itemMethods": [
								{ productId: "136" },
								{ methodId: "2" }
								]
						}, */
		
					//Loop stored designer items
					// Match stored item method id's with item method id's of form values array
					storedDesigner.itemMethods.map( itemMethod => {

						console.log('stored item id to be matched: ' + itemMethod.productId);

						if(typeof targetDesigner[itemMethod.productId] !== 'undefined' 
							&& targetDesigner[itemMethod.productId] !== null) {

							console.log(' product id' + itemMethod.productId + ' was found in the form values array');

							//finally, check if the value have changed
							if(itemMethod.methodId != targetDesigner[itemMethod.productId]){
							identicalFormValues =false;
							}
						} else {
							cl('that product id was not found in the form values array');
							identicalFormValues =false;
							
												
						}

						//return itemMethod !== formValuesShippingMethodInputs[item.designer.id][item.id];		
		
					} )		
						
				} else{
						console.log('the designer id ' + storedDesigner.designerId + ' is not set');
						identicalFormValues =false;
						/* console.log('identicalFormValues is : ' + identicalFormValues); */
				}
		
				} )
			}else{
				//designers are not equal
				cl('isEqual: designers are not equal ');
				identicalFormValues = false;
			}
		}else{
			//if skipping, identicalFormValues must be set to false
			identicalFormValues = false;
		} 
		if(identicalFormValues){
			//no check needed
			cl('the form values are identical');
			cl('----------------------- end -----------------------')
			return;
		}else{
			if(!skip){
				cl('the form values are different - form will be checked');
			}
			
		}
	    
		//Form values are different to local group methods - update local values with form values  
		if(!skip){
			formResult = this.checkCurrentFormUpdateStoredForm(formValuesShippingMethodInputs);
		} 
  
		if(!formResult) {
			//nothing is filled
			cl('check form: nothing filled');
			if(this.state.filled == true){
				// cl('setting state: filled = false (was true)')
				if(this.state.productRemoved){
					cl('productRemoved was true - will be set to false')
				}
				this.setState({
					filled:false,
					productRemoved:false
				})
			} 
			return;
		}
 
		// alert('formResult: ' + formResult);
		//check if already fetching
		
		cl('about to run loadingProcessedCart, if not already fetching')
		if(this.props.loadingProcessedCart) {
				cl('fetching is already ongoing');
				 if(this.state.productRemoved){
					cl('productRemoved will be set to false')
					this.setState({
						productRemoved:false
					})
					cl('returning');
					return;
				}
				
		}
		
		//loop designer groups, create object with designer, items, method, destination
 
		var partialCart = false;

		if(formResult !== 'filled') {  
			//one or more groups are filled, but not all

			console.log('form is partially filled with these deisgners: ', formResult);
			
			 partialCart = cartItemsByDesigner.filter( group => {
 
					let matchingDesigner = formResult.filter( designerId => 
						designerId == group.designer.id
					)
					 if(matchingDesigner.length){
					 	console.log('matchingDesigner : ' + matchingDesigner)
					 }else{
						console.log('designer ' + group.designer.id + ' will NOT be added');
					 }
					return matchingDesigner.length;
				
			})
			
			if (this.state.filled == true){
				// cl('setting state: filled = false (was true)')
				if(this.state.productRemoved){
					cl('productRemoved was true - will be set to false')
				}
				this.setState({
					filled: false,
					productRemoved:false
				})
			} 
		}

		const cart = (partialCart ? partialCart : cartItemsByDesigner).map(group => ({

				designer: group.designer.id,

				items: group.items.map(item => {

					let methodTypeId = formValuesShippingMethodInputs[group.designer.id][item.id];
					let profile = item.shipping_profiles[0];
					let profileId = profile.id;
					
					/* 	Find profileZoneMethod of the profile:
					 *  Big workaround: Find by using shippingCountryId and methodId.
					*/
					console.log(' to find profileZoneMethod: methodTypeId is ' + methodTypeId)
					var profileZoneMethods = [];

					profile.methods.filter( profileMethod => {

						if(profileMethod.method.id == methodTypeId) {
							cl('match: profileMethod.method.id == methodTypeId')
							profileZoneMethods.push({
								profileMethod : profileMethod,
								method : profileMethod.method.id
							});
						}
					});
					let targetProfileZoneMethodId = '';
					let zoneId = '';
					
					//Were there more than 1 method with the same methodId?
					console.log('resulting length of profileZoneMethods: '  + profileZoneMethods.length ); 

					if(profileZoneMethods.length > 1) {
						cl('there are more than 1 profileZoneMethods');

						//take the profileMethod whose zone includes the chosen country
						profileZoneMethods.map( profileZoneMethod => {
							console.log('this.state.shippingCountryId is ' + this.state.shippingCountryId);

							//check if no zone (all countries)
							if(profileZoneMethod.zone == null || profileZoneMethod.zone == 0){
									cl('profileZoneMethod: ' + profileZoneMethod.profileMethod.id + ' has all countries');
								 
									targetProfileZoneMethodId = profileZoneMethod.profileMethod.id;
									zoneId = 0 //profileZoneMethod.profileMethod.zone.id;
							}
							profileZoneMethod.profileMethod.zone.countries.map( country => {
								if(country.id == this.state.shippingCountryId) {
									cl('the country id was found in a profileZoneMethod: ' + profileZoneMethod.profileMethod.id);
								 
									targetProfileZoneMethodId = profileZoneMethod.profileMethod.id;
									zoneId = profileZoneMethod.profileMethod.zone.id;
								}
							} )
						})

					}else{  
						targetProfileZoneMethodId = profileZoneMethods[0].profileMethod.id;
						console.log('targetProfileZoneMethodId: ' + targetProfileZoneMethodId);
						//check if no zone (all countries)
						if(profileZoneMethods[0].zone == null || profileZoneMethods[0].zone == 0) {
							cl('profileZoneMethod: ' + profileZoneMethods[0].id + ' has all countries');
						  
							zoneId = 0 //profileZoneMethod.profileMethod.zone.id;

						}else{
							zoneId = profileZoneMethods[0].profileMethod.zone.id;
						}
						
					}
					return( 
						{
						product: item.id,			//item.product.id
						quantity: item.quantity,
						methodTypeId: formValuesShippingMethodInputs[item.designer.id][item.id],
						profileId: profileId,
						profileZoneMethodId : targetProfileZoneMethodId,
						zoneId,
						shipping_profile_or_default: item.shipping_profile_or_default
						}
					)
				}),
				 destination: this.state.shippingCountryId
			
		})
		)
	 
		console.log('final cart: ', cart);

		/*	-  call action
			- trigger saga
				- do api call
				- success: put action to call reducer
			- reducer: add result to data.checkout reducer

			then:
				bring in shippingCosts data w connect
		*/
		cl('About to fetch processed cart');

		 
		let filled = 'true';

		if(formResult === 'filled'){
			// alert('setting state: filled = true')
			if(this.state.productRemoved){
				cl('productRemoved was true - will be set to false')
			}
			this.setState({
				filled: true,
				productRemoved:false
			})
		}
		
		//sending a "filled" variable with the request
		if(formResult !== 'filled'){
			filled = 'false';
		}
		if(this.props.requestData({
			type:'prepare_checkout',
			args: {cart, filled}
		})) {
			cl('requestData \'prepare_checkout\' was called');
		}


}
	
//Standalone reusable function. "return profile zone methods that matches country" .
//Takes 1 item (obj) from a designer group.
returnProfileZoneMethodsThatMatchesCountry(groupItem, shippingCountryId){
	 
	cl(shippingCountryId, 'returnProfileZoneMethodsThatMatchesCountry: the shippingCountryid to be used for checking methods is: ')
	//Note: each product has only 1 specific shipping profile 
	var itemMethods = groupItem.shipping_profiles[0].methods;
   
	/* 	Loop all the item's profile zone methods. Collect all countries from all methods to an array     */
	var shippingProfileMethodTypes = [];
	itemMethods.map( profileMethod => { 
 
		// If a country has been set, use the zonemethod which contains the country. 
		//If the country isn not available in any profilemethod's zone, no method option will be set for that item.
				   
		if(shippingCountryId) {
			cl(shippingCountryId, 'looping profileMethods: shippingCountryId is ')
			// console.log('shippingCountryId is set: ' + shippingCountryId);
				 
			var targetCountry = false;
				//check zone: is it set to 0 or null, means no zone - all countries included 
				if(profileMethod.zone == null || profileMethod.zone == 0){
					cl('profileMethod is null or 0 - all countries included')
					targetCountry = 'yes'
				}else{
 
					//is chosen country id available in current profileMethod's zone?
					targetCountry = profileMethod.zone.countries.filter( country => 
						shippingCountryId == country.id );
				}
				//if available, add the zone's profileMethod's method type to an array
				// these method types will be the choices in the methods select input
				if(targetCountry.length) {
					shippingProfileMethodTypes.push({
						id : profileMethod.method.id,
						name: profileMethod.method.name,
						profileMethodId: profileMethod.id,
						charge_single: profileMethod.charge_single,
						shipping_zone_id: profileMethod.shipping_zone_id
						})

				}
					 
		}
  
	} )

	return shippingProfileMethodTypes;
}


 /*	checkCurrentFormUpdateStoredForm: to make sure that form values and local state is equal.

	- Check if the form is filled or partially filled  
	- If filled groups differ between form values and local state, local state will be updated.
	- returns one of three things. 
			1. 'filled' 				(form is complete)
			2. array of designer id's 	(only of the filled groups)
			3. false 					(no groups filled)
 */
checkCurrentFormUpdateStoredForm(formValuesShippingMethodInputs) { 
	cl('===================== checkCurrentFormUpdateStoredForm =====================');

	/* 	 
		Collect shipping_method form input values array indexes
		Form name: name={`shipping_method[${currentDesigner.id}][${item.id}]`}
	*/

	const designerIdsWithProducts = [];
 
	/*
		cartItemsByDesigner = {
						
			[index] :  { 
				designer: { 
				},
				items : {
					[index] : ''
				}
				[prodId] : {},
				[prodId] : {},
			}
		}

		- Loop cartItemsByDesigner
		- check form "methods" inputs 
			- match designer.id with methods inputs (Input field names have the format:	 shipping_method[designer id][item id] )
			- Match product Id with methods 	(loop items)
				
		- If any groups are filled but not all, add the group designer id to filledDesigners array.
				 
	*/ 

	let cartIsFilled = true;
	let oneOrMoreGroupsFilled = false;
	let filledDesigners = [];
	
	cl('checkCurrentFormUpdateStoredForm - add any groups that are filled to local state.')

	// console.log('this.props.formValues: ' + JSON.stringify(this.props.formValues));
	 
		//array to replace the stored form values data in state
		var updatedArray = [];

		/* ================= Loop all groups ================= */
		this.props.cartItemsByDesigner.map(group => {

			let currentDesigner = group.designer.id;

			//if shipping_method[designerId] is undefined, cart is not filled
			if( !formValuesShippingMethodInputs || !formValuesShippingMethodInputs[group.designer.id] 
				|| ( formValuesShippingMethodInputs[group.designer.id]  && !formValuesShippingMethodInputs[group.designer.id].toString().length) ) {
					console.log('cartIsFilled will be set to false, since formValuesMethodArray[' + group.designer.id + '] is undefined: ');

				return cartIsFilled = false;
				
			} else {
				//Current designer is set - check if all items for this group are filled
				console.log('Designer ' + group.designer.id + ' is set. check form values if all items for this group are filled' )

				var groupItemsFilled = true;
				var itemsMethodArray= [];
				  
				group.items && group.items.map( item => {

					if(formValuesShippingMethodInputs[group.designer.id][item.id]) { 
						 
						//Add object with product id and method id to an array 
						itemsMethodArray.push({
							productId :  item.id ,
							methodId:  formValuesShippingMethodInputs[group.designer.id][item.id]					 
						})

					}else {
						console.log('formValuesShippingMethodInputs, designer ' + group.designer.id + 
							' , item.id ' +  item.id + ' was NOT filled. this group won\'t be added')
							 
						groupItemsFilled = false;
						cartIsFilled = false;
					}
				})

				if(groupItemsFilled) {
					oneOrMoreGroupsFilled = true;

					//add current designer to the filled designers array
					filledDesigners.push(group.designer.id);

					cl('checkCurrentFormUpdateStoredForm: at least 1 groups items have been filled');
					
					//Form name: name={`shipping_method[${currentDesigner.id}][${item.id}]`}
  
					console.log('checkCurrentFormUpdateStoredForm: object of item - method looks like this: ', JSON.stringify(itemsMethodArray));

					//Update the locallyStoredFilledGroupMethods object in state with the new form value(s) 
					 		//this.state.locallyStoredFilledGroupMethods;
					 
					updatedArray.push({
						designerId : group.designer.id,
						itemMethods: itemsMethodArray
					})
					
					// alert( 'designer with id ' + group.designer.id + ' is completely filled' )
				}else{

				}
				
				//if costs have not yet been processed, request shipping costs

				// --- check + request
			}
 
	}); 

	//if any groupItems were Filled looping, set state
	if(oneOrMoreGroupsFilled){
		console.log('checkCurrentFormUpdateStoredForm: there were filled groups. : overwriting state (locallyStoredFilledGroupMethods) with this array: ' , JSON.stringify(updatedArray));
		this.setState({
			locallyStoredFilledGroupMethods : updatedArray  
		})
	}
	

	if(cartIsFilled) {
		console.log('Cart is completely filled.');
		return 'filled';

	}else if(filledDesigners.length){
		return filledDesigners;
	}else{
		return false;
	}
	 
}
 
	/* Loop items in cart (from cartItemsByDesigner in props)
		- loop each product's profile Zone Methods
		- see if the chosen country is available in the current zonemethod's zone
		*/
  renderItems = (items, currentDesigner, tr,currency) => Object.values(items).map((item, index) => {
	 
	const {shippingCountryId} = this.state;
	var methodsAvailable = true;
	cl(item.name, 'rendering item: ')
	

	//return object with zonemethodtypes 
	var shippingProfileMethodTypes = this.returnProfileZoneMethodsThatMatchesCountry(item, shippingCountryId);
	cl(' here is result of shippingProfileMethodTypes that matches country ' + shippingCountryId)
	cl(shippingProfileMethodTypes)
	 
	if(shippingCountryId && !shippingProfileMethodTypes.length) {
		// Current item has no available shipping option for the chosen country - other country
		//has to be chosen , or item be removed
		console.log('item \'' + item.name + '\' does NOT ship to country id ' + shippingCountryId)
		methodsAvailable = false;

	} else {
		if(!shippingCountryId){
			cl('shippingCountryId is not set')
		}
		if(!shippingProfileMethodTypes.length){
			cl('shippingProfileMethodTypes is not set')
		}
		//sort shippingProfileMethodTypes based on lowest cost first
		console.log( item.name + 'has one or more zones containing chosen country id ' + shippingCountryId)
		  
		cl('shippingProfileMethodTypes before sort:')
		cl(shippingProfileMethodTypes)
		shippingProfileMethodTypes = shippingProfileMethodTypes.sort( (a, b) => (a.charge_single - b.charge_single)   );
		
		cl(shippingProfileMethodTypes, 'SORTING shippingProfileMethodTypes:');
	

		/*  SET INITIAL VALUES for methods - only run before methods have been initialized:
			If country has been set, set redux form initial value for methods to the cheapest one 		*/
			
		if(shippingCountryId && !this.props.initialized) {
			 cl('form\'s methods have not been initialized - setting initial method values.')
		  
			cl('renderItems: about to set initial state for shipping method form values based on shippingCountryId ' + this.state.shippingCountryId)
			var shippingMethodCopy = this.props.initialValues.shipping_method;
			// cl(shippingMethodCopy, 'shippingMethodCopy: ')

			//Prepare two different arrays - which to be used depends on which nested arrays are set in shipping_method (initialValues)

			//nestedArray: 
			var nestedArray = [];
			nestedArray[currentDesigner.id] = [];
			nestedArray[currentDesigner.id][item.id] = shippingProfileMethodTypes[0].id;

			var nestedArray2 = [];
			nestedArray2[item.id] = shippingProfileMethodTypes[0].id;

			if(!shippingMethodCopy || !shippingMethodCopy.length) {
				
				cl(nestedArray, 'setting this.props.initialValues.shipping_method to nestedArray: ')
				this.props.initialValues.shipping_method = nestedArray

			//shipping_method and designer is set
			}else if(Array.isArray(shippingMethodCopy[currentDesigner.id] )) {
			
				cl( shippingProfileMethodTypes[0].id, 'setting this.props.initialValues.shipping_method[currentDesigner.id][item.id] to  shippingProfileMethodTypes[0].id : ')
				 this.props.initialValues.shipping_method[currentDesigner.id][item.id] = shippingProfileMethodTypes[0].id
				
			} else {
				 cl(nestedArray2, 'setting this.props.initialValues.shipping_method[currentDesigner.id]  to nestedArray2: ')
				this.props.initialValues.shipping_method[currentDesigner.id] = nestedArray2;
			}
		}else{
			cl('form\' already initialized - skipping setting initial method values.')
		}
	}
	
	//alert('for ' + item.id + ' countryNotAvailable is ' + countryNotAvailable);

   /* ============================== Shipping ============================== */
   let priceString = item['current_price_string'];
   let price = priceString.match(/\d+/g).map(Number).toString().replace(/,/g, '.');

   //console.log('priceString: ' + priceString);
   //console.log('price formatted as parseFloat: ' + parseFloat(price).toFixed(2) );
   var alertcontainer = !methodsAvailable ? 'alertcontainer' : '';

	return (
		<div key={index} className={`row ${alertcontainer}`}> 
		 

			<div className="cx2">
				<img src={item.gallery.items.length && item.gallery.items[0].sizes.thumbnail 
					? item.gallery.items[0].sizes.thumbnail 
					: '/img/daylicacy-logo_woman-name.jpg'} 
				/>
			</div>
			<div className="cx4">
				{ item.name }
			</div>
			<div className="cx3">
				<div><strong>Quantity: { item.quantity }</strong></div>
				{/* { item.term_attributes.filter(termAttribute => termAttribute.attribute.id != 1).map((termAttribute, index) => 
					<div key={index}><strong>{termAttribute.attribute.name}</strong>: {termAttribute.term.name}</div>
				)}  */}
			</div>
			<div className="cx3" style={{ textAlign: 'right' }}>
				{currency.toLowerCase()} {  price * item.quantity  }

				<br /><br />
				<DaylicacyButton 
					onClick={(e)=> this.handleRemove(e, currentDesigner.id, item.id)}
					disabled={false} 
				>
					{tr.remove || 'Remove'}
					</DaylicacyButton>
			</div>
			<div className="cx12 cs4 margin-right1"> 
			
			<p>Shipping destination</p>	
				 <FormControl>
				
				{/* selecting a country sets the same value for all items (All country inputs have the same "name" ) */}
				<Field
					className="select-input"
					name="shipping_destination"
					// helperText = {(touched && error)}
					component={Select}
					validate={[required]}
					onChange={ (e, countryId) => this.onSelectShippingCountry(e, countryId, item.countries)}
				>

					{ Object.keys(item.countries).map((country, index) => {

						return (<MenuItem
								key={`shipping_destination_${currentDesigner.id}_${item.countries[country].id}`}
								value={item.countries[country].id} 
								//countryName={children[index].props.children}
							>
								{item.countries[country].name}
							</MenuItem>)
						}) 
						}
				</Field>
			   
				</FormControl> 
				
			</div>

			<div className="cx12 cs4 ">
			{/* {JSON.stringify(shippingProfileMethodTypes )} */}
				<p>Shipping method</p>
				
				{/* disabled={!shippingCountryId || !methodsAvailable} */}
				 <FormControl >
					<Field 
						className="select-input"
						placeholder="Shipping method"
						name={`shipping_method[${currentDesigner.id}][${item.id}]`}
						label="Shipping"
						validate={[required]}
						//value={shippingMethodValues[group] ? shippingMethodValues[group].value : "default"}
						component={Select}
						onChange={(key) => this.props.onSelectShippingMethod(key, item.id, currentDesigner.id)}
					> 
				
				{/*  shippingProfileMethodTypes */}

					{ shippingProfileMethodTypes && shippingProfileMethodTypes.map((method, index) => {
						// cl(method.name, 'looping methods again, first value is: ')
						
						return (
							 
							<MenuItem
								key={`shipping_method_${currentDesigner.id}_${method.id}_${index}`}
								value={method.id} 
							>
							{method.name}
							</MenuItem>
						)
						

					} ) 
					}

					</Field>
				</FormControl> 
			</div>
			<div className="cx12 cs4 "></div>
			{!methodsAvailable && 
				<div className="cx12 cs12">
					<p className="errortext2"><i class="fa fa-warning"></i>This item do not ship to {this.state.countryName}. You can remove the item or change country.  </p>
				</div>
			}
			
		</div>
		)
} );

renderGroups = (props, currency) => Object.keys(props.cartItemsByDesigner).map((group, index) => {
 
	console.log('================ renderGroups ================= ');

	const { 
		cartItemsByDesigner,  
		shippingMethodValues,
		loadingProcessedCart,
		processedCart,
		selectedCountry,
		shippingMethod,
		shippingMethodByDesignerId,
		invalid,
		tr
		 
	} = props; 

	const {shippingCountryId} = this.state;

	const currentDesigner = cartItemsByDesigner[group].designer;

	if(!('items' in cartItemsByDesigner[group] )){
		alert('no items in cartItemsByDesigner[group]');
		return (
			<h1>no items in cartItemsByDesigner[group] </h1>
		)
	}
   
   const shippingProfile = '';

   //console.log('shippingProfileCountry 0: ', itemCountries[0].name);

	var totalGroupShippingCost = '';
	var totalPrice = ''
	var numbersGreaterThanZero = true;
   
	if(processedCart){
		console.log('processedCart is SET: ', processedCart);
	}
	//handle current designer if set in processedCart
   if(processedCart && (currentDesigner.id in processedCart.groups)) {
 
	   //collect group summary data to be displayed
	   let currentProcessedDesigner = processedCart.groups[currentDesigner.id];
	   console.log(currentProcessedDesigner, 'currentProcessedDesigner: ');
	   	var onePackage = true; 
		var methodTypeName = '';

	   //see if more than 1 item by counting "item_methods" 
	 
	   if(Object.keys(currentProcessedDesigner.item_methods).length > 1) {
			onePackage = false;
			  
			//see if separate or combine shipping: check if method types are the same

			let profileIds = [];
			let methodTypes = Object.values(currentProcessedDesigner.item_methods).map( item => {

				console.log('item', item);
				console.log('item.profileId: ' + item.profileId);
				//collect profile ids as well
				profileIds.push(item.profileId);

				return item.methodTypeName;
			} );

			cl(methodTypes, 'methodTypesids array: ' );
			cl(profileIds, 'profile ids array: ' );
			 
			let equalProfileIds = false;

			let equalMethodTypes = !!methodTypes.reduce((a, b) =>  
				 (a === b) ? a : NaN
			) 

			console.log('equalMethodTypes is ' + equalMethodTypes);
			 

			if(equalMethodTypes) {
				//check if there are more than 1 profile
				 
				 	equalProfileIds = !!profileIds.reduce((a, b) =>  
					  	(a === b) ? a : NaN
					) 
					console.log('equalProfileIds is ' + equalProfileIds);
 
			}

			var profileZoneMethodObj = undefined; 

			if(equalProfileIds && equalMethodTypes) {
				// Only 1 profile and 1 methodType
				 
				//get total cost inside profile
				//get profile zone method object
					 
				for(var propName in currentProcessedDesigner) {
				
					//if found property beginning with 'profilezonemethod', get its total price 
					if(typeof propName === 'string' && propName.indexOf('profilezonemethod') !== -1 ) {
						cl(propName, 'currentProcessedDesigner propName: ')

						if('combined_shipping' in currentProcessedDesigner[propName]) {
							var combinedShipping = currentProcessedDesigner[propName].combined_shipping;
							// methodName = shippingItem ? shippingItem.method_name : false;
		
						}
						totalPrice = currentProcessedDesigner[propName]['total_price_in_'+ currency.toLowerCase()];
						
					} 
				
				}
			}else{
				cl(false, 'Multiple profiles');
				//get total shipping cost for all items 
				//(if multiple profiles, total products costs can be accessed directly in current group)
				totalPrice = currentProcessedDesigner['total_price_in_'+ currency.toLowerCase()]

			}
			var separateShippingMsg = equalMethodTypes && !equalProfileIds ? 
				props.tr.separate_shipping_different_profiles 
				|| "Note: One or more products of the same chosen shipping method will still be shipped separately due to different shipping profile" 
				: !equalMethodTypes 
				? props.tr.separate_shipping || "Products will be shipped separately." 
				: props.tr.together_shipping || "Products will be sent together";
			 
			totalGroupShippingCost = currentProcessedDesigner['total_shipping_in_'+ currency.toLowerCase()];
			numbersGreaterThanZero = new RegExp("[1-9]").test(totalGroupShippingCost); 
			totalGroupShippingCost = !numbersGreaterThanZero ? 'Free' : totalGroupShippingCost;
			 
		   
	   }else {
		   //one product

		   console.log('designer ' + currentDesigner.id + ' has only 1 item')
		    
			//get profile zone method object
			let profileZoneMethodObj = undefined;

		   	for(var propName in currentProcessedDesigner) {
				
				//get the property if it begins with 'profilezonemethod'
				if(typeof propName === 'string' && propName.indexOf('profilezonemethod') !== -1 ) {
					profileZoneMethodObj = currentProcessedDesigner[propName];
				}

			}
		    if(profileZoneMethodObj) {
				var combinedShipping = false;
				var itemShippingCost = false;
				var methodName = false;
				
				var methodShipping = profileZoneMethodObj.method_shipping;
				var countryShipping = profileZoneMethodObj.country_shipping;

				var shippingItem = false;

				//get item property inside items_shipping
				for(var itemIdProp in currentProcessedDesigner.items_shipping) {
					if(currentProcessedDesigner.items_shipping.hasOwnProperty(itemIdProp)) {
						cl('items_shipping prop was retrieved');
						 shippingItem = currentProcessedDesigner.items_shipping[itemIdProp];
					} else {
						cl('items_shipping prop was not retrieved');
						itemShippingCost = '?';
					}
				}
				
				if('combined_shipping' in profileZoneMethodObj) {
					var combinedShipping = profileZoneMethodObj.combined_shipping;
					methodName = shippingItem ? shippingItem.method_name : false;
					console.log('methodName for combined_shipping is ' + methodName);

				} else { 
						itemShippingCost =  shippingItem ? shippingItem.item_shipping : false;
						methodName = shippingItem ? shippingItem.method_name : false;
						console.log('methodName is ' + methodName);
					} 
					
				
				var totalProducts = profileZoneMethodObj.total_products; 
			}  
			totalGroupShippingCost = !profileZoneMethodObj['free_shipping'] 
			? profileZoneMethodObj['total_shipping_in_'+ currency.toLowerCase()]
			: 'Free';
			totalPrice =  profileZoneMethodObj['total_price_in_'+ currency.toLowerCase()]

			
	   		}
	  
	   //numbersGreaterThanZero = new RegExp("[1-9]").test(totalGroupShippingCost); 	
   }
   
   return (

   <div key={index} className="shipping-profile-designer-group">
   
	   <div className="row title">
		   <h4><Link to={`${currentDesigner.permalink}`}>{ currentDesigner.shop_name }</Link> </h4>
	   </div> 
		  
	   {/* RENDER PRODUCTS */}
	   <div>
			{ this.renderItems(cartItemsByDesigner[group].items, currentDesigner, tr, currency) } 
	   </div>
	   <div className="row item-shipping-and-total-row">
		   
		   <div className="cx12">

			   <div className="item-shipping-and-total">
				   
				   {/* if shipping in one package, it is summarized here*/}
					{loadingProcessedCart && <CircularProgress/> }
				   { processedCart && (currentDesigner.id in processedCart.groups) && 
				   <div>
					   <div>
							   {!currentDesigner['free_shipping'] &&
							  		<div>
									  		{/* <div className="row">
												<div className="cx12">
													<p className="cx6">Shipping method: </p>
													<p className="cx6">{methodName && methodShipping && 
												methodName && methodShipping }</p>
												</div>
											</div>
											<div className="row">
												<div className="cx12">
													<p className="cx6">Destination cost: </p>
													<p className="cx6">{countryShipping && countryShipping}</p>
												</div>
											</div> */}
										{combinedShipping && totalGroupShippingCost !== 'Free' &&
											<div className="row">
												<div className="cx12 flex">
													<p className="cx6">Combined shipping: </p>
													<p className="cx6">{combinedShipping + currency.toLowerCase()}</p>
												</div>
											</div>
										}
										{itemShippingCost && 
											<div className="row">
												<div className="cx12 flex">
													<p className="cx6">Shipping per item: </p>
													<p className="cx6">{itemShippingCost + currency.toLowerCase()}</p>
												</div>
											</div>
										} 
									</div>
								}
								<div className="row">
									<p className="cx6">Total shipping:  </p>
									<p className="cx6"> { totalGroupShippingCost }</p>
									
								</div> 
								
						</div>	
					   <div>
					   		<div className="row">
									<p className="cx6">Total price for this designer: </p>
									<p className="cx6">{totalPrice } </p>
							</div>
							{separateShippingMsg &&
									<div className="row notice1"> 
										<p className="cx12"> { separateShippingMsg }</p> 
									</div> 
							} 
						</div>	
				   </div>
				   }  
				
			   </div> 
		   </div>
	   </div>
	   
   </div>
   )
}); 

	render() {
 
	const { 
        tr, 
        loadingProcessedCart, 
        processedCart, 
        currency, 
        onChangeShippingMethod, 
        error,
        warning, 
		valid,
		invalid,
        shippingMethodByDesignerId,
		nextPage,
		shippingMethodValues,
		stateCart
		
    } = this.props;

	const numCreations = stateCart.numProductsQuantity;
	console.log('ShoppingBasket numCreations: ' + numCreations);
	// console.log('shippingMethodValues : ' + JSON.stringify(shippingMethodValues)); 

	//  if(this.props.formValues) {
	// 	let formValuesShippingMethodInputs = this.props.formValues.shipping_method 

 
	return (
		<Paper className="cx12 cs8 shopping-basket-form">
			<form onSubmit={this.props.handleSubmit}>
			 
				{error && 
				(<h1> Error: {error} </h1>)}
				{warning && 
					(<h1> Error: {warning} </h1>)}    

				{/*================== render groups  ================== */}
				{ numCreations > 0 &&
					this.renderGroups(this.props, currency)
					
				}
				{numCreations > 0 &&
					(<div className="shipping-profile-designer-group">
					
						<div className="row">
							<div className="cx12 flex right-button-ctr">
								<DaylicacyButton 
									type={'submit'}
									disabled={loadingProcessedCart || !this.state.filled}
								>
									{tr.next || "Next "} <div className="fa fa-arrow-right"></div>
								</DaylicacyButton>
							</div>
						</div>
					</div>)
				}
		{ numCreations < 1 &&
			<EmptyMessage>
				Your cart is empty: {numCreations}
			</EmptyMessage>
		}
		 

					
			</form>
		</Paper>
	);
	};
}
	/*
		"Groups": 
			Products grouped by designer.
			Structure:
			[group index] : {
				designer: {},
				items: [
					{55 : {
						id:55,
					},
					}
				]
		} 	*/
	

ShoppingBasket.propTypes = {
	cartItemsByDesigner: PropTypes.arrayOf(PropTypes.shape({
		designer: PropTypes.object.isRequired,
		items: PropTypes.arrayOf(PropTypes.shape({
			quantity: PropTypes.number.isRequired
		})).isRequired,
	})).isRequired,
};

ShoppingBasket = reduxForm({
    form: 'checkout',
    // enableReinitialize:true,
	keepDirtyOnReinitialize: true,
	destroyOnUnmount: false,
    // forceUnregisterOnUnmount: false,
    validate
})(ShoppingBasket);

const selector = formValueSelector('checkout'); 
 

ShoppingBasket = connect(
	state => {  
	 
		//Initialvalues comes from parent (Checkout)
		//Note! : Initial values for methods are set in the renderItems() function
		  
		return {  
			allCountries: state.data.countries,
			cartItemsByDesigner: getCartItemsByDesigner(state),
			selectedCountry: selector(state,'shipping_destination'),
			tr: state.translations,
			currency: state.session.currency,
			valid: isValid('checkout')(state),
			formValues: getFormValues('checkout')(state), 
			stateCart: state.cart
		}
	},
	{ removeFromCart, requestData, unregisterField }
)(ShoppingBasket);

export default ShoppingBasket;
 
/*

{ props.shippingCountries[cartItemsByDesigner[group].designer.id].map((country, index) => (
	<MenuItem
		key={`shipping_destination_${cartItemsByDesigner[group].designer.id}_${index}`}
		value={country.id} 
	>
	{country.name}
	</MenuItem>
)) }

*/
 
