import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import T, { Table } from 'components/Table';
import { selectDesignerOrderLines as selectOrderLines } from 'data/modules/orderLines';
import Image from 'components/Image';
import { Link } from 'react-router-dom';
import { requestData } from 'data/actions';

const tableHeaders = [
    'Date',
    'Order line nb',
    'Customer',
    'Product Description',
    'Estimated Shipping Date',
    'Estimated Delivery Date',
    'Shipping Supplier',
    'Tracking number',
    'Status',
];

class ListOrders extends Component {

	constructor(props) {
		super(props);
		console.log('List Orders: ');
		this.props.requestData({
			type: 'designerOrders',
			args: {
				designerId: this.props.designerId,
			},
		});
	}

    renderCustomerCell(orderLine) {
		const order = orderLine.order;
        const src = order.customer.avatar ? order.customer.avatar.url : 'http://placehold.it/150x150';

        return(
			<div className="row">
				<div className="cx5">
					<Image src={src} />
				</div>
				<div className="cx7">
					<p>{order.user.name} </p>
					<p>Qty: {orderLine.quantity} </p>
					<p>Price: {order.amount} </p>
				</div>
			</div>
        );
    }

	render() {
		return (
			<Table>
				<T.Head>
					<T.TR>
						{tableHeaders.map((header, i) => (
							<T.TH key={i}>
								{header}
							</T.TH>
						))}
					</T.TR>
				</T.Head>
				<T.Body>
                    {this.props.orderLines.map(orderLine =>
						<T.TR key={orderLine.id}>
							<T.TD>
                                {orderLine.order.date}
							</T.TD>
							<T.TD>
								<Link to={"/en/account/my-orders/"+`${orderLine.order.id}`}>
                                	{orderLine.id}
								</Link>
							</T.TD>
							<T.TD>
                                {this.renderCustomerCell(orderLine)}
							</T.TD>
							<T.TD>
                                {orderLine.product.description.substring(0, 20)}
							</T.TD>
							<T.TD>
                                {orderLine.estimated_shipping}
							</T.TD>
							<T.TD>
                                {orderLine.estimated_delivery}
							</T.TD>
							<T.TD>
                                {orderLine.shipping_supplier.name ? orderLine.shipping_supplier.name : 'NA' }
							</T.TD>
							<T.TD>
                                {orderLine.tracking_number}
							</T.TD>
							<T.TD>
                                {orderLine.order.lines.map((line, i) => <span key={i}>{line.delivery_status},</span>)}
							</T.TD>
						</T.TR>
                    )}
				</T.Body>
			</Table>
		);
	}

}

ListOrders.propTypes = {
	ordersLine: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.string.isRequired,
		date: PropTypes.string.isRequired,
		lineNb: PropTypes.string.isRequired,
		customer: PropTypes.shape({
			id: PropTypes.string.isRequired,
			username: PropTypes.string.isRequired,
			avatar: PropTypes.string.isRequired,
		}).isRequired,
		product: PropTypes.shape({
			image: PropTypes.string.isRequired,
			quantity: PropTypes.number.isRequired,
			price: PropTypes.number.isRequired,
			terms: PropTypes.arrayOf(PropTypes.shape({
				name: PropTypes.string.isRequired,
				value: PropTypes.string.isRequired,
			})).isRequired,
		}).isRequired,
		estimatedShippingDate: PropTypes.string.isRequired,
		estimatedDeliveryDate: PropTypes.string.isRequired,
		shippingSupplier: PropTypes.string.isRequired,
		trackingNumber: PropTypes.string.isRequired,
		status: PropTypes.string.isRequired,
	})),
};

export default connect(
	state => ({
		designerId: state.session.designerId,
		orderLines: selectOrderLines(state, state.session.designerId),
	}),
	{ requestData }
)(ListOrders);
