import React from 'react';
//import Icon from 'material-ui-next/Icon';
import Icon from '@material-ui/core/Icon';

const Breadcrumbs = () => {
	return(
		<div className="breadcrumbs cx12">
			<Icon className="fa fa-home" />
				> Shop > Woman Fashion > Dresses > Printed Mesh Dress Colorful flowers Chart
			</div>
	);
};

export default Breadcrumbs;