import React from 'react';
import PropTypes from 'prop-types';

const initialStyles = {
	rootEl: {
		width: '23px', 
		height: '23px',
		paddingTop: '0',
		userSelect: 'none',
	}
};

const DaylicacyFlagIcon = ({
	countryCode,
	mode,
	countryName
}) => {

	let styles;

	if(mode === 'list') {
		styles = Object.assign({}, initialStyles, {
			rootEl: {
				...initialStyles.rootEl,
				position: 'absolute',
				top: 0,
				margin: '12px',
				left: '4px',
			}
		});
	} else {
		styles = initialStyles;
	}

	const imgUrl = 
		window.laroute.url('img/flags', [`${countryCode.toUpperCase()}.png`]);

	return(
		<div style={styles.rootEl}>
			<img 
				className="flag" 
				src={imgUrl}
				// title={countryName ? countryName : ''}
			/>
		</div>
	);
};

DaylicacyFlagIcon.propTypes = {
	countryCode: PropTypes.string.isRequired,
};

export default DaylicacyFlagIcon;