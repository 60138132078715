import { createSelector } from 'reselect';
import { selectProducts, selectDesigners } from 'data/selectors';

export const getIsOpen = state => state.cart.open;
export const selectItemIds = state => state.cart.items;
export const getAnchorEl = state => state.cart.anchorEl;

export const getCart = state => state.cart;

export const countedCartItems = createSelector(
	selectItemIds,
	itemIds => itemIds.reduce((itemIds, item) => {
		if (item in itemIds) {
			itemIds[item]++;
		} else {
			itemIds[item] = 1;
		}

		return itemIds;
	}, {})
);

export const countCartItems = createSelector(
	countedCartItems,
	cartItems => Object.keys(cartItems).length
);




/*
	New version: 
		Since cart items already contain complete data,
		we don't need to get data from "state.data.designers")
	Result structure:
		var result = [
			0 : {
				designer: {},
				items: [
					{55 : {
						id:55,
					}}
				]
			}]
*/ 
export const getCartItemsByDesigner = createSelector(
	[getCart],
	cart => {

		let items = cart.items;
		// console.log('getItemsByDesigner items:', items);

		let indices = {};

		//arrange items by designer: see which items belong to the same designer
		const result = Object.values(items).reduce((result, item) => {
			console.log('obj entries: item: ' + item);

			//get designer object from product
					
			const designer = item.designer;		//const designer = designers[item.product.designer_id];

			//Add designer object if designer has not already been added 
			if (!(designer.id in indices)) {
				//("push" returns array length)
				const length = result.push({
					designer,
					items: [],
				});
				indices[designer.id] = length - 1;
			}
			result[indices[designer.id]].items.push(item);
			
			return result;
		}, []);

		console.log('getItemsByDesigner', result);
		
		return result;
	}
);
 

export const getCartTotalPrice = createSelector(
	getCart,
	cart => {
		let items = cart.items;
		const total = items.reduce((acc, item) => {
			return (
				parseFloat(acc)
				+ (parseFloat(item.current_price) 
				* item.quantity)
			).toFixed(2);
		}, 0) + ' €';
		
		return total;
	}
);

/*
{ 	product: <int>, 
	quantity: <int> 
} 
*/
// export const getItemsWithData = createSelector(
// 	[selectItemIds, selectProducts],
// 	(itemIds, products) => {
// 		console.log('Cart selector - getItemsWithData: itemIds:',itemIds);
// 		console.log('products:',products);
		
// 		// Need { product: <int>, quantity: <int> } structure
// 		//return items.map(item => products[item]);

// 		let indices = {};
		
// 		const result = itemIds.reduce((result, itemId) => {
// 			if (!(itemId in indices)) {

// 				//("push" returns array length)
// 				const length = result.push({
// 					product: products[itemId],
// 					quantity: 0,
// 				});
// 				indices[itemId] = length - 1;
// 			}
// 			++result[indices[itemId]].quantity;
			
// 			return result;
// 		}, []);

// 		console.log('getItemsWithData', result);
		
// 		return result;
// 	}
// );


/* 
	getItemsByDesigner:
		Arrange items in cart by designer 
	Get designer_id från produkt
	Get designer med hjälp av designer_id
	skapar:
	var result = [
        0 : {
            designer: {},
            items: [
                {55 : {
                    id:55,
                }}
            ]
        }]
*/

/*
		 
		"selectDesigners" = state.data.designers.all
		
		Cart items
*/
/*
export const getCartItemsByDesigner = createSelector(
	[getCart, selectDesigners],
	(cart, designers) => {

		let items = cart.items;
		console.log('getItemsByDesigner items:', items);
		console.log('getItemsByDesigner designers:', designers);

		let indices = {};

		const result = Object.entries(items).reduce((result, item) => {
			console.log('obj entries: item[1]: ' + item[1]);
			//find the designer belonging to the product
			if (item[1].designer_id in designers) {					//if (item.product.designer_id in designers) {
				const designer = designers[item[1].designer_id];		//const designer = designers[item.product.designer_id];

				//if designer has not already been added to indices
				if (!(designer.id in indices)) {
					//("push" returns array length)
					const length = result.push({
						designer,
						items: [],
					});
					indices[designer.id] = length - 1;
				}
				result[indices[designer.id]].items.push(item[1]);
			} 

			return result;
		}, []);

		console.log('getItemsByDesigner', result);
		
		return result;
	}
);		*/
