import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import DaylicacyTextField from 'components/Form/DaylicacyTextField';
import { submitSearch } from './actions';

class SearchField extends Component {

	constructor(props) {
		super(props);
		
		this.state = {
			value: '',
		};
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleChange(event, newValue) {
		this.setState({ value: newValue, });
	}

	handleSubmit(e) {
		e.preventDefault();
		const { value } = this.state;
		this.props.submitSearch(value);
	}
	
	render() {
		const { value } = this.state;

		return(
			<form onSubmit={this.handleSubmit}>
				<DaylicacyTextField 
					name="search" 
					hintText="search"
					style={{height: '32px'}}
					hintStyle={{bottom: '4px'}}
					fullWidth={true} 
					onChange={this.handleChange}
					value={value} />
			</form>
		);
	}
}

SearchField.propTypes = {
	submitSearch: PropTypes.func.isRequired,
};

export default connect(
	null,
	{ submitSearch }
)(SearchField);