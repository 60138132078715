import React from 'react';
import DaylicacyButton from 'components/DaylicacyButton';
import IconButton from 'components/IconButton';

export default class MyFavorities extends React.Component {

    constructor(props) {
        super(props);
    }

    onButtonCloseClick() {
        this.props.onDone();
    }

    render() {

        return (
            <div>
                <div
                    className="k-dropdown__close"
                    style={{background:"transparent"}}
                    >
                    <IconButton
                        iconClassName="fa fa-close"
                        className="k-dropdown__close"
                        onClick={this.onButtonCloseClick.bind(this)}
                    />
                </div>

                <center><h2>MY FAVOURITES</h2></center>

                <p>
                    You need to sign in to be able to save your favorites.
                    <br /><br />
                    /Daylicacy team
                </p>

                <div>
                    <center>
                        <DaylicacyButton
                            type="button" 
                            onClick={this.onButtonCloseClick.bind(this)}
                        >
                           OK
                        </DaylicacyButton>
                    </center>

                </div>
            </div>
        )
    }

}

