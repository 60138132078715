import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Filters from 'containers/Filters';
import ShopTabs from './components/ShopTabs';
import SortingToolbar from './components/SortingToolbar';
import Snackbar from 'containers/Snackbar';
import ProductList from 'containers/ProductList';
import Designers from 'views/ListDesigners';
 
import {  getNumProductsTotal } from 'data/selectors';

import {requestData} from 'data/actions';
import {updateChipsByFilters,removeAllChips}  from 'containers/FilterChipsBar/actions';
import { clearFiltersTerms,requestFilterTerms, requestFilterMenu,setFilterMenuNoCounts} from 'containers/Filters/actionCreators';
import {clearCheckedMenuItems} from 'containers/Filters/components/actions'; 
import {setShopPageSlug,clearPageQueryDetails} from 'views/Shop/actionCreators'; 
import { 
	fetchProductsByRootFilter, 
	setRootTermAddObj, 
	setRootTermReplaceObj,
	fetchShop,
	addShopRootTerm
} from 'containers/Filters/actionCreators';

import { countDesigners } from 'data/modules/designers';
import {selectLanguage} from 'containers/Session/selectors'
import { getFetchingStatus,getRequestStatus } from 'data/selectors';

import {debounce} from 'lodash';

import {getParameterByName, cl} from 'utils';


class Shop extends Component {

	constructor(props) {
		super(props);

		this.state = {
			shopTabsvalue: 'creations'
		}

		this.onTabChange = this.onTabChange.bind(this);
		// this.fetchBySlug = this.fetchBySlug.bind(this)
	}
	
	/* 	Fetch products or designers depending on pageSlug.
	* 	If not designers, checks current slug, sets it as root filter and requests products by that filter
	*		- exception if slug is shop: removes filters + chips, requests only the rootTerm (no terms)
	*/
	fetchBySlug = (termSlug, queryString) => {

		cl('fetchBySlug: termSlug is ' + termSlug) 

		//if page is designers - fetch designers
		if(this.props.match.params.pageSlug == 'designers'){

			//if on designers tab and new page was chosen, 
			
			cl('ShopPageSlug:  ' + this.props.match.params.pageSlug) 
			 
			var page = (!queryString || !queryString.length) ? 1 : queryString
			cl('page is ' + page)

			//fetch designers for current page  
			let columns = { 
				fields : [
				'products', 
				'shop_banner',
				'shipping_general_methods.zone.countries', 
				'shipping_general_methods.method'
				],
				count: 12,
				page
			}
	
			console.log('Footer - calling requestdata - designers ');

			this.props.requestData({
				type:'designers',
				args: columns
			});
			
		}else {   

			cl('ShopPageSlug:  ' + this.props.match.params.pageSlug) 
			//if page is products - fetch products
			//set root filter, set chip, fetch products 
			if(!this.props.fetchingProducts) { 
					
				//if no query string - get products of root term, clear filters etc
				if(!queryString || !queryString.length){

					//Get term object belonging to root term, add it to terms + add rootTerm 
					   
					this.props.clearFiltersTerms();
					this.props.removeAllChips(); 
					this.props.clearCheckedMenuItems(); 
					this.props.clearPageQueryDetails(this.props.match.params.pageSlug);
 
					this.props.setRootTermAddObj(termSlug);
					this.props.fetchProductsByRootFilter(termSlug, queryString);
					

				}else {
					//if query string (page nr):
					
					this.props.setRootTermReplaceObj(termSlug) 
					this.props.requestFilterTerms(queryString);

				}
				// if(termSlug !== 'shop') {
				// }else{
				// 	//slug is 'shop' (shop is not a term)
				// 	this.props.fetchShop(termSlug, queryString);
				// }
			}
		}

	}
	
	componentWillMount() {
		cl('componentWillMount');
		//get value of the '?page' query string
		let queryString = getParameterByName('page');
		let slug = this.props.match.params.termSlug;
 
		//fetch products
		if(!this.props.fetchingProducts) {
			   
			this.fetchBySlug(slug, queryString);

			cl(' fetchByslug - setFilterMenuNoCounts')
			//reset product counts
			this.props.setFilterMenuNoCounts();
			//Fetch filter product counts
			this.props.requestFilterMenu();
			 
		}
		
	}

	//Fetching of products and filteritem product counts  
	componentDidUpdate(prevProps) {
		cl('componentDidUpdate')
		let queryString = getParameterByName('page');
		let slug = this.props.match.params.termSlug;

		//only fetch products if url changed
		if ( (this.props.location.pathname !== prevProps.location.pathname) ||
				(this.props.location.search !== prevProps.location.search) ){
			console.log('New route - fetchProductsByRootFilter is : ' + this.props.location.pathname + 
						', and prevoius path name: ' + prevProps.location.pathname);
						console.log('..and search is : ' + this.props.location.search + 
						', and prevoius search: ' + prevProps.location.search); 
			this.fetchBySlug(slug, queryString);

			//Since a new root filter was set, the filter product counts needs to be updated - fetch filter menu
			 
			this.props.setFilterMenuNoCounts();
			this.props.requestFilterMenu();
 
		  } else {
			cl('current pathname: ' + this.props.location.pathname + 
				', and previous path name: ' + prevProps.location.pathname) 
			//check if products are empty, if so fetch products for top categories
			const prodNumber = this.props.numProductsTotal;
			console.log('numProductsTotal is ' + prodNumber);
			
		  }			
 	
	}
	
	onTabChange(event, value) {
		//  alert(value);
		//set shop slug 
		// this.props.setShopPageSlug(value);

		 //this.props.match.params.termSlug
		// if(value == 'designers'){
		// 	value = value + '/' + value;
		// }
		this.setState({
			shopTabsvalue: value
		})
		this.props.history.push(
			'/' + this.props.lang + '/shop/' + this.props.match.params.termSlug + '/' + value
			// `${this.props.match.url}/${value}`
		);
	}
  

	render() {
		const {
			router,
			params,
			numProductsTotal,
			match,
			prepareAddToCart,
			numDesignersTotal,
			currentCreationsQueryTotalSet,
			currentDesignersQueryTotalSet,
			from,
			to,
			lang,
			pageSlug
		} = this.props;

		//Routes for productList:
		// create array of paths in the format: domain/lang/shop/{menuOption}/creations
	 
		
		var urlMinusPageSlug = '';

		//check if ends with '/', 
		if(  match.url.slice(-1) == '/' ){  
			  //remove last slash. Take substring up until last slash
			urlMinusPageSlug = match.url.substr( 0, match.url.slice(0, -1).lastIndexOf("/") );
		}else{ 
			urlMinusPageSlug = match.url.substr(0, match.url.lastIndexOf("/"));
		}

		cl(urlMinusPageSlug, 'urlMinusPageSlug');

		var navigationOptions = this.props.menuOptions;
 

		//example: 		/en/shop + '/' + 'women' + '/creations'
		var shopRoutes =  navigationOptions.map( option =>
			'/' + lang + '/shop/' + option + '/creations'
			//  urlMinusPageSlug + '/' + option + '/creations'
			
		)
			cl(shopRoutes, 'shopRoutes');
			///en/shop/men/shop/creations

		// var pageSlug = location.pathname.substr(location.pathname.lastIndexOf('/') +1, location.pathname.length);
		// cl(pageSlug, 'pageSlug is ')

		var termSlug = this.props.match.params.termSlug; 
		var currentPageSlug = this.props.match.params.pageSlug;

		cl(this.props.match.params.pageSlug, 'this.props.match.params.pageSlug: ')
		//Shoptabs - pass down data related to the current page
			// var currentQueryTotalSet = '';
			// if(pageSlug == 'creations'){
			// 	currentQueryTotalSet = currentCreationsQueryTotalSet
			// }else if(pageSlug == 'designers'){
			// 	currentQueryTotalSet = currentDesignersQueryTotalSet
			// }
			cl(match.url + '/designers', 'DESIGNERS ROUTE: ')
		return(
			<div className="shop container-fluid"> 
				<div className="row">
					<div className="cx12">
						{ /* breadcrumbs */ }
					</div>
				</div>
				<div className="row">
					<div className="cx12 cs4 cm3 cl3">
						<Filters />
					</div>
					<div className="cx12 cs8 cm9 cl9">
						<ShopTabs 
							className="daylicacy-shop-tabs"
							currentDesignersQueryTotalSet={currentDesignersQueryTotalSet}
							currentCreationsQueryTotalSet={currentCreationsQueryTotalSet}
							numCreations={numProductsTotal}
							numDesigners={numDesignersTotal}
							onChange={this.onTabChange}
							selectedTab={this.state.shopTabsvalue}
							fetchingProducts={this.props.fetchingProducts}
							requestStatus={this.props.requestStatus}
						/>
						<SortingToolbar 
							pageSlug={currentPageSlug} 
							fetchBySlug={this.fetchBySlug}
							requestStatus={this.props.requestStatus}
							fetchingProducts={this.props.fetchingProducts}
							router={router}  
							params={params}
							history={this.props.history}
							match={this.props.match}
						/>

						<Route 
							exact path={shopRoutes} 
							render={(props) => (
							<ProductList 
								{...this.props} 
								termSlug={termSlug}
								currentCreationsQueryTotalSet={currentCreationsQueryTotalSet}
								from={from}
								to={to}
							/>
							)}
						/>

						<Route
							path={'/' + lang + '/shop/' + termSlug + '/designers'} 	//{`${match.url}/designers`}
							pageSlug={currentPageSlug}
							component={Designers} />

						<SortingToolbar  
							pageSlug={currentPageSlug}
							router={router} 
							params={params} 
							history={this.props.history}
							match={this.props.match}
						/>
						<Snackbar
							open={this.props.snackbar.open}
							message={this.props.snackbar.message}
							onRequestClose={this.handleRequestClose}
						/>
					</div>
				</div>
			</div>
		);
	}
}

Shop.propTypes = {
	numDesignersTotal: PropTypes.number.isRequired,
};

Shop = connect( 
	state => ({
		lang: selectLanguage(state),
		// lastShopPageSlug: state.shop.shop.lastShopPageSlug,
		// pageSlug: state.shop.shop.lastShopPageSlug || ''
		fetchingCart: state.cart.requestStatus.requesting,
		fetchingProducts: state.data.products.requestStatus.requesting,
		requestStatus: state.data.products.requestStatus.requestStatus,
		currentDesignersQueryTotalSet: state.shop.designers.currentQueryTotalSet || 0,
		currentCreationsQueryTotalSet: state.shop.creations.currentQueryTotalSet || 0,
		from: state.shop.creations.from,
		to: state.shop.creations.to,
		menuOptions : state.interface.header.mainNavigation.menuOptions,
		numProductsTotal: getNumProductsTotal(state),
		numDesignersTotal: countDesigners(state),
		snackbar: state.snackbar, 
		 
	
		
	}),
	{ 	fetchProductsByRootFilter,
		updateChipsByFilters, 
		setRootTermAddObj,
		setRootTermReplaceObj,
		fetchShop,
		// setShopPageSlug,
		addShopRootTerm,
		removeAllChips,
		clearCheckedMenuItems,
		clearFiltersTerms,
		requestFilterTerms,
		requestFilterMenu,
		setFilterMenuNoCounts,
		clearPageQueryDetails,
		requestData
	}
)(Shop);

export default Shop;

//products: selectVisibleProducts(state),
