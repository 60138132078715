import React from 'react';
import PropTypes from 'prop-types';
import Image from 'components/Image';
import styled from 'styled-components';
import Row from 'components/Row';
import Col from 'components/Col';

const OriginalPrice = styled.span`
	color: #ccc;
	text-decoration: line-through;
`;

const Product = props => {
	return (
		<Row>
			<Col xs={6}>
				<Row>
					<Col xs={4}>
						<Image src={props.image} />
					</Col>
					<Col xs={8}>
						{props.name}
					</Col>
				</Row>
			</Col>
			<Col xs={6}>
				<Row>
					<Col xs={6}>
						qty: {props.quantity}
					</Col>
					<Col xs={6} align="right">
						<OriginalPrice>{props.originalPrice}</OriginalPrice>
						<div>{props.price}</div>
					</Col>
				</Row>
				{props.termAttributes.map(termAttribute => (
					<Row key={`${termAttribute.attribute.id}_${termAttribute.term.id}`}>
						<Col xs={12}>
							{termAttribute.attribute.name}: {termAttribute.term.name}
						</Col>
					</Row>
				))}
			</Col>
		</Row>
	);
};

export default Product;
