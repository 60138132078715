/*	Saga boilerplate  												*/

import { call, put, select, takeLatest, takeEvery } from 'redux-saga/effects';
import {addCheckedFilter}  from './actions';
import {SET_ROOT_TERM_REPLACE_OBJ} from '../actionTypes';

import {selectTermIdFromRootTermSlug} from '../selectors'
import {  
	REQUEST_DATA_FAILURE,
	requestDataFailure,
	requestingStartLoading,
	REQUESTING_START_LOADING,
	successStopLoading,
	SUCCESS_STOP_LOADING 
} from 'data/actions';

import api from 'data/api';
  
import {cl} from 'utils'; 



const subject = 'filtermenu';

//Get root term id by the slug
//Add it to checked filters (if not 'shop')
function* addRootFilterAsCheckedId(action) {
	  
	if(action.termSlug == 'shop'){
		return;
	}
	
	try {

		cl(action.termSlug, 'filter menu - addCheckedFilter. action.termSlug: ') 
         var rootTermId = yield select(selectTermIdFromRootTermSlug, action.termSlug)
         cl('slug: ' + action.termSlug + ' was converted to id ' + rootTermId)

         yield put(addCheckedFilter(rootTermId))
  
	} catch(e) { 
        cl(e)
		//Redux-Form-Saga action failure  
		
	}
}





function* sagaListener() { 

	yield takeEvery(SET_ROOT_TERM_REPLACE_OBJ, addRootFilterAsCheckedId); 
}

export default sagaListener;