import React, { Component } from 'react';
import { connect } from 'react-redux';
import Image from 'components/Image';
import Product from 'components/Product';
import Panel from 'components/Panel';
import Row from 'components/Row';
import Col from 'components/Col';
import Customer from 'components/Customer';
import { Link } from 'react-router-dom';
import DaylicacyButton from 'components/DaylicacyButton';
import Alert from 'components/Alert';
import { selectHydratedOrder } from 'data/modules/orders';
import PurchaseSummary from 'components/PurchaseSummary';
import { Field, reduxForm } from 'redux-form';
import { DaylicacyCheckbox } from 'components/Form';
import Select from '@material-ui/core/Select';
import DaylicacyTextField from 'components/Form/DaylicacyTextField';
import Paper from 'material-ui-old/Paper';
//import { MenuItem } from 'material-ui-next/Menu'; 
import MenuItem from '@material-ui/core/MenuItem'; 

import api from 'data/api';

class Order extends Component {

	constructor(props) {
		super(props);

		this.onSubmit = this.onSubmit.bind(this);
	}

	onSubmit(data) {
		const orderLines = data.lines.reduce((result, orderLine) => {
			result.push({
				id: orderLine.id,
				estimated_shipping: orderLine.estimated_shipping,
				estimated_delivery: orderLine.estimated_delivery,
				tracking_number: orderLine.tracking_number,
				delivery_status: orderLine.delivery_status,
			});
			
			return result;
		}, []);
		api.updateOrder(this.props.order.id, {lines: orderLines})
			.then(response => {
				console.log('success', response);
			})
			.catch(error => {
				console.log('error', error);
			});
	}

	render() {

		if( ! this.props.order ) {
			return (
				<p>There is no data</p>
			)
		}

		const { delivery_address } = this.props.order;
        const total = this.props.order.lines.map(
        	(orderLine) => {
                return parseFloat(orderLine.product.price) + parseFloat(orderLine.shipping);
            }
        );

        console.log('total', total);

		return (
			<form onSubmit={this.props.handleSubmit(this.onSubmit)}>
				<div style={{padding: '0.3em'}}>
					<Row>
						<Col xs={8}>
							<Panel header>
								<Row>
									<Col xs={6}>
										Ordernr: {this.props.order.id}
									</Col>
									<Col xs={6} align="right">
										Order Date: {this.props.order.date}
									</Col>
								</Row>
							</Panel>
							{this.props.order.lines.map((orderLine, index) => (
								<div key={index}>
									<Panel key={orderLine.id}>
										<Row style={{marginBottom: '0.3em'}}>
											<Col xs={6}>
												Order Line nr: {this.props.order.id}.{orderLine.id}
											</Col>
											<Col xs={6} align="left">
												<Field
													name={`lines[${index}][delivery_status]`}
                                                 	className="mui-select-bottom-border mui-select-input" 
													label="Order Line Status"
													component={Select}
												>
													<MenuItem value={0}>Open</MenuItem>
													<MenuItem value={1}>Closed</MenuItem>
												</Field>
											</Col>
										</Row>
										<Product
											name={orderLine.product.name}
											image={orderLine.product.featuredImage.url}
											originalPrice={`${orderLine.product.price} ${orderLine.order.currency.symbol}`}
											price={`${orderLine.product.currentPrice} ${orderLine.order.currency.symbol}`}
											termAttributes={orderLine.product.termAttributes}
											quantity={orderLine.quantity}
										/>
									</Panel>
									<Panel>
										<Row>
											<Col xs={5}>
												<div>
													<Field
														type="text"
														name={`lines[${index}][estimated_shipping]`}
														label="Estimated Shipping Date"
														component={DaylicacyTextField}
													/>
												</div>
												<div>
													<Field
														type="text"
														name={`lines[${index}][estimated_delivery]`}
														label="Estimated Delivery Date"
														component={DaylicacyTextField}
													/>
												</div>
												<div>
													<br />
													Return costs paid by the designer: Yes
												</div>
											</Col>
											<Col xs={5} align="right">
												<div>
													Shipping Express Mail
												</div>
												<div>
													<Field
														type="text"
														name={`lines[${index}][tracking_number]`}
														label="Tracking Number"
														component={DaylicacyTextField}
													/>
												</div>
												<div>
													<br />
													Cost of the return (in {this.props.order.currency.name}):
												</div>
											</Col>
											<Col xs={2} align="right">
												{orderLine.shipping} {this.props.order.currency.symbol}
											</Col>
										</Row>
									</Panel>
								</div>

							))}
							<Row gutter={0.1}>
								<Col xs={6} gutter={0.1}>
									<Panel style={{
										paddingTop: '1em',
										paddingBottom: '1em',
										height: '100%',
									}}>
										<h3>Delivery address</h3>
										<div>{delivery_address.title} {delivery_address.first_name} {delivery_address.last_name}</div>
										<div>{delivery_address.address}</div>
										<div>{delivery_address.zip} {delivery_address.city}</div>
										<div>{delivery_address.country.name}</div>
									</Panel>
								</Col>
								<Col xs={6} gutter={0.1}>
									<Panel style={{
										paddingTop: '1em',
										paddingBottom: '1em',
										height: '100%',
									}}>
										<PurchaseSummary
											totalPrice={total}
											totalProducts={this.props.order.lines.length}
										/>
									</Panel>
								</Col>
							</Row>
						</Col>
						<Col xs={4}>
							<div style={{marginBottom: '1em'}}>
								<Customer
									name={this.props.order.customer.fullName}
									avatar={this.props.order.customer.avatar ? this.props.order.customer.avatar.url : ''}
								/>
							</div>
							<div style={{marginBottom: '1em'}}>
								<Alert cssClasses="alertbox alert-notice alert-light" >
									Conditions at the moment of the purchase <Link to="/">Terms&Conditions</Link> and <Link to="/">Return policy</Link>
								</Alert>
							</div>
							<div style={{marginBottom: '1em'}}>
								<DaylicacyButton 
                                                                    fullWidth={true}
                                                                >
                                                                        Print this order {this.props.tr.print_this_order}
                                                                    </DaylicacyButton>
							</div>
							<div style={{marginBottom: '1em'}}>
								<DaylicacyButton fullWidth={true} type="submit"
                                                                >
                                                                Save Change {this.props.tr.save_change}
                                                                </DaylicacyButton>
							</div>
							<div style={{marginBottom: '1em'}}>
								<DaylicacyButton fullWidth={true}>
									Save and send update to Customer
								</DaylicacyButton>
							</div>
						</Col>
					</Row>
				</div>
			</form>
		);
	}

}

Order = reduxForm({
	form: 'order',
	enableReinitialize: true,
})(Order);

Order = connect(
	(state, props) => ({
		order: selectHydratedOrder(state, props.match.params.orderId),
		initialValues: selectHydratedOrder(state, props.match.params.orderId),
	})
)(Order);

export default Order;
