import merge  from 'lodash/merge';
import { put, call, select } from 'redux-saga/effects';
import { normalize, denormalize } from 'normalizr';

import { createSelector } from 'reselect';
import {productSchema, 
		productListSchema, 
		allProductsEntities ,
		productDesignerSchema, 
		termAttributeSchema} 
		from 'data/schemas';
// import { selectItems } from 'containers/Cart/selectors';
// import { selectAll as selectAllDesigners } from 'data/modules/designers';

import { replaceFilterTermObject } from 'containers/Filters/actionCreators';
import {addPageQueryDetails} from 'views/Shop/actionCreators';
import {cl, notObjectOrNotEmptyObject} from 'utils';

import { 
	ADD_KEY_VALUE,
	ADD_RESULT,
	ADD_ENTITIES, 
	addEntities,  
	requestDataSuccess,
	REQUEST_DATA_SUCCESS,
	requestDataFailure,
	REQUEST_DATA_FAILURE,
	addKeyValue,
	addResult,
	successStopLoading,
	SUCCESS_STOP_LOADING, 
	requestingStartLoading,
	REQUESTING_START_LOADING,
	
	
} from '../actions';

import { fetchProduct, fetchProducts } from '../api';
import { REQUEST_UPDATE_DESIGNER_FAILED } from '../../views/Account/MyShop/ShopSetup/actionTypes';

export const STATE_KEY = 'products';

//Action types
export const ADD_REPLACE_PRODUCTS ='ADD_REPLACE_PRODUCTS';
export const ADD_PRODUCT = 'ADD_PRODUCT';
export const CLEAR_PRODUCTS = 'CLEAR_PRODUCTS';

//Action creators
export const addProduct = ({entities, result}) => ({
	type: ADD_PRODUCT,
	payload: {entities, result}
});
export const addReplaceProducts = data => ({
	type: ADD_REPLACE_PRODUCTS,
	payload: data,
});
export const clearProducts = () => ({
	type: CLEAR_PRODUCTS
});

//state.data.products

const initialState = {
	
	products: {},
	visibleProducts: [],
	term_attributes: {}, 
	terms:{},
	attributes: {},
	translations: {}, 
	//ratings: products.ratings,
	favourites:{},
	shippingProfiles: {},
	shippingZones: {},
	zoneCosts:{},
	shippingMethods: {},
	shippingMethodTypes: {},
	shippingSuppliers: {},
	countries: {},
	designers:{},  
	// numProductsTotal: null,
	requestStatus: {
		requesting: false,
		requestStatus: null,
		lastRequestError: {
			statusCode:null,
			message: null
		} 
	},
	// requesting:false,
	// fetchResultStatus:null, 
};

const reducer = (state = initialState, action) => {
	switch (action.type) {

		// case ADD_ENTITIES:
        //             console.log('modules-products reducer: ADD_ENTITIES');
		// 	return {
		// 		...state,
		// 		all: merge({}, state.products, action.payload),
		// 	};

		//adds products, overwriting previous ones
		case ADD_REPLACE_PRODUCTS:
				console.log('modules-products reducer: ADD_REPLACE_PRODUCTS');
				cl(action.payload)
				const {products} = action.payload;

				//alert('ADDING PRODUCT: this payload: ', action.payload);
			return Object.assign({}, state, { 

				featured_images: products.featured_images,
				term_attributes:products.term_attributes,
				term_categories:products.term_categories,
				terms:products.terms,
				attributes:products.attributes,
				translations:products.translations,
				//product designers
			 	//ratings: products.ratings,
				favourites: products.favourites,
				shippingProfiles: products.shippingProfiles,
				shippingZones: products.shippingZones,
				zoneCosts: products.zoneCosts,
				shippingMethods: products.shippingMethods,
				shippingMethodTypes: products.shippingMethodTypes,
				shippingSuppliers: products.shippingSuppliers,
				countries: products.countries,
				designers:products.designers,
				products: products.products,
				// products: merge({}, state.products.products, products.products),
			});
			 
		case CLEAR_PRODUCTS: 
			
			return { 
					requestStatus: state.requestStatus,
					term_attributes: {}, 
					terms:{},
					attributes: {},
					translations: {}, 
					//ratings: products.ratings,
					favourites:{},
					shippingProfiles: {},
					shippingZones: {},
					zoneCosts:{},
					shippingMethods: {},
					shippingMethodTypes: {},
					shippingSuppliers: {},
					countries: {},
					designers:{},
					products: {},
					visibleProducts: []
			}

		//add product to main products object (in shop)
        case ADD_PRODUCT:

			//to receive a normalized product including "entities" object and "result" array
			// console.log('Products reducer add action payload:' + JSON.stringify(action.payload))
			console.log('action.payload.result: ', action.payload.result);
			 
			//get id
			let result = action.payload.result;
			let id = Array.isArray(result) ? result[0] : result;
			console.log('finally, the id is ' + id);
			let product = {[id] : action.payload.entities.products[id] }
			console.log('the Produc that will go into state' + JSON.stringify(product))

			const {entities } = action.payload;
			// console.log('here is entities',entities);
			console.log('feat imgs is as follows', state.featured_images);
            return {
				...state,
				featured_images: merge({}, state.featured_images,entities.featured_images),	 
				 term_attributes: merge({}, state.term_attributes, entities.term_attributes),
				term_categories:merge({}, state.term_categories, entities.term_categories),
				terms: merge({}, state.terms, entities.terms),
				attributes:merge({}, state.attributes, entities.attributes),
				translations:merge({}, state.translations, entities.translations),
				//product designers 
			 	//ratings: merge({}, state., entities.ratings),
				favourites: merge({}, state.favourites, entities.favourites),
				shippingProfiles: merge({}, state.shippingProfiles, entities.shippingProfiles),
				zoneCosts: merge({}, state.zoneCosts, entities.zoneCosts),
				shippingMethods: merge({}, state.shippingMethods, entities.shippingMethods),
				shippingZones: merge({}, state.shippingZones, entities.shippingZones),
				shippingMethods: merge({}, state.shippingMethods, entities.shippingMethods),
				shippingMethodTypes: merge({}, state.shippingMethodTypes, entities.shippingMethodTypes), 
				shippingSuppliers: merge({}, state.shippingSuppliers, entities.shippingSuppliers),
				countries: merge({}, state.countries, entities.countries),
				designers:merge({}, state.designers, entities.designers),
				products: merge({}, state.products, product),
			};
		case ADD_RESULT:
			if (!action.payload.stateKey === STATE_KEY) 
				return state;

			return {
				...state,
				visibleProducts: action.payload.result,
			};
			 
		case ADD_KEY_VALUE:
			if (action.payload.state_key === STATE_KEY) {
				return {
					...state,
					...action.payload.kvp,
				};
			} else {
				return state;
			} 
		case SUCCESS_STOP_LOADING:

			console.log('reducer: SUCCESS_STOP_LOADING, action.payload: ' + JSON.stringify(action.payload))
			switch (action.payload) {

                case 'product':
				case 'products':
				 
				return {
					...state,
					requestStatus: {
						...state.requestStatus,
						requesting: false,
						requestStatus:'success'
					}
					 
				}
				// return Object.assign({}, state, {
				// 	requesting: false
				// }); 
                  
				default:
					return state;
			} 
		case REQUEST_DATA_SUCCESS:
			 
			switch (action.payload) {

				case 'product':
				case 'products':
				return {
					...state,
					requestStatus: {
						...state.requestStatus,
						requesting: false,
						requestStatus:'success'
					}
				
				}
				// fetchResultStatus: 'success',
				default:
					return state;
			} 

		case REQUEST_DATA_FAILURE:

			switch (action.payload.subject) {

				case 'product':
				case 'products':
 
				return {
					...state,
					requestStatus: {
						requesting: false,
						requestStatus: 'failed',
						lastRequestError: {
							statusCode: action.payload.statusCode || '',
							message: action.payload.error.message || ''
						}
					}
					
				}
					// fetchResultStatus: 'failed',
				default:
					return state;
			} 

		case REQUESTING_START_LOADING:

			console.log('reducer: REQUESTING_START_LOADING, action.payload: ' + JSON.stringify(action.payload))
			switch (action.payload) {

                case 'product':
				case 'products':  
				
				return Object.assign({}, state, {
					requestStatus: {
						...state.requestStatus,
						requesting: true,
						requestStatus:  'pending' 
					}
					// requesting: true,
					// fetchResultStatus: 'pending',
				});
				 

				default:
					return state;
			} 
		default:
			return state;
	}
};

export default reducer;


export function* getProduct(action) {
     
    const checkProduct = (yield select()).data.products.products;

    if(checkProduct && Object.keys(checkProduct) === 0 && checkProduct.constructor === Object
        && action.payload.args.id in checkProduct) {

		console.log('The product is already available!');	
			
	} else {
        console.log('product not available - fetch.');	
        try {
            yield put(requestingStartLoading('product'));

            // alert('sending action.payload.args: ');
            // alert(action.payload.args);
            //fetch product by id
            const response = yield call(fetchProduct, action.payload.args);

            //normalize (Response data structure depends on what is returned from server )
            // console.log('response.data[0]: ' + JSON.stringify(response.data[0]));
			const data = normalize(response.data, productSchema); 

            yield put(addProduct({
				entities: data.entities,
				result: data.result
			}));

            //count current products
            const checkNumProducts = (yield select()).data.products.products;
            let numProductsTotal = Object.keys(checkNumProducts).length;

            yield put(addKeyValue({
				key: 'numProductsTotal', 
				value: numProductsTotal,
				state_key: STATE_KEY,
			}));
			
            yield put(successStopLoading('product'));

        }catch (e) {
            console.log('failed: ' + e.message);
            yield put(requestDataFailure(e.message));
        }
    }

}

export function* getProducts(action) {
	console.log('getProducts!');	
	
	//get current product sort option
	const sortOption = (yield select()).sort.creationsSortingToolbar.sortOption

	//get delivery country
	const deliveryCountryId = (yield select()).session.delivery_country_id; 
	cl(deliveryCountryId, 'deliveryCountryId id is ')
	//(not in use) - check available products
	// const checkProducts = (yield select()).data.products.products;
	// if(Object.keys(checkProducts) === 0 && checkProducts.constructor === Object) {

	// 	console.log('products already exist!');	
			
	// } else {}


		try {
			console.log('productsdont exist - fetching');
			yield put(requestingStartLoading('products'));
 
			//add sort option to args
			action.payload.args.sortoption = sortOption;
			//add deliveryCountryId to args
			if(deliveryCountryId){
				action.payload.args.delivery_country_id = deliveryCountryId;
			} 
			console.log(action.payload.args);
 
			const response = yield call(fetchProducts, action.payload.args);
			 
			console.log( 'here is response.data.data', response.data.data);
 
			//normalize	 
			const data = normalize(response.data.products.data, productListSchema);	//response.data.products.data
 
			console.log('normalized prods: ', data);

			yield put(addReplaceProducts({ 
				products: data.entities
			}))
			
			yield put(addResult({
				result: data.result,
				stateKey: STATE_KEY,
			}));

			//add number of pages + total query to shop creations reducer

			cl(response.data.products.total, 'getProducts, total result set: ')

			let pageType =  'products';
			let payload = {
				currentSet: response.data.products.current_page,
				currentQueryTotalSet: response.data.products.total,
				numPages: response.data.products.last_page,
				from: response.data.products.from,
				to: response.data.products.to
			}
			yield put(addPageQueryDetails(pageType, payload))
		
			yield put(addKeyValue({
				key: 'numProductsTotal', 
				value: response.data.total,
				state_key: STATE_KEY,
			}));
			//also, add the root term id to terms
			
			// console.log('adding shop root filter term: ' + response.data.roottermid);
			// yield put(addShopFilter({
			// 	id: response.data.roottermid,
			// 	name: response.data.roottermname,
			// 	selector: 'terms'
			// }))

			yield put(successStopLoading('products'));
			 yield put(requestDataSuccess('products'));
		} catch (e) { 
			 
			console.log('failed: ' + e.message);
			yield put(requestDataFailure(e.message));
		}
	
}

/**
 * Selectors
 */


export const selectHydratedId = (state, id) => 
	denormalize(selectAll, [termAttributeSchema], state.data.products);
  	 

export const selectModule = state => state.data.products.products || {};
export const designerId = state => state.data.designers[0].id || 3;

export  const selectProductsStateProperty = state => state.data.products;

export const selectAll = createSelector(
	selectModule,
	products => products
 	
);
 
export const getNumProductsTotal = createSelector(
	selectModule,
	products => {
		let total = Object.keys(products).length;
		return total;
	}
);

// export const selectVisibleIds = createSelector(
// 	selectModule,
// 	module => module.visibleProducts
// );

// export const selectVisible = createSelector(
// 	selectAll,
// 	selectVisibleIds,
// 	(all, visibleIds) => {		
// 		return visibleIds != null ? visibleIds.map(id => all[id]) : [];
// 	} 
// );
 
export const selectProductIds = (state) => Object.getOwnPropertyNames(state.data.products.products);


export const selectVisibleProductIds = (state) => {
	// cl('product selectors - getting selectVisibleProductIds:');
	// cl( state.data.products.visibleProducts);
	return state.data.products.visibleProducts;
}


export const selectProductIdsByDesignerId = (state, designerId) => {

	console.log('selectProductIdsByDesignerId: getting product ids by designer id: ' + designerId)
	let products = state.data.products.products;

	let productIdsByDesignerId = Object.getOwnPropertyNames(products).filter( product => product.designer_id == designerId );

	cl(productIdsByDesignerId, 'productIdsByDesignerId: ', true);
	return productIdsByDesignerId;
	
}



export const selectProductById = (state, productId) => { 
    console.log('selectProductById: the chosen product: ', state.data.products.products[productId])
	return state.data.products.products[productId];
}

export const selectProductDesignerById = (state, productId) => {
	 
	let product = state.data.products.products[productId];
	let designerId = product.designer;

	//get designer

	return state.data.designers.all[designerId];
}
 
export const selectTermAttributes = (state) => state.data.products.term_attributes;

export const selectTermCategories = (state) => state.data.products.term_categories;

export const selectAttributes = (state) => state.data.products.attributes;
export const selectTerms = (state) => state.data.products.terms; 
// export const selectFeatured_images = (state) => state.data.products.featured_images;


export const selectShippingGeneralMethods = (state) => {
	let methods = state.data.products.shipping_general_methods;
	//console.log('methods: ' + JSON.stringify(methods));
	return methods;
}
 
export const selectProduct2 = createSelector(
	[selectProductById],
	product => {
		return product;
	}
)

export const selectProduct = createSelector(
	[selectProductById],
	product => {
        console.log('the product will be returned: ', product)
		return product;
	}
)

export const getDenormalizedProduct = (state, id) => {
	return denormalize(
		id,
		productSchema,
		state.data.products
	)
}

//strange workaround - this will just return the id sent in
export const selectReturnId = (state, id) => {

//	console.log('selectReturnId: ' + id);
	return id;
}
 
 
export const selectDenormalizedProduct = createSelector(
	
	[selectProductsStateProperty, selectReturnId],
	(productsProperty, id) => {
		//check if there are products
		if(notObjectOrNotEmptyObject(productsProperty.products)){
			// cl('selectDenormalizedProduct: there were no products in state')
			return;
		} 

		let denormalizedProduct = denormalize
		(
			id,
			productSchema,
			productsProperty
		) 
		return denormalizedProduct;
	}

)

//return the 'products' property from the designer in state.data.designers.all
export const selectDesignerProductsProperty = (state, productId, designerId) => {
	// console.log('selectDesignerProductsProperty: productId to be returned: ' + productId);
	// console.log('selectDesignerProductsProperty: designerId: ' + designerId);

	return state.data.designers.all[designerId].products;
}

export const selectDenormalizeProductFromDesigner = createSelector(
	
	[selectDesignerProductsProperty, selectReturnId],
	(productsProperty, productId, designerId) => {
		// console.log('selectDenormalizedProduct: productId returned: ' + productId);
		// console.log('selectDenormalizedProduct: selected productsProperty:  : ' , productsProperty);

		return denormalize
		(
			productId,
			productSchema,
			productsProperty
		)
	}

)


// export const getChallenge = createSelector(
// 	getState, 
// 	({ entities, challenge }) => {
// 		return denormalize
// 		(
// 			entities.challenges[challenge.item], 
// 			entities, schema
// 		)
// 	}
// )

// export const selectDenormalizedProduct = (state, id) => {
//     console.log('denormalize product: ' + id);
//     return createSelector(
// 		denormalize(
// 			id,
// 			productSchema,
// 			state.data.products
// 		)
// 	)
// };




export const selectProductIdBySlug = (state, slug) => {

   console.log('selectProductIdBySlug, slug is ' + slug);
   const products = state.data.products.products;
	const ids = Object.keys(products);
	
	return ids.reduce((result, id) => {
		if (id in products && 'slug' in products[id] && products[id]['slug'] == slug) {
			result = id;

			console.log('there was a match for ' +  result);
        }
        
		return result;
	}, null);
};

 /*
const deNormalizeProductTermAttributes = (product, selectedTermAttributes, selectedAttributes, selectedTerms) => {
	//get each term attribute id from normalized object
	const termAttributes = [];

	Object.values(product.term_attributes).forEach(id => {
		//console.log('mapping selected prod term_attributes. id is : ' + JSON.stringify(id));

		//get the term attribute object using the termAttribute id
		let termAttribute = selectedTermAttributes[id];
		let attributeId = termAttribute.attribute;
		// console.log('the termAttributes attribute is: ' + attributeId)
		// console.log('the attribute itself (from products) is : ' + selectedAttributes[attributeId])
		let termId = termAttribute.term;

		let newTermAttribute = {
			id: id,
			attribute: selectedAttributes[attributeId],
			term: selectedTerms[termId]
		}
		termAttributes.push(newTermAttribute); 
	
	});

	return termAttributes;
}

const deNormalizeProductTermCategories = (product, selectedTermCategories,selectedTerms,termCategories) => {

	let deNormalizedTermCategories = [];
	Object.values(product.term_categories).map(id => {

		//get the termCategory object using the termCategory id
		let termCategory = selectedTermCategories[id];
		let termId = termCategory.term;

		let newTermCategory = {
			id : id,
			term: selectedTerms[termId]
		}
		termCategories.push(newTermCategory);
		// console.log('newTermCategory : ' + JSON.stringify(newTermCategory));
	});	

	return deNormalizedTermCategories;
}

 //Build denormalized product Designer object from product
 
const deNormalizeProductDesigner = (product,selectedAllDesigners,selectedShippingGeneralMethods) => {

	//get associated product designer
	let designerId = product.designer;
	let designer = selectedAllDesigners[designerId];

	let shipping_general_method_id = designer.shipping_general_methods[0];
			
	let newDesigner = {
	 shop_currency : {
		 ...designer.shop_currency
	 },
	 shop_name: designer.shop_name,
	 permalink: designer.permalink,
	 shipping_general_methods: [{
		 ...selectedShippingGeneralMethods[shipping_general_method_id]
	 }]
	}

	return newDesigner;
};

*/