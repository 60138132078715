import { put, call } from 'redux-saga/effects';
import { normalize, denormalize } from 'normalizr';
import { createSelector } from 'reselect';
import { termNodeListSchema } from '../schemas';
import { 
	ADD_KEY_VALUE,
	ADD_ENTITIES, 
	addEntities, 
	requestDataFailure,
	addKeyValue,
} from '../actions';
import api from '../api';
import {cl} from 'utils'
export const STATE_KEY = 'terms';

const initialState = {
	termNodes: {},
	terms: {},
	generalAttributes: window.d_store.general_attributes || []
};

export const ADD_TERMS = 'ADD_TERMS';

export const addTerms = (terms, rootCategories) => ({
	type: ADD_TERMS,
	payload: {terms, rootCategories},
});

export default function reducer(state = initialState, action) {
	switch (action.type) {

		case ADD_TERMS:
			console.log('modules-terms reducer: ADD_TERMS');
			cl(action.payload)
			//alert('termNodes: ' + JSON.stringify(action.payload.termNodes));
			return {
				...state,
				termNodes: {
					...action.payload.terms.termNodes,
				},
				terms: {
					...action.payload.terms.terms,
				},
				rootCategories: {
					...action.payload.rootCategories
				}
			};
		case ADD_KEY_VALUE:
			if (action.payload.state_key === STATE_KEY) {
				return {
					...state,
					...action.payload.kvp,
				};
			} else {
				return state;
			}
		default:
			return state;
	}
}

export function* getTerms(action) {
	try {
		const response = yield call(api.fetchTerms);
		cl(response, 'response terms: ')
		const data = normalize(response.data.terms, termNodeListSchema);
		console.log('putting normalized getTerms:', data);
		
		yield put(addTerms(data.entities, response.data.root_categories));
/*
		let entities = {};
		const visit = (terms) => {
			terms.forEach((term) => {
				entities[term.id] = {
					id: term.id,
					name: term.name,
					children: term.children.map((child) => child.id),
					attributes: [],
				};
				visit(term.children);
			});
		};

		visit(response.data);
		console.log('entities', entities);
*/
	} catch (e) {
		yield put(requestDataFailure(e.message));
	}
}

/**
 * Selectors
 */

export const selectHydrated = (state, id) => 
	denormalize(id, termNodeListSchema, state.data);

export const selectModule = state => state.data.terms || {};

export const selectTermNodes = state => state.data.terms.termNodes || {};

export const selectAll = createSelector(
	selectModule,
	terms => terms.terms
);

export const selectTermNodesIds = state => state.data.terms.generalAttributes;
 