import React, {Component} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { reduxForm, Field, FieldArray, getFormValues } from 'redux-form';
import Alert from 'components/Alert';
import DaylicacyButton from 'components/DaylicacyButton';
import { DaylicacyCheckbox } from 'components/Form'; 
import DaylicacyTextField from 'components/Form/DaylicacyTextField';
import FileField from 'components/FileField';
//import { MenuItem } from 'material-ui-next/Menu';
import {cl} from 'utils'

import {Checkbox, Select, TextField} from 'redux-form-material-ui';

import CircularProgress from '@material-ui/core/CircularProgress'
import FormControlLabel from '@material-ui/core/FormControlLabel';

import MenuItem from '@material-ui/core/MenuItem';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody'; 
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import MuiCheckbox from '@material-ui/core/Checkbox';
 
import {actions} from '../CreateEditShippingGeneral/actions';
import {actions as customProfileActions} from '../CustomProfiles/actions';

/*
	Submit: see "handleSubmit in EditShippingGeneral"

	Form api method: 		updateShippingGeneral
	Route:					api/designer/' + designerId + '/shipping/general'
	function:				postDesignerShippingGeneral
  
	initialValues : {
		methods: [
			0 : {
				id: #,
				zone: {
					countries: [ {}]
				}
			}
		]
	}


*/
 
/* Table row for existing methods or new (New row by Fieldarray - pressing 'Add') */
const GeneralMethodTableRow = ({props, methods, method, methodRowIndex, tr, fields}) => {
 
	 
	//No "Remove" option for existing methods:
	//See the number of existing methods in initial values
	
	var existingMethodsCount = props.initialValues.methods.length;
	cl('There are now ' + existingMethodsCount + ' existing methods.')
	var skipRemove = false;

	//Existing methods will be initialized from index 0 and upwwards
	//See if current method row index is for one of the existing (already saved) methods
	if(methodRowIndex <= existingMethodsCount-1){
		cl('Existing method is on method row ' + methodRowIndex)
		skipRemove = true;
	}

	cl(props.chosenMethodTypes, 'chosen MethodTypes are: ')

	var formValuesProfileMethod = props.formValues.methods[methodRowIndex]
	cl(formValuesProfileMethod, 'current formValuesMethod') 
// 

	// var profileZoneMethod = formValues.methods
	 
	/*
		props={props}
		methodRowIndex={index}
		key={'methods_' + index} 
		methods={methods}
		method={method}
		tr={props.tr}
	*/
	  
		// console.log(JSON.stringify(props.shippingZones));
		// console.log(JSON.stringify(props.shippingSuppliers));
		// console.log(JSON.stringify(props.shippingMethods));
  
	return(
		<TableRow
		>
			<TableCell>
				<MuiCheckbox className="muicheckbox"
				/>
			</TableCell>
			<TableCell>
				{/* "hidden" field - shippingProfileZoneMethod id */}		
				<Field 
					 disabled
					name={`methods[${methodRowIndex}][id]`} 
					component={TextField}		 
				/>
			</TableCell>
			
			<TableCell className="mui-select-container">
 
					<Field 
						// disabled={disabledZones}
						name={`methods[${methodRowIndex}][shipping_zone_id]`}		//shipping_zone_id		method${[index][id]}
						placeholder="Zone"
						component={Select}
					>
						{/* add default value 0: 'All countries' (all countries included - no zone) */}
						<MenuItem 
							// disabled={disabledAllCountriesOption}
							className="shipping_zone_all" 
							key={'shipping_zone_all'} 
							value={0}>
								All countries
							</MenuItem>
						{Object.values(props.shippingZones).map((shippingZone, index) => (
							
							<MenuItem
								
								key={'shipping_zone_' + index}
								value={shippingZone.id} 
							>
								{shippingZone.name}
							</MenuItem>
					)) }
				</Field> 
			</TableCell>
			<TableCell>
					<Field 	  
					
					name={`methods[${methodRowIndex}][shipping_method_id]`}
					placeholder="Method"
					component={Select}
				>
					{/* blank menuitem (to set temporarily while changing methods)  */} 
					<MenuItem value>
					</MenuItem>
					{ props.shippingMethods.map((shippingMethod, index) => {
						
						// disable a method type if it's in the array and not already chosen on this input 
						let currentMethodIsChosen = props.chosenMethodTypes.indexOf(shippingMethod.id) >= 0
						let notChosenOnThisInput = shippingMethod.id !== formValuesProfileMethod.shipping_method_id
						  
						let disabledMethodOption = currentMethodIsChosen && notChosenOnThisInput;

						return(
						<MenuItem 
							disabled={disabledMethodOption} 
							key={'shipping_method_' + index}
							value={shippingMethod.id} 
						>
							{shippingMethod.name}
						</MenuItem>
						)
					
					}) }
				</Field> 
			</TableCell>
			<TableCell>
					<Field 	
					name={`methods[${methodRowIndex}][shipping_supplier_id]`}
					placeholder="Supplier"
					component={Select}
				>
					{/* Add a blank option, then loop supplier options */}
					<MenuItem
								key={'shipping_supplier_0'}
								value='unspecified'
							>	
							{tr.unspecified || 'Unspecified'} 
					</MenuItem>

					{ props.shippingSuppliers.map((shippingSupplier, index) => (
						<MenuItem
							key={'shipping_supplier_' + index}
							value={shippingSupplier.id} 
						>
							{shippingSupplier.name}
						</MenuItem>
					)) }
				</Field> 
			</TableCell>
			<TableCell>
					<Field  			
						name={`methods[${methodRowIndex}][delivery_from]`}
						placeholder="From"
						component={TextField}
					/> 
			</TableCell>
			<TableCell>
					<Field 
						name={`methods[${methodRowIndex}][delivery_to]`}
						placeholder="To"
						component={TextField}
					/> 
			</TableCell>
			<TableCell>
					<Field  
					name={`methods[${methodRowIndex}][min_order_cost_free]`}
					placeholder="Cost"
					component={TextField}
				/> 
			</TableCell>
			<TableCell>
					<Field 
					name={`methods[${methodRowIndex}][max_shipping_cost]`}
					placeholder="Cost"
					component={TextField}
				/> 
			</TableCell>
			<TableCell>
					<Field
					name={`methods[${methodRowIndex}][charge_single]`}
					placeholder="Charge single"
					component={TextField}
				/> 
			</TableCell>
			<TableCell>
					<Field
					name={`methods[${methodRowIndex}][charge_combined]`}
					placeholder="Charge combined"
					component={TextField}
				/> 
			</TableCell>
			<TableCell>
				{!skipRemove &&  
					<DaylicacyButton 
						onClick={() => props.fields.remove(methodRowIndex)}
					>
						{props.tr.remove || 'Remove'}
					</DaylicacyButton> 
				}
			</TableCell>
		</TableRow>
	)
}

//FieldArray - ShippingProfileZoneMethods
const ShippingProfileZoneMethods = (props) => {
  
	// cl('ShippingProfileZoneMethods, here is form values: ')
	// cl(props.formValues);
 
	var methods = []; 
	var disabledAllCountriesOption = false;
	// if(props.initialValues && 'methods' in props.initialValues){
	// 	methods = props.initialValues.methods; 
 
	// }
 
	return (
	<div className="table-container">
		
		 <Table className="shipping-default-profile-form-table">
			<TableHead>
				<TableRow>
					<TableCell>
						<MuiCheckbox className="muicheckbox"
						/>
					</TableCell>
					<TableCell className="tableCol13">Id</TableCell>
					<TableCell className="tableCol13">Country group</TableCell>
					<TableCell>Method</TableCell>
					<TableCell>Supplier</TableCell>
					<TableCell className="tableCol13">Delivery between</TableCell>
					<TableCell className="tableCol13">and</TableCell>
					<TableCell>Free shipping for orders over</TableCell>
					<TableCell>Maximum shipping cost</TableCell>
					<TableCell>Charge single</TableCell>
					<TableCell>Charge combined</TableCell>
					<TableCell></TableCell>
				</TableRow>
			</TableHead>
 
			<TableBody className="tableBody">
				

					{ props.fields.map ( (method, index) => (
						<GeneralMethodTableRow 
							props={props}
							methodRowIndex={index}
							key={'methods_' + index} 
							methods={methods}
							method={method}
							tr={props.tr}
						/>
					)
					
					)}
			
			</TableBody>
			
		</Table> 
		
		<DaylicacyButton
			onClick={ () => {
				 
				props.fields.push({}) 
				}
			}
		>
			{props.tr.add_new_method || 'Add new method'}
		</DaylicacyButton>
		<div className="row">
			{disabledAllCountriesOption && (
				<p className="notice1">
					Note: If any existing method contains all countries, you can't create any more methods for this profile
				</p>	
			)}
				
		</div>
	</div>
	  
)
}

const alertDuration = 3500;

//used both for the default profile and custom profiles
class CreateEditShippingMethodsForm extends Component {
 
	constructor(){
		super()

		this.state = {
			disabledAllCountriesOption: false,
			formPostedCount: 0	
		}
	}

	componentDidUpdate(prevProps) {
		// const { formValues: {methods}, change } = this.props;
		const { methods: prevMethods } = prevProps;

		const formValuesMethods = this.props.formValues.methods;
		const formValuesPrevMethods = prevProps.formValues.methods;

		cl(formValuesPrevMethods.length, 'formValuesPrevMethods.length is: ');
		cl(formValuesMethods.length, 'formValuesMethods.length is: ');

		// Do nothing if no formValuesMethods, or no change to formValuesMethods
		if (!formValuesMethods || formValuesMethods === formValuesPrevMethods 	) {
		  return;
		}
			
		var newMethodDefaultValue = {
			shipping_zone_id: 0
		}

		var existingMethodsCount = this.props.initialValues.methods.length;
		 
		//initialize the first added methods' zone to 'all countries'
		if (!formValuesPrevMethods || formValuesMethods.length > formValuesPrevMethods.length) {
			// alert('!formValuesPrevMethods || formValuesMethods.length > formValuesPrevMethods.length')
		  this.props.change('methods', formValuesMethods.slice(0, formValuesMethods.length - 1).concat(newMethodDefaultValue)); 
		}
		
		// if (formValuesPrevMethods && methods.length === formValuesPrevMethods.length) {
		//   const changedIdx = formValuesMethods.reduce((acc, member, idx) => typeof acc === 'number' ? acc : member === formValuesPrevMethods[idx] ? null : idx, null);
		//   const hobbies = formValuesMethods[changedIdx].hobbies;
		//   const prevHobbies = formValuesPrevMethods[changedIdx].hobbies;
	
		//   if (prevHobbies && hobbies.length > prevHobbies.length) {
		// 	change('formValuesMethods', formValuesMethods.slice(0, changedIdx)
		// 	  .concat({...formValuesMethods[changedIdx], hobbies: hobbies.slice(0, hobbies.length - 1).concat('Another default hobby')})
		// 	  .concat(formValuesMethods.slice(changedIdx + 1)));
		//   }
		// }
	  }

	resetPostStatus = () => {
		cl('resetPostStatus')
		//reset status (Alert will not show)
		//add some extra time for the fade to complete

		setTimeout(() => {

			//reset post status of current profile type
			if(this.props.profileType == 'default'){
				this.props.resetGeneralShippingStatus();
			}else if(this.props.profileType == 'custom'){
				this.props.resetCustomShippingStatus();
			}
			

			cl('formPostedCount will be incremented')
			this.setState({ 
				formPostedCount: this.state.formPostedCount +1
			})

		}, alertDuration );

	 
	}
	render() {
		const {invalid, pristine} = this.props;

		cl(alertDuration, 'alertDuration is ')
		//Disable method type option if chosen:
		//Only one method type (Standard, express..) per type can be chosen per profile
		
		let methodsExist = this.props.formValues.methods.length ? true : false;
		 
		var chosenMethodTypes = methodsExist ? [] : false;
		var disabledMethodTypeIds = methodsExist ? [] : false; 
  
		disabledMethodTypeIds && this.props.formValues.methods.map( profileZoneMethod => { 
			chosenMethodTypes.push(profileZoneMethod.shipping_method_id);
		})
		 
		return (
		<form className="shipping-default-profile-form" onSubmit={this.props.handleSubmit}> 
			
			<FieldArray
				// initialValues={props.initialValues}
				tr={this.props.tr}
				name="methods"
				initialValues={this.props.initialValues}
				formValues={this.props.formValues}
				component={ShippingProfileZoneMethods}					//<--- ShippingProfileZoneMethods    
				shippingMethods={this.props.shippingMethods}
				shippingSuppliers={this.props.shippingSuppliers}
				shippingZones={this.props.shippingZones}
				chosenMethodTypes={chosenMethodTypes}
			/>
			
			
			<div className="row">
				<div className="cx"> 
					<DaylicacyButton  
						type="submit" 
						name="save" 
						disabled={  pristine || invalid}
					>
					{this.props.tr.save || 'Save'} 
					</DaylicacyButton>
				</div>
			</div> 
			{ this.props.status === 'failed' && 
				<Alert cssClasses="alertbox alert-error" >{this.props.errorMsg}</Alert>
			}
			{ this.props.requestError && 
				<div>{this.props.requestError}</div>
			}
			{ this.props.status === 'success' &&
			<h3>Status is SUCCESS</h3>
			 }
			{ this.props.status === 'success' &&
				<Alert delay={alertDuration} formPostedCount={this.state.formPostedCount} resetPostStatus={this.resetPostStatus} cssClasses="alertbox alert-success" >Saved</Alert>
			}
		
		</form>
		)
	}
}

CreateEditShippingMethodsForm.propTypes = {
	isLoading: PropTypes.bool.isRequired,
	status: PropTypes.string.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,
	shippingMethods: PropTypes.array.isRequired,
	shippingSuppliers: PropTypes.array.isRequired,
	shippingZones: PropTypes.array.isRequired,
};

const validate = values => {
	cl(values, 'validate values: ')
	let errors = {};
 

	const required = [
		'shipping_zone_id',
		'shipping_supplier_id',
		'shipping_method_id',
		'delivery_from',
		'delivery_to',
		'charge_single',
		'charge_combined',
		'min_order_cost_free',
		'max_shipping_cost'
	];


	var methodsErrors = []

	required.forEach(field => {
		values.methods.forEach( (method, index) => {
  
			if (!(field in method) || (field in method && typeof method[field] === "undefined") || 
					(field in method && typeof method[field] !== "undefined" &&  method[field].toString().length == 0)){
				  
				methodsErrors[index] = {[field]: ['Required'] }
				// errors[`methods[${index}][${field}]`] = 'Required';
				
			//check if shipping_method_id is not int (and not 'true' if blank )
			}else if( field == 'shipping_method_id' && !(typeof method[field] === "number")) {  
				methodsErrors[index] = {[field]: ['Required'] } 
			} 
		} )
		
	});
	if(methodsErrors.length){
		errors.methods = methodsErrors;
	}
	

	cl(errors, 'errors: ')

	return errors;
};

 

CreateEditShippingMethodsForm = connect(
	state => ({
		formValues: getFormValues('CreateEditShippingMethodsForm')(state),
	}),
	{resetGeneralShippingStatus: actions.resetGeneralShippingStatus,
	 resetCustomShippingStatus: customProfileActions.resetCustomShippingStatus
	}
)(CreateEditShippingMethodsForm)

export default CreateEditShippingMethodsForm = reduxForm({
	form: 'CreateEditShippingMethodsForm',
	enableReinitialize: true,
	validate,
})(CreateEditShippingMethodsForm);


//   indeterminate={numSelected > 0 && numSelected < rowCount}
//   checked={numSelected === rowCount}
//   onChange={onSelectAllClick}



