/* Reducer boilerplate											*/
import { combineReducers } from 'redux';
import * as types from './actionTypes';
// import { SOME_ACTION } from 'containers/Something/actions';
import {cl} from 'utils';

//state/sort
//sort option is being sent with every request
const initialState = {
	  
	creationsSortingToolbar: {
		sortOption: 'recent',
		perPageValue: 1,
		numColumnsValue: 1,
		currentPage: 1,
		
	},
	designersSortingToolbar: {
		sortOption: 'a-z',
		perPageValue: 1,
		numColumnsValue: 1,
		currentPage: 1,
		
	}
};


const creationsSortingToolbarReducer = (state = initialState.creationsSortingToolbar, action) => {

	switch(action.type) {
		
		case types.SET_SORT_OPTION:

		if(action.payload.pageType == 'creations') {

			cl(action.payload, 'creationsSortingToolbarReducer - responding to action SET_SORT_OPTION');

			return {
				...state,
				sortOption: action.payload.value,
			};

		}else{
			return state;
		}
	 
	default:
		return state; 
			 
	}
};
const designersSortingToolbarReducer = (state = initialState.designersSortingToolbar, action) => {

	switch(action.type) {
		
		case types.SET_SORT_OPTION:
		 
		if(action.payload.pageType == 'designers') {

			cl(action.payload, 'designersSortingToolbarReducer - responding to action SET_SORT_OPTION');

			return {
				...state,
				sortOption: action.payload.value,
			};

		}else{
			return state;
		}
		 
	default:
		return state; 
			 
	}
};

//if using more than 1 reducer, combine them
const sortReducer = combineReducers({
	creationsSortingToolbar: creationsSortingToolbarReducer,
	designersSortingToolbar: designersSortingToolbarReducer,
});
export default sortReducer;


