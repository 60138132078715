import React from 'react';
import { connect } from 'react-redux';

import { selectDenormalizeProductFromDesigner } from 'data/modules/products';
import {selectModule} from 'data/modules/countries';
import PropTypes from 'prop-types';
import { truncate } from 'lodash';
import { Link } from 'react-router-dom';
import { Card, CardTitle, CardText } from 'material-ui-old/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';

import CreationControls from 'components/CreationControls';



const shortenProductName = (name) => {
	return truncate(name, {
		length: 19,
	});
};

let DesignerProductCard = props => {

	//if (props.product == null || props.designer == null) return null;
 
	const {
		tr,
		productId,
        product,
        currency
	} = props;
 

	// const shipping = product.designer.shipping_general_methods.length ? 
    // product.designer.shipping_general_methods[0] : null;
	
	if (!product) {

		return (
			<div className="mui-circular-container"> <CircularProgress/></div>
		);
	}
	 
	const imgUrl =  product.gallery && product.gallery.items.length && product.gallery.items[0].url ?
        		   product.gallery.items[0].url :
				   '/img/daylicacy-logo_woman-name.jpg';

	const price_string_in_currency = 'price_string_in_' + currency;		

	const permalink = product.permalink !== undefined ? product.permalink : ''; 

   
//   console.log(price_string_in_currency + ', ' + product[price_string_in_currency]);

//   <img src={imgUrl || '/img/primary-logo.png'} style={{ display: 'block' }} /> 


	return(
	
		<div className="card-container cx12 cs6 cm3 cl3"> 
			<div>
				 <Link to={permalink}>
					<CardMedia className="card-img" image={imgUrl || "/img/primary-logo.png"}>
			
					</CardMedia>
				</Link> 
			<CreationControls productId={productId} product={product} />
			 <CardHeader  
				className="product-card-header"
                style={{ padding: '8px 16px' }}
                title={ <Link to={{
					pathname: permalink,
					state: {
                        productId: productId,
                        product: product
					}
				}}>
						{shortenProductName(product.title)}
					</Link> }
				 
			>
				
			</CardHeader>
			 <CardContent className="cardcontent-product-title">
			
				<Link to={permalink}>{shortenProductName(product.name)}</Link>
			</CardContent> 
			<CardContent>
				<div className="price">
					{ product.discount > 0 &&
						<s style={{fontWeight: 'bold'}} className="original-price"><span>{product[price_string_in_currency]}</span></s> }
					<span style={{fontWeight: 'bold'}} className="current-price">
						<span>{product.current_price_string} </span>
					</span>
				</div>
				{/* { shipping && 
					<div className="shipment">
						<i className="fa fa-truck" aria-hidden="true"></i> {tr.free_shipping_for} <span>{shipping.min_order_cost_free_string}</span>
					</div>
				}  */}
			</CardContent>
			</div>
		</div>
		 
	);
};
	
 
DesignerProductCard.propTypes = {

	product: PropTypes.shape({
		permalink: PropTypes.string.isRequired,
	}).isRequired
};


{/* reselect: Memoization for each product: If we pass makeMapStateToProps to connect, 
	each instance of the container will get its own mapStateToProps function with a private selector. */}

const makeMapStateToProps = () => {

	const mapStateToProps = (state, ownProps) => {

		// console.log('DesignerProductCard: productId is: ' + ownProps.productId + ', designer id is ' + ownProps.designerId)
		return {
			product: selectDenormalizeProductFromDesigner(state, ownProps.productId, ownProps.designerId)
			
		}
	}
	return mapStateToProps
}

DesignerProductCard = connect(
	makeMapStateToProps
)(DesignerProductCard);

// DesignerProductCard = connect(
// 	(state, ownProps) => ({ 
//         countries: selectModule(state),
// 		//product: selectProduct(state, ownProps.productId)
		 
// 	})
// )(DesignerProductCard);

export default DesignerProductCard;

/*
	const {
		tr,
		productId,
		currency,
		product: {
			id,
			featured_image: { sizes: { featured: image } },
			name: title,
			current_price: currentPrice,
			price: originalPrice,
			discount,
			designer,
		},
		product
	} = props;

*/
