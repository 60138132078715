import { types } from './actions';

const initialState = {
	initialValues: {},
	isLoading: false,
	status: '',
	errorMsg: '',
	designerProducts: [],
};

const reducer = (state = initialState, action) => {
	switch(action.type) {
		case types.FETCH_SHIPPING_PROFILE_SUCCESS:
			let products = {};
			if (action.payload.result.data.products) {
				action.payload.result.data.products.forEach(product => {
					products[product.id] = true;
				});
			}
			
			return Object.assign({}, state, {
				initialValues: {
					...action.payload.result.data,
					products,
				}
			});
		case types.CREATE_SHIPPING_PROFILE:
		case types.UPDATE_SHIPPING_PROFILE:
			return Object.assign({}, state, {
				isLoading: true,
			});
		case types.CREATE_SHIPPING_PROFILE_SUCCESS:
		case types.UPDATE_SHIPPING_PROFILE_SUCCESS:
			return Object.assign({}, state, {
				isLoading: false,
				status: 'success',
			});
		case types.CREATE_SHIPPING_PROFILE_FAILURE:
		case types.UPDATE_SHIPPING_PROFILE_FAILURE:
			return Object.assign({}, state, {
				isLoading: false,
				status: 'failed',
				errorMsg: action.payload.error.response.data.message
			});
		case types.FETCH_PRODUCTS_SUCCESS:
			return Object.assign({}, state, {
				designerProducts: action.payload.result.data.data,
			});
		default:
			return state;
	}
};

export default reducer;
