import { createActionTypes, createActions } from 'utils';

const types = createActionTypes([
	'VIEW_EDIT_SHIPPING_GENERAL',
	'VIEW_EDIT_SHIPPING_CUSTOM_PROFILES',
	'VIEW_CREATE_EDIT_SHIPPING_CUSTOM',
	'VIEW_SHIPPING_ZONES',
	'VIEW_EDIT_SHIPPING_ZONE',
	'VIEW_LIST_SHIPPING_PROFILES',
	'VIEW_EDIT_SHIPPING_PROFILE',
	'VIEW_CREATE_SHIPPING_ZONE'
]);

const actions = createActions({
	[types.VIEW_EDIT_SHIPPING_GENERAL]: (defaultProfileId) => ({defaultProfileId}),
	[types.VIEW_EDIT_SHIPPING_CUSTOM_PROFILES]: () => ({}),
	[types.VIEW_CREATE_EDIT_SHIPPING_CUSTOM]: (customProfileId) => ({customProfileId}),
	[types.VIEW_SHIPPING_ZONES]: () => ({}),
	[types.VIEW_EDIT_SHIPPING_ZONE]: (shippingZoneId) => ({ shippingZoneId }),
	[types.VIEW_LIST_SHIPPING_PROFILES]: () => ({}),
	[types.VIEW_EDIT_SHIPPING_PROFILE]: (shippingProfileId) => ({ shippingProfileId }),
	[types.VIEW_CREATE_SHIPPING_ZONE]: () => ({})
});

export { types, actions };
