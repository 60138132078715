import { createSelector } from 'reselect';

const getHeader = state => state.interface.header;
const getSession = state => state.session;

export const getSearchField = createSelector(
  getHeader,
  header => header.searchField,
);

export const getCart = state => state.shop.cart;

export const getCartOpen = createSelector(
  getCart,
  cart => cart.open
);

/* Should be moved to containers/Cart/selectors */
/* Should get product ids instead of creations */
export const getCreations = createSelector(
  getCart,
  cart => cart.creations
);

export const selectUserSignedIn = createSelector(
	getSession,
	session => session.signedIn
);

export const getUserLang = createSelector(
	getSession,
	session => session.language
);
