import React from 'react';

const PageNotFound = () => 
    <div className="container"> 
        <div className="row not-found-page">
                <div className="cx12">
                    <h1>404</h1>
                </div>
                <div className="cx12">
                    <p>That page doesn't exist.</p>
                </div>
                 
        </div>
    </div>
	


export default PageNotFound;