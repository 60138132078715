import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { actions } from './actions';
import { actions as shippingActions  } from 'views/Account/Myshop/Shipping/actions';
import EditShippingZoneForm from './components/EditShippingZoneForm';
import {selectShippingZoneCountriesExceptCurrent} from 'data/modules/shippingProfiles';
import {cl} from 'utils'
import { throttle } from 'lodash';

/*
	parent: shipping/index.js

	"fetchCountriesAndContinents" 		calls api route: 'countries', function getCountries()
	"fetchShippingZone":
				api route: 	api/shipping/zone/' + shippingZoneId, function getCountries()
				function:	getShippingZone
*/

class CreateEditShippingZone extends Component {

	constructor(props) {
		super(props);

		this.state = {
		 
			selectedContinent: 0,
			formPostedCount : 0
		}

		this.onContinentChange = this.onContinentChange.bind(this);
		this.handleSubmit = throttle(this.handleSubmit.bind(this), 4000, {trailing:false});
 

		//fetch all countries
		if(this.props.countries.length == 0) {
			cl('CreateEditShippingZone: countries are empty - fetch countries and continents')
			this.props.fetchCountriesAndContinents();
		}else{
			cl('no fetch - countries length is ' + this.props.countries.length)
		}
		 
		//if shippingZoneId is provided, fetch the shipping zone
		if (this.props.shippingZoneId) {
			cl('shippingZoneId is ' + this.props.shippingZoneId)
			//fetch if initialValues is not set or the current shippingZoneId is not set in intialValues
			if(this.props.initialValues.length == 0 || typeof this.props.initialValues.id !== this.props.shippingZoneId){
				cl('CreateEditShippingZone: No initialvalues loaded for current shippingZoneId - fetch zone')
				this.props.fetchShippingZone(this.props.shippingZoneId);	
			}
			
		}
	}
  
	handleSubmit(data) {
		  
		//prepare form data 
		const payload = Object.assign({}, data, {
			countries: Object.keys(data.countries).map(id => parseInt(id)),
		});

		//UPDATE: if shippingZone is set - send form to update the zone
		if (this.props.shippingZoneId) {
 
			this.props.updateShippingZone(this.props.shippingZoneId, payload);
			 
			// this.setState({
			// 	formPostedCount: this.state.formPostedCount +1
			// })
			
		} else {
			
		//CREATE: if shippingZone is not set - send form to create the zone
		 this.props.createShippingZone(payload);
			 
			// this.setState({ 
			// 	formPostedCount: this.state.formPostedCount +1
			// })
			// this.props.viewShippingZones()
			
		}
		
	};

	onContinentChange(key){
	
		//key is tab index
		this.setState({
			selectedContinent: parseInt(key)
		}) 
	}
	
	render() {

		// return <p>ok</p>
		const { continents, otherZoneCountryIds, eucountries,redirectAfterFormPost, submitSucceeded} = this.props;

		
 
		if(!this.props.countries){
			<p>loading</p>
		}
 
		cl(this.props.shippingZoneId, 'shippingZoneId is ')

		var disabledCountries = [];			//just for logging
		
		var countryWasDisabled = false;
		if(continents && otherZoneCountryIds) { 
			cl(otherZoneCountryIds, 'current not selectable country ids (by selector)');

			//If Edit zone - take current zone's selected countries
			var zoneCountryIds = null;
			if(this.props.shippingZoneId && ('countries' in this.props.initialValues) ){
				 
				zoneCountryIds = Object.keys(this.props.initialValues.countries);
				cl(this.props.initialValues.name, 'zone name: ');
				cl(zoneCountryIds, 'current zones ids:')  
			} 
			//set 'disabled' on countries already taken in other zones
			
			Object.values(continents).forEach( continent => {

					//first reset all countries 'disabled' property  
					Object.values(continent).map( country => {

						country.disabled = false;
					});
					  
					//disable all countries included in disabledCountries array
					Object.values(continent).map( country => {
		
						otherZoneCountryIds.map( otherZoneCountryId => {

							if(otherZoneCountryId == country.id) { 
								disabledCountries.push(otherZoneCountryId);
								countryWasDisabled = true;
								return country.disabled = true;

							}else {
								// return country.disabled = false;
							}
						})
					}) 
					return continent;
				}
				
			)
			
		}
		  
		// let statusText = this.props.postStatus ? this.props.postStatus : 'NULL';
		
		return (
			<div>
				 <div className="title row cx12">
					{ this.props.shippingZoneId ? (<h2>Edit shipping zone </h2>) : (<h2>Create shipping zone </h2>)}
				</div>
				{/* <h1>postStatus: {statusText}</h1> */}
				{/* <h2>submitSucceeded: {submitSucceeded}</h2> */}
				<EditShippingZoneForm
					// formPostedCount={this.props.formPostedCount} 
				   	otherZoneCountryIds={otherZoneCountryIds}
					selectedContinent={this.state.selectedContinent}
					onContinentChange={this.onContinentChange}
					tr={this.props.tr}
					initialValues={this.props.initialValues}
					isLoading={this.props.isLoading}
					postStatus={this.props.postStatus}
					editShippingZoneStatus={this.props.editShippingZoneStatus}
					editShippingZoneErrorMsg={this.props.editShippingZoneErrorMsg}
					onSubmit={this.handleSubmit}
					redirectAfterFormPost={redirectAfterFormPost}
					countries={this.props.countries}
					continents={[continents]}
					eucountries={eucountries}
				/>
			</div>
		);
	}

}

CreateEditShippingZone.defaultProps = {
	countries: [],
};

CreateEditShippingZone.propTypes = {
	onSubmit: PropTypes.func.isRequired,
	designerId: PropTypes.number.isRequired,
	shippingZoneId: PropTypes.number,
};

//Connect to form to get access to its props 
CreateEditShippingZone = reduxForm({
	form: 'editShippingZoneForm',
	// destroyOnUnmount: false,
    // forceUnregisterOnUnmount: false, 
})(CreateEditShippingZone);

CreateEditShippingZone = connect(
	(state, ownProps) => {
  
		// var continents = state.editShippingZone.countries.continents;
		// var otherZoneCountryIds = selectShippingZoneCountriesExceptCurrent(state, ownProps.shippingZoneId ? ownProps.shippingZoneId : null) || null;

		// let disabledCountryIds = selectDisabledCountryIds(state,otherZoneCountryIds);
		return {
			initialValues: ownProps.shippingZoneId ? state.editShippingZone.initialValues : '',
			isLoading: state.editShippingZone.isLoading,
			editShippingZoneStatus: state.editShippingZone.status,
			editShippingZoneErrorMsg: state.editShippingZone.errorMsg,
			designerId: state.session.designerId || 1,
			countries: state.editShippingZone.countries.countries,
			eucountries : state.editShippingZone.countries.eucountries,
			continents: state.editShippingZone.countries.continents,
			otherZoneCountryIds: selectShippingZoneCountriesExceptCurrent(state, ownProps.shippingZoneId ? ownProps.shippingZoneId : null) || null
			
		}
	},
	{
		fetchShippingZone: actions.fetchShippingZone,
		createShippingZone: actions.createShippingZone,
		updateShippingZone: actions.updateShippingZone,
		fetchCountriesAndContinents: actions.fetchCountriesAndContinents,
		viewShippingZones: shippingActions.viewShippingZones,
	}
)(CreateEditShippingZone);

export default CreateEditShippingZone;
