import React from 'react';
import Tabs from 'components/DaylicacyTabs';

const designerTabs = [{
	label: "Creations",
	value: "creations",
}, {
	label: "Collections",
	value: "collections",
}, {
	label: "Profile",
	value: "profile",
}, {	
	label: "Imprint",
	value: "imprint",
}, {
	label: "Ratings",
	value: "ratings",
}, {
	label: "FAQ",
	value: "faq",
}, {
	label: "Terms & conditions",
	value: "terms-and-conditions",	
}, {
	label: "Payment & shipping",
	value: "payment-and-shipping",
}];

const DesignerTabs = ({
	onChange,
	className
}) => {
	return(
		<div className="margin-bottom">
			<Tabs
				className="designer-tabs"
				tabs={designerTabs}
				onActive={onChange}
				theme="rounded"
				classname={className}
			/>
		</div>
	);
};

export default DesignerTabs;
