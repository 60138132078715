import React from 'react';
import PropTypes from 'prop-types';

const ProductCharacteristics = (props) => (
	<div>
		<h2>More characteristics</h2>
		{props.termAttributes.map((termAttribute, index) =>
			<div key={index} className="row">
				<div className="cx4">
					<strong>{termAttribute.attribute.name}</strong>
				</div>
				<div className="cx8">
					{termAttribute.term.name}
				</div>
			</div>
		)}
	</div>
);

ProductCharacteristics.propTypes = {
	termAttributes: PropTypes.array.isRequired,
};

export default ProductCharacteristics;
