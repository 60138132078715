import React from 'react';
import { connect } from 'react-redux';
//import lodash from 'lodash';
import {cl} from 'utils'
import {selectReactSelectCountries,selectInitialCountry} from 'data/modules/countries'
import { Field, FieldArray, reduxForm, formValueSelector } from 'redux-form';

//formAction (redux-form-saga)
import { updateUserAddresses } from '../actions'; 

// import AddressType from './AddressType'; 
import Li from './Li';

import moment from 'moment';

//import { withStyles } from 'material-ui-next/styles';
import { withStyles } from '@material-ui/core/styles';

import FileField from 'components/FileField';

import ImageUploader from 'react-images-upload';
 

//import {Radio} from 'redux-form-material-ui'; 

//import Radio from 'material-ui-next/Radio'; 
import Select from '@material-ui/core/Select';
import ReactSelect from 'react-select'
import * as reactselectFunctions from 'thirdpartycomponents/react-selectcomponents';

import { Checkbox, TextField } from 'redux-form-material-ui'; 

//import { FormControlLabel } from 'material-ui-next/Form'; 
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';

//import { MenuItem } from 'material-ui-next/Menu';
import MenuItem from '@material-ui/core/MenuItem';

//import CircularProgress from 'material-ui-next/CircularProgress';
import CircularProgress from '@material-ui/core/CircularProgress';

//import Button from 'material-ui-next/Button';
import Button from '@material-ui/core/Button';
 
import Alert from 'components/Alert';
// import { DaylicacyRadioGroup } from 'components/Form';
import DaylicacyTextField from 'components/Form/DaylicacyTextField';
 

const validate = (values) => {
      
    const errors = {};
        
    const requiredFields = [ 
        'first_name',
        'last_name',
        'address',
        'zip',
        'city'
    ];

    requiredFields.forEach(field => {
        if (!values[ field ]) {
            errors[ field ] = 'Required';
        }
    }); 

    //at least one address type must be checked
    var addressChecked = [   values['address_delivery'], 
        values['primary_delivery_address'], 
        values['address_manufacturing']].filter( addressType => addressType );
    		 
    var nothingCheckedError = !addressChecked.length ? true : false;
    if(!addressChecked.length){
        errors[ 'nothingchecked' ] = 'At least one address type need to be checked.';
    }
   
    // cl( addressChecked)
    // cl( ' addressChecked.length is ' + addressChecked.length)
    // values['address_delivery'].map( addressCheckbox => {
    //     cl(addressCheckbox, 'addressCheckbox: ');
    // })

    return errors;
};

/* Addressesform: Currently it is initialized with the Primary delivery address (type 1)
    The user can update different addresses by checking the address type checkbox.
    If all 3 types are checked, all 3 will be updated/saved with the values in the form.
    Todo: better functionality, the 3 addresses needs to be viewed separately */
// Api-endpoint: useraddresses , function: updateUserAddresses

class AddressesForm extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
			single: ({ 
				value: this.props.initialCountry.value,     
				label: this.props.initialCountry.label
			}), 
            selectedCountry: null
            
        }
    }
    handleChange = name => object => {

		cl(object, 'handlechange') 
		   
		this.setState({
		  [name]: object,
		  selectedCountry: object.value
        });
        
        this.props.change('country_id', object.value)

    }
    
    handleAddressTypeOnchange(e) {
       // return  e.preventDefault();
        //alert('change')
    }

     
    render() {

        const { 
            tr, 
            classes, 
            invalid, 
            pristine, 
            submitting, 
            submitSucceeded, 
            submitFailed, 
            handleSubmit,
            touched,
            currentErrors,
            countries 
             
        } = this.props;
 
        return(
            <div className="row">
            
                <form className="cx12 addresses-form myaccount-form" onSubmit={handleSubmit(updateUserAddresses)} encType="multipart/form-data">

                <div className="cx12">

                    <h2>{tr.addresses || 'Addresses'}</h2>
                    
                        <div className="cx12 address-type">
                         <ul>

                             <FormControl > 
                                <FormGroup>  
                                <li className="cx12">
                                
                                    <FormControlLabel control={<Field 
                                        name="address_delivery" 
                                        type="checkbox"
                                        component={Checkbox} 
                                        onChange={this.handleAddressTypeOnchange}
                                        /> 
                                        } 
                                        label="Address delivery"  
                                    />
                                </li> 
                                <li className="cx12">
                                    <FormControlLabel control={ <Field 
                                            name="primary_delivery_address"
                                            component={Checkbox} 
                                            value="2"
                                            onChange={this.handleAddressTypeOnchange} 
                                        /> } 
                                    label="Primary delivery address"  
                                    /> 
                                </li>
                                <li className="cx12">
                                    <FormControlLabel control={<Field 
                                            name="address_manufacturing" 
                                            component={Checkbox} 
                                            value="3"
                                            type="checkbox"
                                            onChange={this.handleAddressTypeOnchange}
                                            /> } 
                                        label="Address manufacturing"  
                                    /> 
                               
                                 { currentErrors.nothingchecked && 
                                    <FormHelperText error>{currentErrors.nothingchecked}</FormHelperText>
                                }
                                </li>
                                </FormGroup> 
                            </FormControl>
                            </ul>
                        </div>
                     
                        <ul>
                            <li className="row middle-xs margin-bottom">
                                <div className="cx3">
                                    <label className="label label--left">
                                    {tr.first_name}
                                    </label>
                                </div>
                                <div className="cx9">
                                    <Field
                                        className="mui-text-input-min-width mui-bottom-border"
                                        name="first_name" 
                                        component={TextField}
                                        
                                        type="text"
                                    />
                                </div>
                            </li> 
                            <li className="row middle-xs margin-bottom">
                                <div className="cx3">
                                    <label className="label label--left">
                                    {tr.last_name}
                                    </label>
                                </div>
                                <div className="cx9">
                                    <Field
                                        className="mui-text-input-min-width mui-bottom-border"
                                        name="last_name" 
                                        component={TextField}
                                       
                                        type="text"
                                    />
                                </div>
                            </li>
                            <li className="row middle-xs margin-bottom">
                                <div className="cx3">
                                    <label className="label label--left">
                                    {tr.address}
                                    </label>
                                </div>
                                <div className="cx9">
                                    <Field
                                        className="mui-text-input-min-width mui-bottom-border"
                                        name="address" 
                                        component={TextField}
                                        
                                        type="text"
                                    />
                                </div>
                            </li>
                            <li className="row middle-xs margin-bottom">
                                <div className="cx3">
                                    <label className="label label--left">
                                    {tr.zipcode}
                                    </label>
                                </div>
                                <div className="cx9">
                                    <Field
                                        className="mui-text-input-min-width mui-bottom-border"
                                        name="zip" 
                                        component={TextField}
                                        
                                        type="text"
                                    />
                                </div>
                            </li>
                            <li className="row middle-xs margin-bottom">
                                <div className="cx3">
                                    <label className="label label--left">
                                    {tr.city}
                                    </label>
                                </div>
                                <div className="cx9">
                                    <Field
                                        className="mui-text-input-min-width mui-bottom-border"
                                        name="city" 
                                        component={TextField}
                                        
                                        type="text"
                                    />
                                </div>
                            </li>
                            <li className="row middle-xs margin-bottom">
                                <div className="cx3">
                                    <label className="label label--left">
                                    {tr.country || "Country"}
                                    </label>
                                </div>
                                <div className="cx9 form-select react-select-container">
                                      
                                        <ReactSelect
                                            className="react-select mui-select-bottom-border" 
                                            // onChange={onCountryChange}
                                            options={countries} 
                                            // typeMsg={this.state.typeMsg}
                                            components={reactselectFunctions}
                                            value={this.state.single}
                                            onChange={this.handleChange('single')}
                                            // onFocus={this.handleDeliveryCountryFocus}
                                            // onBlur={this.handleDeliveryCountryBlur}
                                            placeholder="(type to search)"
                                        /> 
								     
                                </div>
                            </li>
                            <li className="row middle-xs margin-bottom">
                                <div className="cx3">
                                    <label className="label label--left">
                                    {tr.state || "State"}
                                    </label>
                                </div>
                                <div className="cx9">
                                    <Field
                                        className="mui-text-input-min-width mui-bottom-border"
                                        name="state" 
                                        component={TextField}
                                        
                                        type="text"
                                    />
                                </div>
                            </li>
                            <li className="row middle-xs margin-bottom">
                                <div className="cx3">
                                    <label className="label label--left">
                                    {tr.region || "Region"}
                                    </label>
                                </div>
                                <div className="cx9">
                                    <Field
                                        className="mui-text-input-min-width mui-bottom-border"
                                        name="regions" 
                                        component={TextField} 
                                        type="text"
                                    />
                                </div>
                            </li>
                            <Field name="id" type="hidden" component={TextField}/>
                            <li className="row middle-xs margin-bottom">
                             
                                <div className="cxo3">
                                    <Button
                                         className="mui-button-standard"
                                        variant="raised"
                                        type="submit"
                                        style={{
                                            marginRight: '15px',
                                        }}
                                        color="secondary"
                                        disabled={invalid || this.props.isLoading || pristine || submitting || currentErrors.nothingchecked}
                                        >
                                        Save
                                    </Button>
                                   
                                </div>
					        </li>
                            { this.props.submitFailed &&
                                <Alert cssClasses="alertbox alert-error" >{this.props.errorMsg}</Alert>
                            }
                            { this.props.submitSucceeded &&
                            <Alert delay={3500} cssClasses="alertbox alert-success" >Saved</Alert>
                            }           
                        </ul>
                
                    </div>
                </form> 
            </div>
        )
    }
}

AddressesForm = reduxForm({
	form: 'myAddressesForm',
	validate,
})(AddressesForm);

AddressesForm = connect(
 
    state => { 
 
        let billingaddress = {};
        //get primary billing address, if existng
        if(state.session.user.hasOwnProperty('addresses')) {    
                let addresses = state.session.addresses;        //state.session.user.addresses;
                for (var property in addresses){ 
                    if(addresses[property].hasOwnProperty('address_type_id')) { 
                        if(addresses[property]['address_type_id'] == 1){
                            // console.log('address_type_id with value 1 found.');
                            billingaddress = addresses[property];
                            // console.log(billingaddress);
                        }
                    }
                }  
                 
        }else{
            console.log('didnt find address object nor addresstype property')
        }
        let additionalValues = {
            address_delivery: true,
            address_manufacturing: true,
            primary_delivery_address: true,
            user_id : state.session.user.id, 
            ...billingaddress,
        }
        // state.session.user.address.
        let initialAddressvalues = Object.assign({}, 
            additionalValues 
        ); 
        
        return {
           currentErrors:  'myAddressesForm' in state.form && 'syncErrors' in state.form.myAddressesForm 
                ? state.form.myAddressesForm.syncErrors
                : false,
            countries: selectReactSelectCountries(state),
            initialCountry: selectInitialCountry(state),
            initialValues: initialAddressvalues 
        }
        
    }
    
	
)(AddressesForm);

export default AddressesForm;

// console.log('state.session.user.id is ' + state.session.user.id)