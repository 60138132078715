import * as types from './actionTypes';

export const submitPaymentForm = (formData) => ({
	type: types.REQUEST_SEND_PAYMENT,
	formData
});

export const requestFailed = e => ({
	type: types.REQUEST_SEND_PAYMENT_FAILED,
	message: e.message,
});

export const requestSuccess = result => ({ 
	type: types.REQUEST_SEND_PAYMENT_SUCCESS,
	result,
});

