import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Toolbar, ToolbarGroup } from 'material-ui-old/Toolbar';

import AppBar from '@material-ui/core/AppBar';
// import Toolbar from '@material-ui/core/Toolbar';


//import Button from 'material-ui-next/Button';
import Button from '@material-ui/core/Button';
import  DaylicacySort  from 'containers/Sort/DaylicacySort';
import DaylicacyTextField from 'components/Form/DaylicacyTextField';
import { listClick, editClick, createClick } from './actionCreators';
import { changeView } from '../actionCreators';

const myShopCreationsOptions = [
	 
	{
		value:"recent",
		label:"Recent creations", 
	},
	{
		value:"oldest",
		label:"Oldest creations",
		 
	}
];

class CreationsToolbar extends Component {

	constructor(props) {
		super(props);

		this.state = {
			view: null,
		};

		this.handleNewProductClick = this.handleNewProductClick.bind(this);
		this.onListClick = this.onListClick.bind(this);
		this.handleEditClick = this.handleEditClick.bind(this);
	}

	handleNewProductClick() {
		const { changeView } = this.props;
		changeView('createProduct');

		this.props.changeView('createProduct');
		//const { createClick } = this.props
		//createClick()
	}

	handleEditClick() {
		const { editClick } = this.props;
		editClick();
	}

	onListClick() {
		const { listClick } = this.props;
		listClick();
	}

	render() {

		const {tr } = this.props;

		return(
			<Toolbar 
				style={{ 
				backgroundColor: '#ffffff',
				 marginTop:'1em'
			}}>
				<ToolbarGroup  className="mui-toolbargroup">
					<Button
                        variant="raised"
						color="secondary"
						style={{ margin:0, marginRight: '16px' }}
						onClick={this.onListClick}
					>
						{tr.creations || "Creations"}
					</Button>
					<Button 
						variant="raised"
						color="secondary"
						disabled={this.props.disabledEdit}
						style={{ margin: 0, marginRight: '16px' }}
						onClick={this.handleEditClick}
					>
						{tr.edit || "Edit"}
					</Button>
					<Button 
						variant="raised"
						color="secondary" 
						style={{ margin: 0, marginRight: '16px' }}
						disabled={true}
					>
					{tr.duplicate || "Duplicate"}
					</Button>
					<Button 
						variant="raised"
						color="secondary" 
						style={{ margin: 0, marginRight: '16px' }}
						disabled={true}
					>
					{tr.delete || "Delete"}
					</Button>
					<Button
						variant="raised"
						color="secondary" 
						onClick={this.handleNewProductClick}
						style={{ margin: 0 }}
					>
						{tr.create || "Create"}
					</Button>
				</ToolbarGroup>
				<ToolbarGroup className="mui-toolbargroup">
					<DaylicacySort 
						sortOptions={myShopCreationsOptions}
						style={{marginRight:'16px'}} value={0}
					/>
					<DaylicacyTextField className="searchfield"
						placeholder="Search..."  
					/>
				</ToolbarGroup>
			</Toolbar>
		);
	}
}

CreationsToolbar = connect(
	null,
	{ listClick, editClick, createClick, changeView }
)(CreationsToolbar);

export default CreationsToolbar;
