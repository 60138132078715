import { put, call } from 'redux-saga/effects';
import { normalize, denormalize } from 'normalizr';
import { createSelector } from 'reselect';
import { countryListSchema } from '../schemas';
import {cl} from 'utils'
import {orderBy} from 'lodash';
import {selectReturnSomething} from 'data/selectors';
import {selectUserAddressType1} from 'containers/Session/selectors'
export const STATE_KEY = 'countries';

const normalizedCountries = normalize(window.d_store.countries, countryListSchema);
const initialState = normalizedCountries.entities.countries || [];

export default function reducer(state = initialState, action) {
	switch (action.type) {
		default:
			return state;
	}
}

 

/**
 * Selectors
 */

export const selectModule = state => 'countries' in state.data ? state.data.countries : {};

 
export const selectDeliveryCountryId = state => state.session.delivery_country_id;
export const selectDeliveryCountryName = state => state.session.delivery_country_name;

//selectCountryIdByName: needs a country name (string)
export const selectCountryIdByName = createSelector(
	[selectModule, selectReturnSomething],
	(countries, countryName) => {
		cl(countryName, 'selectCountryIdByName, input name: ', countryName)

		var countryId = '';
		
		Object.values(countries).map( country => {
			if(country.name == countryName){
				console.log('found ' + countryName + ' in countries. Returning ' + country.id)
				countryId = country.id
			}
		})
		return countryId;
	}
)

 //selectCountryIdByName: needs a country id
export const selectCountryNameById = createSelector(
    [selectModule, selectReturnSomething  ],
    (countries, countryId) => {

		var countryName = '';
		
		Object.values(countries).map( country => {
			if(country.id == countryId){
				console.log('found ' + countryId + ' in countries. Returning ' + country.name)
				countryName = country.name
			}
		})
		return countryName;
    }
)

export const selectCountryIdByCode = createSelector(
	[selectModule, selectReturnSomething  ],
    (countries, countryCode) => {

		var countryId = '';
		
		Object.values(countries).map( country => {
			if(country.code == countryCode){
				console.log('found code ' + countryCode + ' in countries. Returning ' + country.id)
				countryId = country.id
			}
		})
		return countryId;
    }
)

export const selectDeliveryCountryNameByDeliveryCountryId = createSelector(
	[selectDeliveryCountryId, selectModule],
	(countryId, countries) => {
		var countryName = '';
		
		Object.values(countries).map( country => {
			if(country.id == countryId){
				console.log('found ' + countryId + ' in countries. Returning ' + country.name)
				countryName = country.name
			}
		})
		return countryName;
	}
)

//return countries array in a format adapted to react-select, sorted alphabetically
export const selectReactSelectCountries = createSelector(
	selectModule,
	(countries) => {

		var countriesArray = Object.values(countries).map( country => {
 
			return({
				label: country.name,
				value: country.id,
				code: country.code
			})
			
		} )

		countriesArray = orderBy(countriesArray, ['label'],['asc']); 
 
		return countriesArray;
	}
);

export const selectInitialCountry = createSelector(
	[selectModule,selectUserAddressType1],
	(countries, addressType1) => {

		//find the name of the country
		var countryName = '';
			Object.values(countries).map( country => {
			if(country.id == addressType1.country_id){
				countryName = country.name
			}
		} )

		return {
			value:addressType1.country_id,
			label: countryName
		}
	}

)


export const selectHydrated = (state, id) => 
	denormalize(id, countryListSchema, state.data);

export const selectHydratedAll = (state) => 
	denormalize(Object.keys(selectModule(state)), countryListSchema, state.data);
