import { types } from './actions';

const initialState = {
	shippingProfiles: [],
};

const reducer = (state = initialState, action) => {
	switch(action.type) {
		case types.FETCH_SHIPPING_PROFILES_SUCCESS:
			return Object.assign({}, state, {
				shippingProfiles: action.payload.result.data,
			});
		default:
			return state;
	}
};

export default reducer;
