import { BrowserRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();

//(currently not in use)
//The purpose of this is to be able to access router outside react
//this would be be wrapped to the app

export default class ExtendedBrowserRouter extends BrowserRouter {
  history;
}