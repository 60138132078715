import { put, call } from 'redux-saga/effects';
import { normalize, denormalize } from 'normalizr';
import { createSelector } from 'reselect';
import { orderListSchema, orderLineListSchema } from '../schemas';
import { 
	ADD_ENTITIES, 
	addEntities, 
	requestDataFailure,
} from '../actions';
import api from '../api';

export const STATE_KEY = 'orderLines';

const initialState = {
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case ADD_ENTITIES:
                        console.log('modules-orderLines reducer: ADD_ENTITIES');
			return {
				...state,
				...action.payload.orderLines,
			};
		default:
			return state;
	}
}

/**
 * Selectors
 */

export const selectModule = state => state.data.orderLines || {};

export const selectAll = state => {
	const keys = Object.keys(selectModule(state));
	
	return denormalize(
		keys,
		orderLineListSchema,
		{
			...state.data,
			designers: state.data.designers.all,
			products: state.data.products.all,
		}
	);
};

export const selectDesignerOrderLines = (state, id) => {
	return selectAll(state).filter(orderLine => 
		orderLine.order.designer.id == id
	);
};

export const selectCustomerOrderLines = (state, id) => {
	return selectAll(state).filter(orderLine => 
		orderLine.order.customer.id == id
	);
};

