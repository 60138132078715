import React from 'react';
import IconButton from 'components/IconButton';

const MessageButton = props => {
	return (
		<IconButton 
			iconClassName="fa fa-envelope" 
			tooltip="Send message" 
		/>
	);
};

export default MessageButton;