import React from 'react';
import PropTypes from 'prop-types';
//import Icon from 'material-ui-next/Icon';
import Icon from '@material-ui/core/Icon';

import { DaylicacyFlagIcon } from 'components/Icon';

//Clicking LanguagePickerToggle opens LanguagePickerPopover
const LanguagePickerToggle = (props) => {
	const { onClick, languageCode, getAnchorEl, currencyCode,deliveryCountryCode } = props;

	return(
		<div 
			className="language-dropdown row middle-xs"
			onClick={onClick}
			ref={getAnchorEl}
		>
			<span className="flag cx">
				{/* todo: languageCode needs to be converted to countryCode 
					since languageCode and countryCode may differ  */}
					
				<DaylicacyFlagIcon countryCode={languageCode} />
			</span>
			<span className="language cx">
				{ deliveryCountryCode.toUpperCase() }
			</span>
			<span className="currency cx">{currencyCode}</span>
			<span className="caret cx">
				<Icon 
					className="fa fa-caret-down" 
					style={{ 
						fontSize: '14px',
						marginTop: '-3px'
					}}
				/>
			</span>
		</div>
	);
};

LanguagePickerToggle.propTypes = {
	onClick: PropTypes.func.isRequired,
	languageCode: PropTypes.string.isRequired,
};

export default LanguagePickerToggle;