import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
// import SelectField from 'components/SelectField';

import {Select, TextField} from 'redux-form-material-ui';

import DaylicacyTextField from 'components/Form/DaylicacyTextField';
import FileField from 'components/FileField';
import Alert from 'components/Alert';

import DaylicacyButton from 'components/DaylicacyButton';
//import { MenuItem } from 'material-ui-next/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { connect } from 'react-redux';
import { updateBankAccount } from '../formSagaActions'

const validate = values => {
	const errors = {};

	const requiredFields = [
		'shop_name', 
		'shop_currency_id',
		'account_owner',
		'account_country_id',
		'account_bank',
		'account_clearing',
		'account_number',
		'account_iban_rib',
		'account_swift_bic',
	];

	requiredFields.forEach(field => {
		if(!values[ field ]) {
			errors[ field ] = 'Required';
		}
	});

	return errors;
};

const renderSelect = props => (
	<div>
	<Select {...props}/>  
	
	{props.touched && props.meta.error && <span>{props.meta.error}</span>}
 </div>
)

/* 	on submit, form is handled in ShopSetup saga, function "handleUpdateBankAccount"
	Api method: 	updateDesigner
	Endpoint:		/designers
	Backend method:	updateDesigner
	*/
//available reduxform props: handleSubmit, pristine, submitting,submitSucceeded,submitFailed )

let ShopSetupForm = (props) => {

	const { submitSucceeded, submitFailed, errorMsg, error, isLoading, invalid } = props;

	const sortImgsMessage = null;
	const maxNumFiles = 1;
	const fileFieldName = 'shop_setup'

	return(
		<form onSubmit={props.handleSubmit(updateBankAccount)}>
			
			<div className="content margin-bottom content--padded">
				<h2>Name / Banner</h2>
				<ul className="bottommargin_l">
					<li className="row middle-xs form__field">
						<div className="cx3">
							<label>Shop name</label>
						</div>
						<div className="cx9">
							<Field
								className="mui-text-input-min-width mui-bottom-border"
								type="text" 
								component={TextField}
								name="shop_name"
							/>
						</div>
					</li>
					<li className="row middle-xs form__field bottommargin_m">
						<div className="cx3">
							Your shop url
						</div>
						<div className="cx9 ">
                            { 'http://daylicacy.loc' + props.shop_url}
						</div>
					</li>
					<li className="row middle-xs form__field">
						<div className="cx12 cs3">
							Shop Banner
						</div>
						<div className="cx12 cs9">
							<Field
								name="shop_banner"
								dropZoneMessage="Dimensions: 760 x 100 pixels or similar proportions"
								component={FileField}
								sortImgsMessage={sortImgsMessage} 
								maxNumFiles={maxNumFiles}
								fileFieldName={fileFieldName}
							/>
						</div>
					</li>
					<li className="row middle-xs form__field">
						<div className="cx12 cs3"> 
						</div>
						<div className="cx12 cs9">
						{
							props.shop_banner && (props.shop_banner) ? <img style={{height:'200px'}} src={`${props.shop_banner}`} /> : ''
						}
						</div>
					</li>
					<li className="row middle-xs form__field">
						<div className="cx12 cs3">
							Shop Currency
						</div>
						<div className="cx12 cs3">
							<Field
								className="mui-select-input mui-select-bottom-border"
								name="shop_currency_id" 
								component={renderSelect}
							>
								{ [{ id: 1, name: 'EUR' },{ id: 2, name: 'SEK' },{ id: 3, name: 'USD' }].map((currency, index) => (
									<MenuItem 
										key={currency.id} 
										value={currency.id} 
									>
									{currency.name}
									</MenuItem>
								)) }
							</Field>
						</div>
					</li>
				</ul>
				<div className="row middle-xs margin-bottom2">
					<h2 className="cx12 cs3">Bank Account</h2>
					<p className="cx12 cs9 ">The amount of your sales, deducted from the Daylicacy commission, will be transferred to your account according to Daylicacy's Terms & Conditions.</p>
							
				</div>
				
				<div className="form">
					<ul className="bottommargin_l">
						<li className="row middle-xs form__field">
							<div className="cx3">
								<label className="label label--required">Account owner</label>
							</div>
							<div className="cx9">
								<Field
									className="mui-text-input-min-width mui-bottom-border"
									type="text" 
									component={TextField}
									name="account_owner"
								/>
							</div>
						</li>
						<li className="row middle-xs form__field">
							<div className="cx3 cs3">
								<label className="label label--required">Country</label>
							</div>
							<div className="cx9 cs3">
								<Field
									className="mui-select-input mui-select-bottom-border"
									type="select" 
									component={renderSelect}
									name="account_country_id"
								>
									{ [{ id: 1, name: 'England' }].map((country, index) => (
										<MenuItem 
											key={country.id}
											value={country.id} 
											>
											{country.name} 
											</MenuItem>
									)) }
								</Field>
							</div>
						</li>
						<li className="row middle-xs form__field">
							<div className="cx3">
								<label className="label label--required">Bank Name</label>
							</div>
							<div className="cx9">
								<Field
									className="mui-text-input-min-width mui-bottom-border"
									type="text" 
									component={TextField}
									name="account_bank"
								/>
							</div>
						</li>
						<li className="row middle-xs form__field bottommargin_m">
							<div className="cx12 cs3">
								<label className="label label--required">
									Clearingnr/Bank Account
								</label>
							</div>
							<div className="cx12 cs9">
								<div className="row">
									<div className="cx12 cs4">
										<Field
											className="mui-text-input-min-width mui-bottom-border"
											type="text" 
											component={TextField}
											name="account_clearing"
											placeholder="Clearingnr"
										/>
									</div>
									<div className="cx12 cs4">
										<Field
											className="mui-text-input-min-width mui-bottom-border"
											type="text"
											component={TextField}
											name="account_number"
											placeholder="Bank account number"
										/>
									</div>
								</div>
							</div>
						</li>
						<li className="row form__field">
							<div className="cxo3">
							
								<span className="smalltxt01 important">Verify IBAN and Swift/BIC carefully letter by letter! Otherwise the transfer will be lost.</span>
								
							</div>
						</li>
						<li className="row middle-xs form__field">
							<div className="cx12 cs3">
								<label className="label label--required">
									IBAN/RIB
								</label>
							</div>
							<div className="cx12 cs9">
								<Field
									className="mui-text-input-min-width mui-bottom-border"
									type="text" 
									component={TextField}
									name="account_iban_rib"
								/>
							</div>
						</li>
						<li className="row middle-xs form__field">
							<div className="cx3">
								<label className="label label--required">Swift/BIC</label>
							</div>
							<div className="cx9">
								<Field
									className="mui-text-input-min-width mui-bottom-border"
									type="text" 
									component={TextField}
									name="account_swift_bic"
								/>
							</div>
						</li>
						
						<li className="row middle-xs form__field">
							<div className="cx12 cs3">
								<label className="label label--required">
									Shop Notice
								</label>
							</div>
							<div className="cx12 cs9 textareawrap flex">
								<Field
									className="mui-text-input-min-width mui-bottom-border "
									type="text" 
									multiline
									component={TextField}
									name="shop_notice"
								/>
							</div>
						</li>
					
						<li className="row submit_button_rowctr form__field">
							<div className="cxo3">
								<DaylicacyButton
									type="submit"  
									disabled={invalid || props.isLoading || props.pristine || props.submitting}
									>
										{props.tr.apply || 'Apply'}
									</DaylicacyButton>
							</div>
						</li>
						<li className="row form__field">
							<div className="cx12">
								{ props.submitSucceeded &&
								<Alert delay={3500} cssClasses="alertbox alert-success" >Saved</Alert>
								}
								{ props.submitFailed &&
									<Alert cssClasses="alertbox alert-error" >{props.error}</Alert>
								}
							</div>
						</li>
					</ul>
				</div>
			</div>
		</form>
	);
};

ShopSetupForm.propTypes = {
	handleSubmit: PropTypes.func.isRequired,
	onSubmit: PropTypes.func,
	isLoading: PropTypes.bool
};

ShopSetupForm = reduxForm({
	form: 'shopSetupForm',
	validate,
})(ShopSetupForm);

ShopSetupForm = connect(
    state => ({
        initialValues: {
        	shop_name: state.session.user.designer.shop_name,
            shop_currency_id: state.session.user.designer.shop_currency_id ?
				state.session.user.designer.shop_currency_id : 1,
			shop_notice: state.session.user.designer.shop_notice,
			// account: state.session.user.designer.account ? state.session.user.designer.account : null
            account_owner: state.session.user.full_name ? state.session.user.full_name : '',
            account_country_id: state.session.user.designer.account ? state.session.user.designer.account.country_id : '',
            account_bank: state.session.user.designer.account ? state.session.user.designer.account.bank : '',
            account_clearing: state.session.user.designer.account ? state.session.user.designer.account.clearing : '',
            account_number: state.session.user.designer.account ? state.session.user.designer.account.number : '',
            account_iban_rib: state.session.user.designer.account ? state.session.user.designer.account.iban_rib: '',
            account_swift_bic:state.session.user.designer.account ? state.session.user.designer.account.swift_bic: '',
            
		},
		 shop_url: state.session.user.designer.permalink,
		 shop_banner: state.session.user.designer.shop_banner ? state.session.user.designer.shop_banner.url : ''
    })
)(ShopSetupForm)

export default ShopSetupForm;