import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Icon from 'components/Icon';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon  from '@material-ui/core/ListItemIcon';
import ListItemText  from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

//import Checkbox from 'components/Checkbox';
import Checkbox from '@material-ui/core/Checkbox';
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';

import {getParameterByName} from 'utils';
import {requestFilterTerms, requestFilterMenu} from 'containers/Filters/actionCreators';
import {toggleOpenMenuItem, closeAllMenuItems,toggleAddRemoveCheckedMenuItem} from 'containers/Filters/components/actions';

import {updateChipsByFilters } from 'containers/FilterChipsBar/actions'; 

import { getFilters } from '../selectors';
import {cl} from 'utils'


class SelectMenuItem extends Component {

	constructor(props) {
		super(props);
		
		this.state = {
			// open: this.props.open || false,
			 anchorEl: null,
			 activeOpenedSubmenu: false
		};

		this.handleClickApply = this.handleClickApply.bind(this);
		this.handleClickItem = this.handleClickItem.bind(this);
		this.handleClickCheckbox = this.handleClickCheckbox.bind(this);
		// this.handleRequestClosePopover = this.handleRequestClosePopover.bind(this);
		this.handleClose = this.handleClose.bind(this)
	}

	//commits selected filters - applies chips and starts fetch
	handleClickApply(e, termId) {
		
		e.stopPropagation();

		//get current page of result
		// let pageNumQuerystringValue = getParameterByName('page'); 
		// cl(pageNumQuerystringValue, 'pageNumQuerystringValue is: ')
		// this.props.addShopFilter(filter.selector, filter.model.id, pageNumQuerystringValue);

		this.setState({
			activeOpenedSubmenu: false
		})

		this.props.updateChipsByFilters();
		if(Object.keys(this.props.stateFilters.terms).length < 1){
			cl('no filters in stateFilters.terms!');
			return;
		}
		// let slug = this.props.match.params.termSlug;
		// alert('handleclick apply, termslug: ' + slug)
		this.props.requestFilterTerms( );

		// this.handleClose(e, termId);

		this.props.closeAllMenuItems();
	}

	//will only run if the item has submenu
	handleClickItem(e, id) {
		  console.log('SelectmenuItem handleClickItem: the clicked term id is ' + id)

		e.stopPropagation();
		e.preventDefault(); 

		// e.currentTarget
		this.props.toggleOpenMenuItem(id);


		if(!this.props.openState){
			this.setState({ 
				anchorEl: e.currentTarget,
				activeOpenedSubmenu: true
			});
		} 
	}
	 
	handleClickCheckbox(e, id) {
		cl(id, 'handleClickCheckbox: ')
		// alert('check')
		e.stopPropagation();
		//get filters with updated products counts
		 
		this.props.toggleAddRemoveCheckedMenuItem(id);
		this.props.requestFilterMenu();

	}

	handleRequestClosePopover = (e, termId) => {
		// alert('request close!')
		// this.props.closeMenuItem(termId);
		// this.setState({
		// 	open: null,
		// });
	}
	handleClose = (e, termId) => {
		this.setState({
			activeOpenedSubmenu: false
		})
	}

	render() {
		const {handleClickApply, 
			tr, 
			menuItems, 
			level, 
			termId,
			primaryText,
			requestStatus,
			productsNum,
			productsCountActive,
			openState
		} = this.props;
		const hasSubMenu =  menuItems && menuItems.length;
 		const icon = hasSubMenu ? 
		 			<ListItemIcon className="filterlistitem_icon_wrap">
					<i className="fa fa-angle-right"/>
				</ListItemIcon>
			: null;

		const noProducts = productsNum == 0 && productsCountActive ? 'no-products' : false;
		 
		let productsNumStr = productsNum >= 0 && productsCountActive ? '( ' + productsNum.toString() + ' )' : null;

		//if there is no term id, send primary text (for 'made in' or 'made by' categories)
		// var id = termId ? termId : primaryText;
		const activeWithChildren = openState ? 'filterlistitem-active' : ''
		// this.state.activeOpenedSubmenu ? 'filterlistitem-active' : ''
		return (
			<ListItem 
				onClick={ hasSubMenu ? (e) => this.handleClickItem(e, termId) : null} 
				className={`filterlistitem ${activeWithChildren} ${noProducts}`}
				style={{
					fontSize: '0.9em',
					//padding: this.props.showCheckbox ? '10px 65px 12px 52px' : '10px 65px 12px 52px'
				}}
			>
					<ListItemText primary={this.props.primaryText}/>

					{this.props.showCheckbox &&
						<ListItemSecondaryAction className="filterlistitem_checkbox_wrap">
							<Checkbox 
								className="filterlistitem_checkbox"
								checked={this.props.checked}
								onChange={() => this.props.onChange()	}
								onClick={ (e) => this.handleClickCheckbox(e,termId)}
								 
							/>
						</ListItemSecondaryAction>
					}
					{requestStatus == 'pending' ?  
					<ListItemText className="filterlist-productsnum" primary={'...'}/>
					:
					<ListItemText className={`filterlist-productsnum ${noProducts}`}  primary={productsNumStr}/>
					}
					 
					{icon}
					{ menuItems && 
						<Popover
							className="filterpopoversubmenutest"
							open={this.props.openState}
							anchorEl={this.state.anchorEl}
							anchorOrigin={{ horizontal: 'right', vertical: 'top' }} 
							onClose={ (e) => this.handleClose(e, termId)}  
							// onClose={ (e) => this.handleRequestClosePopover(e, termId)}
						>
							<Popover
							className="filterpopoversubmenu-list"
							open={this.props.openState}
							anchorEl={this.state.anchorEl}
							anchorOrigin={{ horizontal: 'right', vertical: 'top' }} 
							onClose={ (e) => this.handleClose(e, termId)}  
								// onClose={ (e) => this.handleRequestClosePopover(e, termId)}
							>
								<List style={{maxHeight:'270px'}}>
									{ menuItems }
								
								</List>
								<Button
									// onClick={this.props.handleClickApply}
									onClick={ (e) => this.handleClickApply(e, termId)}
									
									variant="raised" 
									type="button" 
									//className="filterlist-apply mui-button-standard"
									// disabled={selectedFilters}
									color="secondary"
									>
										{tr.apply || 'Apply'} 	
								</Button>
							</Popover> 
							
							 
						</Popover> 
						
					}
			</ListItem>
		);
	}

}


SelectMenuItem.propTypes = {
    menuItems: PropTypes.object, 
}

SelectMenuItem = connect(
	(state, ownProps) => ({  
		productsCountActive: state.filters.productsCountActive,
		requestStatus: state.filters.requestStatus.requestStatus,
		openState: ownProps.termId in state.interface.filtermenu.menuItemsSubMenuOpenState && state.interface.filtermenu.menuItemsSubMenuOpenState[ownProps.termId].open || false,
		
		// anchorEl: ownProps.termId in state.interface.filtermenu.menuItemsSubMenuOpenState && state.interface.filtermenu.menuItemsSubMenuOpenState[ownProps.termId].anchorEl || null,
		stateFilters: getFilters(state)
	}),
	{ updateChipsByFilters, requestFilterTerms, toggleOpenMenuItem, closeAllMenuItems, requestFilterMenu,toggleAddRemoveCheckedMenuItem}
)(SelectMenuItem);
  


export default SelectMenuItem;
