 
 
export const OPEN_MENU_ITEM = 'OPEN_MENU_ITEM';
export const CLOSE_MENU_ITEM = 'CLOSE_MENU_ITEM';
export const TOGGLE_OPEN_MENU_ITEM = 'TOGGLE_OPEN_MENU_ITEM'
export const CLOSE_ALL_MENU_ITEMS = 'CLOSE_ALL_MENU_ITEMS'
export const TOGGLE_ADD_REMOVE_CHECKED_MENU_ITEM = 'TOGGLE_ADD_REMOVE_CHECKED_MENU_ITEM'
export const ADD_CHECKED_FILTER = 'ADD_CHECKED_FILTER'
export const CLEAR_CHECKED_MENU_ITEMS = 'CLEAR_CHECKED_MENU_ITEMS'
export const REMOVE_CHECKED_MENU_ITEM = 'REMOVE_CHECKED_MENU_ITEM'


export const openMenuItem = (termId) => {
	return({
		type: OPEN_MENU_ITEM,
		termId,
	});
};

export const toggleOpenMenuItem = (termId, currentTarget ='') => {
	return({
		type: TOGGLE_OPEN_MENU_ITEM,
		payload: {termId, currentTarget}
	});
};


export const closeAllMenuItems = () => {
	return({
		type: CLOSE_ALL_MENU_ITEMS
	});
};
export const clearCheckedMenuItems = () => {
	return({
		type: CLEAR_CHECKED_MENU_ITEMS
	});
};

export const removeCheckedMenuItem = (termId) => {
	return({
		type: REMOVE_CHECKED_MENU_ITEM,
		termId,
	});
};
export const closeMenuItem = (termId) => {
	return({
		type: CLOSE_MENU_ITEM,
		termId,
	});
};
export const addCheckedFilter = (filter) => {
	return({
		type: ADD_CHECKED_FILTER,
		filter,
	});
};

export const toggleAddRemoveCheckedMenuItem = (termId) => {
	return({
		type: TOGGLE_ADD_REMOVE_CHECKED_MENU_ITEM,
		termId,
	});
};


