import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import  { selectVisibleProductIds ,selectAll}  from 'data/modules/products';

import ProductCard from 'containers/ProductCard';
import FilterChipsBar from 'containers/FilterChipsBar';
import { selectDesigners } from 'data/selectors';
import BackToTop from 'components/BackToTop';
import CircularProgress from '@material-ui/core/CircularProgress';
 
/*
	Product ids are selected from state and passed down to each product.
	The product data is retrieved from state (denormalized) within each product card with the use of the id
*/
class ProductList extends React.Component  {

	constructor(props) {
		super(props)

		this.state={

		}
	} 
 
	render(){

		const { termSlug, productIds, designers, tr, currency, slug, fetchingProducts, currentQueryTotalSet, from, to } = this.props;
	
			
		//let keys = Object.keys(products);

		// if (Array.isArray(products) || Array.isArray(keys)) {
		// } else {
		// 	return (
		// 		<div>No products found..</div>
		// 	);
		// }
/*
		if(slug) {
			const ds = Object.values(designers);
			const found = ds.filter(function(d) {  return d.shop_slug == slug }, slug );
			const designerId = found ? found[0].id : null;

			const filterProducts = products.filter(function (product) {
				return product.designer_id == designerId;
			}, designerId);

			if(filterProducts) {
				keys = Object.keys(filterProducts);
			}

		} else {
			keys = Object.keys(products);
		}
	*/
 
	// return <div>
	// 			<FilterChipsBar />
	// 			<p> TEMP </p>
	// 		</div>
		if(!productIds || (fetchingProducts && !productIds)){

			return (
			<div className="mui-circular-container">{<CircularProgress/>}</div>
			)
		}
		 
		return (

			 
			<div>
				{/* {currentQueryTotalSet &&
				<i>{currentQueryTotalSet} products</i>
				  } */}
				 
				 <div className="flex spacebetween filterchipsbar-ctr">
					<FilterChipsBar termSlug={termSlug} />
					{currentQueryTotalSet > 0 &&
						(
							<p><i>Showing {from} - {to} </i></p>
						)
					}
				
				</div>

				
				<div className="product-list-wrap">
					  
					<BackToTop label={tr.back_to_top} />
					<div className="product-list row">
						 { productIds.map(id =>
							
							<ProductCard
								productId={id}
								tr={tr}
								currency={currency} 
								key={id }
							/>
						)} 
					</div>
					
					{fetchingProducts && 
						(<div className="load-overlay">
							<div className="load-container">{<CircularProgress/>}</div>
						</div>)
					}
						
				</div>
			</div>
		)
	}
	
};

ProductList.propTypes = {
	productIds: PropTypes.array.isRequired,
};

export default connect(
	state => ({ 
		productIds: selectVisibleProductIds(state),
		designers: selectDesigners(state),
		tr: state.translations,
		currency: state.session.currency.toLowerCase()
	})
)(ProductList);
  

/*
	{ productIds.map((id) => {
							
		return (
			<ProductCard
				tr={tr}
				currency={currency} 
				key={id }
				
			/>
		)
	}
	
) }

*/
/*
tr={tr}
	currency={currency}
	product={products[key]}
	key={i}
*/
/*
<ProductCard
	tr={tr}
	currency={currency}
	product={ products[key].id }
	key={products[key].id }

	/>

*/
//products: selectVisibleProducts(state),

/*
designer={
	designers[products[key]['designer_id']]
} 
*/
 

/*
key: products[key].id 
*/
