import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
 
import AppBar from '@material-ui/core/AppBar';
 import Tabs from '@material-ui/core/Tabs';
 import Tab from '@material-ui/core/Tab';
 import {cl} from 'utils';
 
// import {Tabs, Tab} from 'material-ui-old/Tabs'; 

var styles = {

	flat: {
		tabItemContainerStyle: {
			backgroundColor: '#BDBDCA',
		},
		inkBarStyle: {
			backgroundColor: '#DE1666',
		},
	},
	rounded: {

		tabItemContainerStyle: {
			backgroundColor: 'transparent',
			borderBottom: '1px solid rgb(128, 127, 128)',
		},
		inkBarStyle: {
			backgroundColor: 'rgb(128, 127, 128)',
			display: 'none',
		},
		tabStyle: {
			width: 'auto',
			marginRight: '3px',
			padding: '4px 10px',
			borderTopLeftRadius: '4px',
			borderTopRightRadius: '4px',
			border: '1px solid rgb(128, 127, 128)',
			borderBottom: 0,
			backgroundColor: 'rgb(226, 220, 220)',
 
		}, 
		buttonStyle: {
			
			// color: 'rgb(166, 166, 166)',
			height: 'auto',
			fontSize: '16px',
			textTransform: 'capitalize',
			height:'1.5em',
			width: 'none',
			minWidth: 'none'
		},
		selectedStyle: {
			backgroundColor: 'black'
		},
		myshopTabsAppbar: {
			backgroundColor:'transparent',
			minHeight: 'none'
	
		},
		thing: {
			backgroundColor: 'purple'
		}
	},
	
	
};	
  
const muiClasses = (theme) => {

		root: {
			backgroundColor:'green';
		}

		labelIcon : {
			//todo
		}
}
 
const selectedTabStyle = {background: 'yellow'}

const RenderTab = (tabDataItem, styles, selectedTab) => {
//	cl(tabDataItem, 'tabDataItem: ')
	return(
		<Tab  
			// className={}
			key={tabDataItem.value}
			value={tabDataItem.value}
			label={tabDataItem.label}
			style={styles.tabStyle} 
			thing={tabDataItem.selected ? 'yes selected' : 'no' } 
			className={tabDataItem.value == selectedTab ? 'selected-shop-tab-style' : {}  } 
			classes={ muiClasses.root }
			loading={tabDataItem.loading}
			// style={{ color: tabDataItem.selected ? 'red' : 'blue'}}
			 
		/>
		)
}


const getStyles = (theme) => {
	switch(theme) {
		case 'flat':
			return Object.assign({}, styles.flat);
		case 'rounded':
			return Object.assign({}, styles.rounded);
		default:
			return Object.assign({}, styles.flat);
	}
};
 
const DaylicacyTabs = ({ tabs, onChange, theme, selectedTab,elevation }) => {

	let styles = getStyles(theme);

	
	return(
		<div>
			<div className="daylicacy-tabs">
				<AppBar 
					elevation={elevation || 0}
					position="static" 
					style={styles.myshopTabsAppbar}
				>
					<Tabs  
					// indicatorColor="black" 
					onChange={ (event, value) => onChange(event, value)}
					value={selectedTab} 
					>
						{ tabs.map(tabDataItem => RenderTab(tabDataItem, styles, selectedTab)) } 
					</Tabs>
				</AppBar>
			</div>
		</div>
	);
};

DaylicacyTabs.propTypes = {
	tabs: PropTypes.arrayOf(PropTypes.shape({
		// label: PropTypes.string.isRequired,
		value: PropTypes.string.isRequired,
	})).isRequired,
	onActive: PropTypes.func,
	theme: PropTypes.string,
};

export default withStyles(muiClasses)(DaylicacyTabs);

/*

tabstyle:

			color: 'rgb(166, 166, 166)',
			height: 'auto',
			fontSize: '16px',
			textTransform: 'capitalize',
			height:'2.2em',
			width: 'none',
			minWidth: 'none'


// myshopTabsAppbar: {
		// 	height:'2.1em',
		// 	minHeight: 'none'

		// },
		// thing: {
		// 	backgroundColor: 'purple'
		// }

 
//Tab:
//style={styles.tabStyle}
//buttonStyle={styles.buttonStyle}

*/

/*
	tabItemContainerStyle={styles.tabItemContainerStyle}
	inkBarStyle={styles.inkBarStyle}>
*/

//Appbar:	
/*	 
position="static"
style={styles.myshopTabsAppbar}
*/

//Tabs styling:
// tabItemContainerStyle={classes.tabItemContainerStyle}
//inkBarStyle={classes.inkBarStyle}> 