import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { connect, dispatch } from 'react-redux';
import { reduxForm, formValueSelector, arrayPush, Field, FieldArray, getFormValues, change } from 'redux-form';
// import DaylicacyTextField from 'components/Form/DaylicacyTextField';

import { changeView, setSubjectKey } from 'views/Account/MyShop/Creations/actionCreators'; 

 
import FileField from 'components/FileField'; 

import {Select, TextField} from 'redux-form-material-ui';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
//import { MenuItem } from 'material-ui-next/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Alert from 'components/Alert';
import DaylicacyButton from 'components/DaylicacyButton';
import { withStyles } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import Chip from '@material-ui/core/Chip';

import {cl} from 'utils'; 
import {notObjectOrNotEmptyObject} from 'utils';
 import {difference} from 'lodash';

/* ================ info ================
	function "HandleSubmit" is in parent: CreateProduct
	 
	Also used for EDIT product (parent: EditProduct)

    Posts to api endpoint: products
	function: postProducts
 
	mainCategories needs terms in state.data.terms

	For photos, see FileField component

	Profile zonemethods are retrieved from  state.data.shippingProfiles.profileZoneMethods
*/

// const handleDelete = (props) => (e) => {
// 	const {chipKey, fields} = props;

// 	fields.remove(chipKey);
 
// }
const validate = (values, props) => {
	cl('validae')
	cl(props);

	const errors = {};
	
	const requiredFields = [  
		'price', 
		'vat',
		'shipping_profile', 
		'mainCategory',
		'subCategory' 
	];

	requiredFields.forEach(field => {
		if (!values[ field ]) {
			errors[ field ] = 'Required';
		}
	});
	

	//at least 1 attribute
	// var genAttr = [];
	//  if(props.visitedTouched && 'generalAttributes' in props.visitedTouched) {

	// 	cl('generalAttributes is in fields')
	// 	genAttr = props.visitedTouched.generalAttributes.filter( attr => {
	// 		if(attr && attr.toString().length){
				
	// 			cl(attr, 'attr is set: ')
	// 		}
	// 		//If attribute has been visited + touched, add to array. 
	// 		return attr && attr.toString().length;
	// 	});

		
	// }
	// if(genAttr.length) {}

	// var valuesArray = 'generalAttributesValues' in values && 
	// values.generalAttributesValues.filter( attr => attr && attr.length);
	
	// if(valuesArray && valuesArray.length) {
	// 	cl(valuesArray, 'valuesArray has length: ')

	// }else{
	// 	cl(valuesArray, 'valuesArray has no length: ')
	// 	//set error
	// 	errors.generalAttributes = 'Choose an attribute.';
	// }
	
	// if (!values.images) {
	// 	errors.images = 'Please upload at least one product image.';
	// }

	console.log('validate', values);

	return errors;
};

const renderChip = (props, index) => {

	const {name, value} = props.input;

	cl('here is props generalAttributesValues') 
	cl('generalAttributesValues' in props && props.generalAttributesValues) 
	// cl(props.chipKey, 'the chip key is ');
	// cl('renderChip: here is fields:');
	// console.log(JSON.stringify(props.fields));
	// cl('renderChip: here is field length:');
	// cl(props.fields.length);
	return (
	<div>
		<Chip
			name={props.input.name}
			// fields.remove(chipKey);
			// onClick={() => props.fields.remove(props.chipKey)}
			onDelete={() => {
				cl('chip delete chip key: ' + props.chipKey + ' , props.input.name: ' + props.input.name)
				props.fields.remove(props.chipKey)

				cl('to remove generalAttributes - ' + value.parent_term_id + ' - ' + value.node_id)
				// props.change( 'creationProductForm', `generalAttributes[${value.parent_term_id}][${value.node_id}]`, '' )

				if('generalAttributesValues' in props){

					//remove value in generalAttributes. Find array index in sub array where the value is stored
					var targetIndex = [];
					 props.generalAttributesValues[value.parent_term_id].map( (nodeId, index) => {
						if(nodeId == value.node_id){
							cl('target index found: ')
							cl(index);
							targetIndex.push(index == 0 ? '0' : index)
						}
					}) 
					cl('to remove' + `generalAttributes[${value.parent_term_id}][${targetIndex[0]}]`)
					/* remove the element of target index:
						call custom dispatch function (see 2nd arg of connect() at the bottom of component)
						this function will execute change()
					*/
					props.changeFieldValue(
						`generalAttributes[${value.parent_term_id}][ ${targetIndex[0]} ]`, ''
					)
					
				}
				

			}} //name={`generalAttributes[${node.term.id}]`}
					//props.array.remove(`attributes[${node.id}]`, targetIndex);
			value={`${props.input.value.id}`}
			onBlur={props.input.onBlur}
			onChange={props.input.onChange}
			onDragStart={props.input.onDragStart}
			onDrop={props.input.onDrop}
			onFocus={props.input.onFocus}
			label={`${props.input.value.term.name}`}
		/>
		 
	</div>
	);
};

{/* */}
const renderChips = (props) => {
  
	return(
		<ul>
			{ props.fields.map((field, index) => {

				return (
				<li key={index}>
					<Field
						className="mui-text-input-min-width mui-bottom-border"
						fields={props.fields}
						generalAttributesValues={'generalAttributesValues' in props && props.generalAttributesValues || undefined}
						changeFieldValue={props.changeFieldValue}
						chipKey={index}
						// props={{fields: props.fields}}
						label={field}
						name={field}
						component={renderChip}
					/>
				</li>
				);
			}) }
		</ul>)
}
 
const styles = theme => ({
	container: {
	  display: 'flex',
	  flexWrap: 'wrap',
	},
	textField: { 
	  width: 450,
	},
	margin: {
		margin: theme.spacing.unit,
	  },
	textArea: {
		minHeight:'3em',
		width: 450,
	},
	menu: {
	  width: 200,
	},
  });

  
const InputAdapter = props => 
	<Input
		{...props}
		value={props.input.value}
		onChange={props.input.onChange} 
	/>

// see handleSubmit in containers/CreateProduct
class CreationForm extends Component {

	constructor(props) {
		super(props);
		
		this.state = {
			
		}
	}
	
	componentDidUpdate(prevProps, prevState){
		// editShippingZoneStatus
		 
		 if(prevProps.submitSucceeded == this.props.submitSucceeded) {
 	 
			 cl('prevProps.submitSucceeded is identical to this.props.submitSucceeded');
			
		 }else{
			cl('componentDidUpdate , prevProps.submitSucceeded is ' + prevProps.submitSucceeded)
			cl('componentDidUpdate , this.props.submitSucceeded is ' + this.props.submitSucceeded)

			 //Redirect after form post if status have changed to success  
			if(this.props.submitSucceeded) {
				cl('prevProps.submitSucceeded: ' + prevProps.submitSucceeded + ' , this.props.submitSucceeded: ' + this.props.editShippingZoneStatus +
				' - run redirectAfterFormPost');

				//call redirectAfterFormPost in Shipping. (make sure it is set first)
				 
				if(typeof this.props.redirectAfterFormPost === 'function'){
 
					//set subject key, change view to listproducts
					cl('setting subject key to createProduct, changing view to listProducts')
					this.props.setSubjectKey('createProduct')
					this.props.changeView('listProducts'); 
					this.props.redirectAfterFormPost('success')
				}else{
					cl('this.props.redirectAfterFormPost is not set - no view change - fallback alert will show instead');
				}
					
			}
		} 
	}

	render() {
		const {  
			createOrEdit, 
			transactionStatus,
			productId, 
			shippingProfiles, 
			handleSubmit, 
			isLoading, 
			errorMsg,
			tr, 
			classes, 
			pristine, invalid, error, touched, submitSucceeded,
			profileZoneMethods, 
			
			//form values:
			shipping_profileValue,
			subCategories,
			mainCategory,
			subCategory,
			generalAttributesValues,
			attributesValues,
			initialValues,
			submitFailed,
			requestStatus,
			currentErrors,
			generalAttributesTouched,
			visitedTouched
	
		} = this.props;
		 
	cl('initialValues: ')
	cl(initialValues);
	const sortImgsMessage = !productId ? "The first image to the left will be the <strong>featured image</strong>. Drag the images to change order."
							: "<span class=\'alert\'>Note:</span> if you upload new images, previous ones will be deleted! (remove images to keep the previous)"
	const maxNumFiles = 4; 
	const fileFieldName="images";

	//vat - max five digits
	const maxFive = value =>  {

		if(value.length > 5){
			return value.slice(0, 5)
		}else if(value > 100){
			return 100;
		}else{
			return value;
		}
	}

	//condition: must choose at least 1 attribute   //Disabled - not a condition
/*	var genAttr = [];
	var generalAttributesCustomError = undefined;
	var unTouchedGeneralAttributesValues = false;

	if(visitedTouched && 'generalAttributes' in visitedTouched) {
		cl('generalAttributes is in fields')
		genAttr = visitedTouched.generalAttributes.filter( attr => {
			if(attr && attr.toString().length){
				
				cl(attr, 'attr is set: ')
			}
			//If attribute has been visited + touched, add to array. 
			return attr && attr.toString().length;
		});

		if(genAttr.length) {
			//see if any of the touched values are set in values array
			var valuesArray = generalAttributesValues && 
				generalAttributesValues.filter( attr => attr && attr.length)
			if(valuesArray && valuesArray.length) {
				cl(valuesArray, 'valuesArray has length: ')

			}else if(valuesArray){
				cl(valuesArray, 'valuesArray has no length: ')
				//set error
				generalAttributesCustomError = 'Choose an attribute.';
			}else{
				cl('no values array - generalAttributesValues is not set')
			}
			
		} 
	}else{
		unTouchedGeneralAttributesValues = true;
	}
	 */
 
	//	[{32: 456}] 		initalValues

	return(
		 <div className="form creationform">
		
		 <h1>{createOrEdit === 'edit' && 'Edit' ? 'Edit' : 'Create'} product</h1>
		<form onSubmit={handleSubmit} encType='multipart/form-data'>
			 
			<div className="row">
				<h4 className="cx12 cs3">Title</h4>
				<div className="cx12 cs9 flex">
				
					<Field 
						type="text" 
						className={`${classes.textField} mui-text-input-min-width mui-bottom-border`}
						placeholder="Product title" 
						component={TextField}
						name="translations[en][name]"
					/>
				</div>
			</div>
			<div className="row">
				<h4 className="cx12 cs3">Description</h4>
				<div className="cx12 cs9 textareawrap flex">
					<Field 
						type="text" 
						placeholder="Product description"
						multiline  
						className={`${classes.textField} mui-text-input-min-width mui-bottom-border`}
						component={TextField}
						name="translations[en][description]" 
					/>
				</div>
			</div>
			<div className="row" style={{marginBottom:'16px'}}>
					<h4 className="cx12 cs3">Images</h4>
					<div className="cx12 cs9 flex dropzone-area">
						<Field
							name="images"
							component={FileField}
							sortImgsMessage={sortImgsMessage} 
							maxNumFiles={maxNumFiles}
							fileFieldName={fileFieldName}
									
						/>
					</div>
					{((error && <span>{error}</span>) )}
			</div>
			<div className="row">
					<div className="cx12 cs3"></div>
					<div className="cx12 cs9 flex" style={{marginBottom:'16px'}}>{productId && <h4>Current images</h4>}</div>
				
					<div className="cx12 cs3"></div>
					<div className="cx12 cs9 flex" style={{marginBottom:'16px'}}>
						
						{
							productId && initialValues.gallery && initialValues.gallery.items.map( image => (
								<div>
									<img style={{height:'200px'}} src={`${image.url}`} />  
								</div>
							) )
							 	
						}
					</div>
			</div>

			<div className="row">
				<h4 className="cx12 cs3">Shipping Profile</h4>
				<div className="cx12 cs9 flex flexdir-col">
					<Field
						className="mui-select-input mui-select-bottom-border"  
						placeholder="Shipping profile"
						name="shipping_profile" 
						component={Select}
					>
					 
					 {/* Note - current profile must have one "Standard" option available to be selectable */ }
						{ shippingProfiles && Object.values(shippingProfiles).map((profile, index) => {
							var standardAvailable = false;

							{/* check if any of current Profiles' methods has a Standard option (id 1) */ }
							( ('methods' in profile) && profile.methods.length && profile.methods.map( methodId =>
								
								Object.keys(profileZoneMethods).map( zoneMethod => {
									if(profileZoneMethods[methodId].shipping_method_id == 1){
										console.log('standard option found')
										standardAvailable = true;
									} 
								}) 
							 
							))
							return (
							<MenuItem
								key={`profile_${index +1}`}
								value={profile.id}
								disabled={!standardAvailable}
								className={!standardAvailable && 'notallowed'}
							>
							{ profile.default == 1 ? 'Default' : profile.name }
							</MenuItem>)
							}) }
					</Field>
					<p className="cx12 cs6 notice1">The profile need to have a "Standard" shipping method option available to be selectable (Manage your shipping profiles on Shipping tab) </p>
			
				</div>
				 
			</div>
			<div className="row">
				<h4 className="cx12 cs3">Price</h4>
				<div className="cx12 cs9">
					<Field
						className="mui-text-input-min-width mui-bottom-border"  
						type="text"
						placeholder="Price in euro"
						name="price"
						component={TextField}
						
					/>
				</div>
			</div>
 
			<div className="row">
				<h4 className="cx12 cs3">VAT <span style={{fontWeight: 'normal'}}>(%)</span></h4>
				<div className="cx12 cs9">

		 			<Field			   
							className="mui-text-input-min-width mui-bottom-border mui-input-adorn"  
							component={InputAdapter} 
							type="number"
							maxLength="5"	
							step="0.1"
							placeholder="VAT" 
							name="vat"
							normalize={maxFive} 
							startAdornment={
								<InputAdornment position="start">%</InputAdornment>
							 }
							min="0"
							max="100"
							
						/>

					{/* <div className="left-input-symbol-wrap fa fa-percent"> 

						<Field
							className="mui-text-input-min-width mui-bottom-border"  
							type="text"
							placeholder="VAT" 
							name="vat"
							min={0}
							max={100}
							component={TextField} 
						/>
					</div>  */}
				</div>
			</div>
			<div className="row">
				<h4 className="cx12 cs3">Availability</h4>
				<div className="cx12 cs9">
					<Field
						className="mui-text-input-min-width mui-bottom-border"  
						type="text"
						placeholder="Availability (in Stock)"
						name="stock"
						component={TextField}
					/>
				</div>
			</div>
				
				<div className="row">
					<h4 className="cx12 cs3">Category</h4>
					<div className="cx12 cs6">
						<Field
							className="mui-select-input mui-select-bottom-border"  
							placeholder="Main Category"
							name="mainCategory"   
							component={Select}
						>
							 { this.props.mainCategories.map((node, index) => (
								<MenuItem
									key={`mainCategory_${index}`}
									value={node.id}
								>
								{node.term.name}
								</MenuItem>
							)) } 
						</Field>
					</div>
				</div>
				<div className="row">
						<h4 className="cx12 cs3">Sub category</h4>
						<div className="cx12 cs6 bottommargin_m">
							<Field
								className="mui-select-input mui-select-bottom-border"  
								placeholder="Sub Category"
								name="subCategory" 
								autoWidth
								component={Select}
							>
								 { subCategories.map((node, index) => (
									<MenuItem
										key={`subCategory_${index}`}
										value={node.id} 
									>
									{node.term.name}
									</MenuItem>
								)) } 
							</Field>
						</div>
				</div>
				<div className="row">
					<h4 className="cx12 cs3">General attributes</h4>
				</div>

				 {/* Six general attributes and their subattributes*/}

				 { this.props.generalAttributes.map((node, index) => ( 
				<div key={`generalAttribute_${index}`} className="row">
						 
						<div className="cx12 cs3"></div>
						<div className="cx12 cs6 flex">
							<InputLabel className="cs5" htmlFor={`generalAttributes_${index}`}>{node.name}</InputLabel>
							<Field
								id={`generalAttributes_${index}`}
								className="cs5 mui-select-input mui-select-bottom-border"  
								placeholder={node.name}
								label={node.name} 
								name={`generalAttributes[${node.term.id}]`}
								multiple
								format={value => Array.isArray(value) ? value : []}
								component={Select}
								onChange={(event, newValue, oldValue) => { 
 
									//(multiple - all values are together) see what value was added (will return array of one value )
									let latestValue = difference(newValue, oldValue);
									latestValue = String(latestValue);

									if(latestValue.length) {
										console.log('value ' + latestValue + 'added');
									
										const termNode = this.props.termNodes[latestValue];
										const term = this.props.terms[termNode.term];

										// push to to 'attributes' the attribute as term object. 
										// (node.id is parent node id (ex:  Ethical Products - node id 317 (term id 297)))
										//CHANGE: setting node id of the term (latestValue) instead of parent
										this.props.array.push(`attributes[${node.id}]`, {
											node_id: latestValue,				//previous:	 id: node.id,
											parent_term_id:node.term.id,
											term,
										});
										
									}else {

										//If a value is deselected , it should be removed from attributes array
										//get value by comparing current and previous array (will return array of one value )
										let deselectedValue = difference(oldValue, newValue);

										deselectedValue = String(deselectedValue);

										if(deselectedValue.length) {

											cl(node.term.id , 'node.term.id is ')
											console.log('deselectedValue is ' + (deselectedValue) + ' and is already in this array ', generalAttributesValues[node.term.id]);
											var alreadyInArray = generalAttributesValues[node.term.id].filter( val => val == (deselectedValue));
											cl('alreadyInArray - splicing!');
											cl(attributesValues[node.id], 'here is attributesValues: ');

											
											if( Array.isArray(attributesValues[node.id])){
												//find object whose node id match deselectedValue
												var targetIndex = attributesValues[node.id].findIndex( obj => {

													console.log('comparing ' + obj.node_id + ' to ' + parseInt(deselectedValue));
														if(obj.node_id == parseInt(deselectedValue)){
															cl(deselectedValue, 'match found for deselected value ');
															return obj;
														}
												})
												if(targetIndex >= 0) {
													//splice the term object from attributes.
													cl(targetIndex, 'targetIndex is ')
													this.props.array.remove(`attributes[${node.id}]`, targetIndex);
													// this.props.array.remove(`attributes[${node.id}][${targetIndex}]`, 1);
													// this.props.fields.remove(this.props.chipKey)}
													cl('it was spliced')
												}else{
													cl('no targetindex')
												}
											}else{
												cl('the object was not found')
											} 
											
											return;
										}else{
											cl('error. no value was selected or deselected');
											return;
										}
									}
									 		 
								}}
							>
							 
								{ node.children.map((childNode, childIndex) => (
									
									<MenuItem
										key={`generalAttribute_${index}_${childIndex}`}
										value={childNode.id}
										
									>
									{childNode.term.name}
									</MenuItem>
								)) }
							</Field>
						</div> 
						<div className="cx12 cs3">
							<FieldArray
								name={`attributes[${node.id}]`}
								generalAttributesValues={'generalAttributesValues' in this.props && this.props.generalAttributesValues || undefined}
								changeFieldValue={this.props.changeFieldValue}
								component={renderChips}
							/>
						</div>
						 
				</div>
			)) } 
		 
					 {/*  //generalAttributesCustomError &&  //&& visitedTouched && ('generalAttributes' in visitedTouched) &&
					 <div className="row">
					 	<div className="cx12 cs3"></div>
							<div className="cx12 cs6 flex">
							 <FormHelperText error>{generalAttributesCustomError}</FormHelperText>
							</div>
					 </div>
								*/ }				
				<div className="row">
					<h4 className="cx12 cs3">Category specific attributes</h4>
				</div>
				
				<div className="row">
					<div className="row bottommargin_m"> 
						<div className="cx no-grow-xs">
							<DaylicacyButton
								type="submit" 
								name="saveasdraft" 
								disabled={true} 
							>
								{tr.save_as_draft || "Save as draft" }
							</DaylicacyButton>
						</div>
						<div className="cx">
							<DaylicacyButton 
								type="submit" 
								name="publish" 
								disabled={pristine || invalid }
							>
								 {tr.publish || 'Publish'}
							</DaylicacyButton>
						</div>
					</div>
				</div>
				{/* {submitSucceeded && (<Alert delay={3500} cssClasses="alertbox alert-success" >Saved</Alert>) } */}
	 
				{ requestStatus.requestStatus === 'failed' && 
					<Alert cssClasses="alertbox alert-error" >{ requestStatus.lastRequestError.message }</Alert> }
				{/* { requestStatus.requestStatus === 'success' &&
					<Alert delay={3500} cssClasses="alertbox alert-success" >Product created</Alert> } */}

			</form>
		</div>
	);
	};
}
CreationForm.propTypes = {
	
	handleSubmit: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,
	isLoading: PropTypes.bool.isRequired,
	mainCategories: PropTypes.array.isRequired,
	subCategories: PropTypes.array.isRequired,
};

CreationForm.defaultProps = {
	mainCategories: [],
	subCategories: [],
};

const rootChildren = (state, context) => {

	const module = state.data.terms;

   if (context in module.termNodes) {
	   return module.termNodes[context].children.map(id => {
		   const node = module.termNodes[id];
		   const term = module.terms[node.term];
		   
		   return {
			   id,
			   term,
		   };
	   });
   }
	
   return [];
};

const leafChildren = (state, context, leafs = []) => {
	const module = state.data.terms;
	if (context in module.termNodes) {
		const current = module.termNodes[context];
		if (current.children.length) {
			current.children.forEach(child => {
				leafChildren(state, child, leafs);
			});
		} else {
			const term = module.terms[current.term];
			leafs.push({
				id: current.id,
				term,
			});
		}
	}

	return leafs;
};		


/*
	onChange={(event, index, value) => input.onChange(value)}


const rootAttributes = (state, context) => {
	const module = state.data.terms;
	if (context in module.termNodes) {
		return module.termNodes[context].attributes.map(id => {
			const node = module.termNodes[id];
			const term = module.terms[node.term];
			
			return {
				id,
				term,
				children: rootChildren(state, id),
			};
		});
	}
	
	return [];
};	*/

const generalAttributes = (state) => {				

/*
	//Term Hierarchy Id's

	const termNodesIds = [
		317, // Ethical Products	term id: 297
		345, // Main Material		term id: 325
		449, // Stones/Pearls		term id: 428
		770, // Colour				term id: 742
		810, // Pattern				term id: 777
		492, // Style				term id: 465
	];
*/						
	const termNodesIds = window.d_store.general_attributes;
	const module = state.data.terms;
	
	// console.log('termNodesIds: ');
	// console.log(JSON.stringify(termNodesIds));
	// console.log(termNodesIds);

	
	return termNodesIds.reduce((nodes, id) => {
		
		let name = id[1];
		id = id[0];

//		console.log('termNodesIds: id is ' + id + ' and name is ' + name);

		if (id in module.termNodes) {
			console.log(id + ' found in termnodes');
			const node = module.termNodes[id];
			const term = module.terms[node.term];
			nodes.push({
				id,
				name,
				term,
				children: rootChildren(state, id),
			});
		}
		
		return nodes;
	}, []);						
};					

const selector = formValueSelector('createProductForm');

CreationForm = connect(
 	state => { 

		return { 

		//form values
		shipping_profileValue: selector(state, 'shipping_profile'),
		subCategories: leafChildren(state, selector(state, 'mainCategory')),
		mainCategory: selector(state, 'mainCategory'),
		subCategory: selector(state, 'subCategory'),
		generalAttributesValues: selector(state, 'generalAttributes'),
		attributesValues: selector(state, 'attributes'), 
		currentErrors:  'createProductForm' in state.form && 'syncErrors' in state.form.createProductForm 
			? state.form.createProductForm.syncErrors
			: false,
		visitedTouched:  'createProductForm' in state.form && 'fields' in state.form.createProductForm 
			? state.form.createProductForm.fields
			: false,
		requestStatus: state.creations.createProduct.requestStatus,
		shippingProfiles:state.data.shippingProfiles.shippingProfiles,
		profileZoneMethods: state.data.shippingProfiles.profileZoneMethods,
		termNodes: state.data.terms.termNodes || '',
		terms: state.data.terms.terms || '',
		mainCategories: rootChildren(state,1),
		generalAttributes: generalAttributes(state)
		
	 	}
	 },
	 /* mapDispatchToProps = */ function(dispatch) {
		return { 
			changeFieldValue: function(field, value) {
				// cl('changeFieldValue called. Field: ' + field)
				dispatch(change('createProductForm', field, value))
			},
			changeView: (view) => {
				cl(view, 'change view, here is view:')
				return dispatch( changeView(view) ) 
			},
			setSubjectKey: (key) => {
				cl('set subject key to ' + key)
				return dispatch( setSubjectKey(key) ) 
			}
			
		}
	 }
	 
 )(CreationForm);

 CreationForm = reduxForm({
	form: 'createProductForm',
	validate,
	
})(CreationForm);

export default withStyles(styles)(CreationForm);

/*
rootChildren		rootChildren(state, 1),

categoryAttributes: rootAttributes(state, selector(state, 'subCategory')),


//enableReinitialize: true,
*/

/*	attributes:

	{ props.categoryAttributes.map((node, index) => (
				<div key={`categoryAttribute_${index}`} className="row">
					<div className="cx6">
						<Field
							label={node.term.name}
							name={`categoryAttributes[${node.term.id}]`}
							component={SelectField}
							onChange={(event, newValue, oldValue) => {
								const termNode = props.termNodes[newValue];
								const term = props.terms[termNode.term];
								props.array.push(`attributes[${node.id}]`, {
									id: node.id,
									term,
								});
							}}
						>
							{ node.children.map((childNode, childIndex) => (
								<MenuItem
									key={`categoryAttribute_${index}_${childIndex}`}
									value={childNode.id}
									 
								>
								{childNode.term.name}
								</MenuItem>
							)) }
						</Field>
					</div>
					<div className="cx6">
						<FieldArray
							name={`attributes[${node.id}]`}
							component={renderChips}
						/>
					</div>
				</div>
			)) }
			 
			
			*/
