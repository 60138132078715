
export const STATE_KEY = 'messages';

const initialState = {
};

export default function reducer(state = initialState, action) {
    switch (action.type) {

        case 'ADD_MESSAGES':
            console.log('Inside reducer ADD MESSAGES of orders.js', action);

            return {
                ...state,
                ...action.payload.messages
            };

        default:
            return state;
    }
}

