import React, { Component } from 'react';
import Paper from 'material-ui-old/Paper';
import Avator from 'material-ui-old/Avatar';
//import Icon from 'material-ui-next/Icon';
import Icon from '@material-ui/core/Icon';

import styled from 'styled-components';
import Measure from 'react-measure';
import ReactGridLayout, { Responsive, WidthProvider } from 'react-grid-layout';

const GridItem = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

const CollectionImage = styled.img`
	padding: 1em;
`;

const iconStyle = {
    color: 'rgb(76, 76, 76)',
    marginRight: '10px',
};

class Collection extends Component {

	constructor() {
		super();

		this.state = {
			width: -1,
		};

		this.handleMeasure = this.handleMeasure.bind(this);
	}

	handleMeasure(dimensions) {
		console.log(dimensions);
		this.setState({
			width: dimensions.width,
		});
	}
	
	render() {
		const layouts = {
			xxs: [
				{ i: 'a', x: 0, y: 0, w: 2, h: 2, static: true },
				{ i: 'b', x: 2, y: 0, w: 1, h: 1, static: true },
				{ i: 'c', x: 2, y: 1, w: 1, h: 1, static: true },
				{ i: 'd', x: 0, y: 2, w: 1, h: 1, static: true },
				{ i: 'e', x: 1, y: 2, w: 1, h: 1, static: true },
				{ i: 'f', x: 2, y: 2, w: 1, h: 1, static: true },
			]
		};

		//lg: 1200, md: 996, sm: 768, xs: 480, 
		//lg: 12, md: 10, sm: 6, xs: 4, 

		return (
		<Measure
			onMeasure={this.handleMeasure}>
			<div className="collection">
			<Paper>
			<header className="collection__header">
				<Avator src={this.props.avator} />
				{this.props.name} <br/>
				{this.props.designer.name}
			</header>
			<Responsive 
				layouts={layouts}
				width={this.state.width}
				breakpoints={{xxs: 0}}
				cols={{xxs: 3}}
				rowHeight={this.state.width / 3}
				margin={[0,0]}>
				<GridItem key={'a'}>
					<CollectionImage src="/img/placeholders/placeholder_500x500.jpg" />
				</GridItem>
				<GridItem key={'b'}>
					<CollectionImage src="/img/placeholders/placeholder_500x500.jpg" />
				</GridItem>
				<GridItem key={'c'}>
					<CollectionImage src="/img/placeholders/placeholder_500x500.jpg" />
				</GridItem>
				<GridItem key={'d'}>
					<CollectionImage src="/img/placeholders/placeholder_500x500.jpg" />
				</GridItem>
				<GridItem key={'e'}>
					<CollectionImage src="/img/placeholders/placeholder_500x500.jpg" />
				</GridItem>
				<GridItem key={'f'}>
					<CollectionImage src="/img/placeholders/placeholder_500x500.jpg" />
				</GridItem>
			</Responsive>
			<footer className="collection__footer">
				<div className="icons">
					<a href="#">
						<Icon
							className="fa fa-envelope"
							style={iconStyle}
						/>
					</a>
					<a href="#">
						<Icon
							className="fa fa-facebook-official"
							style={iconStyle}
						/>
					</a>
					<a href="#">
						<Icon
							className="fa fa-twitter"
							style={iconStyle}
						/>
					</a>
				</div>
			</footer>
			</Paper>
			</div>
		</Measure>
		);
	}
}

Collection.defaultProps = {
	name: 'Shorts',
	avator: '/img/cat-bags.jpg',
	products: [
		{
			image: '/img/placeholders/placeholder_500x500.jpg',
			permalink: '#',
		},
	],
	designer: {
		name: 'Bartan Adams'
	}
};

// Collection.propTypes = {
// 	products: PropTypes.arrayOf.shape({
// 		image: PropTypes.string.isRequired,
// 		permalink: PropTypes.string.isRequired,
// 	})
// }

export default Collection;
