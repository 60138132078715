import React from 'react';
import PropTypes from 'prop-types';

const ProductDetails = (props) => (
	<div>
		<h2>{props.productName} - {props.designerName} : {props.designerDescription}</h2>
		{props.productDescription}
	</div>
);

ProductDetails.propTypes = {
	productName: PropTypes.string.isRequired,
	productDescription: PropTypes.string.isRequired,
	designerName: PropTypes.string.isRequired,
	designerDescription: PropTypes.string.isRequired,
};

export default ProductDetails;
