import React from 'react';

const initialStyles = {
	margin: 0,
};

const DaylicacyTitle = ({ title }) => {
	return(
		<h4 style={initialStyles}>
			{ title }
		</h4>
	);
};

export default DaylicacyTitle;