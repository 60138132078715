import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { signOut } from './actionCreators';

/**
 * SIGNOUT COMPONENT
 */

class SignOut extends Component {

	constructor(props) {
		super(props);
		const { signOut } = this.props;
		
		signOut();
	}

	render() {
		const {userLanguage} = this.props;

		return(
			<Redirect to={`/${userLanguage}`} />
		);
	}
	
}

export default connect( 
	null, 
	{ signOut }
)(SignOut);