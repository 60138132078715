import * as types from './actionTypes';

export const fetchProduct = id => ({
	type: types.EDIT_PRODUCT_FETCH_PRODUCT,
	id,
});

 
export const updateProduct = (id, data ) => ({
	type: types.EDIT_PRODUCT_UPDATE_PRODUCT,
	id,
	data
});

export const editProductUpdateProductSuccess = (result) => ({
	type: types.EDIT_PRODUCT_UPDATE_PRODUCT_SUCCESS,
	result
});
