import { createSelector } from 'reselect';
import {cl} from 'utils'



export { selectHydratedId as selectProduct } from './modules/products';
export { selectHydrated as selectDesigner } from './modules/designers';
export { selectAll as selectProducts } from './modules/products';
export { selectAll as selectDesigners } from './modules/designers';
export { selectDesignerBySlug } from './modules/designers';


export {
	getNumProductsTotal, 
	selectVisible as selectVisibleProducts 
} from './modules/products';

export const selectSession = state => state.session;

//silly workaround
export const selectReturnSomething = (state, something) => something;
 
//Fetching, stop fetching, failure - expects a keyword to identify the reducer
// export const getFetchingStatus = createSelector(
// 	[selectReturnSomething],
// 	keyword => {

// 		cl(getFetchingStatus, 'getFetchingStatus, keyword is: ' + keyword)
	
// 		switch(keyword){

// 			case 'products':
			
// 				return state.data.products.requestStatus.requesting;  
	
// 			case 'cart':
	
// 				return state.cart.requestStatus.requesting; 
	
// 			default:
// 				return;
// 		}
// 	}
// )
 
//Fetching, stop fetching, failure - expects a keyword to identify the reducer
// export const getRequestStatus = createSelector(
// 	[selectReturnSomething],
// 	keyword => {

// 		switch(keyword){

// 			case 'products':

// 					return  state.data.products.requestStatus.requestStatus; 

// 			case 'cart':

// 					return state.cart.requestStatus.requestStatus; 

// 			default:
// 				return;
// 		}
// 	}
	
// )


/*
	check keyword
	get path specific to keyword
	use path to return property


*/