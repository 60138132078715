import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import AvatarEditor from 'react-avatar-editor';

import { zoomAction, saveAction } from './actionCreators';
import { closeDialogAction } from 'containers/ImageCropper/actionCreators';
 
import { getZoom, getImage, getDialog, getKey } from './selectors';
import TextField from 'material-ui-old/TextField';
// import Slider from './components/Slider';
// import Cropper from './components/Cropper';
import Dialog from 'components/Dialog';
import Slider from '@material-ui/lab/Slider';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button'

//see https://github.com/mosch/react-avatar-editor

class ImageCropper extends Component {

	constructor(props) {
		super(props);

		this.state={
			level: 1
		}
		
		this.handleSave = this.handleSave.bind(this)
		this.closeCropDialog = this.closeCropDialog.bind(this)
		this.setEditorRef = this.setEditorRef.bind(this)
	}
	 
	/* handleSave: 
		saveAction: Updates redux state with crop data for current image.
		onCrop: 
		"editor" is set on setEditorRef of Cropper
		getCroppingRect (AvatarEditor function) returns object with 
		 	x, y, width and height; all relative to the image size
	*/
	handleSave() {
		const { saveAction, onCrop, fileKey } = this.props;
		const { editor } = this;

		if(editor) {
			const preview = editor.getImageScaledToCanvas().toDataURL();
			const cropRect = editor.getCroppingRect();

			// alert('editor is set, image url is: ' + preview)
			saveAction(fileKey, preview, cropRect);
			
			//Save crop to redux state (handled in parent)
			this.props.onCrop(fileKey, preview, cropRect);

		}else{
			// alert('editor not set');
		}
	}
  
	setEditorRef(editor) {
		if(editor) this.editor = editor;
	}

	closeCropDialog() {
		const { closeDialogAction } = this.props;
		closeDialogAction();
	}


	handleSlider = (event, value) => {
		 
		this.setState({
			level: value
		})
		return;

		const { zoomAction } = this.props;

		//set zoom value in state
		zoomAction(value);
	}

	render() {
		const { onCrop, image, zoom, dialog,onClose,onSave, open, thing } = this.props;

		return(
			<Dialog
				
				title="Resize and crop image"
				open={dialog.open}
				onClose={this.closeCropDialog}   
				// onSave={onSave}
			>
				 
				<AvatarEditor 
					ref={this.setEditorRef}
					image={image}
					width={300}
					height={300}
					color={[0,0,0,0.6]}
					scale={this.state.level}
					rotate={0}
					style={{ borderRadius: '2px' }}
				/>
				<div className="cropdialog">
					<div className="close">×</div>
					<Typography id="label">Zoom</Typography>

					{/*  Zoom control  */}
					<Slider  
							max={2}
							min={0}
							value={this.state.level} 
							aria-labelledby="label" 
							onChange={this.handleSlider} 
					/>
					<Button 
						variant="raised" 
						className="mui-button-standard"
						onClick={this.handleSave} >
						Finish editing
					</Button>
					<p>Zoom level: {this.state.level}</p>
				</div>
				 
			</Dialog>
		);
	}
}

ImageCropper.propTypes = {
	// image: PropTypes.string.isRequired,
	zoom: PropTypes.number.isRequired,
	dialog: PropTypes.object.isRequired
};

 
const mapStateToProps = (state) => ({
	fileKey: getKey(state),
	zoom: getZoom(state),
	image: getImage(state),
	dialog: getDialog(state),
});

ImageCropper = connect(
	mapStateToProps,
	{ zoomAction, saveAction, closeDialogAction }
)(ImageCropper);

export default ImageCropper;