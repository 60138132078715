// Request designers
export const REQUEST_DESIGNER_LIST = 'REQUEST_DESIGNER_LIST';

// Shop filters
export const ADD_SHOP_FILTER_TERM = 'ADD_SHOP_FILTER_TERM';
export const REMOVE_SHOP_FILTER_TERM = 'REMOVE_SHOP_FILTER_TERM';
export const REMOVE_ALL_SHOP_FILTER_CHIPS = 'REMOVE_ALL_SHOP_FILTER_CHIPS';
export const ADD_SHOP_FILTER_CHIP = 'ADD_SHOP_FILTER_CHIP';
export const REMOVE_SHOP_FILTER_CHIP = 'REMOVE_SHOP_FILTER_CHIP';

/**
 * Designer actions
 */

export const DESIGNER_LIST_REQUEST_SUCCESS = 'DESIGNER_LIST_REQUEST_SUCCESS';
export const DESIGNER_LIST_REQUEST_FAILED = 'DESIGNER_LIST_REQUEST_FAILED';

export const REQUEST_DESIGNER = 'REQUEST_DESIGNER';
export const REQUEST_DESIGNER_SUCCESS = 'REQUEST_DESIGNER_SUCCESS';
export const REQUEST_DESIGNER_FAILED = 'REQUEST_DESIGNER_FAILED';

/**
 * Product actions
 */

// Create a product
export const REQUEST_CREATE_PRODUCT = 'REQUEST_CREATE_PRODUCT';
export const REQUEST_CREATE_PRODUCT_SUCCESS = 'REQUEST_CREATE_PRODUCT_SUCCESS';
export const REQUEST_CREATE_PRODUCT_FAILED = 'REQUEST_CREATE_PRODUCT_FAILED';

/**
 * Cart actions
 */

export const REQUEST_ADD_PRODUCT_TO_CART = 'REQUEST_ADD_PRODUCT_TO_CART';
export const REQUEST_ADD_PRODUCT_TO_CART_SUCCESS = 'REQUEST_ADD_PRODUCT_TO_CART_SUCCESS';
export const REQUEST_ADD_PRODUCT_TO_CART_FAILED = 'REQUEST_ADD_PRODUCT_TO_CART_FAILED';
export const ADD_PRODUCT_TO_CART = 'ADD_PRODUCT_TO_CART';
export const REMOVE_PRODUCT_FROM_CART = 'REMOVE_PRODUCT_FROM_CART';
export const EMPTY_CART = 'EMPTY_CART';

/**
 * Snackbar actions
 */

export const OPEN_SNACKBAR = 'OPEN_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';

/**
 * Shop actions
 */

export const PAGINATION_NEXT = 'PAGINATION_NEXT';
export const PAGINATION_PREV = 'PAGINATION_PREV';
export const ADD_PAGE_QUERY_DETAILS = 'ADD_PAGE_QUERY_DETAILS';
export const ADD_SHOP_CURRENT_PAGE = 'ADD_SHOP_CURRENT_PAGE';
export const ADD_SHOP_NUM_PAGES = 'ADD_SHOP_NUM_PAGES';
export const CLEAR_PAGE_QUERY_DETAILS = 'CLEAR_PAGE_QUERY_DETAILS';
export const SET_SHOP_PAGESLUG = 'SET_SHOP_PAGESLUG';
/**
 * Other actions
 */

export const OPEN_SHOPPING_BAG = 'OPEN_SHOPPING_BAG';
export const CLOSE_SHOPPING_BAG = 'CLOSE_SHOPPING_BAG';
export const REQUEST_SEND_PAYMENT = 'REQUEST_SEND_PAYMENT';
export const REQUEST_SEND_PAYMENT_SUCCESS = 'REQUEST_SEND_PAYMENT_SUCCESS';
export const REQUEST_SEND_PAYMENT_FAILED = 'REQUEST_SEND_PAYMENT_FAILED';

/**
 * User actions
 */

export const REQUEST_SIGN_IN = 'REQUEST_SIGN_IN';
export const REQUEST_SIGN_IN_SUCCESS = 'REQUEST_SIGN_IN_SUCCESS';
export const REQUEST_SIGN_IN_FAILED = 'REQUEST_SIGN_IN_FAILED';

/**
 * Modal actions
 */

export const OPEN_SIGNIN_MODAL = 'OPEN_SIGNIN_MODAL';
export const CLOSE_SIGNIN_MODAL = 'CLOSE_SIGNIN_MODAL';
export const MODAL_OPEN_LOGIN = 'MODAL_OPEN_LOGIN';
export const MODAL_CLOSE_LOGIN = 'MODAL_CLOSE_LOGIN';