import React from 'react';
import PropTypes from 'prop-types';
import colors from 'config/colors';

const Panel = props => {
	return (
		<div style={Object.assign({}, {
			backgroundColor: props.header ? colors.panelHeader : colors.panel,
			fontSize: props.header ? '1.3em' : '1em',
			marginBottom: props.footer ? 0 : '0.2em',
			paddingTop: '0.3em',
			paddingBottom: '0.3em',
			paddingLeft: '0.6em',
			paddingRight: '0.6em',
		}, props.style)}>
			{props.children}
		</div>
	);
};

Panel.propTypes = {
	header: PropTypes.bool,
	children: PropTypes.node.isRequired,
};

export default Panel;