import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from 'material-ui-old/Checkbox';

const DaylicacyCheckbox = (props) => {

	const {
		input,
		label,
		className
	} = props;

	return(
		<Checkbox 
			className={className || ''}
			label={label}
			checked={input.value ? true : false}
			onCheck={input.onChange}
			labelStyle={{ 
				margin: 0, 
				whiteSpace: 'nowrap',
				marginLeft: '8px',
			}} 
			style={{ margin: 0 }}
			iconStyle={{ margin: 0 }}
			inputStyle={{ margin: 0 }}
		/>
	);

};

DaylicacyCheckbox.propTypes = {
	input: PropTypes.object.isRequired,
};
								
export default DaylicacyCheckbox;