import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import  { selectProductIds ,selectAll} from 'data/modules/products';

import DesignerProductCard from './DesignerProductCard';
import FilterChipsBar from 'containers/FilterChipsBar';
import { selectVisibleProducts, selectDesigners } from 'data/selectors';
import BackToTop from 'components/BackToTop';
import CircularProgress from '@material-ui/core/CircularProgress';
import {selectModule} from 'data/modules/countries';
import  { selectProductIdsByDesignerId } from 'data/modules/products';
import {cl} from 'utils';

class DesignerProductList extends React.Component  {

	constructor(props) {
		super(props)

		this.state={

		}
	} 
	 
	render(){

		const { tr, currency, slug, countries, designer } = this.props;
		
		//get product ids from designer
		
		var productIds = Object.keys(designer.products.products);
		cl(productIds, 'product ids: ', true)

		//let keys = Object.keys(products);

		// if (Array.isArray(products) || Array.isArray(keys)) {
		// } else {
		// 	return (
		// 		<div>No products found..</div>
		// 	);
		// }
/*
		if(slug) {
			const ds = Object.values(designers);
			const found = ds.filter(function(d) {  return d.shop_slug == slug }, slug );
			const designerId = found ? found[0].id : null;

			const filterProducts = products.filter(function (product) {
				return product.designer_id == designerId;
			}, designerId);

			if(filterProducts) {
				keys = Object.keys(filterProducts);
			}

		} else {
			keys = Object.keys(products);
		}
	*/

	// if(productIds !== null) {
	// 	console.log('productIds not null:'); 
	// 	console.log(JSON.stringify(productIds)); 
	// }else{
	// 	console.log('productIds is null');
    // }
	
	
    if (!productIds) {

        return (
            <div>no productIds {<CircularProgress/>}</div>
        );
    }

		return (
			<div>
               
				<FilterChipsBar />
				<div className="product-list-wrap">
					  
					<BackToTop label={tr.back_to_top} />
					<div className="product-list row">
						  { productIds.map(productId =>
							
							<DesignerProductCard 
								countries={countries}
								designerId={designer.id}
								productId={productId}
								tr={tr} 
								currency={currency}
								key={productId}
							/>
						)}  
					</div>
					
					{/* {getFetchingProducts && 
						(<div className="load-overlay">
							<div className="load-container">{<CircularProgress/>}</div>
						</div>)
					} */}
						
				</div>
			</div>
		)
	}
	
};

DesignerProductList.propTypes = {
	products: PropTypes.array.isRequired,
};

export default connect(
	(state, ownProps) => {

		// let ownPropsCopy = JSON.parse(JSON.stringify(ownProps));
		// delete ownPropsCopy.products; 

		// cl(ownPropsCopy, 'here is ownProps: ', true)
		cl(ownProps.designer.id, 'here is ownProps designer id: ')

		return (
				{ 
				 
				countries: selectModule(state),
				//designers: selectDesigners(state),
				tr: state.translations,
				currency: state.session.currency.toLowerCase()
			}
		)	
	}

	
)(DesignerProductList);
 
/*
	{ productIds.map((id) => {
							
        return (
            <ProductCard
                tr={tr}
                currency={currency} 
                key={id }
                
            />
        )
    }
        
    ) }
 
						*/
/*
tr={tr}
	currency={currency}
	product={products[key]}
	key={i}
*/
/*
<ProductCard
	tr={tr}
	currency={currency}
	product={ products[key].id }
	key={products[key].id }

	/>

*/
//products: selectVisibleProducts(state),

/*
designer={
	designers[products[key]['designer_id']]
} 
*/
 

/*
key: products[key].id 
*/
