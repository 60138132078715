import React from 'react';
import { SortableContainer as sortable } from 'react-sortable-hoc';

const SortableContainer = ({children}) => {
	//console.log('rendering <SortableContainer />');
	  
	return (
		<div className="sortable-container flex">
			{children}
		</div>
	);
};

export default sortable(SortableContainer);