import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const FooterNav = ({
	title,
	items,
	lang,
	tr
}) => {
	const renderNavItem = (item, i) => {
		const itemName = tr[item.name.toLowerCase().replace(/\W+/g, "_")]
			?
            tr[item.name.toLowerCase().replace(/\W+/g, "_")]
			:
			item.name;

		if( ! tr[item.name.toLowerCase().replace(/\W+/g, "_")] ) {
			// console.log(item.name.toLowerCase().replace(/\W+/g, "_"));
		}

		return <li key={i}>
			<Link to={`/${lang}${item.url}`}>
				{itemName}
			</Link>
		</li>;
	};

	return(
		<div className="cx12 cs4 cm3 cl2">
			<div className="footer-col">
				<h3 className="title">{ title }</h3>
				<ul className="nav">
					{ items.map(renderNavItem) }
				</ul>
			</div>
		</div>
	);
};

FooterNav.propTypes = {
	title: PropTypes.string,
	items: PropTypes.array.isRequired,
	lang: PropTypes.string.isRequired,
};

export default FooterNav;