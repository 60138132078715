export const CLICK_FAVORITE_DESIGNER = 'CLICK_FAVORITE_DESIGNER';
export const CLICK_NEW_MESSAGE = 'CLICK_NEW_MESSAGE';

export const favoriteDesigner = () => {
	return {
		type: CLICK_FAVORITE_DESIGNER,
		payload: {},
	};
};

export const messageDesigner = () => {
	return {
		type: CLICK_NEW_MESSAGE,
		payload: {},
	};
};