import React from 'react';
import PropTypes from 'prop-types';
import DaylicacyAvatar from './DaylicacyAvatar';
import DaylicacyImage from './DaylicacyImage';
import styled from 'styled-components';

export {
	DaylicacyAvatar,
	DaylicacyImage,
};

const Image = styled.img`
	max-width: 100%;
	height: auto;
	display: block;

	${props => props.align === 'center' && `margin: 0 auto;`}
`;

// const Image = (props) => {
// 	const { src } = props
// 	return(
// 		<img src={ src } />
// 	)
// }

// Image.propTypes = {
// 	src: PropTypes.string.isRequired,
// }

export default Image;