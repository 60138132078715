import React from 'react'; 
import styled, { css} from 'styled-components';

//border: 2px solid rgb(255, 64, 129);
const Back = styled.button`
    display: none;
    border-radius = 3px;
    padding: 0.75em 1em;
    margin: 0 1em;
    background: transparent;
    color: palevioletred;
    position: fixed;                    
    bottom: 20px;
    right: 30px;
    z-index: 99;
    cursor: pointer;
    color: #595959;
`;

class BackToTop extends React.Component {

    constructor(props) {
        super(props);
        window.onscroll = () => this.scroll();
    }

    scroll() {
        if (document.body.scrollTop > 30 || document.documentElement.scrollTop > 40) {
            document.getElementById("back_to_top").style.display = "block";
        } else {
            document.getElementById("back_to_top").style.display = "none";
        }
    }

    top() {
        document.body.scrollTop = 0; // Safari
        document.documentElement.scrollTop = 0; // Chrome, Firefox, IE & Opera
    }

    componentWillUnmount() {
        window.onscroll = null;
    }

    render() {
        return(
            <Back onClick={()=> this.top()} id="back_to_top">{this.props.label ? this.props.label : 'Back to top'}</Back>
        )
    }
}

export default BackToTop;