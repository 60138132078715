import { normalize, denormalize } from 'normalizr';
import { createSelector } from 'reselect';
import { call, put, select } from 'redux-saga/effects';
import { designerSchema, designersSchema } from 'data/schemas';
import { fetchDesigner, fetchDesigners } from 'data/api';
// import {selectReturnSomething} from 'data/selectors';
import { merge } from 'lodash';
import {productListSchema} from 'data/schemas';

import {notObjectOrNotEmptyObject} from 'utils'
import {cl} from 'utils'

import { 
	ADD_RESULT ,
	ADD_ENTITIES,
	ADD_REPLACE_DESIGNERS, 
	REQUEST_DATA_FAILURE,
	addEntities, 
	requestDataFailure,
	addReplaceDesigners,
	requestingStartLoading,
	REQUESTING_START_LOADING,
	successStopLoading,
	SUCCESS_STOP_LOADING 
} from 'data/actions';

import {EDIT_PRODUCT_UPDATE_PRODUCT_SUCCESS } from 'containers/EditProduct/actionTypes';
import {addPageQueryDetails} from 'views/Shop/actionCreators';

export const ADD_USER_DESIGNER = 'ADD_USER_DESIGNER';
export const ADD_DESIGNER = 'ADD_DESIGNER';
export const ADD_USER_DESIGNER_PRODUCT = 'ADD_USER_DESIGNER_PRODUCT';

export const addUserDesigner = data => ({
	type: ADD_USER_DESIGNER,
	payload: data,
});   
   
export const addDesigner = data => ({
	type: ADD_DESIGNER,
	payload: data,
});
export const addUserDesignerProduct = data => ({
	type: ADD_USER_DESIGNER_PRODUCT,
	payload: data,
});



const subject = 'designers';


export const STATE_KEY = 'designers';

//state.data.designers
const initialState = {
	userDesigner: {},
	all: {},					// [designerID] : {}
	visibleDesigners: [],
	requestStatus: {
		fetching: false,
		requestStatus: null,
		lastRequestError: {
			statusCode:null,
			message: null
		} 
	}
	
};

 
export default function reducer(state = initialState, action) {

	switch (action.type) {

		case ADD_ENTITIES:
			console.log(' designers reducer - ADD_ENTITIES');

			return {
				...state,
				all: {
					...state.all,
					...action.payload.designers,
				},
			}; 
		case ADD_RESULT:
			if (!action.payload.stateKey === STATE_KEY) 
				return state;

			return {
				...state,
				visibleDesigners: action.payload.result,
			};

		case ADD_DESIGNER:
			console.log(' designers reducer - ADD_DESIGNER - adding to state:'); 
 
			return {
				...state,
				all: {
					...state.all,
					[action.payload.id]: {...action.payload}
				},
			};
			 
		case ADD_USER_DESIGNER:
			console.log(' designers reducer - ADD_USER_DESIGNER - adding to state:'); 
			return {
				...state,
				userDesigner: {
					...state.userDesigner,
					...action.payload,
				}
			};

		case ADD_USER_DESIGNER_PRODUCT:
			console.log(' designers reducer - ADD_USER_DESIGNER_PRODUCT - adding to state:'); 
			cl(action.payload);

			return {
				...state,
				userDesigner: {
					...state.userDesigner,
					products: [
						...state.userDesigner.products,
						{...action.payload}
					]
					
				}
			};

		//updates product in userDesigner.products
		case EDIT_PRODUCT_UPDATE_PRODUCT_SUCCESS:
			cl(action.payload, 'updating userdesigner product with this response: ')
			//find index were of existing product object in userdesigner
			let prodIndex = undefined;
			state.userDesigner.products.map( (prod, index) => {
				if(prod.id == action.payload.id){
					prodIndex = index;
					cl('prodIndex found : ' + prodIndex)
				}
			})
			if(prodIndex !== 0 && !prodIndex){
				cl('no prodIndex found')
				return state;
			}
			//replace the existing product
			let productsArrayCopy = [...state.userDesigner.products]
			productsArrayCopy[prodIndex] = {...action.payload }	
			
			return {
				...state,
				userDesigner: {
					...state.userDesigner,
					products: [
						...productsArrayCopy
					]
					
				}
			};
			 
		case REQUESTING_START_LOADING: 
			if(action.payload == 'designer') {
				return {
					...state,
					requestStatus: {
						...state.requestStatus,
						requesting: true,
						requestStatus:  'pending' 
					}
				} 
			}else{
				 return state;
			}
		 

		case SUCCESS_STOP_LOADING: 

			if(action.payload == 'designers') {
				return {
					...state,
					requestStatus: {
						...state.requestStatus,
						requesting: false,
						requestStatus:'success'
					}
					 
				}
			}else{
				return state;
			}
			 

		case ADD_REPLACE_DESIGNERS:
		cl(action.payload, ' designers reducer - ADD_REPLACE_DESIGNERS');

		return {
			...state,
			all: { 
				...action.payload.designers,
			},
		};
		
		case REQUEST_DATA_FAILURE:
			
			if(action.payload.subject == 'designers'){
				cl(action.payload, 'REQUEST_DATA_FAILURE: designers: ') 
				return {
					...state,
					requestStatus: {
						requesting: false,
						requestStatus: 'failed',
						lastRequestError: {
							statusCode: action.payload.statusCode || '',
							message: action.payload.error.message || ''
						}
					}
					
				}
			}else{ 
				return state;
			}
			 
		default:
			return state;
	}
}

//fetches designer with products and shipping data
export function* getUserDesigner(action) {
	//(action content: args.id, args.slug, args.field, args.type)
	const {id } = action.payload.args;
	//console.log('getDesigner by slug, action: ' + JSON.stringify(action));

	//check if the designer already exists in state
	const checkDesigners = (yield select()).data.designers.userDesigner;
		console.log('checking for id ' + id);
		if(checkDesigners.id == id) {
			 
			console.log('The user designer is already loaded!')		
			  
		}else{
			//fetch designer
			try {
				console.log('User designer was not available - fetch!')	
				yield put(requestingStartLoading('designer'));
				const response = yield call(fetchDesigner, action.payload.args);  
				yield put(addUserDesigner(response.data));
			} catch (e) {
				console.log('getUserDesigner failed');
				yield put(requestDataFailure(subject, e, e.response.status));
			}
		}
	
}
export function* getDesigner(action) {

	const {id } = action.payload.args;

	//check if the designer already exists in state
	const checkDesigners = (yield select()).data.designers.all;
        console.log('checking for id ' + id);
        
        var designerWithProducts = true;

		if(id in checkDesigners) {
			 
			const checkHasProducts = (yield select()).data.designers.all[id].products;

			if(checkHasProducts) {
				console.log('The designer existed with products')
			}else {
                console.log('The designer existed without products');
                designerWithProducts = false;
				
			}
					
		}else{
            //the deisgner did not exist
            console.log('The designer did not exist - fetching')
            designerWithProducts = false;
        
            //fetch designer
            try {
               
				const response = yield call(fetchDesigner, action.payload.args); 
				// console.log('response: ', response.data); 
 
				const data = normalize(response.data.products, productListSchema); 
				cl(data.entities, 'normalized prods (getDesigner): ');
		
				response.data.products = data.entities;
				
				yield put(addDesigner(response.data));
				yield put(successStopLoading('designers'));

            } catch (e) {
				console.log('getDesigner failed'); 
				 
                yield put(requestDataFailure(subject, e, e.response.status));
            }
		}
        
	
}
export function* getDesigners(action) {
	 
		
		//get current designers sort option
		const sortOption = (yield select()).sort.designersSortingToolbar.sortOption

		const checkDesigners = (yield select()).data.designers.all;

		if(Object.keys(checkDesigners) === 0 && checkDesigners.constructor === Object) {

			console.log('designers already exist!')		
			  
		} else {
			console.log('designers DONT exist. Fetching.');		
			
			try {

				//add sort option to args
				action.payload.args.sortoption = sortOption;
				console.log(JSON.stringify(action.payload), 'to fetch designers with payload: ');
 
				const designers = yield call(fetchDesigners, action.payload.args);
				const normalizedDesigners = normalize(designers.data, designersSchema);

				cl(designers.data.data, 'designers: ')
				console.log('normalized designers:');
				cl(normalizedDesigners.entities)	
				// console.log(JSON.stringify(normalizedDesigners));	 
				yield put(addReplaceDesigners(normalizedDesigners.entities));	//designers.data.data
				// yield put(addResult({
				// 	result: normalizedDesigners.result,
				// 	stateKey: STATE_KEY,
				// }));

				let pageType =  'designers';
				let payload = {
					currentSet: designers.data.current_page,
					currentQueryTotalSet: designers.data.total,
					numPages: designers.data.last_page,
					from: designers.data.from,
					to: designers.data.to
				}
				cl(payload, 'get designers, page query payload: ')
				yield put(addPageQueryDetails(pageType, payload))

			} catch(e) {
				console.log('request designers failed: ' + e);
				yield put(requestDataFailure(subject, e, e.response.status));
			}
		} 
	

		// 	const response = yield call(fetchDesigners, action.payload.args);
		// 	const data = normalize(response.data, designersSchema);
		
	 
}
/**
 * Selectors
 */

 export const selectVisibleDesignerIds = state => state.data.designers.visibleDesigners;
 

export const selectHydrated = (state, id) => 
	denormalize(id, designerSchema, state.data);

export const selectAll = state => state.data.designers.all;

export const selectVisibleDesigners = createSelector(
	 
	[selectVisibleDesignerIds, selectAll] ,
	(designerIds, designers) => {

		cl(designerIds, 'designerIds: ')
		cl(designers, 'designers: ')
		var selectedDesigners = [];
		
		designerIds.map( id => {

			if(id in designers){
				selectedDesigners.push(designers[id]);
			}
		}) 	
		cl(selectedDesigners, 'selectedDesigners: ')
		return selectedDesigners; 
	}
 )
 export const selectReturnSomething = (state, something) => something;
export const selectDesignerById = (state, designerId) => state.data.designers.all[designerId];

// export const selectDesignerByIdWithProducts = (state, designerId) => state.data.designers

export const selectDesignerBySlug = createSelector(
	[selectReturnSomething, selectAll],
	(slug, designers) => {

		if(!slug.length){
			cl('slug was empty - designer not rettrived')
			return;
		}
		let targetDesigner = Object.values(designers).filter( designer => 
			designer.shop_slug === slug	
		)
		cl(targetDesigner, 'targetDesigner: ')
		if(!targetDesigner.length){
			cl('the designer wasnt found')
			return;
		}
		//check if products are inclded
		if(!('products' in targetDesigner[0])){
			cl('designer products was empty')
			return;
		}
		cl(targetDesigner[0], 'target designer with products was found')
		return targetDesigner[0];
	}
)
// export const selectDesignerBySlug = (state, slug) => {

//     console.log('selectDesignerBySlug');

//     return Object.values(selectAll(state))
//     .find((designer) => designer.shop_slug === slug);
// }
	

export const selectDesignerNameBySlug = createSelector(
	selectDesignerBySlug,
	designer => designer.shop_name
);
 

export const selectUserDesigner = state => state.data.designers.userDesigner;

export const selectUserDesignerProducts = state => state.data.designers.userDesigner.products;

//expects productId . Also requires terms to be loaded in state - will modify 
// designer products with extra node id info
export const getDesignerProductById = createSelector(
	[selectReturnSomething, selectUserDesigner ],
	(productId, designer) => {

		if(notObjectOrNotEmptyObject(designer)){
			cl('selector getDesignerProductById: designer was empty ')
			return;
		}else{
			cl('selector getDesignerProductById: designer object was retrieved ')
		}
		//get product by id 
		let product = designer.products.filter( product => 
			product.id == productId
		)
		
		return product;
	}
)


export const countProductsByDesignerSlug = createSelector(
	selectDesignerBySlug, 
	designer => designer.creations
);

export const countProductsSoldByDesignerSlug = createSelector(
	selectDesignerBySlug,
	designer => designer.sold_creations,
);

export const selectDesignerPermalinkBySlug = createSelector(
	selectDesignerBySlug,
	designer => designer.permalink
);

export const getDesignerNotice = createSelector(
	selectDesignerBySlug,
	designer => {
		if (designer) {
			return designer.shop_notice;
		}

		return false;
	}
);

// export const selectDesigners = createSelector(
// 	selectAll,
// 	designers => {
// 		return Object.keys(designers)
// 			.map(key => designers[key]);
// 	}
// );

// Ugly hack because time limits
export const selectDesignerProducts = (state) => {
	if (!state || !state.data || !state.data.designers || !state.data.products) {
		return {};
	}
	const designers = Object.keys(state.data.designers.all).map(key => state.data.designers.all[key]);
	const products = Object.keys(state.data.products.products).map(key => state.data.products.products[key]);

	return designers.reduce((result, designer) => {
        result[designer.id] = products.filter(product => 
            product.designer_id === designer.id)
			.map(product => ({ ...product, designer }));

		return result;
	}, {});
};

export const countDesigners = createSelector(
	selectAll,
	designers => {
		return Object.keys(designers).length;
	}
);
