import * as types from './actionTypes';


import {  
	REQUEST_DATA_FAILURE,
	REQUESTING_START_LOADING,
	SUCCESS_STOP_LOADING 
} from 'data/actions';

import {cl} from 'utils'
//state.creations.createProduct

const initialState = {

	isLoading: false,
	status: null,
	errorMsg: null,
	terms: [],
	requestStatus: {
		requesting: false,
		requestStatus: null,
		lastRequestError: {
			statusCode:null,
			message: null
		} 
	},
};

const reducer = (state = initialState, action) => {
	switch(action.type) {
		case types.REQUEST_CREATE_PRODUCT:
			return Object.assign({
				...state,
				isLoading: true,
			});
		case types.REQUEST_CREATE_PRODUCT_SUCCESS:
			return Object.assign({}, state, {
				...state,
				isLoading: false,
				status: 'success',
			});
		case types.REQUEST_CREATE_PRODUCT_FAILED:
			return Object.assign({}, {
				...state,
				isLoading: false,
				status: 'failed',
				errorMsg: action.error.response.data.message
			});
		case types.FETCH_TERMS_SUCCESS:
			return Object.assign({}, {
				...state,
				terms: action.result.data,
			});

		case REQUESTING_START_LOADING: 
			if(action.payload == 'createProduct') {
				return {
					...state,
					requestStatus: {
						...state.requestStatus,
						requesting: true,
						requestStatus:  'pending' 
					}
				} 
			}else{
				 return state;
			}

		case REQUEST_DATA_FAILURE:
			
			if(action.payload.subject == 'createProduct') {
				cl(action.payload, 'REQUEST_DATA_FAILURE: createProduct: ') 
				return {
					...state,
					requestStatus: {
						requesting: false,
						requestStatus: 'failed',
						lastRequestError: {
							statusCode: action.payload.statusCode || '',
							message: action.payload.error.message || ''
						}
					}
					
				}
			}else{ 
				return state;
			}

		case SUCCESS_STOP_LOADING: 

			if(action.payload == 'createProduct') {
				cl('createProduct SUCCESS_STOP_LOADING')
				return {
					...state,
					requestStatus: {
						...state.requestStatus,
						requesting: false,
						requestStatus:'success'
					}
					 
				}
			}else{
				return state;
			}


		default:
			return state;
	}
};

export default reducer;
