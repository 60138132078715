import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const ImageWrapper = styled.div`
	position: relative;
`;

const DaylicacyImage = (props) => {
	const { src, alt } = props;

	return(
		<ImageWrapper>
			<img src={src} alt={alt} />
		</ImageWrapper>
	);
};

// DaylicacyImage.defaultProps = {
// 	crop: false,
// }

DaylicacyImage.propTypes = {
	src: PropTypes.string.isRequired,
	alt: PropTypes.string,
//	crop: PropTypes.bool,
};

export default DaylicacyImage;