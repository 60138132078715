import * as types from './actionTypes'

export const openSnackbar = ({ autoHideDuration, message }) => ({
	type: types.OPEN_SNACKBAR,
	autoHideDuration,
	message,
})

export const closeSnackbar = () => {
	return({
		type: types.CLOSE_SNACKBAR,
	})
}

export const paginationNext = () => {
	return({
		type: types.PAGINATION_NEXT,
	})
}
 
export const addCurrentPage = ( currentPage ) => ({
	type: types.ADD_SHOP_CURRENT_PAGE, 
	currentPage,
})

export const clearPageQueryDetails = (pageType) => ({
	type: types.CLEAR_PAGE_QUERY_DETAILS,
	pageType
})

export const addPageQueryDetails = ( pageType, payload ) => ({
	type: types.ADD_PAGE_QUERY_DETAILS,
	pageType,
	payload
})

export const addShopFilter = ( selector, id ) => ({
	type: types.ADD_SHOP_FILTER_TERM,
	selector,
	id,
})
export const setShopPageSlug = ( pageSlug) => ({
	type: types.SET_SHOP_PAGESLUG,
	pageSlug
})

export const removeShopFilter = ( selector, id ) => ({
	type: types.REMOVE_SHOP_FILTER_TERM,
	selector,
	id,
})

export const addShopFilterChip = ( chip ) => {
	return({
		type: types.ADD_SHOP_FILTER_CHIP,
		chip,
	})
}

export const removeShopFilterChip = ( chip ) => {
	return({
		type: types.REMOVE_SHOP_FILTER_CHIP,
		chip,
	})
}

export const removeAllShopFilterChips = () => {
	return({
		type: types.REMOVE_ALL_SHOP_FILTER_CHIPS,
	})
}

// export const viewShop = slug => ({
// 	type: types.VIEW_SHOP,
// 	payload: { slug }
// })