import { createActionTypes, createActions } from 'utils';

const types = createActionTypes([

	'FETCHING', 
	'UPDATE_SHIPPING_CUSTOM', 
	'UPDATE_SHIPPING_CUSTOM_SUCCESS',
	'UPDATE_SHIPPING_CUSTOM_FAILURE',

]);

const actions = createActions({
	[types.FETCHING]: () => ({ }),

	[types.UPDATE_SHIPPING_CUSTOM]: (designerId, formData) => ({ designerId, formData }), 
	[types.UPDATE_SHIPPING_CUSTOM_SUCCESS]: result => ({ result }),
	[types.UPDATE_SHIPPING_CUSTOM_FAILURE]: error => ({ error }),
	
});

export { types, actions };
