import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { requestData } from 'data/actions';
import { selectAll as selectDesigners, selectDesignerProducts } from 'data/modules/designers';
import ListItem from 'views/ListDesigners/ListItem';
import {cl} from 'utils'

/**
 * ListDesigners component
 */

class Designers extends Component {

	constructor(props) {
		super(props);

		// this.props.requestData({
		// 	type: 'designers',
		// 	args: {
		// 		fields: ['products'],
		// 	},
		// });

	}

	render() {
		console.log(this.props.designers);
		
		return(
			<div className="list-designers row">
				
				<ul style={{width: '100%'}}>

					{ Object.values(this.props.designers).map((designer, i) => 
						<ListItem 
							 
							key={i}
							designerId={designer.id}
							name={designer.shop_name}
							rating={designer.rating}
							numRatings={'ratings' in designer ? designer.ratings.length : ''}
							country="France"
							numCreations={this.props.designerProducts[designer.id].length}
							numCreationsSold={0}
							numFavorites={designer.favorites.length}
							permalink={designer.permalink}
							avatar={designer.shop_banner ? designer.shop_banner.url : ''}
							products={this.props.designerProducts[designer.id]} />) 
						}
				</ul>
			</div>
		);
	}
}

Designers.propTypes = {
	requestData: PropTypes.func.isRequired,
	designers: PropTypes.object.isRequired,
};

export default connect(
	state => ({
		designers: selectDesigners(state),
		designerProducts: selectDesignerProducts(state),
	}),
	 { requestData }
)(Designers);
