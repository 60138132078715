/* FilterMenu Reducer  											*/
 
import {
    OPEN_MENU_ITEM, 
    CLOSE_MENU_ITEM,
    TOGGLE_OPEN_MENU_ITEM,
    TOGGLE_ADD_REMOVE_CHECKED_MENU_ITEM,
    CLOSE_ALL_MENU_ITEMS, 
    CLEAR_CHECKED_MENU_ITEMS,
    ADD_CHECKED_FILTER,
    REMOVE_CHECKED_MENU_ITEM
} from './actions';

import {  
	REQUEST_DATA_FAILURE,  
	REQUESTING_START_LOADING, 
	SUCCESS_STOP_LOADING 
} from 'data/actions';

import {SET_ROOT_TERM_REPLACE_OBJ} from '../actionTypes';

import {getRootTerm} from 'containers/Filters/selectors';

import {cl} from 'utils'


//state.interface.filtermenu

const initialState = {  
    menuItemsSubMenuOpenState: {},
    checkedMenuItems: {},
    requestStatus: {
        fetching: false,
        requestStatus: null,
        lastRequestError: {
            statusCode:null,
            message: null
        } 
    }
};


const reducer = (state = initialState, action) => {

	switch(action.type) {
		
		case OPEN_MENU_ITEM:

            var {termId, target} = action.payload;
 
            return {
                ...state,
                menuItemsSubMenuOpenState: {
                    ...state.menuItemsSubMenuOpenState,
                    [termId] : {
                        open: true,
                        target
                    }
                },
            };
       
        case TOGGLE_OPEN_MENU_ITEM: 

        // console.log('action payload is ', action.payload)
        // var {termId, currentTarget} = action.payload;

        console.log('TOGGLE_OPEN_MENU_ITEM: termId is ' + action.payload.termId)
        console.log('TOGGLE_OPEN_MENU_ITEM: currentTarget is ' + action.payload.currentTarget)

        var openState = true;
        if(action.payload.termId in state.menuItemsSubMenuOpenState) {
            console.log('action.payload.termId is in state.menuItemsSubMenuOpenState')
            console.log('open state is ' + state.menuItemsSubMenuOpenState[action.payload.termId].open + ' - will be set to ' +
            !state.menuItemsSubMenuOpenState[action.payload.termId].open );
            openState = !state.menuItemsSubMenuOpenState[action.payload.termId].open;
        }
        console.log('openstate is  ' + openState)
        return {
			...state,
			menuItemsSubMenuOpenState: {
                ...state.menuItemsSubMenuOpenState,
                [action.payload.termId] : {
                    open: openState,
                    // anchorEl: action.payload.currentTarget
                }
            },
        };

        //adds filter id if checked, removes if unchecked
        case TOGGLE_ADD_REMOVE_CHECKED_MENU_ITEM:
            cl(action.termId, 'TOGGLE_ADD_REMOVE_CHECKED_MENU_ITEM: termId is ')

            //if term is present - remove - otherwise add
            if(action.termId in state.checkedMenuItems) {

                //REMOVE
                var checkedMenuItemsCopy = Object.assign({}, state.checkedMenuItems);
                delete checkedMenuItemsCopy[action.termId];

                return {
                    ...state,
                    checkedMenuItems: {
                        ...checkedMenuItemsCopy
                    }
                }
            }else{
                //ADD 
                return {
                    ...state,
                    checkedMenuItems: {
                        ...state.checkedMenuItems,
                        [action.termId]: action.termId
                    }
                }
            }

        //root filter on page load when visiting a root category page. The 
        // corresponding filter menu item  - so it needs to beadd it to checked items (overwrite previous items)
        case SET_ROOT_TERM_REPLACE_OBJ:
            cl(action.termSlug, 'SET_ROOT_TERM_REPLACE_OBJ filtermenu reducer, termslug is ')
            return {
                ...state,
                checkedMenuItems: {
                    [action.termSlug.toLowerCase()]:action.termSlug.toLowerCase()
                }
            }
        case ADD_CHECKED_FILTER:
            cl(action.filter, 'ADD_CHECKED_FILTER filtermenu reducer, id is ')

            return {
                ...state,
                checkedMenuItems: {
                    [action.filter]:action.filter
                }
            }


        case CLOSE_ALL_MENU_ITEMS:

		return {
			...state,
			menuItemsSubMenuOpenState: {}
        };
        
        case CLOSE_MENU_ITEM:

		return {
			...state,
			menuItemsSubMenuOpenState: {
                ...state.menuItemsSubMenuOpenState,
                [action.termId] : {
                    open: false
                }
            },
        };
        case CLEAR_CHECKED_MENU_ITEMS:
            return {
                ...state,
                checkedMenuItems: {} 
            }
         
        case REMOVE_CHECKED_MENU_ITEM:

            var menuItemsCopy = Object.assign({}, state.checkedMenuItems)

            if(action.termId in menuItemsCopy){
                delete menuItemsCopy[action.termId]
            }else{
                return state;
            }
            return {
                ...state,
                checkedMenuItems: {
                    ...menuItemsCopy
                } 
            }

        case REQUEST_DATA_FAILURE:
            
            if(action.payload.subject == 'filtermenu') {
                cl(action.payload, 'REQUEST_DATA_FAILURE: xxxxx: ') 
                return {
                    ...state,
                    requestStatus: {
                        requesting: false,
                        requestStatus: 'failed',
                        lastRequestError: {
                            statusCode: action.payload.statusCode || '',
                            message: action.payload.error.message || ''
                        }
                    }
                    
                }
            }else{ 
                return state;
            }

        case SUCCESS_STOP_LOADING: 

            if(action.payload == 'filtermenu') {
                return {
                    ...state,
                    requestStatus: {
                        ...state.requestStatus,
                        requesting: false,
                        requestStatus:'success'
                    }
                    
                }
            }else{
                return state;
            } 
	default:
		return state; 
			 
	}
};

//if using more than 1 reducer, combine them
// const reducer = combineReducers({
// 	firstThing: someReducer,
// 	secondThing: someOtherReducer,
// });
export default reducer;


