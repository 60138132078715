import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';

import { SortableGrid } from 'components/Grid';
import { SortableElement } from 'components/Sortable';

import { openDialogAction, closeDialogAction } from 'containers/ImageCropper/actionCreators';
import IconButton from 'material-ui-old/IconButton';
import ImageWrapper from './components/ImageWrapper';

// import CropButton from 'components/Image/components/CropButton';
// import Image from 'components/Image';

import {cl} from 'utils';



 


	  
class ImageGrid extends Component {

	constructor() {
		super();
 
		this.onClickCropButton = this.onClickCropButton.bind(this);
		this.closeCropDialog = this.closeCropDialog.bind(this);
	}
 
 
	componentWillReceiveProps(nextProps){
		// alert('imagegrid will receive nextProps')
		// cl( JSON.stringify(nextProps) )
	}
	componentWillUpdate(){
		// alert('imageGrid will update');
	}
	closeCropDialog() {
		const { closeDialogAction } = this.props;
		closeDialogAction();
	}


	//opens crop dialog, sends image to Editor reducer
	onClickCropButton(object){   
		 
			//key is the index (from images in their current order) 
			//Runs IMAGE_CROPPER_DIALOG_OPEN
			// sets dialog to "open" and adds image to redux state.
			this.props.openDialogAction(object.key, object.image);

	}

 
	render() {


		const { indicatechangeswitch, files, onSortEnd, handleRemoveImage, rejectedFiles } = this.props;

		if(!files){
			// return (<p>files are not set</p>)
		}
	//  return <p>{JSON.stringify(files)}</p>
		return(
			<SortableGrid 
				onSortEnd={onSortEnd} 
			>
					{/* {JSON.stringify(files[1])} */}
				{ files && files.length && files.map((file, i) =>  {
 
						return( 
						 <SortableElement 
							 realIndex={i}
							 key={`${(i == undefined) ? 0 : i}_${file.id}`} 
							 index={(i == undefined) ? 0 : i} 
							 value={file.preview}
							 handleRemoveImage={handleRemoveImage}
							 onClickCropButton={this.onClickCropButton}
							 indicatechangeswitch={indicatechangeswitch}
							/>
						)}
					
				)}
				 {/* { rejectedFiles && rejectedFiles.length && 

					 (<h4>Files not accepted:</h4>)
				 }
				{ rejectedFiles && rejectedFiles.length &&
					  rejectedFiles.map((file, i) => 
					 
						<div>
							{file.name}
						</div> 
					 )
				 }  */}
			</SortableGrid>
			
		);
	}

}

ImageGrid.propTypes = {
	files: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.oneOfType([
				PropTypes.string,
				PropTypes.number
			]).isRequired,
			preview: PropTypes.string.isRequired,
		})
	).isRequired,
	onSortEnd: PropTypes.func,
};
 


//connect to the form to be able to get form values
ImageGrid = reduxForm( {
	form: 'createProductForm'
} )( ImageGrid )

const selector = formValueSelector( 'createProductForm' ) 

ImageGrid = connect(
	state => { 
		return { 
			formImages: selector(state, 'images'),	
		}
	},
	{ openDialogAction, closeDialogAction }
)( ImageGrid )


export default ImageGrid;
