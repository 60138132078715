import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';

class LanguageProvider extends Component {

	render() {
		const { translations } = this.props;
		
		return (
			<IntlProvider locale="en" messages={translations}>
				{this.props.children}
			</IntlProvider>
		);
	}

}

LanguageProvider.propTypes = {
	translations: PropTypes.object
};

const mapStateToProps = (state) => ({
	translations: state.translations
});

LanguageProvider = connect(
	mapStateToProps
)(LanguageProvider);

export default LanguageProvider;
