import { combineReducers } from 'redux';
import editProductReducer from 'containers/EditProduct/reducers';
import createProductReducer from 'containers/CreateProduct/reducers';
import toolbarReducer from './Toolbar/reducer';
import {SET_SUBJECT_KEY} from './actionTypes';

//state.creations
const initialState = {
	subjectKey: ''
};

const generalReducer = (state = initialState, action) => {

	switch(action.type){

		case SET_SUBJECT_KEY:
			return {
				...state,
				subjectKey: action.subjectKey
			}

		default:
			return state; 
	}
}

const reducer = combineReducers({
	general: generalReducer,
	toolbar: toolbarReducer,
	createProduct: createProductReducer,
	editProduct: editProductReducer,
});

export default reducer;
