import * as types from './actionTypes';


//requestAddShopFilter: saga runs 'addShopFilter' with the filter id to be added to 'state.filters.filters'
// ,addShopFilter requests products with filters
		//pageNumQuerystringValue needed?

export const addShopFilter = ({id , name,selector}) => ({
	type: types.ADD_SHOP_FILTER_TERM,
	payload: {id, name, selector}
});

export const setRootTermAddObj = termSlug => ({
	type: types.SET_ROOT_TERM_ADD_OBJ,
	termSlug
});
export const setRootTermReplaceObj = termSlug => ({
	type: types.SET_ROOT_TERM_REPLACE_OBJ,
	termSlug
});
 
export const replaceFilterTermObject =  (oldTermId , rootTermObject ) => ({
	type: types.REPLACE_FILTER_TERM_OBJECT,
	payload: {oldTermId, rootTermObject}
});
 
export const addShopRootTerm = (rootTerm) => ({
	type: types.ADD_SHOP_ROOT_TERM,
	rootTerm
});
export const removeCurrentShopRootTerm = () => ({
	type: types.REMOVE_CURRENT_SHOP_ROOT_TERM
});

export const fetchShop = (termSlug, pageNum) => ({
	type: types.FETCH_SHOP,
	termSlug, 
	pageNum
});
export const clearFiltersTerms = () => ({
	type: types.CLEAR_FILTERS_TERMS
});

export const addShopFilters = (selector, idArray) => ({
	type: types.ADD_SHOP_FILTER_TERMS,
	selector,
	idArray,
});
export const removeShopFilter = (id) => {

	console.log('removeShopFilter: ' + id)
	return {
		type: types.REMOVE_SHOP_FILTER_TERM,
		id
	}
};
export const removeShopFilters = (idArray) => {

	return {
		type: types.REMOVE_SHOP_FILTER_TERMS,
		idArray
	}
};
export const fetchProductsByRootFilter = (termSlug, pageNumQuerystringValue) => ({
	type: types.FETCH_PRODUCTS_BY_ROOT_FILTER,
	params: { termSlug, pageNumQuerystringValue }
});

export const requestFilterTerms = (pageNum = null) => ({
	type: types.REQUEST_FILTER_TERMS,
	payload: pageNum
});

export const requestFilterMenu = () => {
	return({
		type: types.REQUEST_FILTER_MENU
	});
};

export const setFilterMenuNoCounts = () => {
	return({
		type: types.SET_FILTER_MENU_NO_COUNTS
	});
};

export const addFilterMenu = (filterMenu) => {
	return({
		type: types.ADD_FILTER_MENU,
		filterMenu
	});
};

export const deactiveProductsCount = () => {
	return({
		type: types.DEACTIVATE_PRODUCTS_COUNT
	});
};
export const activateProductsCount = () => {
	return({
		type: types.ACTIVATE_PRODUCTS_COUNT
	});
};
