import { put, call } from 'redux-saga/effects';
import { normalize, denormalize } from 'normalizr';
import { createSelector } from 'reselect';
import { currencyListSchema } from '../schemas';
import { 
	ADD_KEY_VALUE,
	ADD_ENTITIES, 
	addEntities, 
	requestDataFailure,
	addKeyValue,
} from '../actions';
import api from '../api';

export const STATE_KEY = 'currencies';

const normalizedCurrencies = normalize(window.d_store.currencies, currencyListSchema);
const initialState = normalizedCurrencies.entities.currencies || [];

export default function reducer(state = initialState, action) {
	 
	switch (action.type) {
		case ADD_ENTITIES:
                    console.log('modules-currencies reducer: ADD_ENTITIES');
			return {
				...state,
				...action.payload.currencies,
			};
		default:
			return state;
	}
}

/**
 * Selectors
 */

export const selectHydrated = (state, id) => 
	denormalize(id, currencyListSchema, state.data);

export const selectModule = state => state.data.currencies || {};

export const selectAll = createSelector(
	selectModule,
	currencies => currencies,
);
