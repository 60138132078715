import React, { Component } from 'react';
import { Editor, EditorState, RichUtils } from 'draft-js';
import { connect } from 'react-redux';
import Paper from 'material-ui-old/Paper';
import PropTypes from 'prop-types';
//import Tabs from 'components/DaylicacyTabs';
// import { Tabs, Tab} from 'material-ui-old/Tabs';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

//import RichTextEditor from 'components/RichTextEditor';
import DaylicacyButton from 'components/DaylicacyButton';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Row from 'components/Row';
import Col from 'components/Col';

function TabContainer(props) {
	return (
		<Typography component="div" style={{ padding: 8 * 3 }}>
			{props.children}
	  
		 </Typography>
	
	);
  }

class Faq extends Component {

	constructor(props) {
		super(props);

		this.state = {
			value: 0,
			editorState: EditorState.createEmpty(),
		};

		this.handleEditorChange = (editorState) => this.setState({editorState});
		this.handleKeyCommand = this.handleKeyCommand.bind(this);
	}

	handleChange = (event, value) => {
		this.setState({ value });
	  };

	handleKeyCommand(command) {
		const newState = RichUtils.handleKeyCommand(
			this.state.editorState, command);

		if (newState) {
			this.handleEditorChange(newState);
			
			return 'handled';
		}

		return 'not-handled';
	}

	handleSave() {
		console.log('handleSave()');
	}


	  
	render() {
		console.log(this.state.editorState);
		const { value } = this.state;

		// TODO: Fetch from state.
		const languages = [
			{ id: 1, name: 'SE' },
			{ id: 2, name: 'FR' },
			{ id: 3, name: 'DE' },
			{ id: 4, name: 'ES' },
		];
		
		return (
			<div className="content--padded">
				<h2>Shop FAQ (max 5000 characters - spaces included)</h2>
				<Row>
					<Col xs={12}>
						<AppBar position="static">
							<Tabs value={value} onChange={this.handleChange}>
								{languages.map((language, index) => 
									<Tab label={language.name}/> 
								)}
							</Tabs>
		 				</AppBar>
						 
						{value >= 0 && 
							<TabContainer>
								 
								<h2>{languages[value].name}</h2>
									<Editor 
										handleKeyCommand={this.handleKeyCommand}
										editorState={this.state.editorState}
										onChange={this.handleEditorChange}
									/>
							</TabContainer>
						}
					</Col>
				</Row>
				<Button onClick={this.handleSave}  variant="raised" color="secondary" className="mui-button-standard">
					 {this.props.tr.save || 'Save'}
				</Button>
			</div>
		);
	}

// 	onBoldClick() {
// 		this.onChange(RichUtils.toggleInlineStyle(
// 			this.state.editorState, 'BOLD'));
// 	}

// 	render() {
// 		return (
// 			<div>
// 				<h2>Shop FAQ (max 5000 characters - spaces included)</h2>
// 				<div>
// 					<button onClick={this.onBoldClick.bind(this)}>
// 						Bold
// 					</button>
// 				</div>
// 			</div>
// 		);
// 	}

}

export default Faq;

// Faq.propTypes = {
// 	faq: PropTypes.string.isRequired,
// };

// export default connect(
// 	state => ({
// 		faq: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eligendi corrupti reiciendis repellat enim cumque, et nisi quaerat harum optio unde deserunt fuga doloribus error consequuntur impedit perspiciatis delectus tempora ut.',
// 	})
// )(Faq);
