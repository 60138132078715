import React, { Component } from 'react';
import { connect } from 'react-redux';
import FavoriteButton from 'components/FavoriteButton';
import MessageButton from 'components/MessageButton';
import { favoriteDesigner, messageDesigner } from './actions';

class ActionBar extends Component {

	render() {
		return (
			<div className="row">
				<div className="cx no-grow-xs">
					<FavoriteButton 
						onClick={this.props.favoriteDesigner}
					/>
				</div>
				<div className="cx">
					<MessageButton 
						onClick={this.props.messageDesigner}
					/>
				</div>
			</div>
		);
	}
};

export default connect(
	null,
	{ favoriteDesigner, messageDesigner }
)(ActionBar);