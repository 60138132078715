import { Link } from 'react-router-dom';
import styled from 'styled-components';

const OverlayLink = styled(Link)`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	opacity: 0;
`;

export default OverlayLink;