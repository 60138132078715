export const REQUEST_DATA = 'REQUEST_DATA';
export const REQUEST_DATA_SUCCESS = 'REQUEST_DATA_SUCCESS';
export const SUCCESS_STOP_LOADING = 'SUCCESS_STOP_LOADING';
export const REQUESTING_START_LOADING = 'REQUESTING_START_LOADING;'
export const FETCHING = 'FETCHING';
export const STOPPED_FETCHING = 'STOPPED_FETCHING';
export const REQUEST_DATA_FAILURE = 'REQUEST_DATA_FAILURE';
export const ADD_ENTITIES = 'ADD_ENTITIES';

export const ADD_REPLACE_DESIGNERS = 'ADD_REPLACE_DESIGNERS';

export const ADD_MESSAGES = 'ADD_MESSAGES';
export const ADD_KEY_VALUE = 'ADD_KEY_VALUE';
export const ADD_RESULT = 'ADD_RESULT';
export const SOMETHING = 'SOMETHING';

export const something = () => ({
	type: SOMETHING,
	args: { ok:'ok'}
});
export const requestData = ({ type, args }) => ({
	type: REQUEST_DATA,
	payload: { type, args }
});

export const requestDataSuccess = ({ type, data }) => ({
	type: REQUEST_DATA_SUCCESS,
	payload: { type, data }
});

//on fetching, set loading/fetching to true
export const requestingStartLoading = data => ({
	type: REQUESTING_START_LOADING,
	payload: data
});

//after success, set loading/fetching to false. Provide name to identify the reducer which should respond
export const successStopLoading = (data = '') => ({
	type: SUCCESS_STOP_LOADING,
	payload: data
});
export const requestDataFailure = (subject, error, statusCode=null) => ({
	type: REQUEST_DATA_FAILURE,
	payload: { subject,error, statusCode },
});

export const requesting = stateKey => ({
	type: FETCHING,
	payload: stateKey
})
export const stoppedFetching = stateKey => ({
	type: STOPPED_FETCHING,
	payload: stateKey
})

/* ============== Add stuff ======================== */
export const addEntities = entities => ({
	type: ADD_ENTITIES,
	payload: entities,
});



export const addReplaceDesigners = data => ({
	type: ADD_REPLACE_DESIGNERS,
	payload: data,
});

export const addMessages = messages => ({
    type: ADD_MESSAGES,
    payload: {messages},
});

export const addResult = ({ result, stateKey }) => ({
	type: ADD_RESULT,
	payload: { result, stateKey },
});
 
export const addKeyValue = ({ key, value, state_key }) => ({
	type: ADD_KEY_VALUE,
	payload: {
		kvp: { [key]: value }, 
		state_key,
	}
});

export const actions = {
	requestData,
	requestDataSuccess,
	requestDataFailure,
};

export const types = {
	REQUEST_DATA,
	REQUEST_DATA_SUCCESS,
	REQUEST_DATA_FAILURE,
};