import React from 'react';
import { connect } from 'react-redux';
 
  
import AddressesForm from './components/AddressesForm';
import AccountSetupForm from './components/AccountSetupForm';


class MyAccount extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
		}
	}
 
	cloneObjWithDeletedProp(obj, prop) {
    
		let res = Object.assign({}, obj)
		delete res[prop]
		return res
	
	}
	handleSlider(event, value) {
		this.setState({
			avatarSlider: value
		});
	}

	render() {
		
		const { tr, classes } = this.props;
  
		return(
			<div className="content">
				 
				<div className="my-account section content--padded section--margin-bottom">
			
					<div className="form" >
						<div className="row"> 
							<div className="cx12 cs6">
								<AccountSetupForm tr={tr} />
							</div>
							
							<div className="cx12 cs6">
									<AddressesForm tr={tr}/>
								</div>
						</div> 
					</div>
				</div>
			</div>
		);
	}
}
  
 
export default MyAccount;

 
/*

onSubmit={this.handleSubmit}
				isLoading={this.state.isLoading}
				status={this.state.status}
				errorMsg={this.state.errorMsg} 
				tr={this.props.tr}





	enableReinitialize: true,
 //state.session.user

//color submit:  "#DF5087"

,
			initialValues:state.session.user
*/
	//initialValues:state.session.user
/*
const dobYear = selector(state, 'dobYear');
		const dobMonth = selector(state, 'dobMonth');

		return {
			dobYear,
			dobMonth,
			tr: state.translations
                       
		};
*/


/* i connect:

onSubmitSuccess: (result) => {
		//after post, set new initialization values from result - without avatar 
		 console.log('the posted result is as follows: ');
		 console.log(result);
		let filteredUserCredentials = Object.assign({}, result)
		delete filteredUserCredentials["avatar"]
		console.log('the posted result without avatar is as follows: ');
		console.log(filteredUserCredentials);

	}


	*/