import React from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { createProduct, fetchTerms } from './actionCreators';
import { objectToFormData } from 'utils';
import CreationForm from 'containers/CreationForm';
import {
	getErrorMsg,
	getStatus,
	getIsLoading,
} from './selectors';

import { requestData } from 'data/actions';
import {cl, notObjectOrNotEmptyObject} from 'utils'

/* ================ info ================
	//parent is : Myshop/Creations/index.js		 

	//Also used for EDIT product
 
*/
class CreateProduct extends React.Component {

	constructor(props) {
		super(props);

		this.handleSubmit = this.handleSubmit.bind(this);
  
	}
 
	handleSubmit(data) { 
		console.log('CreateProduct.handleSubmit', data);
	
		const termNodes = this.props.termNodes;
		let terms = [];
  
		('attributes' in data) && data.attributes.forEach((attribute) => {

			if (Array.isArray(attribute)) {
				attribute.forEach((node) => {
					if(node.term.id) {
                        terms.push(node.term.id);
					}
				});
			}
		});


		if (data.mainCategory in termNodes) {
			terms.push(termNodes[data.mainCategory].term);
		}
		if (data.subCategory in termNodes) {
			terms.push(termNodes[data.subCategory].term);
		}
		data['terms'] = terms;

		let dataCopy;
		
		if (data.images !== undefined && Array.isArray(data.images)) {
			dataCopy = Object.assign({}, data, { 
				images: data.images.map(image => ({
					crop: image.crop,
					file: image.file,
				}))
			});
		} else {
			dataCopy = data;
		}

		console.log(JSON.stringify(dataCopy));
		const formData = objectToFormData(dataCopy);

		// Dispatch action
		this.props.createProduct(formData);
	}

	render() {
		const { redirectAfterFormPost, createOrEdit, productId, errorMsg, status, isLoading } = this.props;

		return(
			<CreationForm 
				redirectAfterFormPost={redirectAfterFormPost}
				createOrEdit={createOrEdit}
				productId={productId}
				tr={this.props.tr}
				onSubmit={this.handleSubmit}
				errorMsg={errorMsg}
				status={status}
				isLoading={isLoading}
			/>
		);
	}

}

const mapStateToProps = state => ({
	
	errorMsg: getErrorMsg(state),
	status: getStatus(state),
	isLoading: getIsLoading(state),
	termNodes: state.data.terms.termNodes,
	designerId: state.session.designerId,
});

CreateProduct = reduxForm({
	form: 'createProduct',
})(CreateProduct);

export default connect( 
	mapStateToProps, 
	{ createProduct, requestData } 
)(CreateProduct);
