import React from 'react';
import { connect } from 'react-redux';

import { selectProduct,selectProduct2, selectDenormalizedProduct } from 'data/modules/products';

import PropTypes from 'prop-types';
import { truncate } from 'lodash';
import { Link } from 'react-router-dom';
import { Card, CardTitle, CardText } from 'material-ui-old/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CreationControls from 'components/CreationControls';
import CircularProgress from '@material-ui/core/CircularProgress';


const shortenProductName = (name) => {
	return truncate(name, {
		length: 19,
	});
};

let ProductCard = props => {

	//if (props.product == null || props.designer == null) return null;
 
	const {
		tr,
		productId,
		currency, 
		product
	} = props;
 
	 
	if (!product) {

		return (
			<div className="mui-circular-container"> <CircularProgress/></div>
		);
	}
	//find the shipping profile (only 1 for each product - so the first index in the array) 
	var shipping = null;
	if(product.shipping_profiles.length) {
		shipping = product.shipping_profiles[0];
	}
	var min_order_cost_free_string = '';

	//get "min order cost free" from a profile zone method connected to "Standard" method type
	if(shipping){
		//find standard method type in a profile zonemethod
		min_order_cost_free_string = ('methods' in shipping) && (shipping.methods.length > 0) &&
				shipping.methods ? shipping.methods[0].min_order_cost_free_string : '';
	}
	const imgUrl =  product.gallery && product.gallery.items.length && product.gallery.items[0].url ?
        		   product.gallery.items[0].url :
				   '/img/daylicacy-logo_woman-name.jpg';
	const price_string_in_currency = 'price_string_in_' + product.designer.shop_currency.code.toLowerCase();		

	const permalink = product.permalink !== undefined ? product.permalink : '';
	const designerPermalink = product.designer.permalink !== undefined ? product.designer.permalink : '';


  	//console.log(price_string_in_currency + ', ' + product[price_string_in_currency]);

//   <img src={imgUrl || '/img/daylicacy-logo_woman-name.jpg'} style={{ display: 'block' }} /> 


	return(
	
		<div className="card-container cx12 cs6 cm3 cl3"> 
			 <div>
				<Link to={permalink || ''}>
					 <CardMedia className="card-img" image={imgUrl || "/img/primary-logo.png"}>
					
					</CardMedia> 
				</Link>
			<CreationControls productId={productId} product={product} />
			<CardHeader  
				className="product-card-header" 
				style={{ padding: '8px 16px' }}
				title={ <Link style={{fontWeight:'bold'}} to={{
					pathname: designerPermalink,
					state: {
						designerId: product.designer.id
					}
				}}>
						{product.designer.shop_name}
					</Link> }  
				subheader={<Link to={permalink}>{shortenProductName(product.title)}</Link>} 
			>
				
			</CardHeader>
			<CardContent className="cardcontent-product-title">
			
				<Link to={permalink || ''}>{shortenProductName(product.name)}</Link>
			</CardContent>
			<CardContent>
				<div className="price">
					{ product.discount > 0 &&
						<s style={{fontWeight: 'bold'}} className="original-price"><span>{product[price_string_in_currency]}</span></s> }
					<span style={{fontWeight: 'bold'}} className="current-price">
						<span>{product.current_price_string}</span>
					</span>
				</div>
				{ shipping && 
					<div className="shipment">
						<i className="fa fa-truck" aria-hidden="true"></i> {tr.free_shipping_for} <span>{min_order_cost_free_string}</span>
					</div>
				} 
			</CardContent>
			</div> 
		</div>
		 
	);
};
	
 
ProductCard.propTypes = {

	product: PropTypes.shape({
		permalink: PropTypes.string.isRequired,
		designer: PropTypes.shape({
			permalink: PropTypes.string.isRequired,
			shop_name: PropTypes.string.isRequired,
		}).isRequired
	}).isRequired,
	
};

{/* reselect: Memoization for each product: If we pass makeMapStateToProps to connect, 
	each instance of the container will get its own mapStateToProps function with a private selector. */}

const makeMapStateToProps = () => {

	const mapStateToProps = (state, ownProps) => { 
		return {
			product: selectDenormalizedProduct(state, ownProps.productId)
		 
		}
		
	}
	return mapStateToProps
}

ProductCard = connect(
	makeMapStateToProps
)(ProductCard);

export default ProductCard;

/*
	const {
		tr,
		productId,
		currency,
		product: {
			id,
			featured_image: { sizes: { featured: image } },
			name: title,
			current_price: currentPrice,
			price: originalPrice,
			discount,
			designer,
		},
		product
	} = props;

*/
