import React, {Component} from 'react';
import PropTypes from 'prop-types';
// import { RadioGroup } from 'material-ui-next/Radio';
import { RadioGroup } from 'redux-form-material-ui';


class DaylicacyRadioGroup extends Component {
 
 constructor(props) {
     
    super(props);
    
    this.state = {
        
       value: 2
         
    } 
}

//    componentWillMount() {
//        
//        this.setState({
//            value: this.props.initialSelectedValue
//        })
//    }
    
    handleChange = (event, value) => {

        this.setState({ value :  event.target.value});
  };
  
    render() {
        
        const { input, children } = this.props;
        
        return(
            <RadioGroup 
                name={input.name}
                value={this.state.value}
            >
                { children }
            </RadioGroup>
        );
    } 
};

DaylicacyRadioGroup.propTypes = {
	input: PropTypes.object.isRequired,
	children: PropTypes.node.isRequired
};

export default DaylicacyRadioGroup;


//import React from 'react'
//import { RadioButtonGroup } from 'material-ui-next/RadioButton'

//const DaylicacyRadioGroup = ({ input, ...rest }) => {
//	return(
//		<RadioButtonGroup { ...input } { ...rest }
//			valueSelected={ input.value }
//			onChange={ ( event, value ) => input.onChange( value ) }
//		/>
//	)
//}

//export default DaylicacyRadioGroup
