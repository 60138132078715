import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { fetchDesigner } from 'hoc/designer';
import { fetchProducts } from 'hoc/product';

// Layout components
import Header from 'views/Header';
import Footer from 'views/Footer';

// Views
import Home from 'views/Home';
import Shop from 'views/Shop';
import Account from 'views/Account';
import Checkout from 'views/Checkout';
import Designer from 'views/Designer';
import Designers from 'views/ListDesigners';
import Product from 'views/Product';
import Page from 'views/Page';
import PageNotFound from 'views';

const Master = ({ match }) => {
	const { url } = match;

	return(
		<div>
			<Route path="/:lang" component={Header} />
			<Switch>
				<Route exact path="/:lang" component={Home} />
				<Route 
					path={`${url}/shop/:termSlug/:pageSlug`} 
					component={Shop} />
				{/* <Route
					path={`${url}/shop/designers`} 
						component={Designers} /> */}
				<Route 
					path={`${url}/designer/:designerSlug/product/:productSlug`} 
					component={Product} />
				<Route
					path={`${url}/designer/:slug`}
					component={Designer}
				/>
				<Route 
					path={`${url}/checkout`} 
					component={Checkout} />
				<Route 
					path={`${url}/account`}
					component={Account} />
				<Route
					path="/:lang/:slug"
					component={Page} />

				<Route component={PageNotFound} />
			</Switch>
			<Route path="/:lang" component={Footer} />
		</div>
	);
};

export default Master;
