import { types } from './actions';
import {actions} from './actions'
import {cl} from 'utils'

//state.createEditShippingGeneral
const initialState = {
	initialValues: {},
	isLoading: false,
	status: '',
	requestStatus: '',
	errorMsg: '',
};

const reducer = (state = initialState, action) => {
	switch(action.type) {
		case types.FETCHING:

			if(action.payload.subject === 'createEditShippingGeneral'){
				return Object.assign({}, state, {
				
					requestStatus:'pending'
				
				});
			}else{
				return state;
			}

		
		case types.FETCH_SHIPPING_GENERAL_SUCCESS:
 
			return Object.assign({}, state, {
				requestStatus:'success',
				initialValues: {
					methods: action.payload.result.data.profiles[0].methods,
					
				},
				profileId: action.payload.result.data.profiles[0].id
			});

		case types.RESET_GENERAL_SHIPPING_STATUS:

			return {
				...state,
				status: ''
			}
		//add updated shipping method info to state
		//action has: { designerId, formData } 
		// the form data will contain all the default methods
		case types.UPDATE_SHIPPING_GENERAL:

			console.log('UPDATE_SHIPPING_GENERAL')
			console.log(action)
			console.log(action.payload.formData);
			return Object.assign({}, state, {
				isLoading: true,
			});

		case types.UPDATE_SHIPPING_GENERAL_SUCCESS:
			cl('edit shipping genral educer: UPDATE_SHIPPING_GENERAL_SUCCESS: ')
			cl(action, 'action: ')

			return Object.assign({}, state, {
				isLoading: false,
				status: 'success',
				initialValues: {		
					methods: action.payload.result.shippingprofile.methods,
					
				},
				profileId: action.payload.result.profileId
			});

		case types.UPDATE_SHIPPING_GENERAL_FAILURE:
			return Object.assign({}, state, {
				isLoading: false,
				status: 'failed',
				errorMsg: action.payload.error.response.data.message
			});
		default:
			return state;
	}
};

export default reducer;
