import { put, call } from 'redux-saga/effects';
import { normalize, denormalize } from 'normalizr';
import { 
	shippingConditionsListSchema,
} from 'data/schemas';
import { 
	ADD_ENTITIES, 
	addEntities, 
	requestDataFailure,
} from 'data/actions';
import api from 'data/api';

export const STATE_KEY = 'shippingConditions';

export default function reducer(state = {}, action) {
	switch (action.type) {
		case ADD_ENTITIES:
                    console.log('modules-shippingconditions reducer: ADD_ENTITIES');
			return {
				...state,
				...action.payload.shippingConditions,
			};
		default:
			return state;
	}
}

export function* getShippingConditions(action) {
	console.log('getShippingConditions saga. Doing api call: fetchShippingGeneral');
	try {
		const response = yield call(
			api.fetchShippingGeneral,
			action.payload.args.designerId
		);
		const data = normalize(response.data, shippingConditionsListSchema);
		yield put(addEntities(data.entities));
	} catch (e) {
		yield put(requestDataFailure(e.message));
	}
}

/**
 * Selectors
 */

export const selectAll = state => state.data.shippingConditions;
