import camelCase from 'camelcase';

//console log function (used to write console log messages)
 export const cl = (input = false, string = false, json = false) => {
     
     if(!string){
         return console.log(input);
     }else if(!string && json){                 // cl( variable,false,true)
         return console.log(JSON.stringify(input));
         
     }else if(!input && string){                
         return console.log(string);
         
     }else if(string && !json){
         return console.log(string, input);
         
     }else{
         return console.log( string, JSON.stringify(input));
     }
     
 }
 
 export const intersect = (a, b) => {
    var t;
    if (b.length > a.length) t = b, b = a, a = t; // indexOf to loop over shorter
    return a.filter(function (e) {
        return b.indexOf(e) > -1;
    });
}

 //return true if not object or is empty object
export const notObjectOrNotEmptyObject = (obj) => {
	return !(obj && obj.constructor && obj.constructor === Object) || 
                    (obj.constructor === Object && Object.keys(obj).length === 0 )
}

//if object retrun true
export const isObj = (obj) => {
    return (obj.constructor === Object && Object.keys(obj).length !== 0 )
}
export const isNotEmptyObject = (obj) => {
	return (obj.constructor === Object && Object.keys(obj).length > 0 )
}

 //return true if empty object 
export const emptyObject = (obj) => {
	return obj.constructor === Object && Object.keys(obj).length === 0 
}

//if calling this with only 1 file, make sure it is wrapped in []
// export const normalizeImgFromDB = files => 
// 	files.map(file => ({
// 		id: file.id,
// 		file: file,
// 		preview: file.preview,
// 		crop: {},
// 	}));


export const getParameterByName = (name, url) => {
     if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' ')).replace(/\/$/, "");
     
}

export const stall = async (stallTime = 3000) => {
	await new Promise(resolve => setTimeout(resolve, stallTime));
  }
  
export const createActionTypes = types =>
	types.reduce((result, type) => {
		result[type] = type;
		
		return result;
	}, {});

export const createActions = actions => {
	let result = {};
	Object.keys(actions).forEach(type => {
		result[camelCase(type)] = (...args) => ({
			type: type,
			payload: actions[type](...args),
		});
	});
	
	return result;
};


export const objectToFormData = (obj, form, namespace) => {
	
	let fd = form || new FormData();
	let formKey;
  
	for(let property in obj) {
		if(obj.hasOwnProperty(property) ) {				//&& obj[property]
			if (namespace) {
				
				formKey = namespace + '[' + property + ']';
			} else {
				formKey = property;
			}
	//  cl(formKey, 'objectToFormData: formkey is ');
	 
      // if the property is an object, but not a File, use recursivity.
			if (obj[property] instanceof Date) {
				fd.append(formKey, obj[property].toISOString());
			}
			else if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
				objectToFormData( obj[property], fd, formKey);
			} else { // if it's a string or a File object
				fd.append(formKey, obj[property]);
				console.log(formKey + ' = ' +  obj[property])
			}
		}
	}
  
	return fd;
};
