import React from 'react';
import { Link } from 'react-router-dom';

const Blurb = ({
	icon,
	label,
	link,
}) => {
	return(
		<div className="blurb-container">
			<Link to={link}>
				<div className="blurb box-container">
					<div className="blurb-label cat-box-title"><h2>{ label }</h2></div>
					<div className="blurb-icon cat-box-img">
						<img src={icon}/>
					</div>
				</div>
			</Link>
			<div style={{ paddingBottom: '100%' }}></div>
		</div>
	);
};

export default Blurb;