import React from 'react';
import { Link } from 'react-router-dom';

// import Avatar from 'material-ui-old/Avatar';
// import List from 'material-ui-old/List/List';
// import ListItem from 'material-ui-old/List/ListItem';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon  from '@material-ui/core/ListItemIcon';
import ListItemText  from '@material-ui/core/ListItemText';

// import SvgIcon from '@material-ui/core/SvgIcon';
import Icon from '@material-ui/core/Icon';

const Fashion = ({tr, items, currentLanguage}) => {

    items.reduce(item => {
        if(item.icon.indexOf('icon-') > -1){
            return item;
        }else{
            item.icon = 'icon-'.concat(item.icon);
            return item;
        }
        
    }); 

    return(
        <div className="col-sm-4">
            <List component="nav" className="cx12 fashionmenulistcolumn">
                {items.map((navItem, i) => {
                    const itemName = tr[navItem.name.toLowerCase().replace(/\W+/g, '_')]
                                ?
                                tr[navItem.name.toLowerCase().replace(/\W+/g, '_')]
                                :
                                navItem.name.toLowerCase().replace(/\W+/g, '_');

                    if( ! tr[navItem.name.toLowerCase().replace(/\W+/g, '_')] ) { 
                    }

                    return (
                        <Link to={`/${currentLanguage}${navItem.url}`}key={i} className="menu-link">
                            <ListItem>
                                <Icon className={navItem.icon.replace('.svg', '').toLowerCase() || "icon-fashion_sport"} size="30"/>
                                <ListItemText primary={itemName} />
                               
                            </ListItem>
                        </Link>
                    )
                })}
            </List>
        </div>
    )
}

export default Fashion;