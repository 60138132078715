
import React from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form';

import api from 'data/api';
import { objectToFormData } from 'utils';

import { connect } from 'react-redux';
import MyAccount from 'views/Account/MyAccount';

class MyAccountContainer extends React.Component {

	constructor(props) {
		super(props);

		this.handleSubmit = this.handleSubmit.bind(this);

		this.state = {
			
		};
 	 	
	}
	 
	componentWillMount() {
  	 if(!this.props.userId) {
		   console.log('no user id. redirecting');
		   this.props.history.push("/en");
	   }
	}

	handleSubmit(data) {
		console.log('hello from acc container - setting isloading true');
		this.setState({
			isLoading: true,
		});

  
//        console.log('handleSubmit(data) in myaccount');
//        console.log(data);
//
//        const formData = objectToFormData(data);
//        console.log(formData);
//
//		api.updateUser(this.props.userId, formData).then(response => {
//			console.log('Calling update user from MyAccountContainer', response.data);
//			
//			this.setState({
//				isLoading: false,
//				status: 'success',
//				initialValues: {
//					...this.state.initialValues,
//					old_password: '',
//					new_password: '',
//					new_password_repeat: '',
//					...response.data
//				},
//			});
//		}).catch(error => {
//			this.setState({
//				isLoading: false,
//				status: 'failed',
//				errorMsg: error.response.data.message,
//			});
//		});
	}

	render() {

		const { currentUserAvatar } = this.props;

		return (
			  
			<MyAccount
				onSubmit={this.handleSubmit}
				isLoading={this.state.isLoading}
				status={this.state.status}
				errorMsg={this.state.errorMsg} 
				tr={this.props.tr}
			/>
		);
	}
}

export default connect(
	state => ({
		userId: state.session.userId || false,
		tr: state.translations
	})
)(MyAccountContainer);

/*

initialValues={this.state.user}
*/