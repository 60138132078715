import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';

import { connect } from 'react-redux';
import { actions } from './actions';
import { requestData } from 'data/actions';

import {fetchDesignerShippingZones, selectDesignerShippingZones, selectShippingZoneCountries} from 'data/modules/shippingProfiles';


/* old table */
// import {
// 	Table,
// 	TableBody,
// 	TableHeader,
// 	TableHeaderColumn,
// 	TableRow,
// 	TableRowColumn,
// } from 'material-ui-old/Table';
//import Button from 'material-ui-next/Button';
import Button from '@material-ui/core/Button';

//material ui table elements
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import {cl} from 'utils'

//api endpoint 'designer/{id}/shipping/zones', function: getDesignerShippingZones
class ShippingZones extends Component {

	constructor(props) {
		super(props); 

		this.state = {

			deleteOpen: false 
		}
		this.handleEditShippingZone = this.handleEditShippingZone.bind(this);
		this.handleDeleteShippingZone = this.handleDeleteShippingZone.bind(this);
	}

	componentWillMount(){
		//check if designer shipping zones are avaiable, else fetch:
		 console.log('calling fetchDesignerShippingZones with id ' + this.props.designer.id);

		 //see data/modules/shippingZones for reducer
		  this.props.fetchDesignerShippingZones({id: this.props.designer.id});
	}

	
	componentDidMount(){

		//after saving zone, scroll position is wrong - set to top
		window.scrollTo(0, 0); 
	}

	handleEditShippingZone(zone){
		 
		// this.props.onEdit(shippingZone)
	}
	handleDeleteShippingZone(designerId, zone){

		cl('handleDeleteShippingZone: designer ' + designerId + ' , zone: ' + zone)

		this.props.requestData({
			type: 'deleteShippingZone',
			args: {designerId,zone }
		})
		 
	}

	render() {

		const {shippingZones, countries, onEdit, onDelete} = this.props;

		 
		if(!shippingZones ||
			shippingZones && Object.keys(shippingZones).length === 0 && 
			shippingZones.constructor === Object){
				return <div> No shipping zones yet </div>
		}
		console.log('shippingZones: ')
		console.log(shippingZones)
		return (
			<Paper>
			
			<Table className="list-shipping-zones-table">
				<TableHead className="table-head">
					<TableRow>
						<TableCell>Country group name</TableCell>
						<TableCell>Countries of destination</TableCell>
						<TableCell>VAT to apply</TableCell> 
						<TableCell> </TableCell> 
						<TableCell> </TableCell>
					</TableRow>
				</TableHead>
				<TableBody className="table-body">
				{ Object.values(shippingZones).map((shippingZone, index) => {

						cl(shippingZone, 'here is one shippingZone: ')
				return(
					<TableRow key={shippingZone.id}>
						<TableCell>{shippingZone.name}</TableCell>
						<TableCell>
							{ shippingZone.countries.map( countryId => {
								
								return countries[countryId]
								    
							} ).map( country =>
								<span key={country.name}>{country.name}, </span>
							) 
							}
						</TableCell>
						<TableCell>
							{shippingZone.vat}
						</TableCell>
						<TableCell>
									{/* onEdit will call this.props.viewEditShippingZone in parent (Shipping ) */}
							<Button onClick={() => onEdit(shippingZone)}>		
								Edit
							</Button>
						</TableCell> 
						<TableCell>
									{/* ccccc */} 
							<Button onClick={() => this.handleDeleteShippingZone(this.props.designer.id, shippingZone.id)}>		
								Delete
							</Button>
							 
						</TableCell>
					</TableRow>
					)
				}
				)}
				</TableBody>
			</Table>
    </Paper>
		);
	}

}

ShippingZones.propTypes = {
	onEdit: PropTypes.func.isRequired,
	designer: PropTypes.object.isRequired,
	shippingZones: PropTypes.array.isRequired,
};

ShippingZones = connect(
	state => ({
		designer: state.session.user.designer,
		shippingZones: selectDesignerShippingZones(state),
		countries:selectShippingZoneCountries(state)
	}),
	{fetchDesignerShippingZones,requestData}
)(ShippingZones);

export default ShippingZones;

//Old table:
/*
<div>
				<Table>
					<TableHeader>
						<TableRow>
							<TableHeaderColumn>Name</TableHeaderColumn>
							<TableHeaderColumn>Countries</TableHeaderColumn>
							<TableHeaderColumn></TableHeaderColumn>
						</TableRow>
					</TableHeader>
					<TableBody>
						{ shippingZones.map((shippingZone, index) => (
							<TableRow key={index}>
								<TableRowColumn>{shippingZone.name}</TableRowColumn>
								<TableRowColumn>
									{ shippingZone.countries.map((country, index) => (
										<span key={country.name}>{country.name} </span>
									)) }
								</TableRowColumn>
								<TableRowColumn>
									<Button onClick={() => this.props.onEdit(shippingZone)}>
                                        Edit
                                        </Button>
								</TableRowColumn>
							</TableRow>
						)) }
					</TableBody>
				</Table>
			</div>

			*/
