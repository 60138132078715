import React from 'react';
import PropTypes from 'prop-types';

const initialStyles = {
	note: {
		fontSize: '12px',
		opacity: .6,
	}
};

const renderNote = ({ text }) => {
	return(
		<span className="note" style={initialStyles.note}>
			{ text }
		</span>
	);
};

const renderParagraph = ({ text }) => {
	return(
		<p>{ text }</p>
	);
};

const DaylicacyText = ({ children, type }) => {

	switch(type) {
		case 'note':
			return renderNote({ text:  children });
		case 'paragraph':
			return renderParagraph({ text:  children });
		default:
			return renderParagraph({ text: children });
	}
	
};

DaylicacyText.propTypes = {
	children: PropTypes.string
};

export default DaylicacyText;