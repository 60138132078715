import React from 'react';
import Blurbs from './components/Blurbs';
import Spacer from './components/Spacer';
import { connect } from 'react-redux';  

let Home = (props) => {
	const { blurbs } = window.d_store;
	
	return(
		<Spacer className="container">
			<h2 style={{
				textAlign: 'center', 
				marginTop: 0,
				fontFamily: "'LPBambus', 'Georgia', 'serif'",
				fontSize: '35px'
			}}>
				{ props.tr.fine_handemade_text }
			</h2>
			<Blurbs blurbs={blurbs} />
		</Spacer>
	);
};

Home = connect(
	state=>({ 
		 
		tr: state.translations
	})
)(Home);

export default Home;
