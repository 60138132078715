import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Popover from 'material-ui-old/Popover';
import Badge from 'components/Badge';
import IconButton from 'components/IconButton';
import Cart from 'containers/Cart';
import { openCart, closeCart } from 'containers/Cart/actions';
 import { getItemsByDesigner } from 'containers/Cart/selectors';
 
class ToggleCart extends Component { 

	constructor(props) {
		super(props);

		this.state = {
			anchorEl: null,
		};

		this.handleOpenCart = this.handleOpenCart.bind(this);
		this.handleCloseCart = this.handleCloseCart.bind(this);
	}

	handleOpenCart(anchorEl) {
		this.props.openCart();

		this.setState({
			anchorEl,
		});
	}

	handleCloseCart() {
		this.props.closeCart();
 
		this.setState({
			anchorEl: null,
		});
	}

	render() {
		 
		const { numProducts } = this.props;
		//const numCartItems = Object.keys(countedCartItems).length;

		return(
			<div>
				<Badge 
					value={numProducts}
					position="bottom"
				>
					<IconButton
						iconClassName="fa fa-shopping-bag"
						onClick={e => this.handleOpenCart(e.currentTarget)}
					/>
				</Badge>
				<Popover
					open={this.props.open}
					anchorEl={this.state.anchorEl}
					anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
					targetOrigin={{ horizontal: 'left', vertical: 'top' }}
					onRequestClose={this.handleCloseCart}
				>
					<Cart />
				</Popover>
			</div>
		);
	}
}

ToggleCart.propTypes = {
	//countedCartItems: PropTypes.object.isRequired,
	closeCart: PropTypes.func.isRequired,
	openCart: PropTypes.func.isRequired,
	open: PropTypes.bool.isRequired,
};

export default connect(
	state => ({
		//cartItemsByDesigner: getItemsByDesigner(state),
		//countedCartItems: countedCartItems(state),
		numProducts: state.cart.numProductsQuantity,
		open: state.cart.open,
	}),
	{ openCart, closeCart }
)(ToggleCart);
