export default {
	primary: '',
	primaryLight: '',
	primaryDark: '',
	
	secondary: '',
	secondaryLight: '',
	secondaryDark: '',
	
	warning: '',
	error: '',
	success: '',

	link: '',
	linkActive: '',
	linkHover: '',
	linkDisabled: '',

	panel: '#e2dcdc',
	panelHeader: '#d2bbd3',

	icon: '',
	iconHover: '',
	iconDisabled: '',

	button: '',
	buttonActive: '',
	buttonHover: '',
	buttonDisabled: '',

	notice: '#d2bbd3',

	bodyText: 'rgb(226, 220, 220)',
	bodyTextDark: '#212121',
};