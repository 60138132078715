import React from 'react';

const Leave = () => {
	return(
		<div className="content content--padded">
			<h3>Leave</h3>
		</div>
	);
};

export default Leave;