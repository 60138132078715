import React from 'react';
import IconButton from 'material-ui-old/IconButton';
//import Icon from 'material-ui-next/Icon';
import Icon from '@material-ui/core/Icon';
//import Select from 'material-ui-next/Select';
import Select from '@material-ui/core/Select';
//import { MenuItem } from 'material-ui-next/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const generateMenuItems = (numPages) => {
	const menuItems = [];
	
	for(let i = 1; i < numPages + 1; ++i) {
		menuItems.push({
			value: i,
			label: `Page ${i} of ${numPages}`,
			primaryText: i,
		});
	}

	return menuItems;
};

const renderMenuItem = (menuItem) => {
	return(
		<MenuItem
			className="select-menuitem pagination-menuitem"
			key={menuItem.value}
			value={menuItem.value}
			label={menuItem.label} 
		>
                {menuItem.primaryText}
                </MenuItem>
	);
};

const DaylicacyPagination = ({
	currentPage,
	onChange,
	onNextClick,
	onPrevClick,
	numPages,
}) => {

	const menuItems = generateMenuItems(numPages);

	return(
		<div className="pagination">
			<IconButton 
				tooltip="Previous page"
				onClick={onPrevClick}
			>
				<Icon
					className="fa fa-angle-left"
				/>
			</IconButton>
			<Select
				className="mui-select-bottom-border"
				value={currentPage}
				onChange={onChange}
				iconStyle={{ display: 'none' }}
			 
				style={{ margin: 0 }}
			>
				{ menuItems.map(renderMenuItem) }
			</Select>
			<IconButton 
				onClick={onNextClick}
				tooltip="Next page"
			>
				<Icon
					className="fa fa-angle-right"
				/>
			</IconButton>
		</div>
	);
};

export default DaylicacyPagination;