import React from 'react';
import { connect } from 'react-redux';
import { Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn } from 'material-ui-old/Table';
import { requestData } from 'data/actions';
import DaylicacyButton from 'components/DaylicacyButton';

class Messages extends React.Component {

    constructor(props) {

        super(props);

		this.state = {
			showBody: false
		}
 
        this.showBodyDiv = this.showBodyDiv.bind(this);
    }

	componentWillMount() {

		// this.props.requestData({
        //     type: 'messages',
        //     args: {
        //         designerId: this.props.userId,
        //     },
        // });
	}


    showBodyDiv(body) {
    	console.log('inside show body div', body);
    	return <div>{body}</div>
	}

    renderRow(row, k) {
    	this.row = row;
    	return( <TableRow key={k}>
				<TableRowColumn>{row.from}</TableRowColumn>
				<TableRowColumn>{row.subject}</TableRowColumn>
				<TableRowColumn>{row.category}</TableRowColumn>
				<TableRowColumn>{row.created_at}</TableRowColumn>
				<TableRowColumn>
					<DaylicacyButton 
						onClick={() => {
								const body = row.body;
                            	this.refs.emailBody.innerHTML = body;
								if(this.refs.emailBody.style.display == 'none') {
									this.refs.emailBody.style.display = 'block';
								}
                        	}
						}
					>
					Show {this.props.tr.show}
					</DaylicacyButton>
				</TableRowColumn>
				<TableRowColumn>r</TableRowColumn>
				</TableRow>
		);
	}

	render() {
    	const keys = Object.keys(this.props.messages);

        return (
			<div className="margin-bottom">
				<div className="content">
					<div className="content--padded">
						<Table>
							<TableHeader>
								<TableRow>
									<TableHeaderColumn>From</TableHeaderColumn>
									<TableHeaderColumn>Subject</TableHeaderColumn>
									<TableHeaderColumn>Category</TableHeaderColumn>
									<TableHeaderColumn>Date</TableHeaderColumn>
									<TableHeaderColumn>Body</TableHeaderColumn>
									<TableHeaderColumn>Replied</TableHeaderColumn>
								</TableRow>
							</TableHeader>
							<TableBody>
								{
									keys.map((k,i) => {
                                        return this.renderRow(this.props.messages[k], i)
                                    })
								}
							</TableBody>
						</Table>
					</div>

					<div className="content--padded">
						<Table>
							<TableBody>
								<TableRow>
									<TableRowColumn>
										<div id="emailBody" ref="emailBody" style={{ display:'none', width:'100%', overflowX:'auto', whiteSpace:'nowrap' }}> </div>
									</TableRowColumn>
								</TableRow>
							</TableBody>
						</Table>
					</div>
				</div>
			</div>
        );
    }
};

export default connect(
    state => ({
        userId: state.session.userId,
        messages: state.data.messages,
    }),
    { requestData }
)(Messages);

