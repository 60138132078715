import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from 'material-ui-old/Avatar';
import DaylicacyButton from 'components/DaylicacyButton';
import CircularProgress from '@material-ui/core/CircularProgress';

import { selectProducts} from 'data/selectors';
import { openCart, closeCart } from 'containers/Cart/actions';
 
//parent: ToggleCart
class CartContainer extends Component {

	constructor(props) {
		super(props);

		this.handleClose = this.handleClose.bind(this);
	}

	handleClose() {
		this.props.closeCart();
	}

	render() {
		const {
			items, 
			onCheckout,
			tr
		} = this.props;

		
		return (
			
			<List className="cart">
				 { Object.keys(items).map((id, i) => (
					<ListItem
						key={i}
						  
					>
						<Avatar
							src={items[id].gallery && items[id].gallery.items.length
									? items[id].gallery.items[0].sizes.thumbnail 
									: '/img/daylicacy-logo_woman-name.jpg'}
							style={{borderRadius: '0%'}}
						/>
						 
						<ListItemText primary={items[id].name}/>
						<ListItemText className="cart-price" primary={items[id].current_price_string } />
						{ items[id].current_price_string + (items[id].quantity > 1) &&
								<ListItemText className="cart-quantity" primary={` x ${items[id].quantity }`} />

						}
						
					</ListItem>
				)) } 
				
				<ListItem>
					<Link to="/en/checkout/">
						<DaylicacyButton 
							onClick={onCheckout}
					>
							{tr.view_my_shopping_bag || 'View my shopping bag'}
							</DaylicacyButton>
					</Link>
				</ListItem> 
				<ListItem>
					<DaylicacyButton onClick={this.handleClose} >
						Continue my shopping
						</DaylicacyButton>
				</ListItem>

				{this.props.fetching &&   
					<div className="load-overlay">
						<div className="load-container">{<CircularProgress/>}</div>
					</div>
				}
			</List>
		);
	}
}

CartContainer.propTypes = {
	
	items: PropTypes.object,
	openCart: PropTypes.func.isRequired,
	closeCart: PropTypes.func.isRequired,
};

export default connect(
	state => ({
		fetching: state.cart.requestStatus.requesting,
		requestStatus:state.cart.requestStatus.requestStatus,
		tr: state.translations,
		items: state.cart.items,
		//products: selectProducts(state),
	}),
	{ openCart, closeCart }
)(CartContainer);


// 				<ul 	
// 					className="products-list" 
// 					style={{ marginBottom: '10px', }}
// 				>
// 					{ numProducts > 0 ?
// 						creations.map( this.renderProduct ) :
// 						<li style={{marginBottom:'10px'}}>
// 							Your cart is empty
// 						</li> }
// 				</ul>
// 				<div className="margin-bottom">
// 					<Button 
// 						label="Go to cart"
// 						labelPosition="before"
// 					>
// 						<Link to="/en/checkout" style={{
// 							position: 'absolute',
// 							top: 0,
// 							left: 0,
// 							right: 0,
// 							bottom: 0,
// 							width: '100%',
// 							opacity: 0,
// 						}}>
// 							Go to cart
// 						</Link>
// 					</Button>
// 				</div>
// 				<div>
// 					<Button
// 						label="Continue my shopping"
// 						onClick={ this.handleRequestClose }
// 					/>
// 				</div>
