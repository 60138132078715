import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Paper from 'material-ui-old/Paper';
import DesignerCard from 'components/DesignerCard';
import Alert from 'components/Alert';
import Breadcrumbs from './components/Breadcrumbs';
import ProductDetails from './components/ProductDetails';
import ProductCharacteristics from './components/ProductCharacteristics';
import ProductShipping from './components/ProductShipping';
import Carousel from 'react-slick';
import { requestData } from 'data/actions';
import { selectDenormalizedProduct, selectProductIdBySlug } from 'data/modules/products';
import Image from 'components/Image';
//import Icon from 'material-ui-next/Icon';
import Icon from '@material-ui/core/Icon';

import CircularProgress from '@material-ui/core/CircularProgress';
import Row from 'components/Row';
import Col from 'components/Col';
import { Link, Route } from 'react-router-dom';
import Tabs from 'components/DaylicacyTabs';
import Content from 'components/Content';
import { prepareAddToCart } from 'containers/Cart/actionCreators';
import { openSnackbar } from 'containers/Snackbar/actions';
import DaylicacyButton from 'components/DaylicacyButton';
import {stall} from 'utils';
import { openCart, closeCart } from 'containers/Cart/actions';

const carouselSettings = {
	dots: false,
	infinite: true,
	speed: 500,
	slidesToShow: 1,
	slidesToScroll: 1,
	arrows: false,
};


const tabs = [
	{
		label: 'Product Details',
		value: 'product-details',
	},
	{
		label: 'More Characteristics',
		value: 'more-characterstics',
	},
	{
		label: 'Terms & Conditions',
		value: 'terms-conditions',
	},
	{
		label: 'Payment & Shipping',
		value: 'payment-shipping',
	},
	{
		label: 'Designer',
		value: 'designer',
	}
];

class Product extends Component {

	constructor(props) {
		super(props);

		this.handleTabClick = this.handleTabClick.bind(this);
   
		}
	

    componentWillMount() {
         // See if product or productId is available
         if (!this.props.product) {
           // alert('slug is ' + this.props.match.params.productSlug)
            var productId = undefined;
            if(this.props.location.state && this.props.location.state.productId){
                 alert('this.props.location.state.productId is set')
                productId = this.props.match.location.state.productId;
            }
            //fetch product
             this.props.requestData({
                type: 'product',
                args: {
                    id: productId,
                    slug: this.props.match.params.productSlug,
                    // fields: [
                    // 	'shop_banner'
                    // ]
                }
            });
        }
    }
 
    prepareAddToCart = async(product) => {
            
        const { prepareAddToCart, openCart, closeCart } = this.props;
        console.log('product id :' + prepareAddToCart + ' added to cart');
		openCart(); 
		
        (prepareAddToCart(product));
        closeCart.bind(this);
         
    
    }

	handleTabClick(tab) {
		const activeTab = tab.props.value;
		const { history, match } = this.props;
		history.push(`${match.url}/${activeTab}`);
	}

	render() {
        const { product, tr, openSnackbar, delivery_country } = this.props;
        
		const countries = {
			en: 'UK',
			se: 'Sweden',
			all: 'All'
        };
        
		const countryName = countries[delivery_country];

		
		if (!product) {
            console.log('product not set');
			return <div className="mui-circular-container"><CircularProgress/></div>;
		} 
		 
		const shippingProfile = product.shipping_profiles.length && product.shipping_profiles[0];

		//first available method
		const shipping = shippingProfile ? shippingProfile.methods[0] : null;

		return(
			<div className="container">
				<div className="product-container">
					<Row>
						<Breadcrumbs />
						<Col xs={8}>
							<Paper className="product-detail">
								<div className="product__gallery">
									<Row>
										<Col xs={6}>
											<Link className="product-link" to="/">
												<Icon style={{margin:"6px", textDecoration: 'none'}} className="fa fa-undo" />
												{tr.return_to_search}
											</Link>
										</Col>
										<Col>
											<Link to="/">
												<Icon style={{margin:"6px", textDecoration: 'none'}} className="fa fa-arrow-left" />
												{tr.previous_creation}
											</Link>
										</Col>
										<Col align="right">
											<Link to="/">
												{tr.next_creation}
												<Icon style={{margin:"6px", textDecoration: 'none'}} className="fa fa-arrow-right" />
											</Link>
										</Col>
									</Row>
								</div>

								<div className="product__gallery"><h2>{product.name}</h2></div>

								<div className="product__gallery">
									<Carousel {...carouselSettings}>
                                        {product.gallery.items.map(item => (
											<div key={item.id}>
												<Image src={item.url} align="center" />
											</div>
                                        ))}
									</Carousel>
								</div>
							</Paper>
						</Col>

						<Col xs={4}>
							<div className="designer-card">
								<DesignerCard
									name={product.designer.shop_name}
									avatar={product.designer.shop_banner ? product.designer.shop_banner.url : ''}
									permalink={product.designer.permalink}
									rating={product.designer.rating}
									numRatings={product.designer.ratings.length}
									className="designer-card"
								/>
							</div>
							<Alert cssClasses="alertbox alert-notice" >
                                {product.designer.notice}
							</Alert>
							<Paper className="product-detail">
								<div className="product__details">
									<div className="product__price">
										{ tr.price }: {product.current_price_string}
									</div>
									<div>
										{ tr.vat_included } <br />
										{ tr.international_items }<br /><br />
									</div>
									<div className="product__deliveryTime">
										{ shipping && 
											<div>
												<strong>
													{tr.delivery_time} {tr.to} {countryName}: {shipping.delivery_from} {tr.to} {shipping.delivery_to} {tr.days}<br />
													{tr.free_shipping_over} {shipping.min_order_cost_free_string}<br />
													<a href="#">{tr.shipping_fees}</a> {tr.to} {countryName} <br />
												</strong><br />
											</div>
										}
										<a href="#">{tr.change_delivery_country}...</a><br /><br />
									</div>
									<div className="product__attributes">
										{product.term_attributes.map((termAttribute, index) => {
											const attName = termAttribute.attribute.name;
											if(attName === 'Cloth Size' || attName === 'Colour') {
												return(
													<div key={index}>
														{termAttribute.attribute.name}: {termAttribute.term.name}
													</div>
												)
											}
										}
										)}
										{product.stock === 1 
										? 'Only 1 piece available!'
										: `${product.stock} in stock` }
									</div>

									<DaylicacyButton
										type="button"
										name="save"
										onClick={() => {
                                            
                                            this.prepareAddToCart(product);
                                            openSnackbar({
                                                message: 'Product has been placed in cart',
                                            });
                                        }}
									>
										{tr.add_to_basket || 'Add to basket'}
									</DaylicacyButton>

									<div>
										{tr.sales_terms} <a href="#">Terms&Conditions</a> {tr.of_this_designer}.
									</div>
								</div>
							</Paper>
						</Col>
					</Row>
					<Tabs 
						tabs={tabs}
						theme="rounded"
						onActive={this.handleTabClick} />
					<div style={{
						backgroundColor: 'white',
						padding: '1em',
						marginBottom: '1em',
					}}>
						<Route
							exact={true}
							path={`${this.props.match.url}`}
							component={Content}
						/>
						<Route
							path={`${this.props.match.url}/product-details`}
							render={() => <ProductDetails
								productName={product.name}
								productDescription={product.description}
								designerName={product.designer.shop_name}
								designerDescription={product.designer.shop_description}
							/>}
						/>
						<Route
							path={`${this.props.match.url}/more-characterstics`}
							render={() => <ProductCharacteristics
								termAttributes={product.termAttributes}
							/>}
						/>
						<Route
							path={`${this.props.match.url}/terms-conditions`}
							component={Content}
						/>
						<Route
							path={`${this.props.match.url}/payment-shipping`}
							component={() => <ProductShipping
								product={product}
								conditions={this.props.shippingConditions}
							/>}
						/>
						<Route
							path={`${this.props.match.url}/designer`}
							component={Content}
						/>
					</div>
				</div>
			</div>
		);
	}
};

Product.propTypes = {
	product: PropTypes.object.isRequired,
};

// Conditions for given country
const selectShippingConditions = (state, product, countryId) => {
	return product.shipping_profile[0].methods.filter(condition => {
		return !!condition.zonecost.countries.find(country => country.id === countryId);
	});
};
	
const selectShipping = (state, product, countryId) => {
	console.log('selectShipping', product);

	// Conditions for given country
	const conditions = product.shipping_profile[0].methods.filter(condition => {
		return !!condition.zonecost.countries.find(country => country.id === countryId);
	});
	
	console.log('conditions', conditions);

	// Profiles for given country (returns boolean)
	const profiles = product.shipping_profiles[0].filter(profile => {

		const methods = profile.methods.filter(method => {
			return !!method.zone.countries.find(country => country.id === countryId);
		});
		//find which zone id the country belongs to (todo)
		// let zoneId = profile.
		// const costs = profile.zonecosts.filter(cost => {
		// 	return !!cost.zone.countries.find(country => country.id === countryId);
		// });
		
		return !!methods && !!costs;
	});
	
	console.log('profiles', profiles);

	// ...???
	const result = conditions.map(condition => {
		const mathchingProfiles = profiles.filter(profile => {
			return !!profile.methods.filter(method => method.id === condition.method.id);
		});
	});

	return null;
};

Product.propTypes = {
    prepareAddToCart: PropTypes.func.isRequired,
    openSnackbar: PropTypes.func.isRequired,
};

export default connect(
	(state, ownProps) => {
		console.log('ownProps.match.params.productSlug: ' + ownProps.match.params.productSlug);
        //get denormalized product by id or slug
		let product = undefined;

		//get denormalized product IF products are available
		if(Object.keys(state.data.products.products).length === 0 && state.data.products.products.constructor === Object){
			console.log(' No products in state');
		}else { 
			console.log('There are products in state.data.products.products');

			product = ownProps.location.state && ownProps.location.state.productId ? 
                            selectDenormalizedProduct(state, ownProps.location.state.productId)
                            : selectDenormalizedProduct(state, selectProductIdBySlug(state, ownProps.match.params.productSlug));
		}
		
      
		// const shipping = ownProps.product ? selectShipping(state, ownProps.product, 35) : [];
		// const shippingConditions = ownProps.product ? selectShippingConditions(state, ownProps.product, 35) : [];
		const tr = state.translations;
		
		return {
			product,
			// shipping,
			// shippingConditions,
			tr,
			delivery_country : state.session.delivery_country_name
		};
	}, {prepareAddToCart, openSnackbar, openCart, closeCart,requestData }
)(Product); 
