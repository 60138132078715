export const REMOVE_SHOP_FILTER_CHIP = 'REMOVE_SHOP_FILTER_CHIP';
export const REMOVE_ALL_SHOP_FILTER_CHIPS = 'REMOVE_ALL_SHOP_FILTER_CHIPS';
export const ADD_SHOP_FILTER_CHIP = 'ADD_SHOP_FILTER_CHIP';
export const REQUEST_ADD_SHOP_FILTER_TERM = 'REQUEST_ADD_SHOP_FILTER_TERM';
export const ADD_FILTERS_TERM = 'ADD_FILTERS_TERM';
export const REQUEST_FILTER_TERMS = 'REQUEST_FILTER_TERMS';
export const ADD_SHOP_FILTER_TERM = 'ADD_SHOP_FILTER_TERM';
export const ADD_SHOP_FILTER_TERMS = 'ADD_SHOP_FILTER_TERMS';
export const ADD_FILTER_TERM_OBJECT = 'ADD_FILTER_TERM_OBJECT'; 
export const REPLACE_FILTER_TERM_OBJECT = 'REPLACE_FILTER_TERM_OBJECT'; 
export const SET_ROOT_TERM_ADD_OBJ = 'SET_ROOT_TERM_ADD_OBJ';
export const SET_ROOT_TERM_REPLACE_OBJ = 'SET_ROOT_TERM_REPLACE_OBJ';
export const REMOVE_SHOP_FILTER_TERM = 'REMOVE_SHOP_FILTER_TERM';
export const REMOVE_SHOP_FILTER_TERMS = 'REMOVE_SHOP_FILTER_TERMS';
export const REMOVE_FILTERS_TERM = 'REMOVE_FILTERS_TERM';
export const FETCH_PRODUCTS_BY_ROOT_FILTER = 'FETCH_PRODUCTS_BY_ROOT_FILTER';
export const FETCH_SHOP = 'FETCH_SHOP';
export const CLEAR_FILTERS_TERMS = 'CLEAR_FILTERS_TERMS';
export const ADD_SHOP_ROOT_TERM = 'ADD_SHOP_ROOT_TERM';
export const REMOVE_CURRENT_SHOP_ROOT_TERM = 'REMOVE_CURRENT_SHOP_ROOT_TERM';
export const REQUEST_FILTER_MENU = 'REQUEST_FILTER_MENU';
export const SET_FILTER_MENU_NO_COUNTS = 'SET_FILTER_MENU_NO_COUNTS';
export const ADD_FILTER_MENU = 'ADD_FILTER_MENU';
export const ACTIVATE_PRODUCTS_COUNT = 'ACTIVATE_PRODUCTS_COUNT';
export const DEACTIVATE_PRODUCTS_COUNT = 'DEACTIVATE_PRODUCTS_COUNT';

 