import React from 'react';

// Components
import FooterWidget from './FooterWidget';
import FooterPopover from './FooterPopover';


class MoneyBackGuarantee extends React.Component {

	constructor() {
		super();

		this.state = {
			open: false,
		};
	}

	handleMouseEnter(e) {
		this.setState({
			open: true,
			anchorEl: e.currentTarget,
			width: window.getComputedStyle(e.currentTarget).width,
		});
	}

	handleMouseLeave() {
		this.setState({
			open: false,
		});
	}

	render() {
		const { tr } = this.props;

		return(
				<FooterWidget
					icon="fa-undo"
					label={tr.money_back_guarantee}
					handleMouseEnter={this.handleMouseEnter.bind(this)}
					handleMouseLeave={this.handleMouseLeave.bind(this)}
				>
					<FooterPopover 
						open={this.state.open}
						anchorEl={this.state.anchorEl}
						width={this.state.width}
					>
						<div>
							<h2>{tr.money_back_guarantee}</h2>
							<p>{tr.money_back_guarantee_text}</p>
							<div className="k-dropdown__close">x</div>
						</div>
					</FooterPopover>
			</FooterWidget>
		);
	}
}

export default MoneyBackGuarantee;