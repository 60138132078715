import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Toolbar from './Toolbar';
import CreateProduct from 'containers/CreateProduct';
import EditProduct from 'containers/EditProduct';
import { getView, getDesignerId } from './selectors';
import ProductTable from 'containers/ProductTable';
import { getSelectedProduct } from 'containers/ProductTable/selectors';
import {getDesignerProductById} from 'data/modules/designers'
import { requestData } from 'data/actions';

import {cl, notObjectOrNotEmptyObject} from 'utils'
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from 'components/Alert';

 /*
	fetches: 	- terms 
				- shipping profiles belonging to the user designer  
				- user designer
		profiles:
			api function: 	fetchShippingProfiles
			route (get):	api/designer/' + designerId + '/shipping/profiles
			function: 		getDesignerShippingProfiles

	  	designer:  
			route:		/api/designer/${id}`
			function:	getDesignerById	 
			state.data.userDesigner becomes available

		
		subjectKey: Used for Alert sign. Stored in state.creations.general.subjectKey
					To differentiate which form is being used for the Alert sign,
					which show succes/fail on redirect to this page (Creations).	
					The subject key is represented by a key in the local state, for instance 'createProduct'
					Subject key is set on form post in the forms' componentDidUpdate
*/

const alertDuration = 3500;

//Products are loaded from "UserDesigner" in  state.data.designers.userDesigner
class Creations extends Component {

	constructor(props) {
		super(props);

		this.state = {
			selectedProductListProduct: {},
			createProduct: {
				postStatus: '',
				createProductFormPostedCount: 0
			}
			
		}

		this.handleRowClick = this.handleRowClick.bind(this);
		this.renderView = this.renderView.bind(this);
	}

	componentWillMount(){

		// fetch user designer if not already available
		if(!this.props.userDesigner || typeof this.props.userDesigner.id == 'undefined') {
			cl('no user designer - fetch');

			this.props.requestData({ 
				type: 'userDesigner',
				args: {
					id: this.props.designerId
				}
			});
		}else{
			cl('user designer already available')
		}

		//get designer shipping Profiles if not available
		if(!this.props.shippingProfiles || notObjectOrNotEmptyObject(this.props.shippingProfiles)){
			cl('no user shippingProfiles - fetch');

			this.props.requestData({
				type: 'shippingProfiles',
				args: {
					designerId: this.props.designerId,
				},
			});
		}else{
			cl('user designer already available')
		}
		
	}

	componentDidMount() {

		//get terms if not available
		if(!this.props.terms || notObjectOrNotEmptyObject(this.props.terms)){

			this.props.requestData({
				type: 'terms',
			});
		}
		
	}
	resetPostStatus = subjectKey => {

		cl('resetPostStatus, the subjectKey is ' + subjectKey)

		//reset status (Alert will not show)
		//add some extra time for the fade to complete
		if(!subjectKey || subjectKey && !subjectKey.length ){
			return;
		}
		var dataObj = {};

		if(subjectKey == 'createProduct'){
			dataObj = {
				createProduct: {
					postStatus: null,
					createProductFormPostedCount: 0
				}
			}
		} 
		setTimeout(() => {

			this.setState({
				...this.state,
				...dataObj
				
			})

		}, alertDuration );
 
	}
	redirectAfterFormPost = (successOrFail) => {
		 cl('redirectAfterFormPost')
  
		//change view 
		
		this.renderView('listProducts')

		//activate Alert message
		console.log('after changing view to listProducts - setting state')

		this.setState({
			createProduct: {
				postStatus: successOrFail,
				createProductFormPostedCount: this.state.createProductFormPostedCount +1
			}
			
		})
 
		
	}
	handleRowClick({ rowData, index }) {
		if(rowData !== null) {
			this.setState({
				selectedProductListProduct: {
					data: rowData,
					index,
				},
			});
		} else {
			this.setState({
				selectedProductListProduct: null,
			});
		}
	}
	handleSelectOnchange(value){
		//alert('onchange: ' + value);
	}

	renderView(view, tr,userDesigner) {
		console.log('changed view to ' + view); 
		const { selectedProductListProduct } = this.props;

		let productId = false;
		let product = '';

		if(selectedProductListProduct && 'data' in selectedProductListProduct){
			productId = selectedProductListProduct.data.productNumber;
		}
		//set product to the one selected product in product table 
		// (to be passed down to EditProduct)
		// if(userDesigner && productId) { 
		// 	console.log('  id is ' + productId + ' userDesigner.products: ', userDesigner.products);
		// 	//get product by id 
		// 	product = userDesigner.products.filter( product => 
		// 			product.id == productId
		// 	)
		// 	console.log('filter selected product from userDesigner: ', product);
		// }
		
		switch(view) {
			case 'createProduct':
				console.log('rendering createProduct');
				 
				return  !userDesigner ? <p> no designer</p> : 
						(<CreateProduct 
							createProductFormPostedCount={this.state.createProductFormPostedCount}
							redirectAfterFormPost={this.redirectAfterFormPost} 
							terms= {this.props.terms}
							transactionStatus={this.props.createStatus}
							tr={tr}
							designer={userDesigner}
							createOrEdit="create"
						/>);
				
			case 'editProduct':
				console.log('Rendering view : editProduct');
				let dataMissingMessage = null;
				if(!userDesigner){ dataMissingMessage = 'Designer could not be loaded.'} 
				
				if(notObjectOrNotEmptyObject(this.props.terms)){ dataMissingMessage = 'Attribute data unavailable.'} 
				//   cl(this.props.terms, 'this.props.terms');
				return (
					dataMissingMessage ? <div className="mui-circular-container"><CircularProgress /></div>: 
					( <EditProduct  
						terms= {this.props.terms}
						designer={userDesigner}
						createOrEdit="edit"
						transactionStatus={this.props.editStatus}
						tr={tr}
						productId={productId} 
						product={this.props.product}
					/>)
				);

			case 'listProducts':
			cl('the case is listProducts')
			default:
					cl('returning producttable')
				return (
					!userDesigner ? <p> No designer.</p> : 
					(<ProductTable
						designer={userDesigner}
						onRowClick={this.handleRowClick}
						designerId={this.props.designerId}
					/>)
				);
		}
	}

	render() {
		 const { userDesigner, tr, view, selectedProductListProduct } = this.props;
		console.log('selectedProductListProduct: ', selectedProductListProduct)
		return(
			<div className="creations section section--margin-bottom">
				<Toolbar 
					tr={tr} 
					disabledEdit={!selectedProductListProduct}
					changeView={this.renderView}
				/>
				<section className="section">
					<div className="content content--padded"> 
					
						 { this.renderView(view, tr, userDesigner) } 
						 { this.state.createProduct.postStatus === 'failed' &&  
							<Alert cssClasses="alertbox alert-error" >{this.props.errorMsg}</Alert>
						}
						{ this.state.createProduct.postStatus  == 'success' &&		// this.props.requestStatus.requestStatus
						
							<Alert 
								subjectKey={this.props.subjectKey}
								cssClasses="alertbox alert-success"  
								delay={alertDuration}
								formPostedCount={this.state.createProduct.createProductFormPostedCount}
								resetPostStatus={this.resetPostStatus}
							>
								Saved
							</Alert>
						}
						 
					</div>
				</section>
			</div>
		);
	}

}

Creations.propTypes = {
	view: PropTypes.string.isRequired,
	//selectedProduct: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {

	let productId = state.productTable.selectedProduct 
			? state.productTable.selectedProduct.data.productNumber : null;
	let userDesigner = state.data.designers.userDesigner; 
	
	return {
		shippingProfiles: state.data.shippingProfiles.shippingProfiles || null,
		terms: state.data.terms.terms || null,
		productId,
		userDesigner,
		product: getDesignerProductById(state, productId),
		editStatus: state.creations.editProduct.status,
		createStatus: state.creations.createProduct.status,
		subjectKey: state.creations.general.subjectKey,
		view: state.creations.toolbar.view,
		selectedProductListProduct: getSelectedProduct(state), 
		designerId: getDesignerId(state), 
	}
	  
};
 
Creations = connect(
	mapStateToProps,
	{ requestData } 
)(Creations);
 
export default Creations;
