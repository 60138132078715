import { createSelector } from 'reselect';
import {selectReturnSomething} from 'data/selectors';
import {cl} from 'utils'


export const getFilters = state => state.filters.filters;
export const getRootTerm = state => state.filters.filters.rootTerm;

export const getRootTerms = state => state.filters.rootTerms;


export const selectTermIdFromRootTermSlug = createSelector(
    [selectReturnSomething, getRootTerms],
    (rootTermSlug, rootTerms) => {
        cl(rootTermSlug, 'selectTermIdFromRootTermSlug, rootTermSlug is')

        let rootTermObject = Object.values(rootTerms).filter( termObj => 
            termObj.name.toLowerCase() == rootTermSlug.toLowerCase()
        )
        if(rootTermObject){
            cl(rootTermObject[0].id, 'rootTermObject id found: ')
            return rootTermObject[0].id;
        }else{
            cl('no root term id found')
        }
        
    }
);
 

export const selectRootTermObjectFromRootTermSlug = createSelector(
    [selectReturnSomething, getRootTerms],
    (rootTermSlug, rootTerms) => {
        cl(rootTermSlug, 'selectTermIdFromRootTermSlug, rootTermSlug is')

        let rootTermObject = Object.values(rootTerms).filter( termObj => 
            termObj.name.toLowerCase() == rootTermSlug.toLowerCase()
        )
        if(rootTermObject){
            cl(rootTermObject[0].id, 'rootTermObject id found: ')
            return rootTermObject[0];
        }else{
            cl('no root term id found')
        }
        
    }
);

 