import React from 'react';
import { SortableElement } from 'react-sortable-hoc';
import ImageOverlay from 'containers/ImageGrid/components/ImageOverlay';
import styled from 'styled-components';
import {cl} from 'utils';

 
// const StyledImage = styled(Image)`
// 	boxShadow: rgba(0, 0, 0, 0.117647) 0px 1px 6px, 
// 	rgba(0, 0, 0, 0.117647) 0px 1px 4px;
// `;

const CloseButton = ({onClick, }) => 
 
		<button
			type="button"
			className="windowclose1 fa fa-window-close" 
			onClick={onClick}
		>
		</button>

const CropButton = ({onClick}) => 
		<button 
			type="button"
			onClick={onClick}
			className="crop-button fa fa-crop"  
		></button>


const DaylicacySortableElement = ({ index, realIndex, value, handleRemoveImage, onClickCropButton,indicatechangeswitch }) => {
	cl(index, 'DaylicacySortableElement, index is: ')
	if(index == undefined){
		cl('index undefined')
	}else if(index == ''){
		cl('index empty str')
	}
	cl(realIndex, 'realIndex is ')
	return(
  	<div className="sortable-element">
	  
	  <ImageOverlay>
			<CloseButton onClick={handleRemoveImage(realIndex)} />
		 
			<CropButton onClick={ () => onClickCropButton({
					key: realIndex,
					image: value
					}) }
			/>
		</ImageOverlay>
		<img indicatechangeswitch={indicatechangeswitch}  src={value} title={value}/>
  	</div>
	);
};

export default SortableElement(DaylicacySortableElement);