import * as types from './actionTypes';

const initialState = {
	isLoading: false,
	status: null,
	errorMsg: null,
};

const reducer = (state = initialState, action) => {
	switch(action.type) {
		case types.REQUEST_UPDATE_IMPRINT:
			return Object.assign({
				isLoading: true,
			});
		case types.REQUEST_UPDATE_IMPRINT_SUCCESS:
			return Object.assign({}, state, {
				isLoading: false,
				status: 'success',
			});
		case types.REQUEST_UPDATE_IMPRINT_FAILED:
			return Object.assign({}, {
				isLoading: false,
				status: 'failed',
				errorMsg: action.error.response.data.message
			});
		case types.REQUEST_FETCH_IMPRINT_SUCCESS:
			const initialValues = action.result.data.company_country_id ?
				action.result.data : {};
			
			return Object.assign({}, state, {
				initialValues,
			});
		default:
			return state;
	}
};

export default reducer;
