import React from 'react';
import { reduxForm, Field, SubmissionError } from 'redux-form';
import axios from 'axios';

// Components
import FooterWidget from './FooterWidget';
import FooterPopover from './FooterPopover';
import DaylicacyTextField from 'components/Form/DaylicacyTextField';
import DaylicacyButton from 'components/DaylicacyButton';


class Chat extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			open: false,
		};

        this.onSubmit = this.onSubmit.bind(this);
        this.handleClose = this.handleCloseClick.bind(this);
	}

	handleMouseEnter(e) {
		this.setState({
			open: true,
			anchorEl: e.currentTarget,
			width: window.getComputedStyle(e.currentTarget).width,
		});
	}

	handleMouseLeave() {

	}

	handleCloseClick() {
		this.setState({
			open: false
		})
	}
	handleError(e) {
		console.log(e);
	}

    onSubmit(event) {
        event.preventDefault();
        const data = {
        	name: this.refs.name.value,
			email: this.refs.email.value,
			message: this.refs.message.value
		}
        console.log('Submitting form', data);

        axios.post('/api/email', data).then((response) => {
            if(response.data.result == true) {
				this.handleClose();
			}
        }).catch(this.handleError);
	}

	render() {
        const { handleSubmit } = this.props;
        const user = {
        	name: '',
			email: '',
			message: ''
		}

		return(
			<FooterWidget
				icon="fa-comments-o"
				label={this.props.tr.chat_with_us}
				handleMouseEnter={this.handleMouseEnter.bind(this)}
				handleMouseLeave={this.handleMouseLeave.bind(this)}
			>
				<FooterPopover
					open={this.state.open}
					anchorEl={this.state.anchorEl}
					width={this.state.width}
				>
					<div>
						<h2>{this.props.tr.contact_us}</h2>

						<div>
							<form onSubmit={ (event, user) => this.onSubmit(event, user) } name="email_send">
								<div>
									<Field
										value=""
										name="name"
										ref="name"
										component={DaylicacyTextField}
										type="text"
										placeholder="Your Name" />
								</div>
								<div>
									<Field
										value=""
										name="email"
										ref="email"
										component={DaylicacyTextField}
										type="email"
										placeholder="Email" />
								</div>

								<div style={{marginBottom: '1em', }}>
									<Field
										value=""
										name="message"
										ref="message"
										component={DaylicacyTextField}
										type="textArea"
										placeholder="Your message" />
								</div>
								<div>
									<DaylicacyButton
										type="submit" 
                                                                        >
                                                                         Send {this.props.tr.send}
                                                                        </DaylicacyButton>
								</div>
							</form>
						</div>


						<div onClick={this.handleClose} className="k-dropdown__close">x</div>
					</div>
				</FooterPopover>
			</FooterWidget>
		);
	}
}

Chat = reduxForm({
    form: 'signUpForm'
})(Chat);

export default Chat;