import * as types from './actionTypes';

//add product object to cart
export const prepareAddToCart = product => ({
	type: types.PREPARE_ADD_TO_CART,
	payload: { product },
});

export const addToCart = product => ({
	type: types.ADD_TO_CART,
	payload: { product },
});

export const removeFromCart = (id) => ({
	type: types.CART_REMOVE,
	payload: { id },
});

export const removeAllFromCart = () => ({
	type: types.CART_REMOVE_ALL,
});

export const checkout = products => (formData) => ({
	type: types.REQUEST_CHECKOUT,
	formData,
});

export const checkoutSuccess = (result) => ({
	type: types.REQUEST_CHECKOUT_SUCCESS,
	result,
});

export const checkoutFailed = (error) => ({
	type: types.REQUEST_CHECKOUT_FAILED,
	error,
});
