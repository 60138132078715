import React from 'react';
import { connect } from 'react-redux';
//import { MenuItem } from 'material-ui-next/Menu';
import MenuItem from '@material-ui/core/MenuItem';

//import Select from 'material-ui-next/Select';
import Select from '@material-ui/core/Select';

import { Toolbar, ToolbarGroup } from 'material-ui-old/Toolbar';
import DaylicacySort from 'containers/Sort/DaylicacySort';
import { DaylicacyPagination } from 'components/Navigation';
import { addCurrentPage } from 'views/Shop/actionCreators';
import {selectUserLanguage} from 'containers/Session/selectors';
 
import {setShopProductsSortOption} from 'containers/Sort/actionCreators';
 
import {requestFilterTerms} from 'containers/Filters/actionCreators';
import {requestData} from 'data/actions';

import {cl, getParameterByName} from 'utils';


const designersSortOptions = [
	{
		value:"a-z",
		label:"A-Z", 
	},
	{
		value:"z-a",
		label:"Z-A", 
	},
];
const creationsSortOptions = [
	{
		value:"nooption",
		label:"Sort creations by",
		disabled:"disabled"
	},
	{
		value:"recent",
		label:"Recent creations", 
	},
	{
		value:"oldest",
		label:"Oldest creations",
		 
	}
];
{/* <MenuItem value="nooption"  disabled>
Sort creations by
</MenuItem>
<MenuItem value="recent"  >
Recent creations
</MenuItem>
<MenuItem value="oldest">
Oldest creations
</MenuItem>  */}

/* reusable - different depending on pageSlug  
	See state.sort[pageSlug]SortingToolbar 
	
*/
class SortingToolbar extends React.Component {

	constructor(props) {
		super(props);

		this.state = { 
			perPageValue: 1,
			numColumnsValue: 1,
			currentPage: 1,
		};

		this.handleSortChange = this.handleSortChange.bind(this); 
	}

	
	componentDidUpdate(prevProps) {

		//see if sortOption changed
		if(prevProps.sortOption !== this.props.sortOption) {

			//if fetching do nothing
			if(this.props.fetchingProducts){
				cl('sortingToolbar componentDidUpdate: already fetching - no fetch')
				return;

			}else{
				
				if(this.props.pageSlug == 'creations'){
					//call fetch function in parent - Shop/index

					let queryString = getParameterByName('page');
					let slug = this.props.match.params.termSlug;

					//dd
					this.props.requestFilterTerms(queryString);

				}else if(this.props.pageSlug == 'designers'){

					 
					// let queryString = getParameterByName('page');
					// var page = (!queryString || !queryString.length) ? 1 : queryString;

					cl('sorting toolbar componentDidUpdate, page is designers - get designers');
					//fetch designers for current page  
					let columns = { 
						fields : [
						'products', 
						'shop_banner',
						'shipping_general_methods.zone.countries', 
						'shipping_general_methods.method'
						],
						count: 12,
						 
					}
			
					console.log('Footer - calling requestdata - designers ');

					this.props.requestData({
						type:'designers',
						args: columns
					});
				}
				
 
			}
			
		}
		   
	}

	//change sort option, maake new products request (send category by url parameter and page number)
	handleSortChange(event, value){ 
		cl(event.target.value);

		// alert('handleChange event: ', event.target.value); 
 
		//change sort option in state
		this.props.setShopProductsSortOption(this.props.pageSlug, event.target.value)

		//new value is set after component rerender, so the fetch is called in componentDidUpdate
		 
	};

	handlePagination(direction, page) {
		const { 
			router, 			//???
			params,				//???
			history,
			match, 
			currentPage,
			lang, 
			addShopNumPages,
			pageSlug
	//		fetchProducts,
			
		} = this.props;

		let termSlug = match.params.termSlug || '';

		let nextPage;
		let nextRoute = `/${ lang }/shop/${termSlug}/${pageSlug}`;

		// Handle category parameter
		// if(params.category) nextRoute += `/category/${ params.category}`;

		// Handle page parameter
		if(direction === 'next')(nextPage = currentPage + 1);
		if(direction === 'prev')(nextPage = currentPage - 1);
		if(page)(nextPage = page);
		nextRoute += `?page=${ nextPage }`;

		// Set current page nr in shop reducer
		// addCurrentPage(nextPage)
		// alert('pushing route: ' + nextRoute);
		history.push(nextRoute);
		// history.push(`${match.url}/${value}`);
		// router.push(nextRoute);

		
	}

	 

	render() {
		const {requestStatus, fetchingProducts, pageSlug} = this.props;

		cl(this.props.sortOption, 'sortOption is : ')
		cl('pageSlug is ' + pageSlug)

		let sortOptions = pageSlug == "creations" ? creationsSortOptions : designersSortOptions;

		return(
			<Toolbar className="sorting-toolbar" >
				<ToolbarGroup firstChild={true}>
					 <DaylicacySort
					 	sortOptions={sortOptions} 
						value={this.props.sortOption} 
						onChange={this.handleSortChange}
					/> 
					<Select 
						className="mui-select-bottom-border"
						value={this.state.perPageValue} 
						onChange={this.state.handleChange}
					>
						<MenuItem value={1}>Per page</MenuItem>
						<MenuItem value={2}>10</MenuItem>
					</Select>
					<Select 
						className="mui-select-bottom-border"
						value={this.state.numColumnsValue} 
						onChange={this.state.handleChange}
					>
						<MenuItem value={1} >Columns</MenuItem>
						<MenuItem value={2} >10</MenuItem>
					</Select>
					<p style={{margin: '0', padding: '6px 0 7px', display: 'block'}}>{this.props.requestStatus} </p>
						{this.props.fetchingProducts && 
						(<p>{this.props.fetchingProducts}</p>)
						} 
				</ToolbarGroup>
				<ToolbarGroup>
					<DaylicacyPagination 
						currentPage={this.props.currentPage}
						onChange={(e) => this.handlePagination(false, e.target.value)}
						onNextClick={() => this.handlePaginationNext('next')}
						onPrevClick={() => this.handlePagination('prev')}
						numPages={this.props.numPages}
					/>
				</ToolbarGroup>
			</Toolbar>
		);
	}
}

const mapStateToProps = (state, ownProps) => {

	//default sorting toolbar type
	var pageType = 'creations'; 

	if(ownProps.pageSlug){
		pageType = ownProps.pageSlug;
	} 
	 
	return({ 
		sortOption: state.sort[`${pageType}SortingToolbar`].sortOption,
		currentPage: state.shop[`${pageType}`].currentPage,
		numPages: state.shop[`${pageType}`].numPages,
		lang: selectUserLanguage(state)
	});
};

export default connect( 
	mapStateToProps,
	{ addCurrentPage, setShopProductsSortOption, requestFilterTerms ,requestData} 
)(SortingToolbar);