import { createActionTypes, createActions } from 'utils';

const types = createActionTypes([
	'FETCH_SHIPPING_ZONE',
	'FETCH_SHIPPING_ZONE_SUCCESS',
	'FETCH_SHIPPING_ZONE_FAILURE',
	'CREATE_SHIPPING_ZONE',
	'CREATE_SHIPPING_ZONE_SUCCESS',
	'CREATE_SHIPPING_ZONE_FAILURE',
	'UPDATE_SHIPPING_ZONE',
	'UPDATE_SHIPPING_ZONE_SUCCESS',
	'UPDATE_SHIPPING_ZONE_FAILURE',
	'FETCH_COUNTRIES_AND_CONTINENTS',
	'FETCH_COUNTRIES_AND_CONTINENTS_SUCCESS',
	'FETCH_COUNTRIES_AND_CONTINENTSFAILURE',
]);

const actions = createActions({
	[types.FETCH_SHIPPING_ZONE]: (shippingZoneId) => ({ shippingZoneId }),
	[types.FETCH_SHIPPING_ZONE_SUCCESS]: result => ({ result }),
	[types.FETCH_SHIPPING_ZONE_FAILURE]: error => ({ error }),
	[types.CREATE_SHIPPING_ZONE]: (formData) => ({ formData }),
	[types.CREATE_SHIPPING_ZONE_SUCCESS]: result => ({ result }),
	[types.CREATE_SHIPPING_ZONE_FAILURE]: error => ({ error }),
	[types.UPDATE_SHIPPING_ZONE]: (shippingZoneId, formData) => ({ shippingZoneId, formData }),
	[types.UPDATE_SHIPPING_ZONE_SUCCESS]: result => ({ result }),
	[types.UPDATE_SHIPPING_ZONE_FAILURE]: error => ({ error }),
	[types.FETCH_COUNTRIES_AND_CONTINENTS]: () => ({}),
	[types.FETCH_COUNTRIES_AND_CONTINENTS_SUCCESS]: result => ({ result }),
	[types.FETCH_COUNTRIES_AND_CONTINENTSFAILURE]: error => ({ error }),
});

export { types, actions };
