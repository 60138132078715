import React, { Component } from 'react';
import { reduxForm, Field, SubmissionError } from 'redux-form';
import DaylicacyTextField from 'components/Form/DaylicacyTextField';
import DaylicacyButton from 'components/DaylicacyButton';
import axios from 'axios';
import validator from 'validator';

const validate = values => {
	const errors = {};

	if (!values.username) {
		errors.username = 'Required';
	} else if (values.username.length < 6) {
		errors.username = 'Min length is 6';
	} else if (values.username.length > 100) {
		errors.username = 'Max length is 100';
	} else if (!values.email) {
		errors.email = 'Required';
	} else if (!validator.isEmail(values.email)) {
		errors.email = 'Invalid email address';
	} else if (values.password === values.repeat_password) {
		errors.password = 'Passwords need to match';
	} else if (values.password.length < 6) {
		errors.password = 'Your password needs to be at least 6 characters';
	} else if (!values.password) {
		errors.password = 'Required';
	}

	return errors;
};

class SignUpForm extends Component {

	constructor(props) {
		super(props);

		this.onSubmit = this.onSubmit.bind(this);
	}

	//send registration request (api function 'postUsers()')
	onSubmit(values) {
		return axios.post('/api/users', {
			name: values.username,
			email: values.email,
			password: values.password,
		})
		.then(res => {
			this.props.onDone();
		})
		.catch(err => {
			console.dir(err);
			
			const fields = err.response.data.fields;
			const keys = Object.keys(fields);

			keys.map(key => {
				if (key === 'name') {
					throw new SubmissionError({
						username: fields.name,
					});
				} else if (key === 'email') {
					throw new SubmissionError({
						email: fields.email,
					});
				}
			});
		});
	}

	render() {
		const { handleSubmit, tr } = this.props;

		return (
			<div>
				<h2>{tr.signup}</h2>
				<form onSubmit={handleSubmit(this.onSubmit)}>
					<div>
						<Field
							name="username"
							style={{
                                width: '100%',
                            }}
							component={DaylicacyTextField}
							type="text"
							placeholder={tr.username} />
					</div>
					<div>
						<Field
							name="email"
							style={{
                                width: '100%',
                            }}
							component={DaylicacyTextField}
							type="email"
							placeholder={tr.email} />
					</div>
					<div>
						<Field
							name="password"
							style={{
                                width: '100%',
                            }}
							component={DaylicacyTextField}
							type="password"
							placeholder={tr.password} />
					</div>
					<div style={{marginBottom: '1em', }}>
						<Field
							name="password_repeat"
							style={{
                                width: '100%',
                            }}
							component={DaylicacyTextField}
							type="password"
							placeholder={tr.repeat_password} />
					</div>
					<br />
					<div style={{textAlign:"center"}}>
						<DaylicacyButton
							type="submit"
							style={{
							width: '30%',
							margin: '0 auto',
							}}
						>
							{tr.signup}
						</DaylicacyButton>
					</div>
					<br />
				</form>
			</div>
		);
	}

}

SignUpForm = reduxForm({
	form: 'signUpForm',
	validate,
})(SignUpForm);

export default SignUpForm;