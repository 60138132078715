import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import AccountTabs from './components/AccountTabs';
import CustomerHome from './CustomerHome';
import MyAccountContainer from '../../containers/Account/MyAccount/MyAccountContainer.js';
import MyShop from './MyShop';
import Messages from './Messages';
import ListPurchases from './MyPurchases/ListOrders';
import Purchase from './MyPurchases/Order';
const MyFavorites = () => <div>My favorites</div>;
const MyRatings = () => <div>My ratings</div>;
import ListOrders from './MyOrders/ListOrders';
import Order from './MyOrders/Order';
import PageNotFound from 'views/PageNotFound';


class Account extends Component {

	constructor(props) {
		super(props);

	}

	render() {
		const { match, history, tr, isDesigner,loggedIn } = this.props;

		if(!loggedIn){ 
			return <Redirect to='/'/>
		}
		return(
			<div className="account">
				<AccountTabs 
					tr={tr} 
					match={match}
					history={history}
				/>
				<div className="container">
					<div 
						className="content" 
						style={{
							marginBottom: '1em', 
						}}>
							<Switch>
							<Route
								exact
								path={`${ match.url }`}
								render = { (props) => (
									<CustomerHome tr={tr}/>
								 )}/> 
							/>
							<Route  
								path={`${match.url}/my-account`} 
								render = { (props) => (
									<MyAccountContainer tr={tr} {...props}/>
							)}/>
							<Route  
								path={`${match.url}/messages`} 
								render = { (props) => (
									<Messages tr={tr}/>
							)}/>
							<Route  
								exact
								path={`${match.url}/my-purchases`}
								render = { (props) => (
									<ListPurchases tr={tr}/>
							)}/>
							<Route  
								path={`${match.url}/my-purchases/:orderId`}
								render = { (props) => (
									<Purchase tr={tr}/>
							)}/>
							<Route 
								path={`${match.url}/my-shop`}
								render = { (props) => (
									<MyShop tr={tr} {...props}/>
							)}/>
							<Route 
								exact
								path={`${match.url}/my-orders`}
								render = { (props) => (
									<ListOrders tr={tr}/>
							)}/>
							<Route 
								path={`${match.url}/my-orders/:orderId`}
								render = { (props) => (
									<Order tr={tr}/>
							)}/>
							<Route  
								path={`${ match.url }/my-favorites`}
								render = { (props) => (
									<MyFavorites tr={tr}/>
							)}/>
							<Route  
								path={`${ match.url }/my-ratings`}
								render = { (props) => (
									<MyRatings tr={tr}/>
							)}/>
							<Route component={PageNotFound} />
							</Switch>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	tr: state.translations,
	loggedIn: state.session.signedIn,
	isDesigner: state.session.designerId ? true : false
});


Account = connect(
    mapStateToProps
)(Account);

export default Account;
