import * as types from './actionTypes';

const initialState = {
	isLoading: false,
	productData: null,
	status: ''
};

const reducer = (state = initialState, action) => {
	switch(action.type) {
		case types.EDIT_PRODUCT_FETCH_PRODUCT_SUCCESS:
			return Object.assign({}, state, {
				productData: action.result.data,
				status: 'success'
			});
		default:
			return state;
	}
};

export default reducer;
