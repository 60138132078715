import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
// import { List, ListItem } from 'material-ui-old/List';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
//import ListSubheader from 'material-ui-next/List/ListSubheader';
import ListSubheader from '@material-ui/core/ListSubheader';

import Divider from 'material-ui-old/Divider';

const Sidebar = ({
	menus,
	lang,
}) => {
	return(
		<aside className="sidebar cx12 cs3" style={{ paddingRight: '50px' }}>
			{ menus.map((menu, i) => (
				<List key={i}>
					<ListSubheader>{menu.name}</ListSubheader>
					{ menu.children.map((item, f) => {
						return (
						<ListItem 
							key={f}
							children={ 
								<Link key={f * 100} to={`/${lang}${item.url}`}>
									{item.name}
								</Link> 
							}
						/>
						);
					})}
					{ (i !== menus.length - 1) ? <Divider className="muilistgutter"/> : '' }
				</List>
			))}
		</aside>
	);
};

Sidebar.propTypes = {
// 	menus: PropTypes.array.isRequied,
	lang: PropTypes.string.isRequired,
};

export default Sidebar;