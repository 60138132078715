import React from 'react';
import { connect } from 'react-redux'; 
import { Field, reduxForm, formValueSelector } from 'redux-form';
import ShopProfileForm from './ShopProfileForm';

import DaylicacyTextField from 'components/Form/DaylicacyTextField';

const validate = {

}

const ShopProfile = (props) => {
	return(
		<div className="content content--padded">
			<h3>Shop Profile</h3>

			<div className="row middle-xs margin-bottom">

				<ShopProfileForm  {...props}/>

			</div>
			<div className="row middle-xs margin-bottom">

				<h3>Photos</h3>
			</div>
			
		</div>
	);
};
 
export default ShopProfile;

// validate={this.validate} 