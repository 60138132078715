import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MaterialIconButton from 'material-ui-old/IconButton';

class IconButton extends Component {

	constructor(props) {
		super(props);
	}

	render() {
		const {
			tooltip,
			iconClassName,
			onClick, 
		} = this.props;

		return(
			<MaterialIconButton 
				tooltip={tooltip}
				iconClassName={iconClassName}
				onClick={onClick}
				style={{ padding: 0, width: '32px', height: '32px', }}
			/>
		);
	}
	
}

IconButton.propTypes = {
	iconClassName: PropTypes.string.isRequired,
	onClick: PropTypes.func,
	tooltip: PropTypes.string,
};

export default IconButton;