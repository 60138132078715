import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
//import Icon from 'material-ui-next/Icon';
import Icon from '@material-ui/core/Icon';

import MoneyBackGuarantee from './components/MoneyBackGuarantee';
import SecurePayments from './components/SecurePayments';
import Chat from './components/Chat';
import FooterNav from './components/FooterNav';
import axios from 'axios';
import {notObjectOrNotEmptyObject} from 'utils';

import { requestData } from 'data/actions';

const iconStyle = {
	color: 'rgb(76, 76, 76)',
	marginRight: '10px',
};
	
	
class Footer extends Component {

	constructor(props) {
		super(props);
		this.handleSubmit = this.handleFormSubmit.bind(this);
	}

	componentDidMount() {
		//check if designers are available, otherwise fetch
		if(!this.props.designers || notObjectOrNotEmptyObject(this.props.designers)) {

			// call request data with arg 'designers' - it will start saga in designers
			//alert('footer - component did mount');
			let columns = { fields : [
				'products', 
				'shop_banner',
				'shipping_general_methods.zone.countries', 
				'shipping_general_methods.method'
				],
				count: 12
			}
	
			console.log('Footer - calling requestdata - designers ');

			this.props.requestData({
				type:'designers',
				args: columns
			});
		}else{
			console.log('Footer: designers were found in state.data.designers')
		}
		

		// this.props.requestData({
		// 	type:'products' 
		// });
	}

    handleFormSubmit(event, data) {

		event.preventDefault();
		console.log('Submitting form', event, data);
	}

	render() {
		const { footer_menus } = window.d_store;
		const { tr } = this.props;

        const menuKeys = {
            'About': tr.about,
            'Contact': tr.contact,
            'Press': tr.press,
            'Links': tr.links,
            'Career':tr.career
        }

		return(
			<footer className="main-footer">
			<div className="container">
		
				<div className="footer-widgets">
					<div className="row center-xs">
						<MoneyBackGuarantee tr={tr} />
						<SecurePayments tr={tr} />
						<Chat handleSubmit={this.handleSubmit} tr={tr} />
					</div>
				</div>
				<div className="footer-nav-menus">
					<div className="row center-xs start-sm">
						{ footer_menus.map((menu, i) => (
							<FooterNav
								key={i}
								title={menuKeys[menu.name]}
								items={menu.children}
								lang="en"
								tr={this.props.tr}
							/>))}
						<div className="cx12 cs4 cm3 cl2">
							<div className="footer-col">
								<h3 className="title"> {tr.follow_daylicacy} </h3>
								<div className="icons">
									
									<a href="#">
										<Icon 
											className="fa fa-facebook-official"
											style={iconStyle}
										/>
									</a>
									<a href="#">
										<Icon 
											className="fa fa-twitter"
											style={iconStyle}
										/>
									</a>
									<a href="#">
										<Icon 
											className="fa fa-youtube"
											style={iconStyle}
										/>
									</a>
									<a href="#">
										<Icon 
											className="fa fa-pinterest"
											style={iconStyle}
										/>
									</a>
									<a href="#">
										<Icon 
											className="fa fa-instagram"
											style={iconStyle}
										/>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</footer>
		);

	}

}

Footer.propTypes = {
	match: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
	designers: state.data.designers.all || undefined,
    tr: state.translations
});

export default connect(
	mapStateToProps,
	{requestData}
)(Footer);