import React from 'react';
import PropTypes from 'prop-types';

//import TextField from 'material-ui-next/TextField';
//import TextField from 'material-ui-old/TextField';
import {TextField} from 'redux-form-material-ui';

const DaylicacyTextField = (props) => {
	
    const { input, label, hintStyle, meta: { touched, error }, ...custom, } = props;

	const defaultHintStyle = {
		color: '#b4b0be',
		zIndex: 1,
		pointerEvents: 'none',
		left: '8px',
	};

	const style = {
		
	}
	return(
		<TextField 
			 
			helperText={ touched && error}
			label={label || ''}
			
			{...input}
			{...custom}     
			
		/>
	);
};

DaylicacyTextField.defaultProps = {
	meta: { touched: false, error: null },
};

DaylicacyTextField.propTypes = {
	meta: PropTypes.object.isRequired,
	multiLine: PropTypes.bool,
	rows: PropTypes.number,
	hintStyle: PropTypes.object,
};

export default DaylicacyTextField;
    
//import TextField from 'material-ui-old/TextField'
//import {TextField} from 'redux-form-material-ui';

/*
TODO: apply styles:

underlineStyle={{ 
				bottom: props.label ? '8px' : '-8px' 
			}}
			underlineFocusStyle={{ borderColor: '#DF5087' }}
			floatingLabelFocusStyle={{ color: '#DF5087', }}


*/