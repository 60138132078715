import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, getFormValues, SubmissionError, isValid, FormSection,formValueSelector,submit } from 'redux-form';
import ShoppingBasket from './components/ShoppingBasket';
import Delivery from './components/Delivery';
import Payment from './components/Payment';
import CartSummary from './components/CartSummary';
import CheckoutSteps from './components/CheckoutSteps';
import {cl} from 'utils'


import {
	selectItemIds,
	getCartItemsByDesigner,
} from 'containers/Cart/selectors';


import { submitPaymentForm } from './actionCreators'; 

import api from 'data/api';
import { removeAllFromCart } from 'containers/Cart/actionCreators';
import {Route} from 'react-router-dom';
 
import {selectUserDeliveryAddress} from 'containers/Session/selectors'
import {selectCountryIdByName} from 'data/modules/countries';

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
 
/* ============ info ==================
test card: 4242424242424242

order is sent to api endpoint: "sendPayment" , function "postCheckout"
*/

class Checkout extends Component {

	constructor(props) {
		super(props);

		this.state = {
			finished: false,
			nextButtonLabel: 'Next >',
			page: 1,
			shippingCountries: {},
			openLogin: false,
			error: false,
			errorMsg: '',
			responseIssue: null,
			//Todo: initial values from formValues
			shippingMethodByDesignerId: {			
				designerId : {
					designerId : 0,
					0 : {
						prodId : 0,
						methodId : 0
					}
				}
			},
			shippingCountry : null,
			shippingMethodValues: { 
				 0 : {value: "default"}
			},
			// destinationId: this.props.formValues ? 'shipping_destination' in this.props.formValues 
			// 				? this.props.formValues.shipping_destination 
			// 				: null : null
			 
		};
		 


		this.nextPage = this.nextPage.bind(this);   
		
		this.handleGoBack = this.handleGoBack.bind(this);
		this.onSelectShippingMethod = this.onSelectShippingMethod.bind(this);
		
		this.onSubmit = this.onSubmit.bind(this);
		
	}
 

	componentWillReceiveProps(nextProps){
		// if(this.props.formValues.shipping_destination !== nextProps.formValues.shipping_destination){
		// 	alert('destination is set')
		// 	this.setState({
		// 		destinationId:formValues.shipping_destination
		// 	})
		// }

	}
	nextPage() {
       // alert('you called nextPage');
        //let errors = this.props.dispatch(submit('checkout'))

        if(!this.props.invalid) {
            this.setState({ page: this.state.page + 1 });
        }
            
	}
  


/*		onSelectShippingMethod:		
 	Add to state an array of countries belonging to the designer's shipping profile 
	structure: countries[designer_id] = { {[countryId] : {country}} ] }
	
	Add "method" and "destination" to cartItemsByDesigner array

	Request is handled in modules/checkout
		sends cartItemsByDesigner to api to get costs */

	onSelectShippingMethod(methodId, prodId, designerId) {
		
		// alert('prodId: ' + prodId + ', methodId ' + JSON.stringify(methodId) + ' , designerId ' + designerId )
		 this.setState({
			 
			 shippingMethodByDesignerId: {
				 ...this.state.shippingMethodByDesignerId,
				 [designerId]: { 
					 ...this.state.shippingMethodByDesignerId[designerId],
					 designerId,
					 [prodId] 	: {
						 prodId,
						 methodId
					 } 
				 }
			 }
		 })
	
	}  
		

	handleGoBack() {

        this.setState({
            page: this.state.page -1
        })
		//alert('handlego back');
		//this.props.history.goBack();
	}

	onSubmit(data) {
		console.log('here is data: ' + JSON.stringify(data));
		/*

		designer: group.designer.id,
		items: [
				product: item.id,			//item.product.id
				quantity: item.quantity,
				methodTypeId: formValuesShippingMethod[item.designer.id][item.id],
				profileId: profileId,
				profileZoneMethodId : targetProfileZoneMethodId,
				zoneId,
				shipping_profile_or_default: item.shipping_profile_or_default
		]

		*/

		//add delivery_country_id property to data 
		//with the shipping_destination input value
		data.delivery_country_id = data.shipping_destination;
		
		//each item may have its own method
 
		const cart = this.props.cartItemsByDesigner.map(group => {
 
			return ({
				designer: group.designer.id,

				//loop items
				items: group.items.map(item => {

					var itemMethods = this.props.processedCart.groups[group.designer.id].item_methods;
					var itemMethodsObject = itemMethods[item.id];
					console.log('itemMethods Object object found: ' + itemMethodsObject);
					//find current item in the "item_methods" array of objects
					// Object.keys(itemMethods).map( index => {
					// 	if(item.id in itemMethods[index]) {
					// 		console.log('itemMethods Object object found: ' + JSON.stringify(itemMethods[index]));
					// 		itemMethodsObject = itemMethods[index][item.id];
					// 	}
					// }) 
					// console.log('itemMethodsObject : ' + JSON.stringify(itemMethodsObject));
					// console.log('methodTypeId: itemMethodsObject.methodTypeId: ' + itemMethodsObject.methodTypeId)
					// console.log('itemMethodsObject.profileZoneMethodId: ' + itemMethodsObject.profileZoneMethodId)
					return ({
						product: item.id,
						quantity: item.quantity, 
						method: data.shipping_method[group.designer.id][item.id],
						methodTypeId: itemMethodsObject.methodTypeId,
						methodTypeName: itemMethodsObject.methodTypeName,
						profileId: itemMethodsObject.profileId,
						profileZoneMethodId: itemMethodsObject.profileZoneMethodId,
						zoneId: parseInt(itemMethodsObject.zoneId)
					})
				}),
					
				destination: data.shipping_destination,
			});
		});
		console.log('cart: ' + JSON.stringify(cart));

		//test card: 4242424242424242
		const dirtyData = Object.assign({}, data, {
			billing_title: data.delivery_title,
			billing_first_name: data.delivery_first_name,
			billing_last_name: data.delivery_last_name,
			billing_company: data.delivery_company,
			billing_address: data.delivery_address,
			billing_zip_code: data.delivery_zip_code,
			billing_city: data.delivery_city,
			billing_country_id: data.delivery_country_id,
			method: 'stripe',
			cart: cart,
		});
		console.log('final cart to send: ', JSON.stringify(dirtyData));
		//this.props.submitPaymentForm(dirtyData);
		
//test2: text for commit change //

		api.sendPayment(dirtyData).then(response => {
			console.log('reponse')
			console.log('The api call sendPayment function was called, here is response:')

			console.log(JSON.stringify(response))
			if(response.response && response.response.status === 401) {
                // Login pop
                console.log('status 401');
				//document.getElementById('sign_in').click();
			} else {
				if(response.data && response.data.success) {
					console.log('Response: SUCCESS: ' + response.response)
                    this.props.removeAllFromCart();
                    this.setState({
                        finished: true,
                    });
                } else {
					// error?
					
				}
			}
		}).catch(err => {
			//Handle your error here
			console.log('Response error - setting state with responseIssue: ')
					this.setState(
						{
							error: true, 
							responseIssue: err.response.data.message
						}
					)
					 
		})
	}
 
	render() {
		const { 
            cartItemsByDesigner, 
            match, 
            shipping, 
            tr, 
            numCreations, 
            loadingProcessedCart,
			selectedShippingMethod,
			initialValues,
			sessionDeliveryCountry,
			selectedShippingDestinationId,
			formValues
        } = this.props;
 
		const {destinationId} = this.state;

		const { page } = this.state;

		const totalPrice = this.props.processedCart != null
				? this.props.processedCart['total_price_in_'+this.props.currency.toLowerCase()] 
				: '';
		console.log('checkout: cartItemsByDesigner',cartItemsByDesigner);
  
		// if(formValues){
		// 	destinationId = 'shipping_destination' in formValues ? formValues.shipping_destination : 'no id'
		// }
		 
		return(

			<div className="site-width-primary cart-view"> 
				{/* {destinationId && <h2>destinationId is {destinationId}</h2>} */}
				<div className="content content--padded">
               
					<div style={{ marginBottom: '16px' }}>
						<CheckoutSteps stepIndex={page - 1} />
					</div>
					{ !this.state.finished && 
						<div className="row cx12">
							 
                                { page === 1 && 
                                
								<ShoppingBasket 
									initialValues={initialValues}
									sessionDeliveryCountry={sessionDeliveryCountry}
                                    onSubmit={this.nextPage}
                                    nextPage={this.nextPage}
                                    meta={this.props.meta}
									shippingMethodByDesignerId={this.state.shippingMethodByDesignerId}
									// shippingCountryId={this.state.shippingCountryId}
                                    numCreations={this.props.numCreations}
                                    // cartItemsByDesigner={cartItemsByDesigner}
                                    processedCart={this.props.processedCart}
                                    shippingCountries={this.state.shippingCountries}
                                    onSelectShippingMethod={this.onSelectShippingMethod}
                                    // onSelectShippingCountry={this.onSelectShippingCountry}
                                    loadingProcessedCart={this.props.loadingProcessedCart}
                                />
                                
                                }
                                { page === 2 && 
                                        
									<Delivery 
										initialValues={initialValues}
                                        onSubmit={this.nextPage}
                                        loggedIn={this.props.loggedIn}
										nextPage={this.nextPage}
										// selectedShippingDestinationId={destinationId}
									/> 
                                        
                                }
                                { page === 3 && 
                                    
									<Payment
										initialValues={initialValues}  
                                        onSubmit={this.onSubmit} 
                                        formValues={this.props.formValues}
                                        tr={tr}
                                        finished={this.state.finished}
										error={this.state.error}
										responseIssue={this.state.responseIssue}
                                        errorMsg={this.state.errorMsg}
                                        countryName={this.state.countryName}
                                    /> 
                                }

                                <div className="cx12 cs4">
                                    <CartSummary 
                                        history={this.props.history}
                                        handleNext={this.nextPage}
                                        nextButtonLabel={this.state.nextButtonLabel}
                                        numProducts={this.props.numCreations}
                                        totalPrice={totalPrice}
                                        handleGoBack={this.handleGoBack}
                                        currentStep={page}
                                        dom = {React.DOM}
                                        loadingProcessedCart={this.props.loadingProcessedCart}
                                    />
                                
                                </div>
                               
                          
                        </div>
					}
					{ this.state.finished && 
						<div className="order-success row">
							<div className="cs12">
								<h2>{tr.order_payment_success}</h2>

								<p>Order id is </p>
								<p>
									Confirmation email has been sent to your mail address.
								</p>
								<p>Thanks for shopping</p>
							</div>
						</div>
                    }
                    
				</div>
			</div>
		);
	}

}
 
Checkout.propTypes = {
	cartItemsByDesigner: PropTypes.array.isRequired,
	numCreations: PropTypes.number.isRequired,
};


const selector = formValueSelector('checkout'); 

Checkout = connect(
	state => {
		 
		var userDeliveryAddress = state.session.signedIn ? selectUserDeliveryAddress(state) || '' : '';  
		var deliveryCountry = state.session.delivery_country_name;
		 
		
		return {

			//initialValues for the 3 form parts
			initialValues: {
				shipping_destination: selectCountryIdByName(state, deliveryCountry),
				...userDeliveryAddress,
				card_expiry_month: "exp_month_placeholder",
            	card_expiry_year: "exp_year_placeholder"
			},
			sessionDeliveryCountry: deliveryCountry,
			onSubmit: submit, 
			selectedShippingMethod: selector(state,'shipping_method'), 
			loggedIn: state.session.signedIn,
			processedCart: state.data.checkout.processedCart,
			loadingProcessedCart: state.data.checkout.loadingProcessedCart,
			valid: isValid('checkout')(state),
			cartItemsByDesigner: getCartItemsByDesigner(state),
			// numCreations: state.cart.numProductsQuantity,
			// shippingConditions: state.data.shippingConditions,//	((selectDenormalizedShippingConditons))
			formValues: getFormValues('checkout')(state),
			tr: state.translations,
			currency: state.session.currency
		}
	},
	{ removeAllFromCart }
)(Checkout);


export default Checkout;
