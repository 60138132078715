import React from 'react';
import Chip from 'material-ui-old/Chip';
import Avatar from 'material-ui-old/Avatar';
import { connect } from 'react-redux';
import { 
	addChip, 
	removeChip, 
	removeAllChips, 
} from 'containers/FilterChipsBar/actions';
 
import { 
	removeShopFilter,
	requestFilterTerms,
	removeCurrentShopRootTerm ,
	clearFiltersTerms
} from 'containers/Filters/actionCreators';
import {clearPageQueryDetails} from 'views/Shop/actionCreators';
import {deactiveProductsCount, activateProductsCount} from 'containers/Filters/actionCreators';
import {removeCheckedMenuItem, clearCheckedMenuItems} from 'containers/Filters/components/actions'; 
import {clearProducts} from 'data/modules/products';

import { selectChips } from 'containers/FilterChipsBar/selectors';
import { getFilters }  from 'containers/Filters/selectors';
import { withRouter } from 'react-router'

import {notObjectOrNotEmptyObject} from 'utils';



class FilterChipsBar extends React.Component {

	constructor(props) {
		super(props);

		this.handleDeleteAll = this.handleDeleteAll.bind(this);
		this.handleRequestDelete = this.handleRequestDelete.bind(this);
	}

	handleDeleteAll() {
		this.props.clearFiltersTerms();
		this.props.removeAllChips();
		this.props.clearCheckedMenuItems();
		this.props.clearProducts();
		this.props.clearPageQueryDetails();
		this.props.deactiveProductsCount();
	}

	handleRequestDelete(chipKey, chipName, termSlug) {

		console.log('handleRequestDelete: termSlug is ' + termSlug);

		/* 	1 remove the filter, 
		*		1b check if it matches current slug - remove rootFilter as well
		* 	2 remove the chip
		*	3 remove checkedMenuItem
		*	4 make request (filters are set)
		*/

		this.props.removeShopFilter(chipKey); 
 
		if(termSlug.toLowerCase() == chipName.toLowerCase()){
			console.log('chipName matches current termSlug! removing rootTerm')
			this.props.removeCurrentShopRootTerm();
		} 
		this.props.removeChip(chipKey);
		this.props.removeCheckedMenuItem(chipKey);
		if(Object.keys(this.props.stateFilters.terms).length < 1){
			cl('no filters in stateFilters.terms - No fetch!');
			return;
		}else{
			//more than 0 terms
			console.log('length of stateFilters is: ' + Object.keys(this.props.stateFilters.terms).length + ' - fetch!');
			this.props.requestFilterTerms(null)
		}
		
	}

	renderChip(chip, termSlug) {
		
		return(
			<Chip
				key={chip.key}
				onRequestDelete={() => this.handleRequestDelete(chip.key, chip.name, termSlug)}
				style={{
					marginRight: '4px',
				}}
			>
				{ chip.backgroundColor && 
					<Avatar backgroundColor={chip.backgroundColor} /> }
				{ chip.name }
			</Chip>
		);
	}

	render() {
		const { chips,termSlug } = this.props;

		return(
			<div className="chip-toolbar" style={{ 
				paddingBottom: '10px',
				marginBottom: '10px',
			}}>
				<div className="container-fluid">
					<div className="row middle-xs">
						{/* <div style={{ marginRight: '4px' }}>Filters:</div> */}
						{ !notObjectOrNotEmptyObject(chips) && 
							<div className="clear-button-ctr">
								<button onClick={this.handleDeleteAll}>
									Clear
								</button>
						</div>
						}
						{ chips && Object.values(chips).map( chip => this.renderChip(chip, termSlug) ) }
					</div>
				</div>
			</div>
		);
	}

}

FilterChipsBar = connect(  
	state => ({
		chips: selectChips(state),
		stateFilters: getFilters(state)
	}),
	{ 	addChip, 
		removeChip, 
		removeAllChips, 
		clearFiltersTerms, 
		removeShopFilter,
		requestFilterTerms, 
		removeCurrentShopRootTerm,
		clearProducts,
		clearPageQueryDetails,
		deactiveProductsCount,
		clearCheckedMenuItems,
		removeCheckedMenuItem
	}
)(FilterChipsBar);

export default withRouter(FilterChipsBar);