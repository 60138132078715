import { call, put, takeEvery } from 'redux-saga/effects';
import * as types from './actionTypes';
import * as actions from './actionCreators';
import { removeAllFromCart } from 'containers/Cart/actionCreators';
import api from 'data/api';

function* sendPayment(action) {
	console.log('checkout saga: to send Payment')
	try {
		const result = yield call(api.sendPayment, action.formData);
		yield put(actions.requestSuccess(result));
	} catch(e) {
		yield put(actions.requestFailed(e));
	}
}

function* sendPaymentSuccess(action) {
	yield put(removeAllFromCart());
}
 
function* checkoutSaga() {
	yield takeEvery(types.REQUEST_SEND_PAYMENT, sendPayment);
	yield takeEvery(types.REQUEST_SEND_PAYMENT_SUCCESS, sendPaymentSuccess);
	 
}

export default checkoutSaga;
