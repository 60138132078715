import React, { Component } from 'react';
import { connect } from 'react-redux';
import Image from 'components/Image';
import Product from 'components/Product';
import Panel from 'components/Panel';
import Row from 'components/Row';
import Col from 'components/Col';
import DesignerCard from 'components/DesignerCard';
import { Link } from 'react-router-dom';
import DaylicacyButton from 'components/DaylicacyButton';
import Alert from 'components/Alert';
import { selectHydratedOrder } from 'data/modules/orders';
import PurchaseSummary from 'components/PurchaseSummary';

class Order extends Component {

	constructor(props) {
		super(props);
	}

	render() {
		console.log('Order.render', this.props.order);
		const { delivery_address } = this.props.order;

		return (
			<div style={{padding: '0.3em'}}>
				<Row>
					<Col xs={8}>
						<Panel header>
							<Row>
								<Col xs={6}>
									Ordernr: {this.props.order.id}
								</Col>
								<Col xs={6} align="right">
									Order Date: {this.props.order.date}
								</Col>
							</Row>
						</Panel>
						{this.props.order.lines.map(orderLine => (
							<div>
								<Panel key={orderLine.id}>
									<Row style={{marginBottom: '0.3em'}}>
										<Col xs={6}>
											Order Line nr: {this.props.order.id}.{orderLine.id}
										</Col>
										<Col xs={6} align="right">
											Order Line Status: {orderLine.delivery_status}
										</Col>
									</Row>
									<Product
										name={orderLine.product.name}
										image={orderLine.product.featuredImage.url}
										originalPrice={`${orderLine.product.price} ${orderLine.order.currency.symbol}`}
										price={`${orderLine.product.currentPrice} ${orderLine.order.currency.symbol}`}
										termAttributes={orderLine.product.termAttributes}
										quantity={orderLine.quantity}
									/>
								</Panel>
								<Panel>
									<Row>
										<Col xs={5}>
											<div>
												Estimated Shipping Date: {orderLine.estimated_shipping}
											</div>
											<div>
												Estimated Delivery Date: {orderLine.estimated_delivery}
											</div>
											<div>
												Return costs paid by the designer: Yes
											</div>
										</Col>
										<Col xs={5} align="right">
											<div>
												Shipping Express Mail
											</div>
											<div>
												Tracking Number: {orderLine.tracking_number}
											</div>
											<div>
												Cost of the return (in {this.props.order.currency.name}):
											</div>
										</Col>
										<Col xs={2} align="right">
											{orderLine.shipping} {this.props.order.currency.symbol}
										</Col>
									</Row>
								</Panel>
							</div>
						))}
						<Row gutter={0.1}>
							<Col xs={6} gutter={0.1}>
								<Panel style={{
									paddingTop: '1em',
									paddingBottom: '1em',
									height: '100%',
								}}>
									<h3>Delivery address</h3>
									<div>{delivery_address.title} {delivery_address.first_name} {delivery_address.last_name}</div>
									<div>{delivery_address.address}</div>
									<div>{delivery_address.zip} {delivery_address.city}</div>
									<div>{delivery_address.country.name}</div>
								</Panel>
							</Col>
							<Col xs={6} gutter={0.1}>
								<Panel style={{
									paddingTop: '1em',
									paddingBottom: '1em',
									height: '100%',
								}}>
									<PurchaseSummary 
										totalPrice={`${this.props.order.amount} ${this.props.order.currency.symbol}`}
										totalProducts={this.props.order.lines.length}
									/>
								</Panel>
							</Col>
						</Row>
					</Col>
					<Col xs={4}>
						<div style={{marginBottom: '1em'}}>
							<DesignerCard
								name={this.props.order.designer.shop_name}
								avatar={this.props.order.designer.shop_banner ? this.props.order.designer.shop_banner.url : ''}
								permalink={this.props.order.designer.permalink}
								rating={this.props.order.designer.rating}
								numRatings={this.props.order.designer.ratings.length}
							/>
						</div>
						<div style={{marginBottom: '1em'}}>
							<Alert cssClasses="alertbox alert-notice alert-light">
								Conditions at the moment of the purchase <Link to="/">Terms&Conditions</Link> and <Link to="/">Return policy</Link>
							</Alert>
						</div>
						<div style={{marginBottom: '1em'}}>
                                                    <DaylicacyButton fullWidth={true}>
                                                            Print this order {this.props.tr.print_this_order}
                                                    </DaylicacyButton>
						</div>
					</Col>
				</Row>
			</div>
		);
	}

}

export default connect(
	(state, props) => ({
		order: selectHydratedOrder(state, props.match.params.orderId),
	})
)(Order);
