import PropTypes from 'prop-types';
import styled from 'styled-components';

const Banner = styled.div`
	margin-bottom: 1em;
	height: 200px;
	background-size: contain;
	background-position: center;
	${props => `background-image: url(${props.background});`}
`;

Banner.defaultProps = {
	background: '/img/placeholders/banner_958x320.jpg',
};

Banner.propTypes = {
	background: PropTypes.string,
};

export default Banner;