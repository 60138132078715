import { combineReducers } from 'redux';
import * as types from './actionTypes';

var dstoreMenuOptions = window.d_store.navigation;

dstoreMenuOptions = dstoreMenuOptions.map( option => option.name.toLowerCase() )

//state.interface.header.mainNavigation
const initialState = {
	menuOptions: [...dstoreMenuOptions] ,
 
};

export const mainNavigation = (state = initialState, action) => {
	switch (action.type) {
		 
		default:
			return state;
	}
};

//state.interface.header.searchField
export const searchField = (state = {open: false}, action) => {
	switch (action.type) {
		case types.TOGGLE_SEARCH_FIELD:
			return Object.assign({}, state, { open: !state.open });
		default:
			return state;
	}
};

export default combineReducers({
	searchField,
	mainNavigation
});
