import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import Paper from 'material-ui-old/Paper'; 
// import { requestAddShopFilter, removeShopFilter, } from './actionCreators';
import { addChip, removeChip } from 'containers/FilterChipsBar/actions';
import { requestData } from 'data/actions';
import FilterMenu from './components/FilterMenu';

class Filters extends Component {

	constructor(props) {
		super(props);
 
	} 
	
	render() {

		console.log(JSON.stringify(this.props.filters));
		return (
			<div className="filters">
				<Paper>
					<FilterMenu
						filters={this.props.filters}
						// requestAddShopFilter={this.props.requestAddShopFilter}
						// addChip={this.props.addChip}
						// removeChip={this.props.removeChip}
						requestData={this.props.requestData}
						// removeShopFilter={this.props.removeShopFilter}
						tr={this.props.tr}
					/>
				</Paper>
			</div>
		);
	}

}

export default connect(
	state => ({
		filters: state.filters.filters,
		tr: state.translations
	})
	
)(Filters);