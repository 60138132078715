import React from 'react';
import { connect } from 'react-redux';
import HamburgerButton from 'components/HamburgerButton';
import Spline from './components/Spline';
import MainNavigation from './components/MainNavigation';
import DaylicacyLogo from './components/DaylicacyLogo';

import {getCountryFromIp} from 'containers/Session/actionCreators'; 
import {setShopPageSlug} from 'views/Shop/actionCreators'; 

import {selectUserLanguage} from 'containers/Session/selectors'
import styled from 'styled-components';
 
const Banner = styled.section`
	height: 150px;
	background-color: white;
`;

/**
 * HEADER  
 * 
 * 
 */

class Header extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			menuToggled: false,
			fetchingUsers: false
		};
	}
 
	componentWillMount() {

		/* site language:	check if user country is set
			- check state session 
 
		*/
		// if(!this.props.isLoggedIn){
		// 		var localUserData = localStorage.getItem('state'); 
		// } 

		//if delivery country is not set, get it from IP
		if(!this.props.deliveryCountry){

			//calls CHECK_COUNTRY - see session saga
			this.props.getCountryFromIp();
		} 

		//set current page slug
		this.props.setShopPageSlug();
		 

	}
 

	handleHamburgerClick() {
		this.setState({
			menuToggled: !this.state.menuToggled,
			hasClicked: true
		});
	}

	render() {
		const { language } = this.props;

		return(
			<header className="header">
				<section className="spline">
					<div className="container-fluid">
						<Spline {...this.props} />
						<HamburgerButton
							handleClick={this.handleHamburgerClick.bind(this)} 
							active={this.state.menuToggled}
							hasClicked={this.state.hasClicked}
						/>
					</div>
				</section>
				<Banner className="site-banner">
					<DaylicacyLogo href={`/${ language }`} />
				</Banner>
				<MainNavigation tr={this.props.tr} />
			</header>
		);
	}

}

const mapStateToProps = state => ({
	deliveryCountry: state.session.delivery_country_name,
	language: selectUserLanguage(state),
    isLoggedIn: state.session.signedIn,
	tr: state.translations
});
 
Header = connect(
	mapStateToProps,
	{getCountryFromIp, setShopPageSlug}
)(Header);

export default Header;
 