import { createActionTypes, createActions } from 'utils';

const types = createActionTypes([
	'FETCH_SHIPPING_ZONES',
	'FETCH_SHIPPING_ZONES_SUCCESS',
	'FETCH_SHIPPING_ZONES_FAILURE',
]);

const actions = createActions({
	[types.FETCH_SHIPPING_ZONES]: designerId => ({ designerId }),
	[types.FETCH_SHIPPING_ZONES_SUCCESS]: result => ({ result }),
	[types.FETCH_SHIPPING_ZONES_FAILURE]: error => ({ error }),
});

export { types, actions };
