import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Paper from 'material-ui-old/Paper';
import Icon from 'components/Icon';
import { requestData } from 'data/actions';
import { 
	selectDesignerNameBySlug,
	countProductsByDesignerSlug, 
	countProductsSoldByDesignerSlug,
	selectDesignerPermalinkBySlug,
} from 'data/modules/designers';
import {
	selectUserById,
} from 'data/modules/users';

class DesignerCard extends Component {

	constructor(props) {
		super(props);

		// this.props.requestData({
		// 	type: 'user',
		// 	args: {
		// 		id: this.props.userId,
		// 	}
		// })
	}

	render() {
		const { 
			avatar, 
			name, 
			permalink, 
			numProducts,
			numProductsSold,
		} = this.props;
	
		return (
			<Paper className="designer-card">
				<div className="designer-card row">
					<div className="designer-card__image cx12">
						<img src={avatar} />
					</div>
					<div className="designer-card__content content--padded cx12">
						<div className="designer-card__title">
							<Link to={permalink}>{ name }</Link>
						</div>
						<div className="designer__card-rating"></div>
						<div className="designer__card-country">
							France
						</div>
						<div className="designer__numProducts">
							{numProducts} creations in the shop
						</div>
						<div className="designer__numProductsSold">
							{numProductsSold} sold creations
						</div>
						<div className="designer__favorite">
							<Icon className="fa fa-heart" />
						</div>
						<div className="designer__contact">
							<Icon className="fa fa-envelope-o" /> contact this designer
						</div>
					</div>
				</div>
			</Paper>
		);
	}
}

DesignerCard.propTypes = {
	slug: PropTypes.string,
	//userId: PropTypes.number.isRequired,
};

export default connect(
	(state, ownProps) => ({
		name: selectDesignerNameBySlug(state, ownProps.slug),
		numProducts: countProductsByDesignerSlug(state, ownProps.slug),
		permalink: selectDesignerPermalinkBySlug(state, ownProps.slug),
		numProductsSold: countProductsSoldByDesignerSlug(state, ownProps.slug),
		//user: selectUserById(state, ownProps.userId),
		avatar: '/img/placeholders/designer_500x500.jpg',
	}),
	{ requestData }
)(DesignerCard);