import { types } from './actions';

//state.shipping

const initialState = {
	view: 'shipping_zones',
	shippingZoneId: 0,
	shippingProfileId: 0,
};

const reducer = (state = initialState, action) => {
	switch(action.type) {
		case types.VIEW_EDIT_SHIPPING_GENERAL:
			return Object.assign({}, state, {
				view: 'edit_shipping_general',		
				shippingProfileId: action.payload.defaultProfileId
			});
		case types.VIEW_EDIT_SHIPPING_CUSTOM_PROFILES:
			return Object.assign({}, state, {
				view: 'edit_shipping_custom_profiles'
				
			});
		case types.VIEW_CREATE_EDIT_SHIPPING_CUSTOM:
			return Object.assign({}, state, {
				view: 'create_edit_shipping_custom',
				shippingProfileId: action.payload.customProfileId
			});
		case types.VIEW_SHIPPING_ZONES:
			return Object.assign({}, state, {
				view: 'shipping_zones',
			});
		case types.VIEW_EDIT_SHIPPING_ZONE:
			console.log('VIEW_EDIT_SHIPPING_ZONE');
			return Object.assign({}, state, {
				view: 'edit_shipping_zone',
				shippingZoneId: action.payload.shippingZoneId,
			});
		case types.VIEW_LIST_SHIPPING_PROFILES:
			return Object.assign({}, state, {
				view: 'list_shipping_profiles',
			});
		case types.VIEW_EDIT_SHIPPING_PROFILE:
			return Object.assign({}, state, {
				view: 'edit_shipping_profile',
				shippingProfileId: action.payload.shippingProfileId,
			});
		case types.VIEW_CREATE_SHIPPING_ZONE:
			return Object.assign({}, state, {
				view: 'create_shipping_zone',
			});

		default:
			return state;   
	}
};

export default reducer;
