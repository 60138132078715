import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import MegaMenu from './MegaMenu';
import  { selectUserLanguage } from 'containers/session/selectors'
import {cl} from 'utils'
import {throttle} from 'lodash'

const MainNavItem = ({ 
	// handleMouseOver, 
	// handleMouseOut,
	handlePopoverOpen,
	handlePopoverClose, 
	handleClick, 
	route, 
	label }) => { 
//	cl(route, 'main nav item route: ')
	return(
		<li>
			<Link
				to={`/${route}`}					//todo: see why this is adding traliling slash:   window.laroute.url(route, [])	 
				onMouseEnter={handlePopoverOpen}
				onMouseLeave={handlePopoverClose}
				// onClick={ e => handleClick(e, {label}) }
				value={label}
			>
				{label}
			</Link>
		</li>
	)
}

class MainNavigation extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			
			menuIndex: -1,
			anchorEl: null,
			megaMenuHover: false,
			navItemHover: false
		};

		// this.handleMouseOver = this.handleMouseOver.bind(this);
		// this.handleMouseOut = this.handleMouseOut.bind(this);
		// this.handlePopoverOpen = this.handlePopoverOpen.bind(this);
		// this.handlePopoverClose = this.handlePopoverClose.bind(this);
		// this.handleClick = this.handleClick.bind(this);
	}

	// handleMouseOver(e, i) {
	// 	this.setState({
	// 		// open: true,
	// 		anchorEl: e.target,
	// 		menuIndex: i,
	// 	});
	// }

	// handleMouseOut() {
	// 	this.setState({
	// 		//open: false,
	// 	});
	// }

	handlePopoverOpen = (elementName, i) => event => {
		cl('popover, elementName is' + elementName)

		 
		this.setState({ 
			[`${elementName}Hover`]:true
		}, 
			
			this.setState({ 
				anchorEl: event.currentTarget,
				menuIndex: i
			})
			 
		)
		
	};
	
	handlePopoverClose = elementName => event => {
		// alert('onMouseLeave or onClose')
		// cl('handlePopoverClose, element name is ' + elementName)

		//delay to allow megamenuHover to be set to true
		setTimeout( () => {

			if(elementName == 'navItem' && this.state.megaMenuHover){
				// cl('megaMenuHover is true - no close')
				return;
			}else if( elementName == 'megaMenu' && this.state.navItemHover){
				// cl('navItemHover is true - no close')
				// return;
			}
			if(elementName == 'navItem' && !this.state.megaMenuHover){
				// cl('elm is ' + elementName + ', megaMenuHover is false')
			}
	
			setTimeout( () => {
				this.setState({ 
					anchorEl: null ,
					megaMenuHover: false,
					navItemHover: false
				})
				
			}, 100);
		},200);
		
			
			
		  
	};

	handleThing = () => {
		alert('handleThing') 
	};
 
	// handleClick(event) {
	// 	//alert('value is ' + event.target.value + ' , or ' + value.target);
	// 	this.setState({
	// 		open: false
	// 	});
	// }

	render() {
		const { navigation } = window.d_store;
		const { tr,currentLanguage } = this.props;
		const open = Boolean(this.state.anchorEl);

		// var userLang = 'se' ? 'sv' : currentLanguage.toLowerCase()

		return(
			<nav className="main-navigation">
			 
				<div className="container">
					
					<ul>
						{ navigation.map((navItem, i) => (
							<MainNavItem 
								key={i}
								value={tr[navItem.name.toLowerCase()]}
								aria-owns={open ? 'mouse-over-popover' : undefined}
								aria-haspopup="true"
								handlePopoverOpen={this.handlePopoverOpen('navItem',i)}
								handlePopoverClose={this.handlePopoverClose('navItem')}
								// handleMouseOver={(e) => this.handleMouseOver(e, i)}
								// handleMouseOut={this.handleMouseOut}
								// handleClick={this.handleClick}
								label={tr[navItem.name.toLowerCase()]} 
								route={`${currentLanguage.toLowerCase()}${navItem.url}`}
							/>)) }
					</ul>
					<MegaMenu 
						 className="mega-menu-wrap"
						currentLanguage={currentLanguage.toLowerCase()}
						open={open} 
						anchorEl={this.state.anchorEl}
						// handleRequestClose={() => this.setState({open: false})} 
						handleOnMouseEnter={this.handlePopoverOpen('megaMenu', this.state.menuIndex)}
						handlePopoverClose={this.handlePopoverClose('megaMenu')}
						menuIndex={this.state.menuIndex}
						tr={tr}
					/>
				</div>
			</nav>
		);
	}

}

MainNavigation = connect(
	state => ({
		currentLanguage: selectUserLanguage(state)
	})
)(MainNavigation)
export default MainNavigation;
