import { OPEN_SNACKBAR, CLOSE_SNACKBAR } from './actions';
import { CLICK_FAVORITE_DESIGNER } from 'containers/ActionBar/actions';

const initialState = {
	open: false,
	autoHideDuration: 5000,
	message: '',
};

const snackbarReducer = (state = initialState, action) => {
	switch (action.type) {
		case OPEN_SNACKBAR:
		console.log('action.payload is ' + action.payload);
			return Object.assign({}, 
				state, 
				{ open: true },
				{ ...action.payload }
			);
		case CLOSE_SNACKBAR:
			return Object.assign({},
				state,
				{ open: false, }
			);
		case CLICK_FAVORITE_DESIGNER:
			return Object.assign({}, state,
				{ 
					open: true,
					message: 'Designer added to favorites',
				}
			);
		default:
			return state;
	}
};

export default snackbarReducer;