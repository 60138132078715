import React from 'react';
import DaylicacyFlagIcon from './DaylicacyFlagIcon';

export {
	DaylicacyFlagIcon,
};

const Icon = props => {
	return (
		<div>Icon</div>
	);
};

export default Icon;