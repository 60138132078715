import { call, put, takeEvery } from 'redux-saga/effects';
import { SubmissionError } from 'redux-form'; 
import api from 'data/api'; 
import { updateUser } from './actions'; 
import { updateUserAddresses } from './actions';
import { updateUserSuccess, updateUserAddressesSuccess } from 'containers/Session/actionCreators';
import {objectToFormData} from 'utils';
import {cl} from 'utils'

/**  
 * Handle post of "Account Setup" form
 */

function* handleUpdateUser(action) {

	const { id, avatar } = action.payload;

	console.log('updateUserSaga: ' + updateUser.REQUEST + ' has been called');
	cl(action.oayload)
	
	try {
		//Prepare avatar image file
		let dataCopy;
		
		if (avatar !== undefined && Array.isArray(avatar)) {
			dataCopy = Object.assign({}, action.payload, { 
				avatar: avatar.map(image => ({
					crop: image.crop,
					file: image.file,
				}))
			});
		} else {
			dataCopy = action.payload;
		}
		//change object to the right format
		console.log('avatar datacopy: ')
		console.log(JSON.stringify(dataCopy));
		const formData = objectToFormData(dataCopy);

		console.log('to dispatch this form data:');
		for (var entry of formData.entries()) {
			console.log(entry[0] + ' and ' + entry[1]); 
		 }
		 
		//POST user form data
		const result = yield call(api.updateUser, id, formData);

		yield put(updateUser.success(action.payload));    //notify form of success
		yield put(updateUserSuccess( result ));   //run Session action creator, to trigger its reducer
           
	} catch(e) { 
		console.log('catched saga error : ' + e);
		
		const formError = new SubmissionError({
//              login: 'User with this login is not found', // specific field error
			_error: 'Login failed, please check your credentials and try again', // global form error
		});
		yield put(updateUser.failure(formError)); 
		console.log('here is form error: ' + formError);
	}
}

/**
 * handle post of user addresses form
 */
function* handleupdateUserAddresses(action) {
 

	const { user_id } = action.payload;
	var formData = action.payload;
	var addressTypes = ['primary_delivery_address','address_delivery', 'address_manufacturing'];

	//remove address types that are set to false (not checked)
	addressTypes.map( prop => {
		if(prop in formData && formData[prop] == false ){
			delete formData[prop];
		}
	})
	cl(formData,'handleupdateUserAddresses: formData to be sent: ')

	console.log('handleupdateUserAddresses: ' + updateUserAddresses.REQUEST + ' has been called');
    
	try {
		//POST FORM TO SERVER		backend function: updateUserAddresses
		const userData = yield call(api.updateUserAddresses, user_id, formData);

		cl(userData, 'handleupdateUserAddresses result: ')
		yield put(updateUserAddresses.success(userData.data));    //notify form of success
		yield put(updateUserAddressesSuccess(userData.data));   //run Session action creator, to trigger its reducer
           
	} catch(e) { 
		console.log('catched saga error : ' + e);
		
		const formError = new SubmissionError({
//              login: 'User with this login is not found', // specific field error
			_error: 'Login failed, please check your credentials and try again', // global form error
		});
		yield put(updateUser.failure(formError)); 
		console.log('here is form error: ' + formError);
	}
}


export default function* updateUserSaga() { 
	yield takeEvery(updateUser.REQUEST, handleUpdateUser);
	yield takeEvery(updateUserAddresses.REQUEST, handleupdateUserAddresses);
}
 