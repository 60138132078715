import React from 'react';
import PropTypes from 'prop-types';
//import { MenuItem } from 'material-ui-next/Menu';
import MenuItem  from '@material-ui/core/MenuItem';

const DaylicacyOption = (props) => {
	return(
		<MenuItem 
			{...props} 
		/>
	);
};

DaylicacyOption.PropTypes = {
	value: PropTypes.any.isRequired,
	defaultText: PropTypes.oneOfType([
		PropTypes.number,
		PropTypes.string,
	]).isRequired,
};

export default DaylicacyOption;


// import React from 'react'
// import Select from 'material-ui-next/Select'

//const RenderSelectField = ({ 
//	input, 
//	label, 
//	meta: { touched, error }, 
//	children, 
//	...custom 
//}) => {
//	return(
//		<Select
//			floatingLabelText={ label }
//			errorText={ touched && error }
//			name={ input.name }
//			value={ input.value }
//			onChange={ ( e, i, v ) => input.onChange( v ) }
//			children={ children }
//			{ ...custom }
//			autoWidth={ true }
//			maxHeight={ 200 }
//		/>
//	)
//}
//
////( event, index, value ) => input.onChange( value )
//
//export default RenderSelectField