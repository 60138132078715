 
import React, {Component} from 'react'
import {cl} from 'utils'
/*  
	"setTimer" starts on mount
	Delay:			expects "delay" prop (milliseconds to be shown before removed)
	resetPostStatus:	You can provide "resetPostStatus" prop (function to call after displaying the alert),
						to notify parent after the alert has been shown
 	subjectKey				an identifying name for the key in parent components' state whose properties will be updated
	formPostedCount		provided by parent.  Alert can then be shown each time count changes
	
*/
class Alert extends Component {

	constructor(props){
		super(props)

		this.state = {
			visible: true,
			hide:false
		}
	}

	componentDidMount() {
		this.setTimer();
	}

	setTimer() {
		if(!this.props.delay){
			console.log('Alert - no delay')
			return;
		}
		// clear any existing timer
		this._timer != null ? clearTimeout(this._timer) : null;
	
		// alert('before settimeout')

		// return new Promise(function(resolve, reject) {

			// hide after `delay` milliseconds
			this._timer = setTimeout(function() {

				this.setState({
					visible: false
				});
				 if('resetPostStatus' in this.props){
					console.log('Alert - resetPostStatus')
					if('subjectKey' in this.props){
						 cl('subject key is ' + this.props.subjectKey)
					}
					this.props.resetPostStatus( this.props.subjectKey || '');
				 }
				

			this._timer = null;
			
			}.bind(this), this.props.delay,);
		// } )
		 
	}


	componentWillReceiveProps(nextProps) {
		 
		// reset the timer if children are changed
		if (nextProps.children !== this.props.children) {
			this.setTimer();
			this.setState(
				{visible: true}
				 
			);
				 
		}
		//if post count have changed, show again
		if(nextProps.formPostedCount !== this.props.formPostedCount){
			this.setTimer();
		  	this.setState({visible: true});
		}
	}

	render(){
		const {delay, cssClasses} = this.props;
		const {hide,visible} = this.state;

		let hideOrShow = !visible ? 'hide' : 'shown'; 

		hideOrShow = hideOrShow + (hide ? ' clear' : ''); 
		console.log('hide is' + hide.toString())
		console.log('hideOrShow is ' + hideOrShow);

		return(
				<div className={`${cssClasses} ${hideOrShow}`}>{this.props.children}</div> 
			 
		)
	}
} 
export default Alert;