import * as types from './actionTypes';

export const zoomAction = (value) => {
	return({
		type: types.IMAGE_CROPPER_ZOOM,
		payload: { value },
	});
};

export const saveAction = (key, preview, crop) => {
	return({
		type: types.IMAGE_CROPPER_SAVE,
		payload: { key, preview, crop, },
	});
};

export const openDialogAction = (key, image) => {
	return({
		type: types.IMAGE_CROPPER_DIALOG_OPEN,
		payload: { key, image, },
	});
};

export const closeDialogAction = () => {
	return({
		type: types.IMAGE_CROPPER_DIALOG_CLOSE,
	});
};