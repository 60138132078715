import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field, FieldArray } from 'redux-form';
import Alert from 'components/Alert';
import DaylicacyButton from 'components/DaylicacyButton';
import { DaylicacyCheckbox } from 'components/Form';
import Select from '@material-ui/core/Select';
import DaylicacyTextField from 'components/Form/DaylicacyTextField';  
import FileField from 'components/FileField';
//import { MenuItem } from 'material-ui-next/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import {
	Table,
	TableBody,
	TableHeader,
	TableHeaderColumn,
	TableRow,
	TableRowColumn,
} from 'material-ui-old/Table';

const ShippingProfileZoneCost = (props) => (
	<div>
		<Table>
			<TableHeader adjustForCheckbox={false} displaySelectAll={false} enableSelectAll={false}>
				<TableRow>
					<TableHeaderColumn>Zone</TableHeaderColumn>
					<TableHeaderColumn>Extra Product Cost</TableHeaderColumn>
					<TableHeaderColumn></TableHeaderColumn>
				</TableRow>
			</TableHeader>
			<TableBody displayRowCheckbox={false}>
				{ props.fields.map((cost, index) => (
					<TableRow key={'costs_' + index} selectable={false}>
						<TableRowColumn>
							<Field 
                                                                className="mui-select-input mui-select-bottom-border "
								name={`${cost}[shipping_zone_id]`}
								label="Zone"
								component={Select}
							>
								{ props.shippingZones.map((shippingZone, index) => (
									<MenuItem
										key={'shipping_zone_' + index}
										value={shippingZone.id} 
									>
									{shippingZone.name}
									</MenuItem>
								)) }
							</Field>
						</TableRowColumn>
						<TableRowColumn>
							<Field 
								type="text" 
								name={`${cost}[cost]`}
								label="Cost"
								component={DaylicacyTextField}
							/>
						</TableRowColumn>
						<TableRowColumn>
							<DaylicacyButton 
								onClick={() => props.fields.remove(index)}
							>
								Remove {props.tr.remove}
								</DaylicacyButton>
						</TableRowColumn>
					</TableRow>
				)) }
			</TableBody>
		</Table>
		<DaylicacyButton 
			onClick={() => props.fields.push()}
                  >     
                  Add {props.tr.add}
		</DaylicacyButton>
	</div>
);

const ShippingProfileMethods = (props) => (
	<div>
		<Table>
			<TableHeader adjustForCheckbox={false} displaySelectAll={false} enableSelectAll={false}>
				<TableRow>
					<TableHeaderColumn>Zone</TableHeaderColumn>
					<TableHeaderColumn>Method</TableHeaderColumn>
					<TableHeaderColumn>Cost (single)</TableHeaderColumn>
					<TableHeaderColumn>Cost (combined)</TableHeaderColumn>
					<TableHeaderColumn></TableHeaderColumn>
				</TableRow>
			</TableHeader>
			<TableBody displayRowCheckbox={false}>
				{ props.fields.map((method, index) => (
					<TableRow key={'methods_' + index} selectable={false}>
						<TableRowColumn>
							<Field 
                                                                className="mui-select-input mui-select-bottom-border "
								name={`${method}[shipping_zone_id]`}
								label="Zone"
								component={Select}
							>
								{ props.shippingZones.map((shippingZone, index) => (
									<MenuItem
										key={'shipping_zone_' + index}
										value={shippingZone.id} 
									>
                                                                        {shippingZone.name}
                                                                        </MenuItem>
								)) }
							</Field>
						</TableRowColumn>
						<TableRowColumn>
							<Field 
                                                                className="mui-select-input mui-select-bottom-border "
								name={`${method}[shipping_method_id]`}
								label="Method"
								component={Select}
							>
								{ props.shippingMethods.map((shippingMethod, index) => (
									<MenuItem
										key={'shipping_method_' + index}
										value={shippingMethod.id} 
									>
                                                                        {shippingMethod.name}
                                                                        </MenuItem>
								)) }
							</Field>
						</TableRowColumn>
						<TableRowColumn>
							<Field 
								type="text" 
								name={`${method}[charge_single]`}
								label="Cost"
								component={DaylicacyTextField}
							/>
						</TableRowColumn>
						<TableRowColumn>
							<Field 
								type="text" 
								name={`${method}[charge_combined]`}
								label="Cost"
								component={DaylicacyTextField}
							/>
						</TableRowColumn>
						<TableRowColumn>
							<DaylicacyButton
								label="Remove"
								onClick={() => props.fields.remove(index)}
							/>
						</TableRowColumn>
					</TableRow>
				)) }
			</TableBody>
		</Table>
		<DaylicacyButton
			label="Add"
			onClick={() => props.fields.push()}
		/>
	</div>
);

const CreateShippingProfileForm = (props) => (
	<form onSubmit={props.handleSubmit}>
		<div className="row">
			<div className="cx3">
				<label>Name</label>
			</div>
			<div className="cx9">
				<Field 
					type="text" 
					name="name"
					label="Name" 
					component={DaylicacyTextField}
				/>
			</div>
		</div>
		<h4>Extra costs per zone</h4>
		<FieldArray
			name="costs"
			component={ShippingProfileZoneCost}
			shippingZones={props.shippingZones}
		/>
		<h4>Shipping methods per zone</h4>
		<FieldArray
			name="methods"
			component={ShippingProfileMethods}
			shippingZones={props.shippingZones}
			shippingMethods={props.shippingMethods}
		/>
		<h4>Products included</h4>
		<Table>
			<TableHeader adjustForCheckbox={false} displaySelectAll={false} enableSelectAll={false}>
				<TableRow>
					<TableHeaderColumn>ID</TableHeaderColumn>
					<TableHeaderColumn>Name</TableHeaderColumn>
					<TableHeaderColumn>Price</TableHeaderColumn>
				</TableRow>
			</TableHeader>
			<TableBody displayRowCheckbox={false}>
				{ props.designerProducts.map((product, index) => (
					<TableRow key={'rows_' + index} selectable={true}>
						<TableRowColumn>
							<Field
								type="checkbox"
								name={`products[${product.id}]`}
								label={product.id}
								component={DaylicacyCheckbox}
							/>
						</TableRowColumn>
						<TableRowColumn>
							{product.name}
						</TableRowColumn>
						<TableRowColumn>
							{product.price} €
						</TableRowColumn>
					</TableRow>
				)) }
			</TableBody>
		</Table>
		{ props.status === 'failed' && 
			<Alert cssClasses="alertbox alert-error" >{props.errorMsg}</Alert>
		}
		{ props.status === 'success' &&
			<Alert delay={3500} cssClasses="alertbox alert-success" >Saved</Alert>
		}
		<div className="row">
			<div className="cx">
				<DaylicacyButton 
					type="submit" 
					name="save"
					label="Save"
					disabled={props.isLoading}
				/>
			</div>
		</div>
	</form>
);

CreateShippingProfileForm.propTypes = {
	isLoading: PropTypes.bool.isRequired,
	status: PropTypes.string.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,
	shippingZones: PropTypes.array.isRequired,
	shippingMethods: PropTypes.array.isRequired,
	designerProducts: PropTypes.array.isRequired,
};

const validate = values => {
    let errors = {};

    const required = ['name'];

    required.forEach(field => {
        if (!(field in values)) {
            errors[field] = 'Required';
        }
    });

    if (values.products) {
    	for(var key in values.products ) {
            if (values.products[key] !== true) {
                delete values.products[key];
            }
		}
	}

	return errors;
};

export default reduxForm({
	form: 'createShippingProfileForm',
	enableReinitialize: true,
	validate,
})(CreateShippingProfileForm);
