import { types, actions } from './actions';
import api from 'data/api';
import {
	call,
	put,
	takeLatest,
} from 'redux-saga/effects'; 

import { normalize, denormalize, schema } from 'normalizr';


import {cl} from 'utils'
import {mergeToDesignerShippingZones} from 'data/modules/shippingProfiles';
import {shippingZoneSchema} from 'data/schemas';
import {addDesignerShippingZones} from 'data/modules/shippingProfiles';

function* fetchShippingZone(action) {
	try{
		const result = yield call(api.fetchShippingZone, action.payload.shippingZoneId);
		yield put(actions.fetchShippingZoneSuccess(result));
	} catch(error) {
		yield put(actions.fetchShippingZoneFailure(error));
	}
}

function* createShippingZone(action) {
	cl('createShippingZone');
	const { formData } = action.payload;

	try {
		const result = yield call(api.createShippingZone, formData);
   
		//the form was successfully posted - normalize and add result to redux 
		cl('form was successfully posted')
		cl(result)

		//normalize the shippingZone
		const normalizedShippingZone = normalize(result.data, shippingZoneSchema);
			console.log('normalizedShippingZone');
			console.log(normalizedShippingZone);

		//add (merge) the zone with existing zone data
		yield put(mergeToDesignerShippingZones(normalizedShippingZone.entities));
 
		yield put(actions.createShippingZoneSuccess(result));

	} catch(error) {
		yield put(actions.createShippingZoneFailure(error));
	}
}

export const countrySchema = new schema.Entity('countries');
const myZoneSchema = new schema.Entity('shippingZones', {
	countries: [countrySchema]
	
});

function* updateShippingZone(action) {
	const { shippingZoneId, formData } = action.payload;

	cl('createEditShippingZone saga: updateShippingZone')
	try {
		const result = yield call(api.updateShippingZone, shippingZoneId, formData);
		cl(formData, 'formData')
		//the form was successfully posted - normalize and add result to redux
		//addDesignerShippingZones runs state.data.shippingProfiles reducer 
	 
		//normalize the shippingZone
		  
		 var normalizedShippingZone = normalize( result.data, shippingZoneSchema);
			console.log('normalizedShippingZone');
			console.log(normalizedShippingZone);
		cl('mergeToDesignerShippingZones')
		//add (merge) the zone with existing zone data
		yield put(mergeToDesignerShippingZones(normalizedShippingZone.entities)); 
 
		yield put(actions.updateShippingZoneSuccess(result));

	} catch(error) {
		console.log('error: ', error)
		yield put(actions.updateShippingZoneFailure(error));
	}
}

function* fetchCountriesAndContinents(action) {
	cl(action, 'fetchCountriesAndContinents' );

	try{
		const result = yield call(api.fetchCountriesAndContinents);
		cl(result, 'fetchCountriesAndContinents result: ')

		yield put(actions.fetchCountriesAndContinentsSuccess(result));

	} catch(error) {
		cl(error);
		yield put(actions.fetchCountriesAndContinentsFailure(error));
	}

}

export default function* listenerSaga() {
	yield takeLatest(types.FETCH_SHIPPING_ZONE, fetchShippingZone);
	yield takeLatest(types.CREATE_SHIPPING_ZONE, createShippingZone);
	yield takeLatest(types.UPDATE_SHIPPING_ZONE, updateShippingZone);
	yield takeLatest(types.FETCH_COUNTRIES_AND_CONTINENTS, fetchCountriesAndContinents);
}
