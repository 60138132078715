import React, { Component } from 'react';
import { connect } from 'react-redux';
import { requestProducts } from './actionCreators';
import { getProducts } from './selectors';

function getDisplayName(WrappedComponent) {
	return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export const fetchProducts = (WrappedComponent, args = {}) => {
	class FetchProducts extends Component {
		constructor(props) {
			super(props);
			const { requestProducts } = this.props;
			requestProducts();
			// const { id, slug, page } = args
			
			// if (page) {
			// 	this.props.requestProducts({ page })
			// }
		}

		render() {
			const { products } = this.props;
			console.log('rendering hoc');
			console.log(products);
			//const products = []
			
			return (
				<WrappedComponent products={products} {...this.props} />
			);
		}
	}

	FetchProducts = connect(
		state => ({
			products: getProducts(state),
		}), {requestProducts}
	)(FetchProducts);

	FetchProducts.displayName = 
		`FetchProducts(${getDisplayName(WrappedComponent)})`;

	return FetchProducts;
};
