export const selectSales = () => ([
	{
		id: 1,
		salesPercent: '50',
		salesName: 'Spring Sales 2015 Old Creations 50%',
		salesFrom: '2015-04-05',
		salesTo: '2015-05-01',
		salesAppliedTo: '5 products',
	},
	{
		id: 2,
		salesPercent: '25',
		salesName: 'Spring Sales 2015 New Creations 25%',
		salesFrom: '2015-04-05',
		salesTo: '2015-05-01',
		salesAppliedTo: '0 products',
	}
]);