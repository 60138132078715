import { put, call, select, takeLatest } from 'redux-saga/effects';
//import lodash from 'lodash';
import { requestData } from 'data/actions';
import {updateChipsByFilters}  from 'containers/FilterChipsBar/actions';
import {addCheckedFilter}  from 'containers/Filters/components/actions';

import { 
	getFilters, 
	getRootTerms,
	getRootTerm,
	selectRootTermObjectFromRootTermSlug 
} from './selectors';

import {selectCheckedFilters} from 'containers/Filters/components/selectors';

import {selectDeliveryCountryId} from 'containers/Session/selectors';

import api from 'data/api'

import {
	FETCH_PRODUCTS_BY_ROOT_FILTER,
	REQUEST_FILTER_TERMS,
	SET_ROOT_TERM_REPLACE_OBJ ,
	SET_ROOT_TERM_ADD_OBJ,
	FETCH_SHOP,
	REQUEST_FILTER_MENU

} from './actionTypes';

import { 
	replaceFilterTermObject, 
	clearFiltersTerms, 
	addShopRootTerm, 
	removeCurrentShopRootTerm,
	addFilterMenu
} from './actionCreators';

import {  
	requestDataFailure,
	requestingStartLoading,
	successStopLoading, 
} from 'data/actions';

import {removeAllChips} from 'containers/FilterChipsBar/actions';
import {clearPageQueryDetails} from 'views/Shop/actionCreators';
import {clearCheckedMenuItems} from 'containers/Filters/components/actions'

import {cl} from 'utils'

const subject = 'filters';




function* updateShop(action) {
 	
/*
	const filters = yield select(state => {
		madeIn: { ...state.filters.filters.madeIn },
		madeBy: { ...state.filters.filters.madeBy },
		terms: { ...state.terms },
	}); 
*/
  
	console.log('updateshop, action type is: ' + action.type);
 
	const filters = Object.assign({}, yield select(getFilters)); 

	//get queryString "page" value (does not have to be set if page 1)
	let pageNumQuerystringValue = '';

	let rootTermObject = undefined;


	switch (action.type) {

		//fetch products based on root term - no other filters included
		case 'FETCH_PRODUCTS_BY_ROOT_FILTER' :
  
			pageNumQuerystringValue =  action.params.pageNumQuerystringValue || '';
 
			//Get array of term Ids
			// var termIds = Object.keys(filters.terms);
			// cl(termIds, 'terms array: ')
 
			try { 
				//get products with specified terms
				yield put(requestData({
					type: 'products',
					args: ({ 
						filters : {
							rootTerm: action.params.termSlug
						},
						page: pageNumQuerystringValue
					}),
				}));
		    	
			}catch(e){
				console.log('filter saga : fetch failed: ' + e)
			}

			break;

		

		 
		// Fetch products - all ('shop'), or filtered by recipient attribute ('men','women','teenagers','children','baby')
		// case 'FETCH_SHOP':
		// 	console.log('FETCH_SHOP: term slug is ' + action.termSlug + ' and pageNum is ' + action.pageNum)
		// 	try{
		// 		//Clear terms + rootTerm in filters, remove chips
		// 		yield put(clearFiltersTerms());
		// 		yield put(removeAllChips())
		// 		yield put(clearCheckedMenuItems()); 
		// 		yield put(clearPageQueryDetails());

		// 		//add 'shop' as rootTerm  
		// 		yield put(addShopRootTerm())

		// 		var modifiedFilters = {};
  
		// 		//RECIPIENT ATTRIBUTE: if termSlug is not shop, add the recipent attribute to 
		// 		//filters as a string
				 
				
		// 		//Set shop as rootTerm 
		// 		var filterObject = { 
		// 			...filters,
		// 			terms: [					// <-- array
		// 				action.termSlug !== 'shop' ? action.termSlug : ''
		// 			],
		// 			rootTerm: 'shop'
		// 		}
		// 		cl(filterObject, 'filterObject: ')
		// 		console.log('FETCH_SHOP - starting REQUEST DATA! ');

		// 		yield put(requestData({
		// 			type: 'products',
		// 			args: ({
		// 				type: 'products',
		// 				filters : filterObject,
		// 				page: action.pageNum
		// 			}),
		// 		}));

		// 	}catch(e){
		// 		cl(e , 'saga fetch shop saga error: ')
		// 	}

		// 	break;

		//Get products based on current state filters terms
		case 'REQUEST_FILTER_TERMS':

			//first check if there are any terms - if not, return
			if(Object.keys(filters.terms).length == 0 && ( filters.rootTerm == undefined || filters.rootTerm.length == 0 )){
				cl('No terms or root term - returning')
				return;
			}
		   
			//"terms" need to be changed from objects to array of term Ids
			var termIds = Object.keys(filters.terms);

			cl(termIds, 'REQUEST_FILTER_TERMS - termId array: ')
			var args = { 
				filters:  {
					...filters,
					terms: [ ...termIds ] 
				}
			} 
			//add 'page' property if a page number was included
			if(action.payload){
				args.page = action.payload
			}

			try {
		
				//get products with specified terms
				yield put(requestData({
					type: 'products',
					args
				}));
		   
			}catch(e){
				console.log('filter saga : fetch failed: ' + e)
			}

			break;
		
 
		/*
			If root term changes and current filters are to be kept, the root term's corresponding term object 
				need to be removed from filters. (Exception: root term 'shop' is not a real term)
			1. Get old root term id: if it matches termSlug, no replacement is needed
			2. Set new root term slug by the provided termSlug
			3. Get term object by termSlug 
			4. Add term object to terms, remove previous one (by the old root term id) 
		*/ 
		case 'SET_ROOT_TERM_REPLACE_OBJ' :
			cl(action.termSlug, 'SET_ROOT_TERM_REPLACE_OBJ action.termSlug: ')
			let terms = yield select(state => state.filters.filters.terms);
			
			let oldRootTerm = undefined;

			if(action.termSlug !== 'shop'){

				//get old root term id
				oldRootTerm = yield select(state => state.filters.filters.rootTerm);
				cl(oldRootTerm, 'oldRootTerm is ')
				var oldRootTermObject = undefined;
				if(oldRootTerm){
						if(oldRootTerm == 'action.termSlug'){
							return;
						}
						oldRootTermObject = Object.values(terms).filter( termObj => 
						termObj.name.toLowerCase() == oldRootTerm
					)
				}else{
					cl('no existing root term')
				}
			}
		  
			//Replace rootTerm by provided slug
			yield put(addShopRootTerm(action.termSlug))


			//If term slug is shop, nothing more needs to be done
			if(action.termSlug == 'shop'){
				return;
			}

			//use termSlug to get term object out of the available root terms
			rootTermObject = yield select(selectRootTermObjectFromRootTermSlug, action.termSlug.toLowerCase());
  
			if(rootTermObject) {
				console.log('matching term object: ', rootTermObject);
				// add term object to redux state (filters.terms) 
				
				rootTermObject = {
					id: rootTermObject.id,
					name:rootTermObject.name,
					selector: rootTermObject.selector,
				}
				
				yield put(replaceFilterTermObject(
						oldRootTerm ? oldRootTermObject.id : undefined,
						rootTermObject 
						));
				yield put(updateChipsByFilters());			//set chip based on filter
				//add checked filter if not a "recipient" category
				if( ['men','women','teenagers','children','baby'].indexOf(action.termSlug.toLowerCase()) == -1 ){
					yield put(addCheckedFilter(rootTermObject.id));
				}
				

			}else{
				console.log('Warning: No root term matched the object: ', termSlug.toLowerCase())
			}
		 
	   break;
		case 'SET_ROOT_TERM_ADD_OBJ':
		
			//Replace rootTerm by provided slug
			yield put(addShopRootTerm(action.termSlug))	

			if(action.termSlug == 'shop'){
				return;
			}
			//use termSlug to get term object out of the available root terms
			rootTermObject = yield select(selectRootTermObjectFromRootTermSlug, action.termSlug.toLowerCase());
			if(rootTermObject) {
				console.log('matching term object: ', rootTermObject);
				// add term object to redux state (filters.terms) 
				
				rootTermObject = {
					id: rootTermObject.id,
					name:rootTermObject.name,
					selector: rootTermObject.selector,
				}
				
				yield put(replaceFilterTermObject(false, rootTermObject));
				yield put(updateChipsByFilters());			//set chip based on filter
				yield put(addCheckedFilter(rootTermObject.id));

			}else{
				console.log('Warning: No root term matched the object: ', termSlug.toLowerCase())
			}


		break;
		default:
			throw new Error('The data type you specified doesn\'t exist');
	} 
}

//Get filter menu (categories, subcategories hierarchy) with product counts  
//(based on current active filters , filtered on delivery country)
function* requestFilterMenu() {
	  
	const checkedFilters = yield select(selectCheckedFilters);
	const deliveryCountryId = yield select(selectDeliveryCountryId);
	const rootTerm = yield select(getRootTerm);

	cl(checkedFilters, 'checkedFilters');
	cl('interface saga - requestFilterMenu')
	   
	var filterObject = { 
			filters: {
				rootTerm,
				terms: [					// <-- array
					...checkedFilters,
				],
			},
			
			delivery_country_id: deliveryCountryId
	}

	try {
 
		yield put(requestingStartLoading('filters'));
			
		cl(filterObject, 'filterObject: ')
	 
		const response = yield call(api.fetchFilterMenu, filterObject);
		 
		cl(response.data)
		yield put(successStopLoading('filters')); 

		yield put(addFilterMenu( response.data[0] )); 
		  
		cl('saga requestFilterMenu - fetchFilterMenu was successful')

		//success action 
		 
		cl('someWorker -requestSomethingSuccess was run successfully')
  
	} catch(e) {  
			 
		console.log('failed: ' + e.message);
		yield put(requestDataFailure(subject, e, e.response.status));
		//Redux-Form-Saga action failure 
		
	}
}

export default function* listenerSaga() {
	yield takeLatest(REQUEST_FILTER_MENU, requestFilterMenu); 
	yield takeLatest(FETCH_SHOP, updateShop);
	yield takeLatest(SET_ROOT_TERM_REPLACE_OBJ, updateShop);
	yield takeLatest(SET_ROOT_TERM_ADD_OBJ, updateShop);
	yield takeLatest(REQUEST_FILTER_TERMS, updateShop);
	yield takeLatest(FETCH_PRODUCTS_BY_ROOT_FILTER, updateShop);
}
