import * as types from './actionTypes';
import { combineReducers } from 'redux';

//state.cropper
const initialState = {
	editor: {
		key: null,
		zoom: 1,
		image: null,
		preview: null,
		crop: {},
	},
	dialog: {
		open: false,
	}
};

const dialogReducer = (state = initialState.dialog, action) => {
	switch(action.type) {
		case types.IMAGE_CROPPER_DIALOG_OPEN:
			return Object.assign({}, state, {
				open: true,
			});
		case types.IMAGE_CROPPER_DIALOG_CLOSE:
			return Object.assign({}, state, {
				open: false,
			});
		case types.IMAGE_CROPPER_SAVE:
			return Object.assign({}, state, {
				open: false,
			});
		default:
			return state;
	}
};

const editorReducer = (state = initialState.editor, action) => {
	switch(action.type) {
		case types.IMAGE_CROPPER_ZOOM:
			return Object.assign({}, state, {
				zoom: action.payload.value
			});
		case types.IMAGE_CROPPER_SAVE:
			return Object.assign({}, state, {
				key: action.payload.key,
				preview: action.payload.preview,
				crop: action.payload.crop,
			});
		case types.IMAGE_CROPPER_DIALOG_OPEN:
			return Object.assign({}, state, {
				key: action.payload.key,
				image: action.payload.image,
			});
		default:
			return state;
	}
};

const reducer = combineReducers({
	dialog: dialogReducer,
	editor: editorReducer,
});

export default reducer;