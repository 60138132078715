import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Image from 'components/Image';
import Paper from 'material-ui-old/Paper';
import FavoriteButton from 'components/FavoriteButton';
import MessageButton from 'components/MessageButton';
import Row from 'components/Row';
import Col from 'components/Col';
import StarRating from 'containers/StarRating';

const DesignerCard = props => {
	return(
		<Paper style={{marginBottom:'1em'}}>
			<Row>
				<Col xs={4}>
					<Image src={props.avatar || props.image} />
				</Col>
				<Col xs={8}>
					<div className="designer-card__title">
						<Link to={props.permalink}>{props.name}</Link>
					</div>
					<div>
						<StarRating
							rating={props.rating}
							numRatings={props.numRatings} />
					</div>
					<div className="designer__card-country">
						{props.country}
					</div>
					<div className="designer__numProducts">
						{props.numCreations} creations in the shop
					</div>
					<div className="designer__numProductsSold">
						{props.numSoldCreations} sold creations
					</div>
					<Row>
						<Col>
							<FavoriteButton />
						</Col>
						<Col>
							<MessageButton /> contact this designer
						</Col>
					</Row>
				</Col>
			</Row>
		</Paper>
	);
};

DesignerCard.propTypes = {
	name: PropTypes.string.isRequired,
	permalink: PropTypes.string.isRequired,
	rating: PropTypes.number.isRequired,
	country: PropTypes.string,
	numRatings: PropTypes.number.isRequired,
	numCreations: PropTypes.number,
	numSoldCreations: PropTypes.number,
};

export default DesignerCard;
