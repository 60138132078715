import React, { Component } from 'react';
import { connect} from 'react-redux';
import RichTextEditor from 'components/RichTextEditor';
import Row from 'components/Row';
import Col from 'components/Col';
import RestfulForm from 'components/RestfulForm';
import { Field } from 'redux-form';
//import { MenuItem } from 'material-ui-next/Menu';
import MenuItem from '@material-ui/core/MenuItem';
// import Select from '@material-ui/core/Select';
import {Select} from 'redux-form-material-ui';

class TermsAndConditions extends Component {  

	constructor(props) {
		super(props);
	}

	render() {
		const selects = [
			{
				name: 'creation_was_on_sale',
				label: 'The creation was on sale',
				canPayReturn: true,
			},
			{
				name: 'creation_has_been_customized',
				label: 'The creation has been customized',
				canPayReturn: true,
			},
			{
				name: 'creation_was_worn',
				label: 'The creation was worn',
				canPayReturn: false,
			},
			{
				name: 'creation_did_not_fit',
				label: 'The creation didn\'t fit/suit',
				canPayReturn: true,
			},
			{
				name: 'creation_is_personal_care',
				label: 'The creation is a personal care product',
				canPayReturn: false,
			},
		];

		return (
			<div className="content--padded">
				<RestfulForm
					name="designerFaqForm"
					route={`/api/designer/${this.props.designerId}/terms`}
				>
					<h2>Satisfied or Refund</h2>
					<Row style={{marginBottom: '1em',}}>
						{selects.map((select, index) => {
							return ([
								<Col xs={6}>
									<Field
                                                                                
                                        className="mui-select-input mui-select-bottom-border" 
										label={select.label}
										name={select.name}
										component={Select}
									>
										<MenuItem
											value={1} 
										>
											Yes
											</MenuItem>
										<MenuItem
											value={0} 
										>
											No
											</MenuItem>
									</Field>
								</Col>,
								<Col xs={6}>
									{select.canPayReturn &&
										<Field
                                                                                        className="mui-select-input mui-select-bottom-border"
											label="Do you want to pay the cost for return"
											name={`${select.name}_pay_return`}
											component={Select}
										>
											<MenuItem
												value={1} 
											>
												Yes
												</MenuItem>
											<MenuItem
												value={0} 
											>
												No
												</MenuItem>
										</Field>
									}
								</Col>,
							]);
						})}
					</Row>

					<h2>Terms & Conditions (max 2000 characters - spaces included)</h2>
					<strong>General Terms & Conditions</strong>
					<p>ISAmade's T&C shall be in accordance to the Daylicacy's Terms & Conditions: Terms & Conditions</p>
					<Row style={{marginBottom: '1em',}}>
						<Col xs={6}>
							<RichTextEditor
								languages={[
									'en',
								]}
							/>
						</Col>
						<Col xs={6}>
							<RichTextEditor
								languages={[
									'fr', 'se', 'de', 'es',
								]}
							/>
						</Col>
					</Row>
				</RestfulForm>
			</div>
		);
	}
}

export default connect(
	(state) => ({
		designerId: state.session.designerId,
	})
)(TermsAndConditions);
