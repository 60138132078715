import { put, call } from 'redux-saga/effects';
import { normalize, denormalize } from 'normalizr';
import { createSelector } from 'reselect';
import { orderSchema, orderListSchema, orderLineListSchema } from '../schemas';
import { 
	ADD_ENTITIES, 
	addEntities,
	addMessages,
	requestDataFailure,
} from '../actions';
import api from '../api';

export const STATE_KEY = 'orders';

const initialState = {
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case ADD_ENTITIES:
                     console.log('modules-orders reducer: ADD_ENTITIES');
			return {
				...state,
				...action.payload.orders,
			};

		default:
			return state;
	}
}

export function* getDesignerOrders(action) {
	try {
		const response = yield call(
			api.fetchDesignerOrders, 
			action.payload.args.designerId
		);
		const data = normalize(response.data, orderListSchema);
		yield put(addEntities(data.entities));
	} catch (e) {
		yield put(requestDataFailure(e.message));
	}
}

export function* getCustomerOrders(action) {
	try {
		const response = yield call(
			api.fetchCustomerOrders, 
			action.payload.args.customerId
		);
		const data = normalize(response.data, orderListSchema);
		yield put(addEntities(data.entities));
	} catch (e) {
		yield put(requestDataFailure(e.message));
	}
}

export function* getMessages(action) {
    try {
    	console.log('Inside saga of getMessages');

        const response = yield call(
            api.fetchMessages,
            action.payload.args.designerId
        );
        console.log(response);
        // const data = normalize(response.data, orderListSchema);
        yield put(addMessages(response.data));
    } catch (e) {
        yield put(requestDataFailure(e.message));
    }
}

/**
 * Selectors
 */

export const selectModule = state => state.data.orders || {};

export const selectHydratedOrder = (state, id) => {
	return denormalize(
		id,
		orderSchema,
		{
			...state.data,
			designers: state.data.designers.all,
			products: state.data.products.all,
		}
	);
};

