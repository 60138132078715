import React from 'react';
import { Card, CardActions, CardHeader, CardText } from 'material-ui-old/Card';
//import Button from 'material-ui-next/Button';
import Button from '@material-ui/core/Button';

import { connect } from 'react-redux';

import { DaylicacyAvatar } from 'components/Image';


const CustomerHome = (props) => {
	
	

	return(
		<div className="customer-home content vertical-padding">
			<div className="row">
				<div className="cx12 cs2">
					<DaylicacyAvatar user={props.user} />
				</div>
				<div className="cx12 cs10">
					Welcome {props.user.name} to your personal account!
				</div>
			</div>
			<div className="row customerinfo">
				<div className="cx12 cs6">
					<Card className="newscard">
						<CardHeader
							title="News"
						/>
						<CardText>
							We have discovered some new talents in Sweden....
						</CardText>
						<CardActions>
                                                    <Button>Read more</Button>
                                                    <Button>Blog</Button>
						</CardActions>
					</Card>
				</div>
				<div className="cx12 cs6">
					<Card>
						<CardHeader
							title="Question?"
						/>
						<CardText>
							Check the Guide of the Daylicacy that gathers all information on the site and the advices to develop your business...
						</CardText>
						<CardActions>
                                                <Button> Read more</Button>
						</CardActions>
					</Card>
				</div>
			</div>
		</div>
	);
};

export default connect(
    state => ({
        user: state.session.user
    })
)(CustomerHome);

