/**
 * Cart actions
 */
export const PREPARE_ADD_TO_CART = 'PREPARE_ADD_TO_CART'
export const ADD_TO_CART = 'ADD_TO_CART';
export const CART_REMOVE = 'CART_REMOVE';
export const CART_REMOVE_ALL = 'CART_REMOVE_ALL';
export const REQUEST_CHECKOUT= 'REQUEST_CHECKOUT';
export const REQUEST_CHECKOUT_SUCCESS = 'REQUEST_CHECKOUT_SUCCESS';
export const REQUEST_CHECKOUT_FAILED = 'REQUEST_CHECKOUT_FAILED';
