import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field, getFormValues } from 'redux-form';

import {Checkbox, Select} from 'redux-form-material-ui';

import MuiCheckbox from '@material-ui/core/Checkbox';
//import { MenuItem } from 'material-ui-next/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress'
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Alert from 'components/Alert';
import DaylicacyButton from 'components/DaylicacyButton';
import { DaylicacyCheckbox } from 'components/Form';
// import SelectField from 'components/SelectField';
import DaylicacyTextField from 'components/Form/DaylicacyTextField';
import FileField from 'components/FileField';
import TabControl from './TabControl';
import { DaylicacyFlagIcon } from 'components/Icon';



const CheckBoxField = ({item, disabled}) =>

	<div title={`${disabled ? 'This country is already taken in one of your other zones - remove it from that zone to enable it here' : ''}`} >
		<FormControlLabel
			control={
				<Field
                    disabled={disabled}
                    className= {`${disabled && ' disabled-country-checkbox'}`} 
					name={`countries[${item.id}]`}
					component={Checkbox}
				/>
			}
			/>
		
		<span className="fa-icon-span">
			<DaylicacyFlagIcon 
				countryCode={item.code} 
				countryName={item.name}
			/>
		</span>
		<span className={`country-name ${disabled && 'disabled-country'}`}>{item.name}</span>
	</div>

const ContinentColumn = ({continents, selectedContinentName, startValue, endValue, euChecked1, euChecked2, euChecked3}) => {
 
	//console.log('startValue is' + startValue + ' and endValue is ' + endValue);
	
	return (
	<div className="cxs6 cs3">
		{Object.values(continents[selectedContinentName].slice(startValue,endValue)).map( (country, index) =>{
			//trim name
			if(country.name.length > 23){
				// console.log('country.name.length > 23');
				country.name = country.name.slice(0,23) + '...';
			}
			return(
            <CheckBoxField 
                disabled={country.disabled}
                item={country} 
                key={country.id} />
			)
		})
		}
	</div>
	)
}

let Continent = (props) => {

    const {
        initialValues, 
        continentColumnCount,
        totalContinentCountries,
        continents,
        selectedContinentName,
        euChecked1,
        euChecked2,
        euChecked3,
    } = props;
   
    var startValue = 0;
    var endValue = 0;
    var count = 0;

    return(

        <div className="row cx12 country-columns">
         {/* column 1 */}
         { [1,2,3,4].map( col => {
 
            count +=1;
            // console.log('count is ' + count);
            

            switch(count){
                case 1:
                    startValue = 0;
                    endValue = continentColumnCount;
                    // console.log('case 1 startValue is ' + startValue);
                    // console.log('endValue is ' + endValue);
                break;

                case 2:
                    startValue = endValue;
                    endValue = continentColumnCount * 2;
                    // console.log('case 2 startValue is ' + startValue);
                    // console.log('endValue is ' + endValue);

                break;

                case 3:
                    startValue = endValue;
                    endValue = endValue + continentColumnCount;
                    // console.log('case 3 startValue is ' + startValue);
                    // console.log('endValue is ' + endValue);
                break;  

                case 4:
                    startValue = endValue;
                    endValue = totalContinentCountries;
                    // console.log('case 4 startValue is ' + startValue);
                    // console.log('endValue is ' + endValue);
                break;
            } 

            return(
            <ContinentColumn 
                key={`${selectedContinentName}_${count}`}
                continents={continents} 
                selectedContinentName={selectedContinentName}  
                startValue={startValue}
                endValue={endValue} 
                euChecked1={euChecked1}
                euChecked2={euChecked2}
                euChecked3={euChecked3}

            />
        )
        })
        }
         </div>
    )
   
}
Continent = reduxForm({
    form: 'editShippingZoneForm',
    enableReinitialize:true,
	keepDirtyOnReinitialize: true,
	destroyOnUnmount: false,
    forceUnregisterOnUnmount: false
})(Continent);

export default Continent;