import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
// import { routerMiddleware } from 'react-router-redux';
//import { connectRouter, routerMiddleware } from 'connected-react-router'
 
import createLogger from 'redux-logger';
import reducers from './reducers.js';
import rootSaga from './saga';
import {loadState, saveState, loadFromSessionStorage, saveToSessionStorage } from './localStorageHandling.js';
import throttle from 'lodash/throttle';

const sagaMiddleware = createSagaMiddleware();
const logger = createLogger({
	collapsed: true,
});

const middlewares = [
	sagaMiddleware,
//	routerMiddleware(history),
	
];

//logger,

const enhancers = [
	applyMiddleware(...middlewares)
];

//))(JSON.parse(localStorage.getItem('cart')))


/* eslint-disable no-underscore-dangle */
const composeEnhancers = 
	// process.env.NODE_ENV !== 'production' &&
	// typeof window === 'object' &&
	window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
	window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose;
/* eslint-enable */

let localStorageState = loadState();
let sessionStorageState = loadFromSessionStorage();


//bring in data from localStroage and sessionStorage
// const persistedState = {
	
// 	...localStorageState,
// 	data: {
// 		products: {
// 			...sessionStorageState
// 		}
// 	}
	
// }
const persistedState = loadState();

 console.log('persistedState', persistedState);
const store = createStore(
//	connectRouter(history)(reducers),                       // connected-react-router
        reducers,
	persistedState,
	composeEnhancers(...enhancers)
);
//persistedState,


store.subscribe(throttle(() => {
	saveState({
		cart: store.getState().cart,
		session: store.getState().session
	});
	saveToSessionStorage({ 
		products: store.getState().data.products
	});
}), 1000);

sagaMiddleware.run(rootSaga);

export default store;
