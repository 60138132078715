import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Snackbar from 'material-ui-old/Snackbar';
// import Snackbar from '@material-ui/core/Snackbar';
import { closeSnackbar } from 'containers/Snackbar/actions';

class SnackbarContainer extends Component {

	constructor(props) {
		super(props);

		this.handleRequestClose = this.handleRequestClose.bind(this);
	}

	handleRequestClose() {
		//alert('close snackbar');
		this.props.closeSnackbar();
	}

	render() {
		return (
			<Snackbar 
				className="snackbar"
				open={this.props.open}
				message={this.props.message}
				autoHideDuration={this.props.autoHideDuration}
				onRequestClose={this.handleRequestClose}
			/>
		);
	}

}

SnackbarContainer.propTypes = {
	open: PropTypes.bool.isRequired,
	autoHideDuration: PropTypes.number,
	message: PropTypes.string.isRequired,
};

export default connect(
	state => ({
		open: state.snackbar.open,
		autoHideDuration: state.snackbar.autoHideDuration,
		message: state.snackbar.message,
	}),
	{ closeSnackbar }
)(SnackbarContainer);