import React from 'react';
import { connect } from 'react-redux';
import {
	updateImprint,
 	fetchImprint,
} from './actionCreators';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import {Select} from 'redux-form-material-ui';
import {TextField} from 'redux-form-material-ui';


import Alert from 'components/Alert';
import DaylicacyTextField from 'components/Form/DaylicacyTextField';

import DaylicacyButton from 'components/DaylicacyButton';

//import { MenuItem } from 'material-ui-next/Menu';
 import MenuItem from '@material-ui/core/MenuItem';         // <-- test to see if works

const validate = values => {
	const errors = {};

	const requiredFields = [
		'company_country_id',
		// 'company_registration_name',
		// 'company_registration_number',
		// 'company_vat_number',
		// 'company_managing_director',
		// 'address_zip',
		// 'address_city',
		// 'address_state',
		// 'address_country_id',
		// 'address_email',
		// 'address_phone',
		// 'address_fax',
	];

	requiredFields.forEach(field => {
		if(!values[ field ]) {
			errors[ field ] = 'Required';
		}
	});

	return errors;
};

let ImprintForm = (props) => (
	
	 
	<form className="imprintform" onSubmit={props.handleSubmit}>

		<div className="content content--padded">
			<h3>Company info</h3>
			<ul>
				<li className="row middle-xs">
					<div className="cx12 cs6 cm3">
						<label>Company declared in country</label>
					</div>
					<div className="cx12 cs6 cm3">
						<Field
							className="mui-select-bottom-border mui-select-input"
							name="company_country_id"
							component={Select}
							placeholder="Company country id"
						>
							{ [{ id: 1, name: 'England' },{ id: 2, name: 'Sweden' }].map((country, index) => (
								<MenuItem 
									value={country.id}
									key={index}
								>{country.name} 
								</MenuItem>
							)) }
						</Field>
					</div>
					
				</li>
				<li className="row middle-xs">
					<div className="cx12 cs6 cm3">
						<label>Company registration name</label>
					</div>
					<div className="cx12 cs6 cm3">
						<Field
						 	className="mui-text-input-min-width mui-bottom-border"
							type="text"
							component={DaylicacyTextField}
							name="company_registration_name"
						/>
					</div>
				</li>
				<li className="row middle-xs">
					<div className="cx12 cs6 cm3">
						<label>Company registration number</label>
					</div>
					<div className="cx12 cs6 cm3">
						<Field
							className="mui-text-input-min-width mui-bottom-border"
							type="text"
							component={DaylicacyTextField}
							name="company_registration_number"
						/>
					</div>
				</li>
				<li className="row middle-xs">
					<div className="cx12 cs6 cm3">
						<label>VAT number</label>
					</div>
					<div className="cx12 cs6 cm3">
						<Field
							className="mui-text-input-min-width mui-bottom-border"
							type="text"
							component={DaylicacyTextField}
							name="company_vat_number"
						/>
					</div>
					<div className="cx12 cs6 cm3">
						<label>For country</label>
					</div>
					<div className="cx12 cs6 cm3">
						<Field
							className="mui-select-bottom-border mui-select-input"
							name="company_for_country"
							component={Select}
						>
							{ [{ id: 1, name: 'England' },{ id: 2, name: 'Sweden' }].map((country, index) => (
								<MenuItem 
									key={index}
									value={country.id}
								>{country.name}
								</MenuItem>
							)) }
						</Field>
					</div>
				</li>
					
				
				<li className="row middle-xs">
					<div className="cx12 cs6 cm3">
						<label>Managing director</label>
					</div>
					<div className="cx12 cs6 cm3">
						<Field
							className="mui-text-input-min-width mui-bottom-border"
							type="text"
							component={DaylicacyTextField}
							name="company_managing_director"
						/>
					</div>
				</li>
				 
			</ul>
			<h3>Address</h3>
			<ul>
				<li className="row middle-xs">
					<div className="cx12 cs6 cm3">
						<label>Zip Code</label>
					</div>
					<div className="cx12 cs6 cm3">
						<Field
							className="mui-text-input-min-width mui-bottom-border"
							type="text"
							component={DaylicacyTextField}
							name="address_zip"
						/>
					</div>
				</li>
				<li className="row middle-xs">
					<div className="cx12 cs6 cm3">
						<label>City</label>
					</div>
					<div className="cx12 cs6 cm3">
						<Field
							className="mui-text-input-min-width mui-bottom-border"
							type="text"
							component={DaylicacyTextField}
							name="address_city"
						/>
					</div>
				</li>
				<li className="row middle-xs">
					<div className="cx12 cs6 cm3">
						<label>State/Region</label>
					</div>
					<div className="cx12 cs6 cm3">
						<Field
							className="mui-text-input-min-width mui-bottom-border"
							type="text"
							component={DaylicacyTextField}
							name="address_state"
						/>
					</div>
				</li>
				<li className="row middle-xs">
					<div className="cx12 cs6 cm3">
						<label>Country</label>
					</div>
					<div className="cx12 cs6 cm3">
						<Field
							className="mui-select-bottom-border mui-select-input"
							name="address_country_id"
							component={Select}
							placeholder="address country id"
						>
							{ [{ id: 1, name: 'England' },{ id: 2, name: 'Sweden' }].map((country, index) => (
								<MenuItem value={country.id}>{country.name}</MenuItem>
							)) }
						</Field>
					</div>
				</li>
				<li className="row middle-xs">
					<div className="cx12 cs6 cm3">
						<label>Email</label>
					</div>
					<div className="cx6 cs6 cm3">
						<Field
							className="mui-text-input-min-width mui-bottom-border"
							type="text"
							component={DaylicacyTextField}
							name="address_email"
						/>
					</div>
				</li>
				<li className="row middle-xs">
					<div className="cx12 cs6 cm3">
						<label>Phone</label>
					</div>
					<div className="cx12 cs6 cm3">
						<Field
							className="mui-text-input-min-width mui-bottom-border"
							type="text"
							component={DaylicacyTextField}
							name="address_phone"
						/>
					</div>
				</li>
				<li className="row middle-xs">
					<div className="cx12 cs6 cm3">
						<label>Fax</label>
					</div>
					<div className="cx12 cs6 cm3">
						<Field
							className="mui-text-input-min-width mui-bottom-border"
							type="text"
							component={DaylicacyTextField}
							name="address_fax"
						/>
					</div>
				</li>
				
				
				
			</ul>
			<ul>
				<h3>Additional Info</h3>
				<li className="row middle-xs">
					<div className="cx12 cs6 cm3">
						<label>Additional information to display</label>
					</div>
					<div className="cx12 cs6 cm3 textareawrap">
                        <Field	
						// className="mui-text-input-min-width mui-bottom-border"
                            name="info"
                            component={TextField}
                            multiline 
                            type="text" 
                            rows="8"
                            rowsMax="8"
                        />
                    </div>
				</li>
			</ul>
			<ul>
				<li className="row">
						<div className="cxo3">
							<DaylicacyButton
								type="submit" 
								label="Save"
								disabled={props.isLoading}
							>
							 {props.tr.apply || 'Apply'}
							</DaylicacyButton>
						</div>
					</li>
					<li className="row">
					{ props.status === 'failed' && 
						<Alert cssClasses="alertbox" className="cx12" error>{ this.props.errorMsg }</Alert>
					}
					{ props.status === 'success' &&
						<Alert cssClasses="alertbox" className="cx12" success>Saved</Alert>
					}
				</li>
			</ul>
		</div>
	</form>
);

// const selector = formValueSelector('imprintForm')

ImprintForm = reduxForm({
	form: 'imprintForm',
	validate,
})(ImprintForm);

class Imprint extends React.Component {

	constructor(props) {
		super(props);

		this.handleSubmit = this.handleSubmit.bind(this);
	}

	componentDidMount() {
		this.props.fetchImprint();
	}

	handleSubmit(data) {
		this.props.updateImprint(data);
	}

	render() {
		return (
			<section className="section">
				<div className="content content--padded">
					<ImprintForm
						tr={this.props.tr}
						initialValues={this.props.initialValues}
						onSubmit={this.handleSubmit}
						errorMsg={this.props.errorMsg}
						status={this.props.status}
						isLoading={this.props.isLoading}
					/>
				</div>
			</section>
		);
	}

}

export default connect(
	state => ({
		errorMsg: state.imprint.errorMsg,
		status: state.imprint.status,
		isLoading: state.imprint.isLoading,
		initialValues: state.imprint.initialValues,
		userId: state.session.userId,
	}),
	{ updateImprint, fetchImprint },
)(Imprint);
