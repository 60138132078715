import React from 'react';
import PropTypes from 'prop-types'; 

import { Field, reduxForm, getFormValues, SubmissionError, isValid, FormSection,formValueSelector,submit } from 'redux-form';
import { Card, CardActions, CardTitle, CardText } from 'material-ui-old/Card';
//import Button from 'material-ui-next/Button';
import Button from '@material-ui/core/Button';

import { DaylicacyRadioGroup, DaylicacyOption } from 'components/Form';
//import Radio, { RadioGroup } from 'material-ui-next/Radio';
import Radio from '@material-ui/core/Radio'; 
import RadioGroup from '@material-ui/core/RadioGroup';
//import { FormControlLabel } from 'material-ui-next/Form';
import FormControlLabel  from '@material-ui/core/FormControlLabel';

import PaymentForm from './PaymentForm';
import Alert from 'components/Alert';

//posts to endpoint: 'checkout' , function 
//See "onSubmit function in parent: Checkout"
let Payment = ({ onSubmit, formValues, tr, finished, error, responseIssue, errorMsg,countryName, handleSubmit,pristine,submitting }) => {

    //currently not in use
    const formatError = (errorMsg) => {
        return(
        	<div>
                <p>
                    <strong>Message: </strong>
                    <span>{errorMsg.data.message}</span> <br/>
                    <span>{errorMsg.message ? errorMsg.message : ''}</span> <br/>
                    <span>{errorMsg.data.fields.card_expiry_month ? errorMsg.data.fields.card_expiry_month : ''}</span> <br/>
                    <span>{errorMsg.data.fields.delivery_country_id ? errorMsg.data.fields.delivery_country_id : ''}</span> <br/>
                    <span>{errorMsg.data.fields.card_expiry_year ? errorMsg.data.fields.card_expiry_year : ''}</span> <br/>
                    <span>{errorMsg.data.fields.card_cvc ? errorMsg.data.fields.card_cvc : ''}</span> <br/>
                </p>
            </div>
		)
    }
 

	return(
       
		<div className="cx12 cs8 payment-form">
            {error && (<h1> Error!!! </h1>)}
           <form onSubmit={handleSubmit} className="row cx12">
                <div className="cx12 cs6">
                    <Card className="payment-address payment-delivery-address">
                        <CardTitle title={tr.delivery_address || 'Delivery address'} />
                        <CardText>
                            { formValues.delivery_title } { formValues.delivery_first_name } { formValues.delivery_last_name }
                        </CardText>
                        <CardText>
                            { formValues.delivery_address }
                        </CardText>
                        <CardText>
                            { formValues.delivery_zip_code } { formValues.delivery_city }   
                        </CardText>
                        <CardText> 
                            { countryName }
                        </CardText>
                        <CardActions>
                            <Button color="secondary" label={tr.edit_delivery_address || 'Edit delivery address'} />
                        </CardActions>
                    </Card>
                    <Card className="payment-address payment-billing-address">
                        <CardTitle title={tr.billing_address || 'Billing address'} />
                        <CardText>
                            { formValues.delivery_title } { formValues.delivery_first_name } { formValues.delivery_last_name }<br/>
                            { formValues.delivery_address }<br/>
                            { formValues.delivery_zip_code } { formValues.delivery_city }<br/>
                            { countryName }
                        </CardText>
                        <CardActions>
                            <Button color="secondary" label={tr.edit_billing_address || 'Edit billing address'} />
                        </CardActions>
                    </Card>
                    
                </div>
                <div className="cx12 cs6 payment-card-details">
                    { true === false &&
                    <div className="payment-method">
                        <p>Choose your secure payment method:</p>
                        <DaylicacyRadioGroup name="paymentMethod">
                            <DaylicacyOption
                                label={tr.pay_by_credit_debit_card}
                                value="creditCard"
                            />
                            <DaylicacyOption
                                label={tr.pay_using_paypal}
                                value="paypal"
                            />
                        </DaylicacyRadioGroup> 
                    </div> 
                    }

                    <PaymentForm onSubmit={onSubmit}/>
                    {responseIssue && 
                        <div>
                            <Alert cssClasses="alertbox alert-error" > { responseIssue} </Alert>
                        </div>
                        }
                </div>
                <div className="cx12 cs6">
                    { error ? <Alert cssClasses="alertbox alert-error" > {formatError(errorMsg)} </Alert> : '' }
                    {finished && <p>The order has been processed successfully</p>}
                </div>
                
                {finished && <p>The order has been processed successfully</p>}
               
            </form>
		</div>
	);
};
Payment.propTypes = {
	formValues: PropTypes.object.isRequired,
};

Payment = reduxForm({
    form: 'checkout',
    // enableReinitialize:true,
	keepDirtyOnReinitialize: true,
	destroyOnUnmount: false,
    forceUnregisterOnUnmount: false
})(Payment);

 
export default Payment;
