import React from 'react';
import PropTypes from 'prop-types';
import IconButton from 'components/IconButton';
import Badge from 'components/Badge';

const FavoriteDesigner = props => {
	return(
		<Badge
			value={props.numFavorites || 0}
			position="bottom"
		>
			<IconButton 
				iconClassName="fa fa-archive" 
				tooltip="Add to my favorite designers"
				onClick={props.onClick}
			/>
		</Badge>
	);
};

FavoriteDesigner.propTypes = {
	numFavorites: PropTypes.number,
	onClick: PropTypes.func,
};

export default FavoriteDesigner;