import { put, call,select } from 'redux-saga/effects';
import { normalize, denormalize } from 'normalizr';
import { createSelector } from 'reselect';
import {selectReturnSomething} from 'data/selectors';

import {types as customProfileTypes } from 'views/Account/Myshop/Shipping/containers/CustomProfiles/actions';
import {selectUserDesignerProducts} from 'data/modules/designers'; 
import {
	shippingProfileListSchema,
	shippingProfileSchema,
	shippingZoneListSchema
} from 'data/schemas';

import { 
	ADD_ENTITIES,  
	REQUEST_DATA_FAILURE,
	addEntities,  
	requestDataFailure,
} from 'data/actions';

import {DELETE_SHIPPING_ZONE} from 'data/modules/shippingZones';
import api from 'data/api';
import {cl,notObjectOrNotEmptyObject, isNotEmptyObject, emptyObject} from 'utils'
import { types } from 'views/account/MyShop/Shipping/containers/CreateEditShippingGeneral/actions';

export const STATE_KEY = 'shippingProfiles';

const subject = 'shippingProfiles';

export const ADD_SHIPPING_PROFILES = 'ADD_SHIPPING_PROFILES';
export const ADD_SHIPPING_PROFILE = 'ADD_SHIPPING_PROFILE';
export const FETCH_DESIGNER_SHIPPING_ZONES = 'FETCH_DESIGNER_SHIPPING_ZONES';
export const ADD_DESIGNER_SHIPPING_ZONES = 'ADD_DESIGNER_SHIPPING_ZONES';
export const MERGE_TO_DESIGNER_SHIPPING_ZONES = 'MERGE_TO_DESIGNER_SHIPPING_ZONES'
// export const FETCH_DESIGNER_SHIPPING_ZONES_FAILURE = 'FETCH_DESIGNER_SHIPPING_ZONES_FAILURE';
export const HANDLE_POSTED_SHIPPING_ZONES = 'HANDLE_POSTED_SHIPPING_ZONES';

//Actions

export const addShippingProfile = entities => ({
	type: ADD_SHIPPING_PROFILE,
	payload: entities,
});

export const addShippingProfiles = entities => ({
	type: ADD_SHIPPING_PROFILES,
	payload: entities,
});

export const fetchDesignerShippingZones = ({ id }) => ({
	type: FETCH_DESIGNER_SHIPPING_ZONES,
	payload: {id}
});
export const addDesignerShippingZones = ( result ) => ({
	type: ADD_DESIGNER_SHIPPING_ZONES,
	payload: result
});   
export const mergeToDesignerShippingZones = ( result ) => ({
	type: MERGE_TO_DESIGNER_SHIPPING_ZONES,
	payload: result
});
// export const fetchDesignerShippingZonesFailure = ({ error }) => ({
// 	type: FETCH_DESIGNER_SHIPPING_ZONES_FAILURE,
// 	payload: {error}
// });
export const handlePostedShippingZones = ( result ) => ({
	type: HANDLE_POSTED_SHIPPING_ZONES,
	payload: result
});

export const sagaError = ({ error }) => ({
	type: SAGA_ERROR,
	payload: {error}
});


/* =================== state.data.shippingProfiles (reducer) =================== 	 */
const initialState = {
	shippingProfiles: {},
	shippingZones: {
		shippingZones:{}, 
		requestStatus: {
			requesting: false,
			requestStatus: null,
			lastRequestError: {
				statusCode:null,
				message: null
			} 
		},
	},
	profileZoneMethods: [], 
	requestStatus: {
		requesting: false,
		requestStatus: null,
		lastRequestError: {
			statusCode:null,
			message: null
		} 
	},
	shippingMethodTypes: window.d_store.shipping_methods,
	shippingSuppliers: {}
};

export default function reducer(state = initialState, action) {
	switch (action.type) {

			// case ADD_ENTITIES:
        //             console.log('modules-shippingZones reducer: ADD_ENTITIES');
		// 	return {
		// 		...state,
		// 		...action.payload.shippingZones,
		// 	};


		//merge
		case customProfileTypes.FETCH_SHIPPING_CUSTOM_PROFILES_SUCCESS: 
		 
			cl(action.payload, 'action.payload: ')
			if(action.payload.result.length == 0){
				cl('FETCH_SHIPPING_CUSTOM_PROFILES_SUCCESS: action.payload.result.length is 0')
				return {
					...state
				}
			}
			return {
				...state,
				shippingProfiles: {
					...state.shippingProfiles,
					...action.payload.result.shippingProfiles
				},
				shippingZones: {

					...state.shippingZones,
					shippingZones: {
						...state.shippingZones.shippingZones,
						...action.payload.result.shippingZones,
						
					}
					
				},
				profileZoneMethods: {
					...state.profileZoneMethods,
					...action.payload.result.profileZoneMethods		//shippingMethods								
				},
				requestStatus: {											
					requesting: false,
					requestStatus: 'success',
					lastRequestError: {
						statusCode:null,
						message: null
					} 
				},
			}

		case ADD_SHIPPING_PROFILE:

			cl(action.payload, 'ADD_SHIPPING_PROFILE , action.payload: ')

			//Copy redux methods, Remove methods belonging to the profile
			let profileZoneMethodsCopy = Object.assign({}, state.shippingProfiles.profileZoneMethods);
			cl(profileZoneMethodsCopy, 'copied redux profileZoneMethods: ')
			profileZoneMethodsCopy = Object.values(profileZoneMethodsCopy).filter( prof => prof.id !== action.payload.profileId)
			profileZoneMethodsCopy = profileZoneMethodsCopy[0] 
			profileZoneMethodsCopy = {profileZoneMethodsCopy}

			//add methods
			profileZoneMethodsCopy = Object.assign({}, profileZoneMethodsCopy, { ...action.payload.profileZoneMethods });
			cl(profileZoneMethodsCopy, 'final profileZoneMethodsCopy: ')

			return {
				...state, 
				shippingProfiles: {
					...state.shippingProfiles,
					...action.payload.shippingProfiles,							
						
				},
				profileZoneMethods: { 
					...profileZoneMethodsCopy								
				},
				shippingZones: {
					...state.shippingZones,
					shippingZones: {
						...state.shippingZones.shippingZones,
						...action.payload.shippingZones	
					}
				},
				// fetchResultStatus: ,								
				requestStatus: {											
					requesting: false,
					requestStatus: 'success',
					lastRequestError: {
						statusCode:null,
						message: null
					} 
				},
			}
		case ADD_SHIPPING_PROFILES:
			//To recieve normalized shippingProfiles
			
			cl('add_shipping_profiles reducer: action.payload: ')
			cl(action.payload)
			
			var shippingProfilesState = {
				...state, 
				shippingProfiles: {
					...state.shippingProfiles,
					...action.payload.shippingProfiles,							
						
				},
				profileZoneMethods: {
					// ...state.profileZoneMethods,
					...action.payload.profileZoneMethods								
				},
				shippingZones: {
					...state.shippingZones,
					shippingZones: {
						...state.shippingZones.shippingZones,
						...action.payload.shippingZones	
					}
				},
				// fetchResultStatus: ,								
				requestStatus: {											
					requesting: false,
					requestStatus: 'success',
					lastRequestError: {
						statusCode:null,
						message: null
					} 
				},

			};

			//If result contains shippingZones, add them, else add existing ones  
			if('shippingZones' in action.payload) {

				cl('ADD_SHIPPING_PROFILES: result contains shippingZones - adding')
				shippingProfilesState.shippingZones = {
					...state.shippingZones,
					countries: {
						// ...state.shippingZones.countries,
						...action.payload.countries								
					},				
					shippingZones : {
						// ...state.shippingZones.shippingZones,
						...action.payload.shippingZones							
					}		 
					
				}
			}else {
				cl('ADD_SHIPPING_PROFILES : result didn\'t contain shippingZones')
				shippingProfilesState.shippingZones = {
					...state.shippingZones
				}
			};

			return {
				 ...shippingProfilesState 
				
			};
		

		//This will replace previous zones data
		case ADD_DESIGNER_SHIPPING_ZONES:
			console.log('shippingZones reducer: ADD_DESIGNER_SHIPPING_ZONES, action.payload: ',action.payload);
			 
			cl('shippingZones')
			console.log(action.payload.shippingZones)
			// console.log(action.payload.shippingZones)
			return {
				...state, 
				shippingProfiles: {
					...state.shippingProfiles, 	
				},
				shippingZones: {  				 
					
					shippingZones: {
						// ...state.shippingZones.shippingZones,
						...action.payload.shippingZones,		// <-- adding
					},
					countries: {
						// ...state.shippingZones.countries,
						...action.payload.countries					// <-- adding
					},
					requestStatus: {											// <-- adding
						requesting: false,
						requestStatus: 'success',
						lastRequestError: {
							statusCode:null,
							message: null
						} 
					}
				}
			};

			//the result will merge with existing zones data
			case MERGE_TO_DESIGNER_SHIPPING_ZONES:
				console.log('shippingZones reducer: MERGE_TO_DESIGNER_SHIPPING_ZONES, action.payload: ',action.payload);
				// cl('countries')
				// console.log(action.payload.countries)
				cl('shippingZones')
				console.log(action.payload.shippingZones)
				// console.log(action.payload.shippingZones)
				return {
					...state, 
					shippingProfiles: {
						...state.shippingProfiles,
							
					},
					shippingZones: { 
						...state.shippingZones,				 
						
						shippingZones: {
							...state.shippingZones.shippingZones,
							...action.payload.shippingZones,		// <-- adding
						},
						countries: {
							...state.shippingZones.countries,
							...action.payload.countries					// <-- adding
						},
						requestStatus: {											// <-- adding
							requesting: false,
							requestStatus: 'success',
							lastRequestError: {
								statusCode:null,
								message: null
							} 
						},
					}
				};

		case DELETE_SHIPPING_ZONE:

			let zonesObject = state.shippingZones
			return {
				...state, 
				shippingZones: {
					...state.shippingZones,
					 
						
				}  
			};

		// case HANDLE_POSTED_SHIPPING_ZONES:

		case REQUEST_DATA_FAILURE:

			if(action.payload.subject == 'shippingZonesModule') {

				return {
					...state, 
					shippingZones: {
						...state.shippingZones,
						requestStatus: {
							requesting: false,
							requestStatus: 'failed',
							lastRequestError: {
								statusCode: action.payload.statusCode || '',
								message: action.payload.error.message || ''
							}
						}
							
					}  
				};

			}else{
				return state;
			}
			
			break;

 

		default:
			return state;
	}
}

export function* getShippingProfiles(action) {
	try {
		const response = yield call(
			
			//Backend function: getDesignerShippingProfiles
			api.fetchShippingProfiles,
			action.payload.args.designerId
		);

		cl('getShippingProfiles : response.data')
		cl(response.data);
		const data = normalize(response.data, shippingProfileListSchema);

				//alert('shippingProfiles result: ' + JSON.stringify(action.payload.shippingProfiles));
			// const checkData = (yield select()).data.terms.terms;	
			// const checkData2 = (yield select()).data.shippingprofiles;	 
//			console.log('hello from getshippingprofiles, this is state.data.terms.terms: ' + JSON.stringify(checkData));
//			console.log('hello from getshippingprofiles, this is state.data.shippingprofiles: ' + JSON.stringify(checkData2));

			//alert('shipping response: ' + JSON.stringify(response.data));

			// if(Object.keys(state.data.terms.terms) === 0 && state.data.terms.terms.constructor === Object){
			// 	alert('about to add shippingprofile result. This terms in state is empty');
			// }else{
			// 	//alert('terms in state is ' + JSON.stringify(state.data.terms.terms));
			// }

		//if any shipping_zone_id is null, change to 0
		Object.values(data.entities.profileZoneMethods).map( method => {
			cl(method, 'method: ')
			if(method.shipping_zone_id == null ){
				method.shipping_zone_id = 0;
			}
		})
		yield put(addShippingProfiles(data.entities));
		
	}  catch (e) {
		console.log('getShippingProfiles failed');
		yield put(requestDataFailure(subject, e, e.response.status));
	}
}
 







/* ====================================== Selectors ====================================== */

/* shippingProfiles selectors */
 
export const selectShippingProfilesRequestStatus = state => state.data.shippingProfiles.requestStatus;


/* shippingZones selectors */ 

export const selectShippingZonesRequestStatus = state => state.data.shippingProfiles.shippingZones.requestStatus;

// export const selectDenormalizedShippingZones = createSelector(
// 	selectDesignerShippingZones,
// 	(shippingZones) => {
// 		denormalize(selectAll, shippingZoneListSchema, state.data.products);
// 	}
// ) 
 
export const selectShippingZoneCountries = state => state.data.shippingProfiles.shippingZones.countries;
export const selectDesignerShippingZones = state => state.data.shippingProfiles.shippingZones.shippingZones;
export const selectContinents = state =>  state.editShippingZone.countries.continents;

export const selectShippingProfiles = state => state.data.shippingProfiles.shippingProfiles;

//includes everything (zones, methods etc) 
export const selectShippingProfilesProperty = state => state.data.shippingProfiles;


// export const selectDesignerShippingZoneByIds = createSelector(
// 	[selectDesignerShippingZones,selectShippingZoneCountries ],
// 	(zones, zoneCountries) => {
// 		return ids.map( id => {
// 			//return country of the current id
// 			return zoneCountries[id];
// 		})
// 	}
// )


export const selectShippingZoneCountriesExceptCurrent = createSelector(
	[selectReturnSomething,selectDesignerShippingZones],
	(id, designerShippingZones) => {
		cl(designerShippingZones,'selectShippingZoneCountriesExceptCurrent selector - designerShippingZones:') 
		//see if there are more than 1 zone 
		if(Object.keys(designerShippingZones).length > 0) {
			 
			//get list of country Ids of the other zones
			console.log('the id to be excluded: ' + id);
			var zones = Object.values(designerShippingZones).filter( zone => zone.id !== id)
			console.log('zones:');
			console.log(zones);

			var countryIdArray = [];

			zones.map( zone => {
 
				countryIdArray = countryIdArray.concat( zone.countries);
			})

			//(remove duplicates?)
			console.log(countryIdArray, 'selectShippingZoneCountriesExceptCurrent - countryIdArray:')
			return countryIdArray;
			
			  
		} else{
			console.log('There were no shipping zones')
		}
 
	}
	  
)

//get profile id where default == 1
export const selectDefaultShippingProfileId = createSelector(
    [selectShippingProfiles],
    shippingProfiles => {
		if(!shippingProfiles.length){
			cl('no shipping profiles');
		}
       var defaultProfile = Object.values(selectShippingProfiles).filter( profile => 
            profile.default == 1
		)
		cl(defaultProfile, 'defaultProfile: ');
        return defaultProfile.id;
    }
)
 

/* ----------- Custom profiles selectors -------------------- */
 
export const selectCustomShippingProfileIds = createSelector(
    [selectShippingProfiles],
    shippingProfiles => {

        var ids = [];
        Object.values(shippingProfiles).map( profile => {

            if( profile.default != 1 ) {
                ids.push(profile.id)
            }
        }) 

        return ids;
       
    }
)
 
//Get number of products connected to each of the user designers custom profile
 export const selectNumProductsConnectedToCustomProfile = createSelector(
	[selectCustomShippingProfileIds,selectUserDesignerProducts],
	(customProfileIds, userDesignerProducts) => {
		// cl(userDesignerProducts, 'userDesignerProducts: ')
        cl(customProfileIds, 'customProfileIds: ')
        //put custom profile id as property, and number of products as value
        var profileProductCounts = {};

        //if not empty array
        if(customProfileIds.length > 0 ) {
                 
             customProfileIds.map( id => {
                // cl('about to add property: ' + profile.id);
                    profileProductCounts[id] = 0
            } )
        } 
 
        //for each product that has custom profile, add +1 to its count 
        userDesignerProducts && userDesignerProducts.length > 0 && userDesignerProducts.map( product => {
			//  cl('user designer has products')
			var defaultProperty = product.shipping_profiles[0].default;
			var productProfileId = parseInt(product.shipping_profiles[0].id);
			// cl(productProfileId, 'the product profile has id: ');

			if(defaultProperty != '1') {
 
				if(productProfileId in profileProductCounts){
					profileProductCounts[productProfileId] +=1
				}
				
			}
            
        });
        cl(profileProductCounts, 'final profileProductCounts: ');
        return profileProductCounts;


    }
)
 
//get denormalized custom profiles
export const selectDenormalizedCustomProfiles = createSelector(
    [selectCustomShippingProfileIds, selectShippingProfilesProperty],
    (customProfileIds,profilesProperty) => { 
        cl(customProfileIds, 'customProfileIds: ')

        //if there are no shippingProfiles, don't denormalize
        if(emptyObject(profilesProperty.shippingProfiles)){
            cl('shippingProfiles is empty')
            return [];
        }
        cl(profilesProperty, 'profilesProperty: ')
        var denormalizedProfiles = denormalize
		(
		    customProfileIds,
			[shippingProfileSchema],
			profilesProperty 
        )

        cl(denormalizedProfiles, 'denormalizedProfiles: ');
        return denormalizedProfiles;
    }
)

//selectReturnSomething
export const selectDenormalizedCustomProfile = createSelector(
    [selectReturnSomething,selectShippingProfilesProperty],
    (customProfileId,profilesProperty) => { 
        cl(customProfileId, 'the custom customProfileId is: ')

        //check id if numeric
        if( isNaN(customProfileId) ){
            cl('id was not an int')
            return;
        }
        //if shippingProfiles is empty object, don't denormalize
        if(emptyObject(profilesProperty.shippingProfiles)){
            cl('shippingProfiles is empty')
            return ''
        }
        cl(profilesProperty, 'profilesProperty: ')
        var denormalizedProfile = denormalize
		(
		    customProfileId,
			shippingProfileSchema,
			profilesProperty 
        )

        cl(denormalizedProfile, 'denormalizedProfile: ');
        return denormalizedProfile;
    }
)


