export const ADD_CHIP = 'ADD_CHIP';
export const REMOVE_CHIP = 'REMOVE_CHIP';
export const UPDATE_CHIPS = 'UPDATE_CHIPS';
export const GET_FILTERS_TO_UPDATE_CHIPS = 'GET_FILTERS_TO_UPDATE_CHIPS';
export const REMOVE_ALL_CHIPS = 'REMOVE_ALL_CHIPS';
 


export const addChip = (id, name, selector) => {
	return ({
		type: ADD_CHIP,
		payload: { id, name, selector },
	});
};

export const removeChip = (id) => {
	return ({
		type: REMOVE_CHIP,
		payload: { id },
	});
};
 
export const updateChips = (filters) => {
	return ({
		type: UPDATE_CHIPS, 
		payload: filters
	}); 
}
 

export const updateChipsByFilters = () => {
	return ({
		type: GET_FILTERS_TO_UPDATE_CHIPS, 
	}); 
}

export const removeAllChips = () => {
	return({
		type: REMOVE_ALL_CHIPS,
	});
};