import { call, put, takeLatest, takeEvery, select } from 'redux-saga/effects';
import * as types from './actionTypes';
import api from 'data/api';
import {
	requestSignInSuccess,
	requestSignInFailed,
	changeCountry
} from './actionCreators';

import {selectCountryIdByCode} from 'data/modules/countries';
import { SubmissionError } from 'redux-form'; 
import { loginFormAction } from './formActions';
import { CHECK_COUNTRY  } from './actionTypes';
import {cl} from 'utils'; 

function * checkCountry(action){
	cl('session saga: checkCountry')

	//find user ip
	try{

		 const request = yield call(api.getUserIp);

		console.log('User\'s Location Data is ', request);
		console.log('User\'s Country', request.data.country);

		//try to get country id by the code  
		var countryId =  yield select(state =>selectCountryIdByCode(state, request.data.countryCode));
		cl(countryId, 'checkCountry - ip check result: ' + request.data.country + 
			', trying to get country id from it resulted in: ')
		//set delivery country id and name
		yield put(changeCountry(countryId, request.data.country, request.data.countryCode))
//		alert('country has changed')
		cl(request.data.country, 'successfully put country: ' )
	}catch(e){
		cl('session saga: checkCountry error:')
		cl(e);
	}




}
function* signInWorker(action) {
	 
	const { email, password } = action.payload;

	try {
		// const request = yield call(api.requestLogin, email, password);
		// yield put(requestSignInSuccess(request));

		cl('signInWorker - sending login data to api')
		//post user form data
		const result = yield call(api.requestLogin, email, password);

		cl('signInWorker - sign in successful')
		yield put(loginFormAction.success( email, password));    //notify form of success
		cl('signInWorker -loginFormAction was run successfully')

		 
		yield put(requestSignInSuccess( result ));   //run Session action creator, to trigger its reducer
		cl('signInWorker -requestSignInSuccess was run successfully')
  
	} catch(e) { 
		// yield put(requestSignInFailed(e));
		const formError = new SubmissionError({ 
			_error: 'Login failed, please check your credentials and try again', // global form error
		});
		yield put(loginFormAction.failure(formError)); 
		
	}
}

// function languageChange*(action){
// 	cl( action.language, 'session saga, languageChange: ')




// }

function* sagaListener() { 
	yield takeEvery(loginFormAction.REQUEST, signInWorker);
	yield takeEvery(CHECK_COUNTRY, checkCountry);
	// yield takeEvery(LANGUAGE_CHANGE, languageChange);
}

export default sagaListener;
