import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Blurb from './Blurb';
import Row from 'components/Row';
import Col from 'components/Col';
import { connect } from 'react-redux';
import { selectLanguage } from 'containers/Session/selectors';

class Blurbs extends Component {

	constructor(props) {
		super(props);
	}
	
	render() {
		const { tr } = this.props;
		return(
			<Row className="blurbs">
				{this.props.blurbs.map((blurb, i) => (
					<Col key={i} xs={6} sm={3} className="cmp20">
						<Blurb 
							label={tr[blurb.label.toLowerCase()]}
							icon={blurb.image.url}
							link={`${this.props.currentLanguage}/${blurb.url}`} />				
					</Col>
				))}
			</Row>
		);
	}

};

Blurbs.propTypes = {
	blurbs: PropTypes.array.isRequired,
};

export default connect(
	state => ({
		currentLanguage: selectLanguage(state),
		tr: state.translations
	})
)(Blurbs);