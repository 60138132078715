import { createSelector } from 'reselect';
import {cl} from 'utils'

export const selectLanguage = state => state.session.language;
export const selectDeliveryCountryId = state => state.session.delivery_country_id;


//return user language - if 'se', change to 'sv'
export const selectUserLanguage = createSelector(
    selectLanguage,
    lang => { 
        lang = lang.toLowerCase(); 
        cl(lang, 'selectuserLanguage: ')
        let returnLang = (lang == 'se' || lang == '/se') ? 'sv' : lang; 
        cl(lang, 'selectuserLanguage: current language is ')
        return returnLang;
    }
    
   

)

// export const selectUserAddresses = state => state.session.user.addresses;
export const selectUserAddresses = state => state.session.addresses;

export const selectUserAddressType1 = createSelector(
    [selectUserAddresses],
    addresses => {
        var addressType1 = Object.values(addresses).filter( address => {
            return 'address_type_id' in address && address.address_type_id == 1;
        })
        cl(addressType1, 'addressType1: ')
        return addressType1[0];
    }
)



//Get Primary delivery address (id=1)

export const selectUserDeliveryAddress = createSelector(
    selectUserAddresses,
    addresses => {

        var deliveryAddress = undefined;
        console.log('hello from selectUserDeliveryAddress');
        //make sure an array of objects was retrieved
        if(Array.isArray(addresses)) {
            
            deliveryAddress = addresses.filter(address => address.address_type_id == 1) 
                
        } else{
            cl('selectUserAddresses - not array')
            return;
        }
        console.log('deliveryAddress is ', deliveryAddress);
        if(!deliveryAddress || !deliveryAddress.length){
            console.log('not deliveryaddres')
            return '';
        }
        deliveryAddress = deliveryAddress[0];
          //change keys of deliveryAddress to match delivery form input names
            
          let newObject = {
            delivery_title : deliveryAddress.title_id == 0 ? 'Mr' : deliveryAddress.title_id == 1 ? 'Mrs' : 'Miss',
            delivery_first_name: deliveryAddress.first_name,
            delivery_last_name: deliveryAddress.last_name,
            delivery_company: deliveryAddress.company_name,
            delivery_address: deliveryAddress.address,
            delivery_zip_code: deliveryAddress.zip,
            delivery_city: deliveryAddress.city,
            delivery_country_id: deliveryAddress.country_id,
            email: deliveryAddress.email,
            phone: deliveryAddress.phone
        }
        
        console.log('heres newobject ',newObject);
        return newObject;

    }
    
 
);

