import * as types from './actionTypes';

export const requestSuccess = translations => ({
	type: types.FETCH_TRANSLATIONS_SUCCESS,
	result: translations
});

export const requestFailed = e => ({
	type: types.FETCH_TRANSLATIONS_FAILED, 
	message: e.message
});

export const fetchTranslations = (locale) => ({
	type: types.FETCH_TRANSLATIONS,
	locale,
});