import { put, takeEvery, call } from 'redux-saga/effects';
import api from 'data/api';
import { types, actions } from './actions';
import { getProducts, getProduct } from './modules/products';
import { getDesigner, getUserDesigner, getDesigners } from './modules/designers';
import { getDesignerOrders, getCustomerOrders, getMessages } from './modules/orders';
import { getShippingConditions } from './modules/shippingConditions';
import { getShippingZones } from './modules/shippingZones';
import { getShippingProfiles } from './modules/shippingProfiles';
import {callDeleteShippingZone} from 'data/modules/ShippingZones'
import { getTerms } from './modules/terms';
import { prepareCheckout } from './modules/checkout';
import { throttle } from 'lodash';

// listen for REQUEST_DATA
// dispatch requestData({ type, args })
// try: fetch data depending on type
	// success: put addEntities action with the result
	// failure: catch error and put requestDataFailure with error message

export function* requestData(action) {
	console.log('============== requestData (data saga) ==============')
	console.log('requestData action - the type is '+ action.payload.type);

	try {
		switch (action.payload.type) {
			
			case 'product': 
			// alert(JSON.stringify(action))
				yield getProduct(action);
				//yield throttle(getProducts(action) , 7000);
				
				break;
			case 'products': 
				console.log('requestData Products: ')
				console.log(action);

				yield getProducts(action);
				//yield throttle(getProducts(action) , 7000);
				
				break;
			case 'designer':

				console.log('requestData - designer');
				yield getDesigner(action); 
				
				break;
			case 'userDesigner':

				console.log('requestData - designer');
				yield getUserDesigner(action); 
			
			break;
			case 'designers':
				yield getDesigners(action);
				break;
			case 'designerOrders':
				yield getDesignerOrders(action);
				break;
			case 'customerOrders':
				yield getCustomerOrders(action);
				break;
			case 'shippingConditions':
				yield getShippingConditions(action);
				break;
			case 'shippingZones':
				yield getShippingZones(action);
				break;
			case 'deleteShippingZone':
				yield callDeleteShippingZone(action);
				break;
			case 'shippingProfiles':
				yield getShippingProfiles(action);
				break;
			case 'terms':
				yield getTerms(action);
				break;
			case 'prepare_checkout':
				yield prepareCheckout(action);
				break;
			case 'messages':
				console.log('Inside saga get messages');
				yield getMessages(action);
				break;
			default:
				throw new Error('The data type you specified doesn\'t exist');
		}
	}catch(e){
		console.log('error: Request Data saga failed: ' + e)
	}
	// try {
	// 	const { payload } = action
	// 	const result = yield call(apiMethod, payload.args)

	// 	yield put(actions.requestDataSuccess({ 
	// 		type: dataType,
	// 		data: result.data
	// 	}))
	// } catch (e) {
	// 	yield put(actions.requestDataFailure(e.message))
	// }
}

export default function* dataSaga() {
	console.log('inside data Saga');
	yield takeEvery(types.REQUEST_DATA, requestData);
}
