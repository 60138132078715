import * as types from './actionTypes';

export const updateImprint = (args) => ({
	type: types.REQUEST_UPDATE_IMPRINT,
	args,
});

export const updateImprintSuccess = (data) => ({
	type: types.REQUEST_UPDATE_IMPRINT_SUCCESS,
	data,
});

export const updateImprintFailed = (error) => ({
	type: types.REQUEST_UPDATE_IMPRINT_FAILED,
	error,
});

export const fetchImprint = (id) => ({
	type: types.REQUEST_FETCH_IMPRINT,
	id,
});

export const fetchImprintSuccess = (result) => ({
	type: types.REQUEST_FETCH_IMPRINT_SUCCESS,
	result,
});

export const fetchImprintFailed = (error) => ({
	type: types.REQUEST_FETCH_IMPRINT_FAILED,
	error,
});
