import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchProduct, updateProduct } from './actionCreators';
import { objectToFormData } from 'utils';
import CreationForm from 'containers/CreationForm';
import { getIsLoading } from './selectors';
import { getSelectedProduct } from 'containers/ProductTable/selectors';
import CircularProgress from '@material-ui/core/CircularProgress';
import { requestData } from 'data/actions';
import {notObjectOrNotEmptyObject,cl, intersect} from 'utils'
 

/*	Product Id was selected in Creations list.
	The product is retrieved from state.data.designers.userDesigner in parent: Creations.index
	
	post to route:	 	/api/product/' + id, data
	function:	updateProduct
	The result updates userDesigner.products (see modules/designers, EDIT_PRODUCT_UPDATE_PRODUCT_SUCCESS)
	

*/
class EditProduct extends Component {

	constructor(props) {
		super(props);
  
		this.state = {
			postStatus: null
		}
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	 
	componentDidMount() {
 
		//Fetch terms - if terms in state is not empty
		if(!notObjectOrNotEmptyObject(this.props.terms)){
			cl('no terms - requesting terms!')
			this.props.requestData({
				type: 'terms',
			});
		}
		
	}
 

	handleSubmit(data) {
		 
		cl(this.props.product[0].id, 'handleSubmit - about to update product: ' + this.props.product[0].id)
		console.log('EditProduct.handleSubmit - data', data);

		const termNodes = this.props.termNodes;
		let terms = [];
  
		data.attributes.forEach((attribute) => {
			if (Array.isArray(attribute)) {
				attribute.forEach((node) => {
					if(node.term.id) {
                        terms.push(node.term.id);
					}
				});
			}
		});


		if (data.mainCategory in termNodes) {
			terms.push(termNodes[data.mainCategory].term);
		}
		if (data.subCategory in termNodes) {
			terms.push(termNodes[data.subCategory].term);
		}
		data['terms'] = terms;

		let dataCopy;

		if (data.images !== undefined && Array.isArray(data.images)) {
			dataCopy = Object.assign({}, data, { 
				images: data.images.map(image => ({
					crop: image.crop,
					file: image.file,
				}))
			});
		} else {
			dataCopy = data;
		}

		console.log('EditProduct.handleSubmit - dataCopy', dataCopy);
		const formData = objectToFormData(dataCopy);
		console.log('EditProduct.handleSubmit - formData', formData);

		// Dispatch action
		 this.props.updateProduct(this.props.product[0].id, formData);
	}

	render() {
		//the product object is in product[0]
		const { 
			createOrEdit, 
			designer, 
			product, 
			tr, 
			productId, 
			errorMsg, 
			status, 
			isLoading,
			transactionStatus,
			rootCategories
		} = this.props;
		 
		if (!product){
			return <div className="mui-circular-container"><CircularProgress /></div>;
		}

		const images = product[0].gallery.items.map((item, i) => {
			return { 
				id: item.id, 
				preview: item.url 
			};
		});

		/* ======================= initial Values ======================= 
			adjust data for initial values								*/
  
		if(product[0]) {  
  
			// (new uploaded images will overwrite old in backend)

			// var imagesObject = { images: [] }

			// product[0].gallery.items.map( item => {

			// 	//get filename including extension
			// 	var str = item.url;
			// 	var n = str.lastIndexOf('/'); 
			// 	var fileName = str.substring(n + 1);
			// 	imagesObject.images.id = fileName;
			// 	imagesObject.images.crop = {}
				
			// })
			var initialValues = {
				translations : product[0].translations, 
				shipping_profile: product[0].shipping_profiles[0].id,
				price: product[0].price,
				vat: product[0].vat,
				stock: product[0].stock,
				// images:  imagesObject,
				gallery: product[0].gallery
			}

			/* Form data structure of product attributes:

			General attributes - array with [term_id] = [node_id] 
			(A "node id" is a Term hierarchy id)
			((todo - maybe change to better structure, not array with numbered indexes))

			The product attributes belong to one of the 8 main categories (ex: Ethical products)

			If the product has attributes, it is saved in "generalAttributes" array as:
			 generalAttributes[category (term) id] = [array of NODE ids]

			and in attributes[category parent(!) node id] = [array of objects],  with the structure:
				{
					node_id: ##,			<-- node id belonging to the term  
					term: { id: ##, name: 'Fair trade/Ethical Labour'}
				} 
			*/

			/* Product data: 
				all attributes and categories are saved in "term_attribute" array of objects.
				( naming is questionable ) :

				{ id: [node Id]							<-- belongs to the term
				  attr { id, node_id, name, slug }		<--- Main Category or main Attribute (ex Ethical Products)
				  term {id, name slug}					<--- Attribute (child to the category or main attribute)
				}
			*/	
			
			cl(rootCategories, 'rootCategories: ')
			//collect main categories/attributes
			if(rootCategories && !notObjectOrNotEmptyObject(rootCategories)) {

				var rootCategoryIds = Object.keys(rootCategories).map( cat => parseInt(cat));
				cl(rootCategoryIds, 'rootCategoryIds: ')
				var mainAttributeIds = [];
				var attributes = [];
				product[0].term_attributes.map( nodeObject => {
					mainAttributeIds.push(nodeObject.id);
					attributes.push(nodeObject.term.id);
				})
			 
				//remove duplicates in mainAttributeIds
			 
				mainAttributeIds = mainAttributeIds.filter((id, index, self) => 
					self.indexOf(id) === index
				);
				//remove 1 if exist
				var index = mainAttributeIds.indexOf(1);
				if( index > -1 ){
						mainAttributeIds.splice(index, 1);
				}
				cl(mainAttributeIds, 'mainAttributeIds: ')
				cl(rootCategoryIds, 'rootCategoryIds: ')
				//Identify and set mainCategory - intersect the two arrays
				var mainCategoryId = intersect(mainAttributeIds, rootCategoryIds)[0];
				initialValues.mainCategory = mainCategoryId;
				cl(mainCategoryId, 'mainCategoryId is ')
				//Set Main attributes, attributes and subCategory from term_attributes array in product
				initialValues.generalAttributes = [];
				initialValues.attributes = [];

				product[0].term_attributes.map( termAttribute => {

					//main category is term_id of one of the root categories (ex: jewellery, id 41)
					//sub category is term id of a sub category belonging to the root category
			 
					//see if a category or sub category  (parent will be 1)
					if(termAttribute.attribute.id == 1) {
						cl('termAttribute.attribute.id == 1: ' + termAttribute.term.id);
						//skip if mainCategory
						if(termAttribute.term.id == mainCategoryId) {
							return;
						}else{
							//set sub category id
							initialValues.subCategory = termAttribute.term.id;
							cl( termAttribute.term.id, 'subCategory: ' )
						}
					}else{
						//general and sub attributes 
						//if array position is not empty, push value to existing sub array, else set value inside sub array 
						if(Array.isArray(initialValues.generalAttributes[ termAttribute.attribute.id])){
							initialValues.generalAttributes[ termAttribute.attribute.id].push(termAttribute.id);
						}else{
							initialValues.generalAttributes[ termAttribute.attribute.id] = [termAttribute.id];
						}
						let attrObj = {
							node_id: termAttribute.id,					//<-- node id belonging to the term
							term: {
								id: termAttribute.term.id,
								name: termAttribute.term.name
							}
						}; 
						cl('setting attribute value on index position ' + termAttribute.id);
						if(Array.isArray(initialValues.attributes[termAttribute.attribute.node_id])) {
							initialValues.attributes[termAttribute.attribute.node_id].push(attrObj);
						}else{
							initialValues.attributes[termAttribute.attribute.node_id] = [attrObj];
						}
						
					}
				
				})
			}else{
				cl('rootCategories is empty')
			}
			
			// cl('logging initialValues.generalAttributes :')
			// cl(JSON.stringify(initialValues.generalAttributes));
			 
		}
 
			// translations: product[0].translations,
			// price: product[0].price,
			// vat: product[0].vat,
			// images: images,
		  

		return(
			<CreationForm 
				transactionStatus={transactionStatus}
				tr={tr}
				productId={productId}
				createOrEdit={createOrEdit}
				onSubmit={this.handleSubmit}
				errorMsg={errorMsg}
				status={status}
				isLoading={isLoading}
				initialValues={initialValues}
			/>
		);
	}

}

EditProduct.propTypes = {
	productId: PropTypes.number.isRequired,
	isLoading: PropTypes.bool,
};

const mapStateToProps = state => ({
	rootCategories: state.data.terms.rootCategories,
	terms: state.data.terms.terms || null,
	termNodes: state.data.terms.termNodes,
	isLoading: getIsLoading(state),
	// productData: state.creations.editProduct.productData,
});

export default connect(
	mapStateToProps,
	{ requestData, fetchProduct, updateProduct }
)(EditProduct);
