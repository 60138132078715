import React from 'react';
//import Button from 'material-ui-next/Button';
import Button from '@material-ui/core/Button';

const Collections = () => {
	return(
		<div className="content content--padded">
			<h3>My collections</h3>
                        <Button variant="raised">Add new collection</Button>
		</div>
	);
};

export default Collections;