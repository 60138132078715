import React from 'react';
import { connect } from 'react-redux'; 

import { Field, reduxForm, formValueSelector } from 'redux-form';

//redux-form-saga formAction
import { updateUser } from '../actions'; 

//import { withStyles } from 'material-ui-next/styles';
import { withStyles } from '@material-ui/core/styles';

import FileField from 'components/FileField';

import ImageUploader from 'react-images-upload';

import validate from '../validate.js';

//import {Radio} from 'redux-form-material-ui'; 

//import Radio from 'material-ui-next/Radio';
import Radio from '@material-ui/core/Radio';

import { RadioGroup, TextField } from 'redux-form-material-ui';

//import { FormControlLabel } from 'material-ui-next/Form';
import FormControlLabel from '@material-ui/core/FormControlLabel';
 
//import { MenuItem } from 'material-ui-next/Menu';
import MenuItem from '@material-ui/core/MenuItem';

//import CircularProgress from 'material-ui-next/CircularProgress';
import CircularProgress from '@material-ui/core/CircularProgress';

//import Button from 'material-ui-next/Button';
import Button from '@material-ui/core/Button';

import moment from 'moment';
 
import Alert from 'components/Alert';
// import { DaylicacyRadioGroup } from 'components/Form';
import DaylicacyTextField from 'components/Form/DaylicacyTextField';

 
 const styles = theme => ({  
    
});

/* submit dispatches formaction 'UPDATEUSER' and handled in saga - handleUpdateUser

*/
class MyAccountSetupForm extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			
		}
	}
 
	cloneObjWithDeletedProp(obj, prop) {
    
		let res = Object.assign({}, obj)
		delete res[prop]
		return res
	
	}
        
	handleSlider(event, value) {
		this.setState({
			avatarSlider: value
		});
	}

	render() {
		
		const { 
					 
			tr, 
			classes,
			currentUserAvatar,
            handleSubmit,
            invalid, 
			dobYear, 
			dobMonth, 
			pristine, 
			submitting,
			submitSucceeded,
			submitFailed

		} = this.props;


		const currentYear = moment().format('YYYY');
		const yearsAgo = currentYear - 100;
		const yearsAgoRounded = Math.floor(yearsAgo / 100) * 100;
		const years = [];
		let daysInMonth = 31;	

		for(var i = currentYear; i > yearsAgoRounded; --i) {
			years.push(i);
		}

		const months = [
			1, 2, 3, 4,
			5, 6, 7, 8,
			9, 10, 11, 12
		];

		if(dobYear && dobMonth) {
			daysInMonth = moment({
				year: dobYear,
				month: dobMonth
			}).daysInMonth();
		}

		const days = Array(daysInMonth).fill(0).map((x, i) => i + 1);
        const sortImgsMessage = null;
        const maxNumFiles = 1;
        const fileFieldName="avatar";

		return(
			
			<form className="cx12 account-setup-form myaccount-form" onSubmit={handleSubmit(updateUser)} encType="multipart/form-data">  
				 
				<div className="cx12">
                   
                    <h2>{tr.account_setup || 'Account Setup'}</h2>
                   

                    <ul>
                       
                        <li className="row middle-xs">
                            <div className="cx4">
                                <label className="label label--left">
                                    {tr.username}
                                </label>
                            </div>
                            <div className="cx8">
                                <Field
                                    className="mui-text-input-min-width mui-bottom-border"
                                    name="name"
                                    component={TextField} 
                                    type="text"
                                />
                            </div>
                        </li>
                        <li className="row middle-xs">
                            <div className="cx4">
                                <label className="label label--left">{tr.change_password}</label>
                            </div>
                            <div className="cx8">
                                <Field 
                                    className="mui-text-input-min-width mui-bottom-border mui-bottom-border mui-smallbmargin"
                                    name="old_password"
                                    component={TextField}
                                    
                                    type="password"
                                    placeholder={tr.old_password} 
                                />
                            </div>
                                
                            <div className="cx4"></div>
                            <div className="cx8">
                                <Field
                                    className="mui-text-input-min-width mui-bottom-border mui-smallbmargin"
                                    name="new_password"
                                    component={TextField}
                                    type="password"
                                    placeholder={tr.new_password} 
                                />
                            </div>
                            <div className="cx4"></div>
                            <div className="cx8">
                                <Field
                                    className="mui-text-input-min-width mui-bottom-border mui-smallbmargin"
                                    name="new_password_repeat"
                                    component={TextField}
                                    placeholder={tr.new_password}
                                    type="password"  
                                />
                            </div> 
                        </li>
                        <li className="row middle-xs">
                            <div className="cx4">
                                <label className="label label--left">{tr.company_queston_1}</label>
                            </div>
                            <div className="cx8 mui_radiogroup">        
                                <Field

                                    name="company" 
                                    component={RadioGroup} 
                                > 
                                    <FormControlLabel  
                                        control={<Radio />} 
                                        value="no" 
                                        label="No"
                                        style={{ width: '50%' }} 
                                    />
                                    <FormControlLabel  
                                        control={<Radio />} 
                                        value="yes"
                                        label="Yes"
                                        style={{ width: '50%' }}  
                                    /> 
                                </Field>
                            </div> 
                            <div className="cx8 cxo4 smalltxt01">
                                {tr.company_queston_2}
                            </div>
                        </li>
                        <li className="row middle-xs">
                            <div className="cx4">
                                <label className="label label--left">{tr.orgnr_siret_persnr}</label>
                            </div>
                            <div className="cx8">
                                <Field
                                    className="mui-text-input-min-width mui-bottom-border"
                                    name="company_org_number"
                                    component={TextField}
                                    type="text"
                                    // placeholder={tr.orgnr_siret_persnr }
                                />
                            </div>
                        </li>
                        <li className="row middle-xs">
                            <div className="cx4">
                                <label className="label label--left">{tr.company_name}</label>
                            </div>
                            <div className="cx8">
                                <Field
                                    className="mui-text-input-min-width mui-bottom-border"
                                    name="company_name"
                                    component={TextField}
                                    type="text"
                                    // placeholder={tr.company_name}
                                />
                            </div>
                        </li>
                        <li className="row middle-xs">
                            <div className="cx4">
                                <label className="label label--left">Title</label>
                            </div>
                            <div className="cx8 mui_radiogroup">

                                <Field
                                    className="row"
                                    name="title_id" 
                                    component={RadioGroup}                                         
                                >
                                    <FormControlLabel  
                                        control={<Radio />} 
                                        value="1"
                                        label="Mr"
                                        style={{ width: '33%' }}   
                                    />
                                    <FormControlLabel  
                                        control={<Radio />} 
                                        value="2"
                                        label="Mrs"
                                        style={{ width: '33%' }}  
                                    />
                                    <FormControlLabel  
                                        control={<Radio />} 
                                        value="3"
                                        label="Miss"
                                        style={{ width: '33%' }}   
                                    />  
                                </Field> 
                            </div>
                        </li>
                        <li className="row middle-xs">
                            <div className="cx4">
                                <label className="label label--left"> {tr.first_name}</label>
                            </div>
                            <div className="cx8">
                                <Field
                                    className="mui-text-input-min-width mui-bottom-border"
                                    name="first_name"
                                    component={TextField}
                                    type="text"
                                    // placeholder={tr.first_name}
                                />
                            </div>
                        </li>
                        <li className="row middle-xs">
                            <div className="cx4">
                                <label className="label label--left"> {tr.last_name}</label>  
                            </div>
                            <div className="cx8">
                                <Field
                                    className="mui-text-input-min-width mui-bottom-border"
                                    name="last_name"
                                    component={TextField}
                                    type="text"
                                    // placeholder={tr.last_name}  
                                />
                            </div>
                        </li>
                        <li className="row middle-xs">
                            <div className="cx4">
                                <label className="label label--left">{tr.date_of_birth}</label>
                            </div>
                            <div className="cx8">
                                <Field
                                    className="mui-text-input-min-width mui-bottom-border"
                                    name="birth_date"
                                    component={TextField}
                                    type="date"
                                    // placeholder={tr.date_of_birth}
                                />
                            </div>
                        </li>
                        <li className="row middle-xs">
                            <div className="cx4">
                                <label className="label label--left">{tr.main_contact_email}</label>
                            </div>
                            <div className="cx8">
                                <Field 
                                    className="mui-text-input-min-width mui-bottom-border"
                                    name="email"
                                    component={TextField} 
                                    type="email"
                                    // placeholder={tr.main_contact_email}
                                />
                            </div>
                        </li>
                        <li className="row middle-xs">
                            <div className="cx4">
                                <label className="label label--left">{tr.main_contact_phone} </label>
                            </div>
                            <div className="cx8">
                                <Field
                                     className="mui-text-input-min-width mui-bottom-border"
                                    name="phone"
                                    component={TextField}
                                    type="text"	
                                    // placeholder={tr.main_contact_phone} 
                                />
                            </div>
                            
                        </li>

                        <li className="row middle-xs avatar">
                            <div className="cx4">
                                <label className="label label--left">{tr.avatar}</label>
                            </div>
                            <div className="cx8">
                                <Field
                                    name="avatar"
                                    component={FileField}
                                    sortImgsMessage={sortImgsMessage}
                                    maxNumFiles={maxNumFiles}
                                    fileFieldName={fileFieldName}
                                />
                            </div>
                        </li>
                      

                        <li className="row middle-xs">
                            <div className="cx4">
                                <label className="label label--left">{tr.current_avatar} </label>
                            </div>
                            <div className="cx8">
                                {/* {this.props.initialValues.avatar_thumb ? <img src={this.props.initialValues.avatar_thumb} /> : '' } */}
                                { currentUserAvatar ? <img src={ `${currentUserAvatar}`} /> : '' }
                                
                            </div>
                            
                        </li>
    
                        <li className="row">
                            
                            <div className="cxo4">
                                <Button
                                    className="mui-button-standard"
                                    variant="raised"
                                    type="submit" 
                                    style={{
                                        marginRight: '15px',
                                    }}
                                    color="secondary"
                                    disabled={invalid || this.props.isLoading || pristine || submitting}
                                    >
                                    Save
                                </Button>
                                
                            </div>
                        </li>
                        { this.props.submitFailed &&
                            <Alert cssClasses="alertbox alert-error" >{this.props.errorMsg}</Alert>
                        }
                        { this.props.submitSucceeded &&
                        <Alert delay={3500} cssClasses="alertbox alert-success" >Saved</Alert>
                        }
                    </ul>
                </div>
			</form>
							
		);
	}
}

MyAccountSetupForm = reduxForm({
	form: 'myAccountForm',
	validate,
})(MyAccountSetupForm);

const selector = formValueSelector('myAccountForm');

MyAccountSetupForm = connect(

	state => {
		const dobYear = selector(state, 'dobYear');
		const dobMonth = selector(state, 'dobMonth');

        //Initialization values from state - session 'user' object - without avatar 
         

		let filteredUserCredentials = Object.assign({}, state.session.user, {company: 'no'});
	    delete filteredUserCredentials["avatar"];
        var currentUserAvatar = state.session.user.avatar ? state.session.user.avatar.url : '';

		return {
			dobYear,
			dobMonth,
            initialValues: filteredUserCredentials, 
            currentUserAvatar
 
		}; 
	}
)(MyAccountSetupForm);
 
export default withStyles(styles)(MyAccountSetupForm);

/*

	enableReinitialize: true,
 //state.session.user

//color submit:  "#DF5087"

,
			initialValues:state.session.user
*/
	//initialValues:state.session.user
/*
const dobYear = selector(state, 'dobYear');
		const dobMonth = selector(state, 'dobMonth');

		return {
			dobYear,
			dobMonth,
			tr: state.translations
                       
		};
*/


/* i connect:

onSubmitSuccess: (result) => {
		//after post, set new initialization values from result - without avatar 
		 console.log('the posted result is as follows: ');
		 console.log(result);
		let filteredUserCredentials = Object.assign({}, result)
		delete filteredUserCredentials["avatar"]
		console.log('the posted result without avatar is as follows: ');
		console.log(filteredUserCredentials);

	}


	*/