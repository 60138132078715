import * as types from './actionTypes'
import { normalize, denormalize } from 'normalizr';
import { createSelector } from 'reselect';
import {  select } from 'redux-saga/effects';
 
import api from 'data/api'
import {
	call,
	put,
	takeLatest,
	takeEvery,
	dispatch
} from 'redux-saga/effects'

import { addToCart } from './actionCreators'

import {
	successStopLoading,
	SUCCESS_STOP_LOADING, 
	requestingStartLoading,
	REQUESTING_START_LOADING,
	requestDataFailure,
	requestDataSuccess
} from 'data/actions';
import { fetchProduct } from 'data/api'; 

import { productSchema } from 'data/schemas';
import {addProduct, selectDenormalizedProduct} from 'data/modules/products';


//returns false if data is complete
const checkMissingDataInItem = item => {

	
	if (!('designer' in item)){
		return 'designer';

	} 

	let shippingMethods = '';
	  
	if(!('shipping_profiles' in item)) {
			return 'shipping_profiles';
	}
	if(!('countries' in item)) {
		return 'shipping_profiles';
	}
	shippingMethods = item.shipping_profiles[0].methods;
    
	if( !('zone' in shippingMethods)) { 
		return 'zone';
	}else if(!('countries' in shippingMethods[zone])){
		return 'countries';
	} 
 
}

/*	 
	Idea: cart items must be complete with designer, shipping profile, zone, etc etc
	checkItemOnPrepareAddToCart checks to see if item is complete
	if not, makes api call to fetch complete item
	
	if item has missing data, calls fetchProduct, route: `/api/product/${id}
*/
function* checkItemOnPrepareAddToCart( action ) {
	 console.log('cart saga: PREPARE_ADD_TO_CART')

	//item needs shipping profile with costs and methods
	var item = action.payload.product;
	console.log('item is ', item)

	// First see if product is already in cart - then use that item instead.
	var productId = item.id;

	const getItems = (state) => state.cart.items;
	var cartItems = (yield select(getItems));

	console.log('cartItems', cartItems);

	Object.keys(cartItems).map( cartItem => {
		if(cartItem == productId){
			console.log('cart saga: product is already in cart - existing cart item will be checked');
			//cartItem will be used instead of the payload item
			item = cartItems[cartItem];
		}
		}
	)
	//check if missing data
	let missingdata = checkMissingDataInItem(item);

	if(missingdata) {
			console.log('cart saga, checkItemOnPrepareAddToCart: Missing data: ' +  missingdata);
		try {
            yield put(requestingStartLoading('cart'));

            // alert('sending action.payload.args: ');
			// alert(action.payload.args);
			
			//FETCH PRODUCT by id

			let id = {id: item.id}
            const response = yield call(fetchProduct, id);

			yield put(successStopLoading('cart'));
			yield put(requestDataSuccess('cart'));

			console.log('cart saga, checkItemOnPrepareAddToCart: the received item: ',response.data);
			console.log('cart saga - put addToCart');

			//add product to Cart.
			 console.log('cart saga, checkItemOnPrepareAddToCart: adding to cart')
			
			 yield put(addToCart(response.data));

			 // add normalized product to products  (Not necessary(?) - will cause re-render of products) 
			//  let normalizedItem = normalize(response.data, [productSchema]); 
			  
			//  console.log('cart saga, checkItemOnPrepareAddToCart: item was normalized, here it is ', normalizedItem);
  
			//  yield put(addProduct(normalizedItem)); 

        }catch (e) {
            console.log('failed: ' + e);
			yield put(requestDataFailure(e.message));

			return;
        }

	} else{
		//alert('everything ok')
		console.log('cart saga: item contains the necessary properties')
		console.log('adding to cart')
		yield put(addToCart(item));
	}
}

function* requestCheckout( action ){
/*
	try{
		const result = yield call( api.updateDesigner, action.args )
		yield put( updateDesignerSuccess( result ) )
	}catch( error ){
		yield put( updateDesignerFailed( error ) )
	}
*/
}

export default function* listenerSaga(){
	yield takeLatest( types.REQUEST_CHECKOUT, requestCheckout )
	yield takeEvery( types.PREPARE_ADD_TO_CART,checkItemOnPrepareAddToCart )
}
