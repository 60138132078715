import React, { Component } from 'react'; 
import { connect } from 'react-redux';
import { reduxForm,Field } from 'redux-form';

import { TextField } from 'redux-form-material-ui';
import Alert from 'components/Alert';

import Button from '@material-ui/core/Button';

const validate = values => { 

	const errors = {};
        
	const requiredFields = [ 
		'name',  
	];
    requiredFields.forEach(field => {
		if (!values[ field ]) {
			errors[ field ] = 'Required';
		}
	});
	return errors;
};

let CreateProfilesForm = ({handleSubmit, submitCreateProfilesForm, invalid, submitting, pristine, requestStatus, errorMsg}) => 

    <form className="cx12 margin-bottom3"  onSubmit={ handleSubmit(submitCreateProfilesForm)}>
          
            <div className=" margin-bottom">
                <h2>Create custom shipping profile</h2>
                <label className="label label--left">
                    Name
                </label>
            </div>
            <div className=" margin-bottom">
                <Field
                    className="mui-text-input-min-width mui-bottom-border"
                    name="name"
                    component={TextField} 
                    type="text"
                />
                <Button
                    className="mui-button-standard margin-bottom"
                    variant="raised"
                    type="submit" 
                    // onSubmit={this.handleSubmit}
                    style={{
                        marginRight: '15px',
                    }}
                    color="secondary"
                    disabled={invalid || pristine || submitting}  
                    >
                    Save
                </Button>
            </div>
            <div className=" cx12 margin-bottom2">
                
                { requestStatus === 'failed' &&
                        <Alert cssClasses="alertbox alert-error" >{errorMsg}</Alert>
                }
                { requestStatus === 'success' &&
                    <Alert delay={3500} cssClasses="alertbox alert-success" >Saved</Alert>
                }
            </div>
    </form>

CreateProfilesForm = reduxForm({
	form: 'createProfilesForm',
    validate,
   
})(CreateProfilesForm);

CreateProfilesForm = connect(
	state => ({ 
        status: state.createEditShippingCustom.status,
        errorMsg: state.createEditShippingCustom.errorMsg,
    })
)(CreateProfilesForm);

export default CreateProfilesForm;