import React from 'react';

const DaylicacyAvatar = (props) => {
	const avatarUrl = props.user && props.user.avatar ? props.user.avatar.url : "http://placehold.it/150x150";
	 

	return(
		<div className="avatar" style={{
			backgroundColor: 'white',
			border: '1px solid #ccc',
		}}>
			<div className="avatar-image">
				<img src={avatarUrl} />
			</div>
		
		</div>
	);
};

export default DaylicacyAvatar;