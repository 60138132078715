import { types } from './actions';
import {cl} from 'utils';


//state.editShippingZone
const initialState = {
	initialValues: {},
	isLoading: false,
	status: '',
	errorMsg: '',
	countries: [],
};

const reducer = (state = initialState, action) => {
	switch(action.type) {
		case types.FETCH_SHIPPING_ZONE_SUCCESS:

			cl('FETCH_SHIPPING_ZONE_SUCCESS - adding initialValues for zone id: ' + action.payload.result.data.id);
			//create countries object where country id properties will be added (set to: true)
			let countries = {};
			action.payload.result.data.countries.forEach(country => {
				countries[country.id] = true;
			});
			
			return Object.assign({}, state, {
				initialValues: {
					...action.payload.result.data,
					countries,
				}
			});
		case types.CREATE_SHIPPING_ZONE:
		case types.UPDATE_SHIPPING_ZONE:
			return Object.assign({}, state, {
				isLoading: true,
			});
		case types.CREATE_SHIPPING_ZONE_SUCCESS:
		case types.UPDATE_SHIPPING_ZONE_SUCCESS:
			return Object.assign({}, state, {
				isLoading: false,
				status: 'success',
			});
			
		case types.UPDATE_SHIPPING_ZONE_FAILURE:
		case types.UPDATE_SHIPPING_ZONE_FAILURE:
			return Object.assign({}, state, {
				isLoading: false,
				status: 'failed',
				errorMsg: action.payload.error.response.data.message
			});
		case types.FETCH_COUNTRIES_AND_CONTINENTS_SUCCESS:
			return Object.assign({}, state, {
				countries: action.payload.result.data,
			});
		default:
			return state;
	}
};

export default reducer;
