import React from 'react';

const FooterWidget = ({
	icon,
	label,
	handleMouseEnter,
	handleMouseLeave,
	children,
}) => {
	return(
		<div className="cx12 cs4">
			<div 
				className="widget" 
				onClick={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
			>
				<i 
					className={'fa ' + icon} 
					aria-hidden="true"
					style={{
						marginRight: '15px',
					}}
				>
				</i>
				<span className="widget-text">{ label }</span>
			</div>
			{ children }
		</div>
	);
};

export default FooterWidget;