import { 
	ADD_CHIP, 
	REMOVE_CHIP,
	REMOVE_ALL_CHIPS, 
	UPDATE_CHIPS
} from 'containers/FilterChipsBar/actions';

//state.filterChpsBar
const initialState = {
	chips: {}

		// { key: 0, label: 'France' },
		// { key: 1, label: 'Sweden' },
		// { key: 2, label: 'Short dresses' },
		// { key: 3, label: '40' },
		// { key: 4, label: 'Blue', backgroundColor: '#20B2EE' },
		// { key: 5, label: 'Purple', backgroundColor: '#AF60D3' },
		// { key: 6, label: '0-45 EUR', },
	
};

const FilterChipsBar = (state = initialState, action) => {
	switch (action.type) {
		case ADD_CHIP:
			// chips.push({ key: action.payload.id, label: action.payload.id });

			return Object.assign({}, state, {
                	chips: {
						...state.chips,
						[action.payload.id] : {
							key: action.payload.id, 
							label: action.payload.name,
							selector: action.payload.selector
						}
						
					} 
             	}
			);
			
		case REMOVE_CHIP:

			console.log('REMOVE_CHIP is RUNNING')

			let newChips = Object.assign({}, state.chips);
			delete newChips[action.payload.id]
			  
			return {
				...state,
				chips: {
					...newChips
				},
			  };
			   
	
		//  chips: state.chips.filter(chip => 
		// 				action.payload.id === chip.key ? false : true

		case UPDATE_CHIPS:

			console.log('Chips reducer - to update chips with these filters: ')
			console.log(action.payload)

			return Object.assign({}, state, {
				chips: {
					...action.payload
				}
			});
			 
			
			
		case REMOVE_ALL_CHIPS:
		
			return Object.assign({}, state, { chips: {} });
 
		default:
			return state;
	}
};

export default FilterChipsBar;