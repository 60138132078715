import * as types from './actionTypes';
import api, { fetchDesigner } from 'data/api';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
	updateDesignerSuccess,
	updateDesignerFailed,
	fetchDesignerSuccess,
	fetchDesignerFailed,
} from './actionCreators';

import { updateBankAccount } from './formSagaActions';
import {cl, objectToFormData} from 'utils' 



function* updateDesigner(action) {
	try{
		const result = yield call(api.updateDesigner, action.args);
		yield put(updateDesignerSuccess(result));
	}catch(error) {
		yield put(updateDesignerFailed(error));
	}
}

function* getDesigner(action) {
	try{
		const result = yield call(fetchDesigner, action.id);
		yield put(fetchDesignerSuccess(result));
        console.log('fetchDesigner');
		console.log(result);
	}catch(error) {
            console.log('fetch designer failed');
		yield put(fetchDesignerFailed(error));
	}
}

function* handleUpdateBankAccount(action){
	cl('handleUpdateBankAccount!');
	const { shop_banner } = action.payload;
	cl( shop_banner, 'shop_banner is')

	try{
		//Prepare image file
		let dataCopy;
		
		if (shop_banner !== undefined && Array.isArray(shop_banner)) {
			dataCopy = Object.assign({}, action.payload, { 
				shop_banner: shop_banner.map(image => ({
					crop: image.crop,
					file: image.file,
				}))
			});
		} else {
			dataCopy = action.payload;
		}

		console.log('shop_banner datacopy: ')
		console.log(JSON.stringify(dataCopy));
		const formData = objectToFormData(dataCopy);

		console.log('to dispatch this form data:');
		for (var entry of formData.entries()) {
			console.log(entry[0] + ' and ' + entry[1]); 
		 }


		console.log('posting updateDesigner');

		const result = yield call(api.updateDesigner, formData)
        yield put(updateBankAccount.success( action.payload));    //notify form of success
        
        //update session data (see session reducer)
		yield put(updateDesignerSuccess(result.data));  		
		

	}catch(error){
		console.log('handleUpdateBankAccount failed');
                yield put(updateDesignerFailed(error));
	}
}

export default function* listenerSaga() {
	yield takeLatest(types.REQUEST_UPDATE_DESIGNER, updateDesigner);
	yield takeLatest(types.REQUEST_FETCH_DESIGNER, getDesigner);
	yield takeLatest(updateBankAccount.REQUEST, handleUpdateBankAccount);
	
}

 
/*
	prepare update bank account form post
	
	handleSubmit(data) {
		console.log('handleSubmit(data)');
		console.log(data);
		let dataCopy;
		
		if(data.shop_banner !== undefined && Array.isArray(data.shop_banner)) {
			dataCopy = Object.assign({}, data, { 
				shop_banner: data.shop_banner.map(image => ({
					crop: image.crop,
					file: image.file,
				}))[0]
			});
			console.log('dataCopy', dataCopy);

			// const images = data.shop_banner.map((obj) => {
			// 	const key = Object.getOwnPropertyNames(obj)
			// 	const image = obj[key]
			// 	return {
			// 		crop: image.crop,
			// 		file: image.file,
			// 	}
			// })

			// dataCopy = Object.assign({}, data, { shop_banner: images[0] })
		} else {
			dataCopy = data;
		}

		// const formData = objectToFormData(dataCopy);

		// Dispatch action
		// this.props.updateDesigner(formData);
	}

	*/
