import * as types from './actionTypes';

export const updateDesigner = (args) => ({
	type: types.REQUEST_UPDATE_DESIGNER,
	args,
});

export const updateDesignerSuccess = (result) => ({
	type: types.REQUEST_UPDATE_DESIGNER_SUCCESS,
	result,
});

export const updateDesignerFailed = (error) => ({
	type: types.REQUEST_UPDATE_DESIGNER_FAILED,
	error,
});

export const fetchDesigner = (id) => ({
	type: types.REQUEST_FETCH_DESIGNER,
	id,
});

export const fetchDesignerSuccess = (result) => ({
	type: types.REQUEST_FETCH_DESIGNER_SUCCESS,
	result,
});

export const fetchDesignerFailed = (error) => ({
	type: types.REQUEST_FETCH_DESIGNER_FAILED,
	error,
});

// export const updateBankAccountSuccess = (result) => ({
// 	type: types.REQUEST_UPDATE_ACCOUNT_SUCCESS,
// 	result,
// })
