import { createActionTypes, createActions } from 'utils';

const types = createActionTypes([

	'FETCHING',
	'FETCH_SHIPPING_CUSTOM_PROFILES',  
	'FETCH_SHIPPING_CUSTOM_PROFILES_SUCCESS', 
    'FETCH_SHIPPING_CUSTOM_PROFILES_FAILURE', 
    'UPDATE_SHIPPING_CUSTOM', 
    'UPDATE_SHIPPING_CUSTOM_SUCCESS', 
    'CREATE_CUSTOM_PROFILE',
    'CREATE_CUSTOM_PROFILE_SUCCESS',
	'CREATE_CUSTOM_PROFILE_FAILURE',
	'RESET_CUSTOM_SHIPPING_STATUS',
]);

const actions = createActions({
	[types.FETCHING]: (subject) => ({ subject }),
    [types.FETCH_SHIPPING_CUSTOM_PROFILES]: (designerId) => ({ designerId }),  
	[types.FETCH_SHIPPING_CUSTOM_PROFILES_SUCCESS]: result => ({ result }),
	[types.FETCH_SHIPPING_CUSTOM_PROFILES_FAILURE]: error => ({ error }),
	[types.UPDATE_SHIPPING_CUSTOM]: (designerId, formData) => ({ designerId, formData }),
	[types.UPDATE_SHIPPING_CUSTOM_SUCCESS]: (result) => ({ result }),
	[types.CREATE_CUSTOM_PROFILE]: formData => ({ formData }),
	[types.CREATE_CUSTOM_PROFILE_SUCCESS]: result => ({ result }),
	[types.CREATE_CUSTOM_PROFILE_FAILURE]: error => ({ error }),
	[types.RESET_CUSTOM_SHIPPING_STATUS]: () => ({ }),
});

export { types, actions };
