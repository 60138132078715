const { schema, normalize } = require('normalizr');


/**
 * ShippingSupplier schema
 */
export const shippingSupplierSchema = new schema.Entity('shippingSuppliers');
export const shippingSupplierListSchema = new schema.Array(shippingSupplierSchema);

/**
 * Currency schema
 */
export const currencySchema = new schema.Entity('currencies');
export const currencyListSchema = new schema.Array(currencySchema);

/**
 * Country schema
 */
 
export const countrySchema = new schema.Entity('countries');
export const countryListSchema = new schema.Array(countrySchema);

/**
 * User schema
 */

export const addressTypesSchema = new schema.Entity('addressTypes');

export const addressSchema = new schema.Entity('addresses', {
address_type: addressTypesSchema
});

// export const addressListSchema = new schema.Array('addresses', {
// 	address_types: addressTypesSchema
// });

export const userSchema = new schema.Entity('users', {}, {
	processStrategy: (value) => ({
		...value,
		firstName: value.first_name,
		lastName: value.last_name,
		fullName: value.full_name,
	})
});

export const loggedUserSchema = new schema.Entity('users', {
	addresses: [addressSchema]
});

const image = new schema.Entity('images');

const translation = new schema.Entity('translations', {}, {
	idAttribute: (value) => {
		for (let lang in value) {
			return value[lang].id;
		}
	},
	processStrategy: (value) => {
		for (let lang in value) {
			return value[lang];
		}
	},
});

/**
 * Terms schema
 */
export const termSchema = new schema.Entity('terms');
export const attributeSchema = new schema.Entity('attributes');

export const termAttributeSchema = new schema.Entity('term_attributes',{
	attribute: attributeSchema,
	term: termSchema
});
  
export const termNodeSchema = new schema.Entity('termNodes');
export const termNodeListSchema = new schema.Array(termNodeSchema);

export const termCategorySchema = new schema.Entity('term_categories', {
	term: termSchema
}); 


termNodeSchema.define({
	term: termSchema,
	attributes: termNodeListSchema,
	children: termNodeListSchema,
});
 

export const zoneSchema = new schema.Entity('shippingZones', {
	countries: [countrySchema]
	
});
//countries: [countrySchema]
export const shippingGeneralMethodsSchema = new schema.Entity('shipping_general_methods', {
	//zone: zoneSchema,
	
});

// ,
	// method: shippingMethodSchema,
	// supplier: shippingSupplierSchema
/**
 * Product schema
 */
// export const shippingGeneralMethodsListSchema = new schema.Array(shippingGeneralMethodsSchema);

 const ratingsSchema = new schema.Entity('ratings');
const favouritesSchema = new schema.Entity('favourites');
// const shopBannerSchema = new schema.Entity('shop_banners');
 const translationSchema = new schema.Entity('translations');
 const translationListSchema = new schema.Array(translationSchema);

export const productDesignerSchema = new schema.Entity('designers',{
	shipping_general_methods: [shippingGeneralMethodsSchema],
	favorites: [favouritesSchema]
 
});

  //favourites: [favouritesSchema]
/*
	shipping_general_methods: [shippingGeneralMethodsSchema],
	ratings: [ratingsSchema],
	favorites: [favoritesSchema]
}, {
	processStrategy: (value) => ({
		...value,
		shopBanner: value.shop_banner,
		shopCurrency: value.shop_currency,
		translations: value.translations
	})
*/
 
 
// const termSchema = new schema.Entity('terms');

// const termCategoriesSchema = new schema.Entity('term_categories', {
// 	term: termSchema
// });

 
export const shippingZoneSchema = new schema.Entity('shippingZones', {
	countries: [countrySchema]
	
});

export const methodSchema = new schema.Entity('shippingMethodTypes');
 
export const shippingMethodSchema = new schema.Entity('profileZoneMethods', {		//shippingMethods
	 zone: shippingZoneSchema,
	 method: methodSchema
});
 
export const shippingMethodListSchema = new schema.Array(shippingMethodSchema);

export const zoneCostsSchema = new schema.Entity('zoneCosts');

/**
 * ShippingProfile schema
 */
export const shippingProfileSchema = new schema.Entity('shippingProfiles',{
	 zonecosts : [zoneCostsSchema],
	 methods : [shippingMethodSchema]
	
});

//alternate shipping profile schema for products (no methods)
export const shippingProfileProductSchema = new schema.Entity('shippingProfiles',{
	zonecosts : [zoneCostsSchema] 
});

/* 
* productSchema
* 
*/
export const productSchema = new schema.Entity('products', {
	translations: [translationSchema],
	// attributes: [attributeSchema],
		
	term_attributes:[termAttributeSchema],
	term_categories: [termCategorySchema],
		// designer: productDesignerSchema,
	shipping_profiles: [shippingProfileProductSchema],
});
  
//specifically for cart
export const cartItemSchema =  new schema.Entity('items', {
	translations: [translationSchema], 
	term_attributes:[termAttributeSchema],
	term_categories: [termCategorySchema],
	shippingProfiles: [shippingProfileSchema],

});
  

/*
 {
	processStrategy: (value) => ({
		...value,
		currentPrice: value.current_price,
		featuredImage: value.featured_image,
		termAttributes: value.term_attributes,
	}),
*/
export const productListSchema = new schema.Array(productSchema);
 
//product entities
export const allProductsEntities = {
	products : {
		translations: translationListSchema,
		term_attributes:[termAttributeSchema],
		term_categories: [termCategorySchema],
	}
}
//???
// productDesignerSchema.define({
// 	products: productListSchema,
// });


/**
 * Designer schema
 */
export const designerSchema = new schema.Entity('designers', {
	products: [productSchema],
	shipping: shippingConditionsListSchema,
}, {
	processStrategy: (value) => ({
		...value,
		shopBanner: value.shop_banner,
	})
});
 
/**
 * Designers schema
 */
export const designersSchema = new schema.Array([designerSchema]);


//method
//supplier
export const shippingZoneListSchema = new schema.Array(shippingZoneSchema);
  


 

  

export const shippingProfileListSchema = new schema.Array(shippingProfileSchema);

/**
 * ShippingConditions schema
 */
export const shippingConditionsSchema = new schema.Entity('shippingConditions');
export const shippingConditionsListSchema = new schema.Array(shippingConditionsSchema);

/**
 * 
 */
 
 
/**
 * OrderLines schema
 */
export const orderLineSchema = new schema.Entity('orderLines', {
	product: productSchema,
}, {
	processStrategy: (value) => ({
		...value,
		order: value.order_id,
	}),
});

export const orderLineListSchema = new schema.Array(orderLineSchema);

/**
 * Orders schema
 */
export const orderSchema = new schema.Entity('orders', {
	customer: userSchema,
	designer: designerSchema,
	lines: orderLineListSchema,
	currency: currencySchema,
}, {
	processStrategy: (value) => ({
		...value,
		date: value.created_at,
		customer: value.user,
	}),
});

export const orderListSchema = new schema.Array(orderSchema);

// Define order for orderLine
orderLineSchema.define({
	order: orderSchema,
});

export default {
	designerSchema,
	productListSchema,
};
