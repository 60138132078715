import React from 'react';
import PropTypes from 'prop-types';
import Image from 'components/Image';
import { Link } from 'react-router-dom';
import MessageButton from 'components/MessageButton';
import Row from 'components/Row';
import Col from 'components/Col';

const Customer = props => {
	return (
		<Row>
			<Col xs={4}>
				<Image src={props.avatar} />
			</Col>
			<Col xs={8}>
				<div>
					{props.name}
				</div>
				<div>
					<MessageButton /> Contact this customer
				</div>
			</Col>
		</Row>
	);
};

Customer.propTypes = {
	avatar: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
};

export default Customer;
