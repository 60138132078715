import React from 'react';
import Collection from './Collection';
import styled from 'styled-components';

const Container = styled.div`
	padding: 1em;
`;

const Collections = () => {
	return(
		<div className="row">
			<div className="cx6">
				<Container>
					<Collection />
				</Container>
			</div>
			<div className="cx6">
				<Container>
					<Collection />
				</Container>
			</div>
		</div>
	);
};

export default Collections;