import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { actions } from './actions';
import CreateEditShippingMethodsForm from '../components/CreateEditShippingMethodsForm';
import CircularProgress from '@material-ui/core/CircularProgress'
import {cl, notObjectOrNotEmptyObject} from 'utils'
/*	
	CreateEditShippingGeneral : Both CREATE and edit
	
	see reducer createEditShippingGeneral

	Fetches general shipping methods:
	Api function: 	fetchShippingGeneral
	Route: 			/api/designer/' + designerId + '/shipping/general
	function: 		getDesignerShippingGeneral

	Posts to: 
	Api function: 	updateShippingGeneral:  
	Route: 			/api/designer/' + designerId + '/shipping/general'
	function: 		postDesignerShippingProfileMethods
*/
class CreateEditShippingGeneral extends Component {

	constructor(props) {
		super(props);

		this.state = { 
			requestError: null,
				
		}
		this.handleSubmit = this.handleSubmit.bind(this);
		
	}

	componentWillMount(){
		//(already fetched in parent)
		// this.props.fetchShippingGeneral(this.props.designerId);
	}

	componentDidUpdate(){
		cl('componentDidUpdate');
	}
	handleSubmit(data) {
		console.log('handleSubmit');
 
		// console.log(JSON.stringify(data));
	 
		if(!this.props.profileId){
			console.log('profileId is NOT set');

			this.setState({
				requestError : "Error - profile id could not be retrieved."
			})
		 
		}else{
			//add profile id and send request
			console.log('profileId is set');

			data.profileId = this.props.profileId;
			//add 'default' : 1 to indicate default profile
			data.default = 1;
			const payload = Object.assign({}, data); 
			 
			this.props.updateShippingGeneral(this.props.designerId, payload);

			// this.props.onSubmit();		//onödig?

		}
		
	}

	render() {

		//passed to child component to distinguish the profile type
		const profileType = 'default';

		if(this.props.requestStatus === 'pending'){
			return (
				<div className="mui-loader"><CircularProgress /></div>
			);
		}
		// if( notObjectOrNotEmptyObject(this.props.shippingZones)){
		// 	return <p>No shipping zones - create a shipping zone first!</p>
		// }
		return (
			<div>
				<div className="row cx12">
					<h2 className="cx12">{this.props.tr.default_profile || 'Default shipping profile'}</h2>
					<h4 className="cx12">{this.props.tr.create_edit_default_methods || 'Create or edit your shipping methods'}</h4>
				</div>
				<CreateEditShippingMethodsForm
					profileType={profileType}
					tr={this.props.tr}
					initialValues={this.props.initialValues} 
					profileId = {this.props.profileId}
					isLoading={this.props.isLoading}
					status={this.props.status}
					onSubmit={this.handleSubmit}
					shippingMethods={window.d_store.shipping_methods}
					shippingSuppliers={window.d_store.shipping_suppliers}
					shippingZones={this.props.shippingZones}
					requestError={this.props.requestError}
					errorMsg={this.props.errorMsg}
				/>
			</div>
		);
	}

}

CreateEditShippingGeneral.propTypes = {
	onSubmit: PropTypes.func.isRequired,
	designerId: PropTypes.number.isRequired,
};

CreateEditShippingGeneral = connect(
	state => ({
		initialValues: state.createEditShippingGeneral.initialValues,
		profileId:  state.createEditShippingGeneral.profileId || undefined,
		isLoading: state.createEditShippingGeneral.isLoading,
		status: state.createEditShippingGeneral.status,
		requestStatus: state.createEditShippingGeneral.requestStatus,
		designerId: state.session.designerId || null, 
		shippingZones: state.data.shippingProfiles.shippingZones.shippingZones,
		errorMsg: state.createEditShippingGeneral.errorMsg,
	}),
	{
		fetchShippingGeneral: actions.fetchShippingGeneral,
		updateShippingGeneral: actions.updateShippingGeneral,
	}
)(CreateEditShippingGeneral);

export default CreateEditShippingGeneral;
