export const TOGGLE_SEARCH_FIELD = 'TOGGLE_SEARCH_FIELD';

/**
 * Cart actions
 */
export const CART_OPEN = 'CART_OPEN';
export const CART_CLOSE = 'CART_CLOSE';


/**
 * Language actions
 */

export const MODAL_OPEN_LOGIN = 'MODAL_OPEN_LOGIN';
export const MODAL_CLOSE_LOGIN = 'MODAL_CLOSE_LOGIN';

/**
 * User actions
 */

export const USER_SIGN_OUT = 'USER_SIGN_OUT';