import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
//import Button from 'material-ui-next/Button';
import Button from '@material-ui/core/Button';

const DaylicacyDialog = (props) => {
	const {
		children,
		open,
		title,
		onCancel,
		onDone,
	} = props;

	const actions = [];
  

    
	if(onCancel) {
		actions.push(
			<Button
				label="Cancel"
				onClick={onCancel}
			/>
		);
	}

	if(onDone) {
		actions.push(
			<Button
				label="Done"
				onClick={onDone}
			/>
		);
	}
	
	return(
		<Dialog
			open={open}
			title={title}
			// autoScrollBodyContent={true}
			// autoDetectWindowHeight={false}
			className="dc-dialog"
			// bodyClassName="dc-dialog__body"
			// titleClassName="dc-dialog__title"
			// overlayClassName="dc-dialog__overlay"
			// contentClassName="dc-dialog__content"
			actions={actions}
			
			{...props}
		>
			{ children }
		</Dialog>
	);
};

DaylicacyDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	onCancel: PropTypes.func,
	onDone: PropTypes.func,
};

export default DaylicacyDialog;