import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
 
import Tabs from 'components/DaylicacyTabs';
import shoptabs from 'data/shoptabs';
import Creations from './Creations';
import ShopSetup from './ShopSetup';
import Collections from './Collections';
import ShopProfile from './components/ShopProfile';
import Imprint from './Imprint';
import Faq from './Faq';
import TermsAndConditions from './TermsAndConditions';
import Shipping from './Shipping';
import Leave from './components/Leave';
import Sales from './Sales';
import Advertise from './components/Advertise';

class MyShop extends Component {

	constructor(props) {
		super(props);

		this.state = {
			selectedTab: "shop-setup"
		}
	
	}

	componentWillMount(){
		//selected
	}
 
	handleTabClick = (event, value) => {
		 //event, value
		// alert('handleTabClick: value is' + value);
		this.setState({ selectedTab: value});
		const { history, match } = this.props;
		if(value === 'shop-setup') {
			history.push(match.url);
		} else {
			history.push(`${ match.url }/${ value }`);
		}
	}
 
	 

	render() {
		const { tr, match } = this.props; 
		 

		return(
			
			<div className="my-shop myshop_tabs">
				
				<Tabs 
					
					tabs={shoptabs}  
					onChange={this.handleTabClick}
					theme="rounded"
					elevation={0}
					selectedTab={this.state.selectedTab}
				/>
				<Switch>
				
					<Route exact path={`${match.url}`} render = { (props) => (
                      	<ShopSetup tr={tr} {...props} 
					/>
                    )}/>
					<Route  
						path={`${ match.url }/creations`} render = { (props) => (
							 
							 <Creations tr={tr} {...props}  />
					)}/>
					<Route  
						path={`${match.url}/collections`} render = { (props) => (
							<Collections tr={tr} {...props} 
							/>
					)}/>
					<Route 
						path={`${match.url}/shop-profile`} render = { (props) => (
							<ShopProfile tr={tr} {...props} 
							/>
					)}/>	
					<Route  
						path={`${match.url}/imprint`} render = { (props) => (
							<Imprint tr={tr} {...props} 
							/>
					)}/>	
					<Route  
						path={`${match.url}/faq`} render = { (props) => (
							<Faq tr={tr} {...props} 
							/>
					)}/>	
					<Route  
						path={`${match.url}/terms-and-conditions`} render = { (props) => (
							<TermsAndConditions tr={tr} {...props} 
							/>
					)}/>		
					<Route  
						path={`${match.url}/payment-and-shipping`} render = { (props) => (
							<Shipping tr={tr} {...props} 
							/>
					)}/>		
					<Route  
						path={`${match.url}/leave`} render = { (props) => (
							<Leave tr={tr} {...props} 
							/>
					)}/>	
					<Route  
						path={`${match.url}/sales`} render = { (props) => (
							<Sales tr={tr} {...props} 
							/>
					)}/>		
					<Route 
						path={`${match.url}/advertise`} render = { (props) => (
							<Advertise tr={tr} {...props} 
						/>
					)}/>		
				</Switch>
			</div>
		);
	}
}

export default MyShop;

 
MyShop.propTypes = {
    tr: PropTypes.object
}


//appPosition="static"
 