import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';
import { arrayMove } from 'react-sortable-hoc';
// import FileUploader from 'containers/FileUpload';
import {reduxFormTest} from 'data/modules/reduxform';

import Dropzone from 'components/Dropzone'; 
import ImageGrid from 'containers/ImageGrid';
import ImageCropper from 'containers/ImageCropper'; 

import {cl} from 'utils';

const maxFileSize = 3000000

const acceptedFileTypes = 'image/x-png, image/png, image/jpg, image/jpeg, image/gif';

const acceptedFileTypesArray = acceptedFileTypes.split(',')
	.map( type => type.trim())

/* FileField info:

		Needs three props from parent: fileFieldName, sortImgsMessage and maxNumFiles.
			(fileFieldName is the filefield name)
		Files are added to redux form state AND local state.
		Because files were not updated in ImageGrid, files were also added to local state as well.
		- Each time files are updated, local state are updated first, then redux form state.

		The files sent down to ImageGrid come from this local state.
		(There were problems, which was solved by modifying "key" prop in map function 
		with reference to both index and file name.) 
		 
*/
class FileField extends Component {

	constructor(props) {
		super(props);

		this.state = {
			files: {
				images: []
			},
			fileFieldError: null,
			rejectedFiles: null,
			rejectedFilesError: null,
			maxFileSize: maxFileSize,
			maxNumFiles: this.props.maxNumFiles,
			sortImgsMessage: this.props.sortImgsMessage,
			acceptedFileTypesArray: acceptedFileTypesArray,
			indicatechangeswitch: 0
		};  
 
		this.handleSortEnd = this.handleSortEnd.bind(this);
		this.handleCrop = this.handleCrop.bind(this); 
	}

	componentWillReceiveProps(nextProps){
	
		//  alert('componentWillReceiveProps: nextProps.input.value.length is ' + nextProps.input.value.length); 
		if(nextProps.input.value.length !== this.state.files.length){
			//  console.log('Filefield componentWillReceiveProps: this.props.input.value is ' + nextProps.input.value)
            this.setState({
				files: nextProps.input.value
			});
		}
		
		// if(nextProps.input.value.length !== this.state.files.length){
		// 	// alert('componentWillReceiveProps: this.props.input.value changed ')
        //     this.setState({
		// 		files: nextProps.input.value
		// 	});
        // }
	}

	componentWillUpdate(){

		
		
		//  alert('FileField componentWill update.  this.state.files length is: ' + this.state.files.length)
	}
	componentDidUpdate(){
		//  alert('FileField update ');

	}


	
	normalizeFiles(files) {
		console.log('normalizeFiles(files)');
		console.log(files);
		 
		return files.map(file => ({
			id: file.name,
			file: file,
			preview: file.preview,
			crop: {},
		}));
	}

	checkRejectedFiles(rejectedFiles){
		cl(rejectedFiles, 'rejectedFiles! ')
		var rejectedFilesErrors = [];

		rejectedFiles.map( file => {
			if(file.size > this.state.maxFileSize){
				cl('fileSize was bigger than max size')
				rejectedFilesErrors.push('This file was too large: ' + file.name);
				
			}else{
				//wrong file type
				cl('fileSize was ok - wrong file type')

				rejectedFilesErrors.push('This file had wrong file type (should be jpg, png, or gif): ' + file.name);
			}
		})

		this.setState({
			fileFieldError: 'File was rejected',
			rejectedFilesErrors: rejectedFilesErrors,
			rejectedFiles: rejectedFiles		
		})
	}


	handleSortEnd({ oldIndex, newIndex }) {
		 
		// const files = input.value || [];
		const files = this.state.files;
		 
		const sortedFiles = arrayMove(files, oldIndex, newIndex);

		//sort local files
		this.setState({
			files: sortedFiles
		},
		//after setState, update redux Form state
		this.props.input.onChange(sortedFiles)
		);
		 
	}

	/* handleDrop:
			- check if any file was rejected - add error
			- change structure of file data (normalize)
			- update local state with file data (state data pass down to ImageGrid who display the product)
			- update redux form state with file data
	*/
	handleDrop = (acceptedFiles, rejectedFiles) => { 
		  
		// alert('handleDrop')
		const {maxNumFiles} = this.state;

		console.log('handleDrop - max file size is ' + this.state.maxFileSize)
		if(rejectedFiles.length){
			cl(rejectedFiles, 'rejectedFiles');
			
			return this.checkRejectedFiles(rejectedFiles);
			 
		}

		const normalizedFiles = this.normalizeFiles(acceptedFiles);
		
		//check what total number of files would be (should be less than maxNumFiles):
		let updatedFiles = this.props.input.value;
		// alert('updatedFiles');
		//  alert(JSON.stringify(JSON.stringify(updatedFiles)));
	 
		if(this.props.input.value.length + normalizedFiles.length > maxNumFiles){
			this.setState({
				fileFieldError: 'Max number of files is ' + maxNumFiles + '!'
			});
			return false;
		}
	
		//merge existing files with new ones  
		updatedFiles = [
			...this.props.input.value,
			...normalizedFiles
		];
	
		//update local state's files
		//  alert('handleDrop: abut to call setstate')
		this.setState({
				files: updatedFiles,
				fileFieldError:null,
				rejectedFilesErrors:null,
				rejectedFiles:null
			},
			//after setState, update redux Form state
			this.props.input.onChange(updatedFiles)
		);
		 
		 
	}

	//update file in redux form + local state
	handleCrop(fileKey, preview, crop) {
		console.log('image index (fileKey) is ' + fileKey)
		cl(crop, 'cropdata: ')
		// The input prop is automatically injected to FileField by Redux Form on Field component (CreationForm)
		const { input } = this.props;
		const files = input.value;
		
		//find image in array, update with the new crop data
		let match = false;
		const filesCopy = files;

		filesCopy[fileKey] = {
			...filesCopy[fileKey],
			preview,
			crop
		} 
		cl(filesCopy, 'updated files to state')
		//update local state first
		
		let updatedFile = this.state.files[fileKey];
		updatedFile.preview = preview,
		updatedFile.crop = crop
		let updatedStateFiles = this.state.files

		updatedStateFiles[fileKey] = updatedFile;

		cl(updatedStateFiles, 'updated files to local state')
		this.setState({
				files: updatedStateFiles ,
				indicatechangeswitch: this.state.indicatechangeswitch == 1 ? 0 : 1
		},
			//after setState, update redux Form state
			input.onChange(filesCopy) 
		);
		 
	}

	//Takes the index of the image object to be removed
	handleRemoveImage = (imgObjIndex) => (e) => {

		// alert('handleRemoveImage, imgObjIndex ' + imgObjIndex)
		/*
			1. Removes the index from the images array
			2. updates local state with setState
			3. Updates redux form state with onChange (in setState callback)
	
		*/
		// alert('to remove index' + imgObjIndex + ' , onChange will be run');
	
		let formValueImages = this.props.input.value; 
	
	   //  alert(JSON.stringify(this.props.fields.images));
	
		
	   let newFileArray = formValueImages;
	
	   newFileArray.splice(imgObjIndex, 1);
	
	   cl(newFileArray, 'newFileArray: ');
		  
	//update local state's files
		 
	// alert('because indicatechangeswitch is ' + this.state.indicatechangeswitch 
	// 			+ ', it will be set to ' + !this.state.indicatechangeswitch)
		this.setState({
				files: newFileArray,
				fileFieldError:null,
				indicatechangeswitch: this.state.indicatechangeswitch == 1 ?  0 :  1
			},
			//after setState, update redux Form state
			this.props.input.onChange(newFileArray)
		);
 
	   // alert('length after update: ')
	   // alert(newFileArray.length);
	   //   alert(JSON.stringify(newFileArray));
	   //save
	
	
	}

	 
	render() {
		const { formImages, fileFieldName, input, name, change, fields, dispatch,  } = this.props;

		const {files,sortImgsMessage,maxNumFiles} = this.state;

		const filesAdded = files.length ? true : false;

		const dropZoneErrorMessage="Max size: " + this.state.maxFileSize/1000 + "kb";

		if(!files){
			<p>no imgs</p>
		}
		return(
				<div>
					 
					 {files &&
					(<p style={{color: 'blue'}}>
						{/* files are set and {JSON.stringify(files[1])} */}
					</p>)
					} 
					<Dropzone
						fileFieldError={this.state.fileFieldError}
						name={name}
						onDrop={this.handleDrop}
						maxNumFiles={maxNumFiles}
						maxFileSize={this.state.maxFileSize}
						acceptedFileTypesArray={this.state.acceptedFileTypesArray}
						rejectedFilesErrors={this.state.rejectedFilesErrors}
						files={this.state.files} 
						dropZoneErrorMessage={dropZoneErrorMessage}
					/>
					{fileFieldName && 
						<div>
							<ImageGrid
								handleRemoveImage={this.handleRemoveImage}
								onSortEnd={this.handleSortEnd}
								files={this.state.files}  
								indicatechangeswitch={this.state.indicatechangeswitch}
								rejectedFiles={this.state.rejectedFiles}
							/> 
							<ImageCropper
								onClose={this.handleClose}
								onCrop={this.handleCrop}
							/>
						</div>
					}
					{filesAdded && sortImgsMessage &&
						<div className="dropzone-text">
							<span dangerouslySetInnerHTML={{__html: sortImgsMessage}} />
						</div>
					}
						{/* <div>new content:</div>
					{ files.map((file, i) => 
						<img key={i} src={file.preview} style={{maxWidth: '150px'}}/>
					)} */}

			</div>
		
		);
	}
}
{/* <FileUploader 
// error={this.state.error}
name={input.name}
// onDrop={this.handleDrop}
// handleRemoveImage={this.handleRemoveImage}
// formImages={formImages}
onSortEnd={this.handleSortEnd}
onCrop={this.handleCrop}
dropZoneMessage={dropZoneMessage}
/> */}

FileField.propTypes = { 
	input: PropTypes.object.isRequired,
	name: PropTypes.string,
	onSortEnd: PropTypes.func,
	onCrop: PropTypes.func,
};
 
//connect to the form to be able to get form values
FileField = reduxForm( {
	form: 'createProductForm'
} )( FileField )

const selector = formValueSelector( 'createProductForm' ) 

FileField = connect(
	(state, ownProps) => { 
		return { 
			hello: "ok",
			formImages: selector(state, ownProps.fileFieldName),	
		},
		{reduxFormTest}
	}
)( FileField )


export default FileField;
