import { createActionTypes, createActions } from 'utils';

const types = createActionTypes([
	'FETCH_SHIPPING_PROFILE',
	'FETCH_SHIPPING_PROFILE_SUCCESS',
	'FETCH_SHIPPING_PROFILE_FAILURE',
	'CREATE_SHIPPING_PROFILE',
	'CREATE_SHIPPING_PROFILE_SUCCESS',
	'CREATE_SHIPPING_PROFILE_FAILURE',
	'UPDATE_SHIPPING_PROFILE',
	'UPDATE_SHIPPING_PROFILE_SUCCESS',
	'UPDATE_SHIPPING_PROFILE_FAILURE',
	'FETCH_PRODUCTS',
	'FETCH_PRODUCTS_SUCCESS',
	'FETCH_PRODUCTS_FAILURE',
]);

const actions = createActions({
	[types.FETCH_SHIPPING_PROFILE]: (shippingProfileId) => ({ shippingProfileId }),
	[types.FETCH_SHIPPING_PROFILE_SUCCESS]: result => ({ result }),
	[types.FETCH_SHIPPING_PROFILE_FAILURE]: error => ({ error }),
	[types.CREATE_SHIPPING_PROFILE]: (formData) => ({ formData }),
	[types.CREATE_SHIPPING_PROFILE_SUCCESS]: result => ({ result }),
	[types.CREATE_SHIPPING_PROFILE_FAILURE]: error => ({ error }),
	[types.UPDATE_SHIPPING_PROFILE]: (shippingProfileId, formData) => ({ shippingProfileId, formData }),
	[types.UPDATE_SHIPPING_PROFILE_SUCCESS]: result => ({ result }),
	[types.UPDATE_SHIPPING_PROFILE_FAILURE]: error => ({ error }),
	[types.FETCH_PRODUCTS]: (designerId) => ({ designerId }),
	[types.FETCH_PRODUCTS_SUCCESS]: result => ({ result }),
	[types.FETCH_PRODUCTS_FAILURE]: error => ({ error }),
});

export { types, actions };
