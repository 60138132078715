import React, { Component } from 'react';
import { connect } from 'react-redux';
//import { requestSignI } from './actionCreators'
import Dialog from 'components/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import DaylicacyButton from 'components/DaylicacyButton';
import LoginForm from 'containers/LoginForm';
import Divider from 'components/Divider';
import SignUpForm from 'components/SignUpForm';
import Col from 'components/Col';

class SignInModal extends Component {

	constructor() {
		super();

		this.state = {
			open: false,
            signUpDialogOpen: false
		};

		//this.handleSubmit = this.handleSubmit.bind( this )
		this.handleRequestClose = this.handleRequestClose.bind(this);
		this.handleClick = this.handleClick.bind(this);
        this.handleSignUpClick = this.handleSignUpClick.bind(this);
        this.handleSignUpClose = this.handleSignUpClose.bind(this);
	}

    componentDidMount() {
        this.setState({open : this.props.email ? true : false } );
    }

	// handleSubmit( values ){
	// 	const { signInAction } = this.props
	// 	const { email, password } = values
	// 	signInAction( email, password )
	// }

	handleRequestClose() {
		this.setState({
			open: false,
		});
	}

	handleClick() {
		this.setState({
			open: true,
		});
	}

    handleSignUpClick() {
        this.setState({
            signUpDialogOpen: true,
        });
    }

    handleSignUpClose() {
        this.setState({
            signUpDialogOpen: false,
        });
    }

	render() {
		const { errorMsg, label, tr } = this.props;

		return(
			<div>
				<DaylicacyButton
					id="sign_in"
					onClick={this.handleClick}
				>
					{tr.login}
				</DaylicacyButton>
				<Dialog 
					 aria-labelledby="login-dialog"
					open={this.state.open} 
					onClose={this.handleRequestClose}
                    width="550px"
				>
				 	<DialogTitle >{tr.login}</DialogTitle>

					<DialogContent id="login-dialog" className="mui-dialog login-dialog" style={{ paddingTop: '16px' }}>
							<div className="cx12">
								<LoginForm tr={tr} errorMsg={errorMsg} email={this.email} />
								{ /* <Divider /> */ }
								{/*<ConnectWithFacebook />*/}
								
								<div className="text-center">
									<a href="#">
										{tr.here_to_join_text}
									</a>
									
								</div>
							</div>
					</DialogContent>
				
				</Dialog>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	errorMsg: state.session.errorMsg,
	tr: state.translations,
	auto: state.auto,
	email: new URLSearchParams(window.location.search).get('email')
});

SignInModal = connect(
	mapStateToProps
)(SignInModal);

export default SignInModal;
