import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Editor, EditorState, RichUtils } from 'draft-js';
import Tabs from 'components/DaylicacyTabs';
import Paper from 'material-ui-old/Paper';

class RichTextEditor extends Component {

	constructor(props) {
		super(props);

		this.state = {
			editorState: EditorState.createEmpty(),
		};

		this.handleChange = (editorState) => this.setState({editorState});
		this.handleKeyCommand = this.handleKeyCommand.bind(this);
	}

	handleKeyCommand(command) {
		const newState = RichUtils.handleKeyCommand(
			this.state.editorState, command);

		if (newState) {
			this.handleChange(newState);
			
			return 'handled';
		}

		return 'not-handled';
	}

	render() {

		const tabs = this.props.languages 
			? this.props.languages.map((language) => 
				({ label: language.toUpperCase() }))
			: null;

		return (
			<Paper style={{
				marginBottom: '1em',
			}}>
				<Tabs
					tabs={tabs} />
				<div style={{
					padding: '1em',
				}}>
					<div style={{
						borderBottom: '1px solid #ccc',
						padding: '0.25em 0.125em',
					}}>
						<Editor 
							handleKeyCommand={this.handleKeyCommand}
							editorState={this.state.editorState}
							onChange={this.handleChange} />
						</div>
					</div>
				</Paper>
		);
	}

}

RichTextEditor.propTypes = {
	languages: PropTypes.array,
};

export default RichTextEditor;