import { combineReducers } from 'redux';
import { normalize } from 'normalizr';
import { types } from './actions';
import { productListSchema, designerSchema, } from './schemas';
import countries, { STATE_KEY as COUNTRIES_STATE_KEY } from './modules/countries';
import currencies, { STATE_KEY as CURRENCIES_STATE_KEY } from './modules/currencies';
import products, { STATE_KEY as PRODUCTS_STATE_KEY } from './modules/products';
import profiles, { STATE_KEY as PROFILE_STATE_KEY } from './modules/profiles';
import users, { STATE_KEY as USERS_STATE_KEY } from './modules/users';
import designers, { STATE_KEY as DESIGNERS_STATE_KEY } from './modules/designers';
import orders, { STATE_KEY as ORDERS_STATE_KEY } from './modules/orders';
import orderLines, { STATE_KEY as ORDER_LINES_STATE_KEY } from './modules/orderLines';
import shippingProfiles, { STATE_KEY as SHIPPING_PROFILES_STATE_KEY } from './modules/shippingProfiles';
// import shippingConditions, { STATE_KEY as SHIPPING_CONDITIONS_STATE_KEY } from './modules/shippingConditions';
// import shippingZones, { STATE_KEY as SHIPPING_ZONES_STATE_KEY } from './modules/shippingZones';
// import shippingMethods, { STATE_KEY as SHIPPING_METHODS_STATE_KEY } from './modules/shippingMethods';
// import shippingSuppliers, { STATE_KEY as SHIPPING_SUPPLIERS_STATE_KEY } from './modules/shippingSuppliers';

import terms, { STATE_KEY as TERMS_STATE_KEY } from './modules/terms';
import checkout, { STATE_KEY as CHECKOUT_STATE_KEY } from './modules/checkout';
import messages from './modules/messages';


//state.data
const initialState = {
	products: {
		byId: {},
		allIds: [],
	},
	images: {},
	translations: {},
	designers: {},
};

/**
 * Entity reducer
 */

const entity = (state, action) => {
	switch (action.type) {
		case 'products':
			const { payload } = action;
			console.log('processing products entity');
			console.log(action);

			return Object.assign({}, {
				byId: Object.assign({}, state.byId, payload.entity),
				allIds: [ 
					...state.allIds, 
					...payload.ids.filter(id => !state.allIds.includes(id))
				]
			});
		default:
			return state;
	}
};

/**
 * Data reducer
 */

const data = (state = initialState, action) => {
	let normalized;
	const newState = {};
	const { payload } = action;

	switch (action.type) {
		case types.REQUEST_DATA_SUCCESS:

			switch (payload.type) {
				case 'products':
					normalized = normalize(payload.data.data, productListSchema);
					break;
				case 'designer':
					normalized = normalize(payload.data, designerSchema);
					break;
				default:
					throw new Error('The data type you specified doesn\'t exist');
			}

			for (let key in normalized.entities) {
				newState[key] = entity(
					state[key], 
					{ 
						type: key,
						payload: {
							entity: normalized.entities[key],
							ids: normalized.result,
						}
					}
				);
			}

			return newState;

			// return Object.assign({}, state, {
			// 	products: productsReducer(state.products, { 
			// 		type: 'ADD_REPLACE_PRODUCTS',
			// 		payload: { products: entities.products, ids: result }
			// 	})
			// })
			break;
 
		default:
			return state;

		case types.FETCHING:
 
			switch (action.stateKey) {

				case 'shippingZones':

					return Object.assign({}, state.shippingZones, {
						fetching: false
					});
					break;

				default:
					throw new Error('Data type not available');
			}

		case types.STOPPED_FETCHING:

			switch (action.stateKey) {

				case 'shippingZones':

					return Object.assign({}, state.shippingZones, {
						fetching: false
					});
					break;

				default:
					throw new Error('Data type not available');
			}
	}
};

export default combineReducers({
	[COUNTRIES_STATE_KEY]: countries,
	[CURRENCIES_STATE_KEY]: currencies,
	[PRODUCTS_STATE_KEY]: products,
	[PROFILE_STATE_KEY]: profiles,
	[USERS_STATE_KEY]: users,
	[DESIGNERS_STATE_KEY]: designers,
	[ORDERS_STATE_KEY]: orders,
	[ORDER_LINES_STATE_KEY]: orderLines,
	[SHIPPING_PROFILES_STATE_KEY]: shippingProfiles,
	// [SHIPPING_CONDITIONS_STATE_KEY]: shippingConditions,
	// [SHIPPING_ZONES_STATE_KEY]: shippingZones,
	// [SHIPPING_METHODS_STATE_KEY]: shippingMethods,
	// [SHIPPING_SUPPLIERS_STATE_KEY]: shippingSuppliers,
	[TERMS_STATE_KEY]: terms,
	[CHECKOUT_STATE_KEY]: checkout,
	messages: messages
});


// case types.REQUEST_PRODUCTS_SUCCESS:
// 	const { 
// 		current_page, 
// 		data, 
// 		next_page_url, 
// 		total, 
// 		per_page,
// 		last_page,
// 	} = action.payload.products.data

// 	return Object.assign({}, state, {
// 		currentPage: current_page,
// 		products: data,
// 		nextPageUrl: next_page_url,
// 		totalCreations: total,
// 		perPage: per_page,
// 		numPages: last_page,
// 		isLoading: false,
// 	})
