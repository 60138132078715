import { call, put, takeLatest } from 'redux-saga/effects';
import { types, actions } from './actions';
import api from 'data/api';

import {addShippingProfile} from 'data/modules/shippingProfiles';
import {shippingProfileSchema} from 'data/schemas';
import {cl} from 'utils'
import { normalize } from 'normalizr';

function* fetchShippingGeneral(action) {
	const { designerId } = action.payload;

	try {
		yield put(actions.fetching('createEditShippingGeneral'));
		const result = yield call(api.fetchShippingGeneral, designerId);
		
		//Adapt the result (data.methods) to initial values for the form
		/*
				 methods[${methodRowIndex}][shipping_zone_id]
				`methods[${methodRowIndex}][shipping_method_id]`}
				`methods[${methodRowIndex}][shipping_supplier_id]`}
		*/
		cl(result, 'result:')
		result.data.profiles[0].methods.map( method => {
			cl(' parseInt(method.shipping_zone_id): ' +  parseInt(method.shipping_zone_id))

			// set any shipping_zone_id which is null to 0
			if(method.shipping_zone_id != 0 && !method.shipping_zone_id){
				cl('method id ' + method.id + ', zone id was null/undefined, changing method.shipping_zone_id to 0')
				method.shipping_zone_id = 0;
			}else{
				method.shipping_zone_id = parseInt(method.shipping_zone_id);
			} 

		})

  
		yield put(actions.fetchShippingGeneralSuccess(result));


	} catch(error) {
		yield put(actions.fetchShippingGeneralFailure(error));
	}
}

//to create/update shipping methods for default shipping profile
function* updateShippingGeneral(action) {
	const { designerId, formData } = action.payload;

	cl('Editshippinggeneral saga: update shipping general:');

	try{
		//backend function:  postDesignerShippingProfileMethods
		//the result contains the designers' shipping methods
		const result = yield call(api.updateShippingGeneral, designerId, formData);
		cl('result.data:');
		cl(result.data);
 
		//Add methods to initialvalues in state.editShippinggeneral
		yield put(actions.updateShippingGeneralSuccess(result.data));

		cl('result.data.shippingprofile: ')
		cl(result.data.shippingprofile);
 
		// set any shipping_zone_id which is null to 0
		result.data.shippingprofile.methods.map( method => { 
			
			if(method.shipping_zone_id != 0 && !method.shipping_zone_id){
				cl('method id ' + method.id + ', zone id was null/undefined, changing method.shipping_zone_id to 0')
				method.shipping_zone_id = 0;
			}else{
				method.shipping_zone_id = parseInt(method.shipping_zone_id);
			}
		})
		 
		//state.data.shippingProfiles also needs to be updated, with normalized data
		const data = normalize(result.data.shippingprofile, shippingProfileSchema);
		cl( 'updateshippingGeneral, normalized: ');
		cl(data);

		//if any shipping_zone_id is null, change to 0
		// Object.values(data.entities.profileZoneMethods).map( method => {
		// 	cl(method, 'method: ')
		// 	if(method.shipping_zone_id == null ){
		// 		method.shipping_zone_id = 0;
		// 	}
		// })

		yield put(addShippingProfile(data.entities));
		
		
	} catch(error) {
		cl(error,  'saga error: ')
		yield put(actions.updateShippingGeneralFailure(error));
	}
}

export default function* listenerSaga() {
	yield takeLatest(types.FETCH_SHIPPING_GENERAL, fetchShippingGeneral);
	yield takeLatest(types.UPDATE_SHIPPING_GENERAL, updateShippingGeneral);
}
