import React from 'react';
import { connect } from 'react-redux';
import { objectToFormData } from './utils';
import ShopSetupForm from './components/ShopSetupForm';
import {
	updateDesigner,
	fetchDesigner,
} from './actionCreators';

class ShopSetup extends React.Component {

	constructor(props) {
		super(props);
 
	}

	componentDidMount() {
		//this.props.fetchDesigner(null);
	}


	render() {
		return(
			<section className="section shopsetupform">
				<div className="content content--padded">
				<ShopSetupForm
					// initialValues={this.props.initialValues}
					tr={this.props.tr} 
					errorMsg={this.props.errorMsg}
					status={this.props.status}
					isLoading={this.props.isLoading}
					// user={this.props.user}
				/> 
				</div>
			</section>
		);
	}

}

const mapStateToProps = state => ({
	// errorMsg: state.shopSetup.errorMsg,
	// status: state.shopSetup.status,
	// isLoading: state.shopSetup.isLoading,
	// initialValues: state.shopSetup.initialValues,
	// userId: state.session.userId,
	// user: state.session.user ? state.session.user : null
});

export default connect(
	mapStateToProps,
	{ updateDesigner, fetchDesigner }
)(ShopSetup);

