import React from 'react';

// Material UI
import Popover from 'material-ui-old/Popover';

const FooterPopover =({
	open,
	anchorEl,
	children,
	width,
}) => {
	return(
		<Popover 
			className="popover"
			open={open}
			anchorEl={anchorEl}
			useLayerForClickAway={false}
			animated={true}
			style={{ padding: '20px', width, transition: '' }}
			anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
			targetOrigin={{ horizontal: 'left', vertical: 'bottom' }}
		>
			{ children }
		</Popover>
	);
};

export default FooterPopover;