import * as types from './actionTypes';
import {cl} from 'utils';

const initialState = {
	view: 'listProducts',
};

const reducer = (state = initialState, action) => {
	switch(action.type) {
		case types.CHANGE_VIEW:

			cl('toolbar reducer: CHANGE_VIEW: ' + action.payload.view)
			return Object.assign({}, state, {
				view: action.payload.view,
			});
		case types.CREATIONS_TOOLBAR_LIST_CLICK:
			return Object.assign({}, state, {
				view: 'listProducts',
			});
		case types.CREATIONS_TOOLBAR_EDIT_CLICK:
			return Object.assign({}, state, {
				view: 'editProduct',
			});
		case types.CREATIONS_TOOLBAR_CREATE_CLICK:
			return Object.assign({}, state, {
				view: 'createProduct',
			});
		default:
			return state;
	}
};

export default reducer;