import { call, put, takeLatest } from 'redux-saga/effects';
import api from 'data/api';
import { normalize, denormalize } from 'normalizr';
import {
	FETCH_DESIGNER_SHIPPING_ZONES,
	ADD_DESIGNER_SHIPPING_ZONES, 
	REQUEST_DATA_FAILURE,
	HANDLE_POSTED_SHIPPING_ZONES

} from 'data/modules/shippingProfiles';

import { 
	addDesignerShippingZones,
	sagaError
} from 'data/modules/shippingProfiles';
	
import { fetching, stoppedFetching} from 'data/actions';
import {shippingZoneListSchema} from 'data/schemas';


// const subject = 'shippingZones';


function* fetchDesignerShippingZones(action) {
	// console.log('shippingZones saga: fetchDesignerShippingZones, action:', action)
	try{
		// yield put(fetching(result));			//result ???
		const result = yield call(api.fetchShippingZones, action.payload.id);
		console.log('listShippingzones : api call successful')
		console.log(result);

		//normalize shippingZones
		const normalizedShippingZones = normalize(result.data, shippingZoneListSchema);
			console.log('normalizedShippingZones');
			console.log(normalizedShippingZones);

		yield put(addDesignerShippingZones(normalizedShippingZones.entities));
		console.log('Shippingzones : addDesignerShippingZones was put')
		 
		yield put(stoppedFetching('fetchShippingZones'));
		console.log('Shippingzones : stoppedFetching was put')
		
		
	} catch (e) {
		console.log('fetchDesignerShippingZones failed', e.message);
		// yield put(requestDataFailure(subject, e, e.response.status));
	}
}

// function* handlePostedShippingZones(action) {
// 	console.log('shippingZones saga: handlePostedShippingZones , action:', action)
// 	try{
// 		//normalize shippingZones
// 		const normalizedShippingZones = normalize( action.payload, shippingZoneListSchema);
// 			console.log('normalizedShippingZones');
// 			console.log(normalizedShippingZones);

// 		yield put(addDesignerShippingZones(normalizedShippingZones.entities));
		 
// 	}catch(e){
// 		console.log('Shippingzones saga error.')
// 		yield put(sagaError(e));
// 	}
// }

export default function* listenerSaga() {
	yield takeLatest(FETCH_DESIGNER_SHIPPING_ZONES, fetchDesignerShippingZones);
	// yield takeLatest(HANDLE_POSTED_SHIPPING_ZONES, handlePostedShippingZones);
}
