import * as types from './actionTypes';

export const requestSignIn = data => ({
	type: types.REQUEST_USER_SIGN_IN,
	payload: { email: data.email, password: data.password }
});

export const requestSignInSuccess = result => ({
	type: types.REQUEST_USER_SIGN_IN_SUCCESS, 
	result
});

export const requestSignInFailed = error => ({ 
	type: types.REQUEST_USER_SIGN_IN_FAILED, 
	error
});
				
export const updateUserSuccess = result => ({ 
	type: types.UPDATE_USER_SUCCESS, 
	result
});
export const updateUserToken = data => ({ 
	type: types.UPDATE_USER_TOKEN, 
	data
});
 
export const updateUserAddressesSuccess = result => ({ 
	type: types.UPDATE_USER_ADDRESSES_SUCCESS, 
	result
});
export const changeLanguage = language => ({
	type: types.LANGUAGE_CHANGE,
	language
});

export const changeCountry = (countryId, countryName, countryCode) => ({
    type: types.COUNTRY_CHANGE,
    payload: {countryId, countryName,countryCode}
});

export const requestData = params => ({
    type: types.REQUEST_DATA,
    payload: params
});

export const changeCurrency = currency => ({
    type: types.CURRENCY_CHANGE,
    currency
});

export const userBecameDesigner = designerId => ({
	type: types.USER_BECAME_DESIGNER,
	designerId,
});


export const getCountryFromIp = () => ({
	type: types.CHECK_COUNTRY
})

export const setLanguagePopoverConfirmed = () => ({
	type: types.SET_LANGUAGE_POPOVER_CONFIRMED
})
