import React, { Component } from 'react';
import { connect } from 'react-redux';

import List from '@material-ui/core/List';
import SelectMenuItem from './SelectMenuItem'; 
import { addShopFilter, removeShopFilter, removeShopFilters} from 'containers/Filters/actionCreators';
import { addChip, removeChip } from 'containers/FilterChipsBar/actions';
import Button from '@material-ui/core/Button';
import { getFilters } from '../selectors';

import {cl} from 'utils';
import {withRouter} from 'react-router'

class FilterMenu extends Component {

	constructor(props) {
		super(props);

		/* selectedFilters:
			Stores menu item selections within each menu .
			Menus are stored on their id. Levels are normalized to flat structure 
		*/
		this.state = {
			
		}
		
		this.onToggleFilter = this.onToggleFilter.bind(this);
		this.renderFilter = this.renderFilter.bind(this);
	}

	//add filter to selectedFilters in local state  		//filters.terms in state
	onToggleFilter(filter, checked, level,parentFilterId, parentChecked) { 
		
		//  alert('filter name is ' + filter.model.name + ', selector is ' + filter.selector + ' id is ' + filter.model.id)
		  
		// alert('onToggleFilter, filter model is ' + filter.model.id + ', and level is ' + level); 
		// cl(searchFilters, 'searchFilters: '); 

		const {selectedFilters} = this.state
	
		//add filter + remove parent filter if set + remove children if set
		if (!checked) {  	  

			this.props.addShopFilter({
					id: filter.model.id,
					name: filter.model.name,
					// selector: filter.selector
				});

			//remove parent checkbox
			if(level >= 2 && parentChecked){
				this.props.removeShopFilter(parentFilterId);
			}
			//remove children checkboxes
			if(filter.children.length){ 

				//collect any checked children ids - to be removed
				var checkedTermIds = [];
				filter.children.map((childFilter, index) => {
					if( childFilter.model.id in this.props.filters[childFilter.selector]){
						cl('A checked child was found - ' + childFilter.model.name)
						checkedTermIds.push(childFilter.model.id);
						if(childFilter.children.length){ 
							childFilter.children.map((grandChildFilter, index) => {
								if( grandChildFilter.model.id in this.props.filters[grandChildFilter.selector]){
									cl('Checked grandchild found - ' + childFilter.model.name)
									checkedTermIds.push(grandChildFilter.model.id);
									 
								}
							})
						}
					}
				})
				if(checkedTermIds.length){

					//remove possible duplicates 
					checkedTermIds = checkedTermIds.filter((id, index, self) => 
						self.indexOf(id) === index
					);
					 
					cl(checkedTermIds, 'checkedTermIds: ')
					this.props.removeShopFilters(checkedTermIds);
				}

			} 
				
			
		} else { 

			this.props.removeShopFilter(filter.model.id);
			// this.props.removeChip(filter.model.id);
		}

	}

	//recursive rendering of menuitems and submenus
	renderFilter(filter, index, level, parentFilterId, parentChecked = false) {
		level+=1;
		//  console.log('current filter name is ' + filter.model.name + ', id is' + filter.model.id 
		//  	+ ', parent Filter Name is ' + parentFilterId)
		  
		
		const root = 'type' in filter;
		//const leaf = !(filter.children && filter.children.length)

		// var checked = filter.model.id in this.props.stateFilters.terms; 

		const checked = filter.model.id in this.props.filters[filter.selector]; 
		if(checked){
			console.log('checked: filter.model.id: ' + filter.model.id + ' is IN this.props.filters[filter.selector] : ' + filter.selector)
		}
		// const checked = this.props.filters[filter.selector].indexOf(filter.model.id) !== -1;

		let primaryText = filter.model.name.toLowerCase();
		primaryText = primaryText.replace(/\s+/g, '_');

		primaryText = this.props.tr[primaryText] ? this.props.tr[primaryText] : filter.model.name;

		//exception - "made in" or "made by" does not have term id - set it to the primary text
		if(!filter.model.id){
			cl('no menu item id found. Name is: ' + filter.model.name);
 
		}
		let id = filter.model.id ? filter.model.id : filter.model.name

		if(! this.props.tr[primaryText]) {
			//check missing translations
           // console.log('Filter menu - Primary Text', primaryText);
		}
		// const menuItems = filter.children ? 
		// 	filter.children.map((filter, index) => 
		// 		this.renderFilter(filter, index, level)) : null

		var childrenFilters = filter.children ? filter.children : null

		var zeroProductsCount=0;
		var emptyFilters = false;

		//move filters with productsnum = 0 to last)
		if(childrenFilters) {
			childrenFilters.map( (child, index) => {
				if(child.productsnum == 0){
					zeroProductsCount++;
					cl('child products num 0 was pushed to the end')
					childrenFilters.push(childrenFilters.splice(index, 1)[0]);
				}
			})
			if(level == 1){
				cl(primaryText + ', ' + zeroProductsCount + ' items where 0 out of ' + childrenFilters.length)
			}
			if(level == 1 && zeroProductsCount == childrenFilters.length) {
				emptyFilters = true;
			}
		}
		  

		{/* checked is when the filter id is present in filters[filter.selector], which is set with "add chip" */}
		return (
			<SelectMenuItem
				className={emptyFilters}
				key={index}
				level={level}
				termId={id}
				parentFilterId={parentFilterId}
				parentChecked={parentChecked}
				primaryText={primaryText}
				productsNum={filter.productsnum}
				showCheckbox={!root}
				isRoot={root ? true : false}
				checked={checked}
				menuItems={childrenFilters ? 
						childrenFilters.map((childFilter, index) => 
							this.renderFilter(childFilter, index, level, filter.model.id,checked)) : null}
				onChange={() => this.onToggleFilter(filter, checked, level,parentFilterId, parentChecked)}
				tr={this.props.tr}
			/>
		);
	}

	render() {
		
		const {tr, addChip} = this.props;
		const {selectedFilters} = this.state;
		 cl(selectedFilters)
		const {searchFilters} = this.props; 
		//  this.level = 0;
		  

		return (
			<List>
				{ searchFilters.map((filter, index) => {
					let level = 0;
					return this.renderFilter(filter, index, level)
				})
						
				}
				{/* {!root &&
					<Button
					onClick={this.handleClickApply}
					variant="raised" 
					type="button" 
					className="filterlist-apply mui-button-standard"
					disabled={selectedFilters}
					color="secondary"
					>
						{tr.apply || 'Apply'} 	
					</Button>
				} */}
					
			</List>
		);
	}

}

// <Paper style={{ 
// 				display: 'inline-block', 
// 				marginBottom: '1em', 
// 			}}>
FilterMenu = connect(
	state => ({
		// stateFilters: getFilters(state),
		tr: state.translations,
		searchFilters: state.filters.search_filters
	}),
	{ addShopFilter, removeShopFilter, removeShopFilters, addChip, removeChip}
)(FilterMenu);

export default withRouter(FilterMenu);


//isChecked