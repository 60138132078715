import * as types from './actionTypes';

export const initalState = {
	isLoading: false,
	products: [],
	currentPage: 1,
	from: 1,
	lastPage: 1,
	perPage: '60',
	to: 1,
	total: 1,
	errorMsg: null,
	status: null,
	selectedProduct: null,
};

const reducer = (state = initalState, action) => {
	switch(action.type) {
		case types.PRODUCT_TABLE_CLICK_ROW:
			const { rowData, index } = action.payload;

			if(rowData !== null) {
				return Object.assign({}, state, {
					selectedProduct: {
						data: rowData,
						index,
					},
				});
			} else {
				return Object.assign({}, state, {
					selectedProduct: null,
				});
			}
		default:
			return state;
	}
};

export default reducer;