import { call, put, takeLatest } from 'redux-saga/effects';
import { types, actions } from './CreateEditShippingCustom/actions';
import { types as customProfileTypes, actions as customProfileActions } from './actions';
import api from 'data/api';

import {addShippingProfiles} from 'data/modules/shippingProfiles'; 
import {addShippingProfile} from 'data/modules/shippingProfiles'; 

import {shippingProfileSchema} from 'data/schemas';
import {cl} from 'utils'
import { normalize } from 'normalizr';

function* fetchShippingCustomProfiles(action) {
    const { designerId } = action.payload;
    
    cl('saga: fetchShippingCustomProfiles');

	try{
        yield put(customProfileActions.fetching('createEditShippingCustom'));
        
		const result = yield call(api.fetchShippingCustom, designerId);
		cl(result, 'result:')
		//Adapt the result (data.methods) to initial values for the form
		/*
				 methods[${methodRowIndex}][shipping_zone_id]
				`methods[${methodRowIndex}][shipping_method_id]`}
				`methods[${methodRowIndex}][shipping_supplier_id]`}
        */
       cl(result.data.profiles, 'result.data.profiles: ')
		//make sure zone id is int
		if(result.data.profiles.length) { 
			result.data.profiles.map( profile => {
			
				// cl('looping profile id : ' + profile.id);
				profile.methods.map( method => {
					// cl(' parseInt(method.shipping_zone_id): ' +  parseInt(method.shipping_zone_id))
					
					if(method.shipping_zone_id != 0 && !method.shipping_zone_id){
						cl('method id ' + method.id + ', zone id was undefined, changing method.shipping_zone_id to 0')
						method.shipping_zone_id = 0;
					}else{
						method.shipping_zone_id = parseInt(method.shipping_zone_id);
					}
				})
			})

			const data = normalize(result.data.profiles, [shippingProfileSchema]);
			cl(data, 'normalized result: ')
			 
       		 yield put(customProfileActions.fetchShippingCustomProfilesSuccess(data.entities));
		}else{
			cl('result.data.profiles.length was 0 - no profiles')
			yield put(customProfileActions.fetchShippingCustomProfilesSuccess( [] ));
		}
         
	} catch(error) {
		cl(error, 'saga error: ')
		yield put(customProfileActions.fetchShippingCustomProfilesFailure(error));
	}
}

function* createCustomProfile(action) {
	const { formData } = action.payload;

	cl(formData, 'customProfiles saga, formdata: ');

	try {
		//backend function:  postCustomShippingProfile
		//the result contains all the designers' profiles
		const result = yield call(api.createShippingProfileCustom, formData);
		cl(result, 'result: ')
		 
        //The result contains all profiles and methods
        //Update profiles in state.createShippingProfileCustom.initialValues.profiles
		 yield put(customProfileActions.createCustomProfileSuccess(result.data));   
 
		// Normalize the result 
		const data = normalize(result.data, [shippingProfileSchema]);
		cl( 'normalized custom profiles: ');
		cl(data);

        //put in data.shippingProfiles, with normalized data
		yield put(addShippingProfiles(data.entities));
		
		
	} catch(error) {
        cl(error, 'error: ')
		yield put(customProfileActions.createCustomProfileFailure(error));
	}
}
 

//to create/update shipping methods for custom shipping profile
function* updateShippingCustom(action) {
	const { designerId, formData } = action.payload;

	cl('Editshippingcustom saga: update shipping custom:');

	try{
		//backend function:  postDesignerShippingProfileMethods
		//the result contains the designers' shipping methods
		const result = yield call(api.updateShippingCustom, designerId, formData);
		cl('result.data:');
		cl(result.data);
 
		 //The result contains the one updated custom profile + methods
		//Update initialvalues in state.editShippingcustom with the new methods
		yield put(actions.updateShippingCustomSuccess(result.data));

		cl('result.data.shippingprofile: ')
		cl(result.data.shippingprofile);

		//data.shippingProfiles also needs to be updated, with normalized data
		const data = normalize(result.data.shippingprofile, shippingProfileSchema);
		cl( 'updateshippingCustom, normalized: ');
		cl(data);

		//if any shipping_zone_id is null, change to 0
		Object.values(data.entities.profileZoneMethods).map( method => {
			cl(method, 'method: ')
			if(method.shipping_zone_id == null ){
				method.shipping_zone_id = 0;
			}
		})
		
		yield put(addShippingProfile(data.entities));
		
		
	} catch(error) {
		yield put(actions.updateShippingCustomFailure(error));
	}
}

export default function* listenerSaga() {     
	yield takeLatest(customProfileTypes.FETCH_SHIPPING_CUSTOM_PROFILES, fetchShippingCustomProfiles);
	yield takeLatest(customProfileTypes.CREATE_CUSTOM_PROFILE, createCustomProfile);
	yield takeLatest(types.UPDATE_SHIPPING_CUSTOM, updateShippingCustom);
}
