import React from 'react';

const TabControl = (props) => {

    return(

        <ul className="countries row">
            
             {React.Children.map(props.children, (child, i) => {
                    // console.log('child.key is ' + child.key);
                    // console.log(' props.selectedContinent is ' +  props.selectedContinent);  
                   var className = 'continent-listitem';
  
                   if(parseInt(child.key) === parseInt(props.selectedContinent)) {
                        // console.log(' child.key === props.selectedContinent:  ' +  child.key);  
                        className = `${className} active`;
                        // console.log(' className ' +  className);  
                   }
                return (
                    <div
                        className={className}
                        onClick={ ()  => {
                            props.onChange(child.key)
                        }}
                    >
                        {child}
                    </div>
                )
            })} 
            <div className="active-style"></div>
        </ul>
    )
}

    

export default TabControl;