import React from 'react';
import DaylicacyButton from 'components/DaylicacyButton';
import IconButton from 'components/IconButton';

export default class HelpOpenShop extends React.Component {

    constructor(props) {
        super(props);
    }

    onButtonCloseClick() {
        this.props.onDone();
    }

    render() {
        const { tr } = this.props;

        return (
            <div>
                <div
                    className="k-dropdown__close"
                    style={{background: "transparent"}}
                    >
                    <IconButton
                        iconClassName="fa fa-close"
                        className="k-dropdown__close"
                        onClick={this.onButtonCloseClick.bind(this)}
                    />
                </div>

                <center><h2>{tr.open_a_shop}</h2></center>
                <p>
                    {tr.open_a_shop_text}
                </p>
                <p>
                    {tr.passionate_designer}
                </p>
                <p>
                    {tr.we_will_contact_you}
                    <br /><br />
                    /Daylicacy team
                </p>
                <div>
                    <center>
                        <DaylicacyButton
                            type="button" 
                            onClick={this.onButtonCloseClick.bind(this)}
                        >
                        OK
                        </DaylicacyButton>
                    </center>

                </div>
            </div>
        )
    }

}

