import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { actions } from '../actions';
import CreateEditShippingMethodsForm from '../../components/CreateEditShippingMethodsForm';
import CircularProgress from '@material-ui/core/CircularProgress'
import {selectDenormalizedCustomProfile} from 'data/modules/shippingProfiles';
import {updateShippingCustom} from '../actions'

import {cl, notObjectOrNotEmptyObject} from 'utils'

/*	
	 CreateEditShippingCustom : Create and edit profile zone methods for one specific custom profile
	
	see reducer createEditShippingGeneral

	Fetches general shipping methods:
	Api function: 	fetchShippingGeneral
	Route: 			/api/designer/' + designerId + '/shipping/general
	function: 		getDesignerShippingGeneral

	Posts to: 
	Api function: 	updateShippingGeneral:  
	Route: 			/api/designer/' + designerId + '/shipping/general'
	function: 		postDesignerShippingProfileMethods
*/

class CreateEditShippingCustom extends Component {

	constructor(props) {
		super(props);

		this.state = { 
			requestError: null
		}
		this.handleSubmit = this.handleSubmit.bind(this);
		
	}

	componentDidMount(){

		//profiles already fetched in parent?
		// this.props.fetchShippingCustomProfiles(this.props.designerId);
	}

	componentDidUpdate(){
		cl('componentDidUpdate');
	}
	handleSubmit(data) {
		console.log('handleSubmit');
 
		// console.log(JSON.stringify(data));
	 
		if(!this.props.profileId){
			console.log('profileId is NOT set');

			this.setState({
				requestError : "Error - profile id could not be retrieved."
			})
		 
		}else{
			//add profile id and send request
			console.log('profileId is set');

			data.profileId = this.props.profileId;
			//add 'default' : 'null' to indicate custom profile
			data.default = 'null';
			const payload = Object.assign({}, data); 
			 
			this.props.updateShippingCustom(this.props.designerId, payload);

			// this.props.onSubmit();		//onödig?

		}
		
	}

	render() {
		const {tr, initialValues,profileId,isLoading,status,shippingZones,requestError,requestStatus,errorMsg } = this.props;

		//passed to child component to distinguish the profile type
		const profileType = 'custom'; 

		if(!initialValues){
            return <p>loading</p>
        }
		if(requestStatus === 'pending'){
			return (
				<div className="mui-loader"><CircularProgress /></div>
			);
		}
		if( notObjectOrNotEmptyObject(shippingZones)){
			cl('No shipping zones');
			//return <p>No shipping zones - create a shipping zone first!</p>
		}
		return (
			<div>
				<div className="row cx12">
					<h2 className="cx12">{initialValues.name}</h2>
					<h4 className="cx12">{this.props.tr.create_edit_default_methods || 'Create or edit your shipping methods'}</h4>
				</div>
                
				 <CreateEditShippingMethodsForm
				 	profileType={profileType}
					tr={tr}
					initialValues={initialValues} 
					profileId = {profileId}
					isLoading={isLoading}
					status={status}
					onSubmit={this.handleSubmit}
					shippingMethods={window.d_store.shipping_methods}
					shippingSuppliers={window.d_store.shipping_suppliers}
					shippingZones={shippingZones}
					requestStatus={requestStatus}
					requestError={requestError}
					errorMsg={errorMsg}
				/> 
			</div>
		);
	}

}

CreateEditShippingCustom.propTypes = {
	onSubmit: PropTypes.func.isRequired,
	designerId: PropTypes.number.isRequired,
};

CreateEditShippingCustom = connect(
	(state,ownProps ) => {
		 let profileId =  state.shipping.shippingProfileId || undefined;

		return { 
			profileId,
			initialValues: profileId ? selectDenormalizedCustomProfile(state, profileId) : null,			//<-- the custom profile
			designerId: state.session.designerId || '',  
			isLoading: state.createEditShippingCustom.isLoading || undefined,
			status: state.createEditShippingCustom.status,
			requestStatus: state.createEditShippingCustom.requestStatus, 
			errorMsg: state.createEditShippingCustom.errorMsg,
			shippingZones: state.data.shippingProfiles.shippingZones.shippingZones,
		}
	},
	{
		// fetchShippingCustomProfiles: actions.fetchShippingCustomProfiles,	 
		updateShippingCustom: actions.updateShippingCustom,
	}
)(CreateEditShippingCustom);

export default CreateEditShippingCustom;
