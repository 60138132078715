
//redux-form-saga action

import { createFormAction } from 'redux-form-saga';
 
export const updateUser = createFormAction('UPDATEUSER');

export const updateUserAddresses = createFormAction('UPDATEUSERADDRESSES');


