import React from 'react';

const Advertise = () => {
	return(
		<div className="content content--padded">
			<h3>Advertise</h3>
		</div>
	);
};

export default Advertise;