import React from 'react';

import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography'; 
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper'; 

//(scroll down for LanguagePickerPopover)

/* =============== React-select custom components (adapted to mui) ===============
			see: https://material-ui.com/demos/autocomplete/
			and: https://react-select.com/components

	Control, (+ mui inputComponent), Option, Menu, ValueContainer
*/ 


export const inputComponent = ({ inputRef, ...props }) => {
	return <div ref={inputRef} {...props} />;
  }

export const Control = (props) => {
	return (
	  <TextField
		fullWidth
		InputProps={{
		  inputComponent,
		  inputProps: {
			className: "mui-reactselect-input",
			inputRef: props.innerRef,
			children: props.children,
			...props.innerProps,
		  },
		}}
		// {...props.selectProps.textFieldProps}
	   
	  />
	);
  }


  export const Option = (props) => {
	return (
	  <MenuItem
		buttonRef={props.innerRef}
		selected={props.isFocused}
		component="div"
		className="mui-reactselect-options-menuitem"
		{...props.innerProps}
	  >
		{props.children}
	  </MenuItem>
	);
  }

//   export const Menu = (props) => {
// 	return (
// 	  <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
// 		{props.children}
// 	  </Paper>
// 	);
//   }

export const ValueContainer = (props) => {
	return <div className="mui-reactselect-valuecontainer">{props.children}</div>;
  } 

export const Menu = (props) => {
	return (
		<Paper square className="mui-reactselect-paper" {...props.innerProps}>
		{props.children}
		</Paper>
	);
}

export const Placeholder = (props) => {
	return (
		<Typography 
			className="mui-reactselect-placeholder"
			{...props.innerProps}
			>
			{props.children}
		</Typography>
	);
}

{/* <span>{props.typeMsg}</span> */}
export const SingleValue = (props) => {
	return (
	  <Typography{...props.innerProps}>
			{props.children} 
	  </Typography>
	);
  }
  export const NoOptionsMessage = (props) => {
	return (
	  <Typography
		className="mui-reactselect-nooptionsmessage"
		{...props.innerProps}
	  >
		{props.children}
	  </Typography>
	);
  }