import { createActionTypes, createActions } from 'utils';

const types = createActionTypes([

	'FETCHING',
	'FETCH_SHIPPING_GENERAL',
	'FETCH_SHIPPING_GENERAL_SUCCESS',
	'FETCH_SHIPPING_GENERAL_FAILURE',
	'UPDATE_SHIPPING_GENERAL',
	'UPDATE_SHIPPING_PROFILES',
	'UPDATE_SHIPPING_GENERAL_SUCCESS',
	'UPDATE_SHIPPING_GENERAL_FAILURE',
	'RESET_GENERAL_SHIPPING_STATUS',
]);

const actions = createActions({
	[types.FETCHING]: (subject) => ({subject }),
	[types.FETCH_SHIPPING_GENERAL]: (designerId) => ({ designerId }),
	[types.FETCH_SHIPPING_GENERAL_SUCCESS]: result => ({ result }),
	[types.FETCH_SHIPPING_GENERAL_FAILURE]: error => ({ error }),		
	[types.UPDATE_SHIPPING_GENERAL]: (designerId, formData) => ({ designerId, formData }),
	// [types.UPDATE_SHIPPING_PROFILES]: (designerId, formData) => ({ designerId, formData }),
	[types.UPDATE_SHIPPING_GENERAL_SUCCESS]: result => ({ result }),
	[types.UPDATE_SHIPPING_GENERAL_FAILURE]: error => ({ error }),
	[types.RESET_GENERAL_SHIPPING_STATUS]: () => ({  }),
});

export { types, actions };
