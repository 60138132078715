import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import dataReducer from 'data/reducers';
 
import {USER_SIGN_OUT} from 'containers/Session/actionTypes'
import {cl} from 'utils'
// Containers
import sessionReducer from 'containers/Session/reducer';
import cartReducer from 'containers/Cart/reducers';
import filtersReducer from 'containers/Filters/reducers';
import productTableReducer from 'containers/ProductTable/reducers';
import createProductReducer from 'containers/CreateProduct/reducers';
// import listShippingZones from 'containers/ListShippingZones/reducers';
import listShippingProfiles from 'views/Account/MyShop/Shipping/containers/ListShippingProfiles/reducers';
import editShippingZone from 'views/Account/MyShop/Shipping/containers/CreateEditShippingZone/reducers';
import editShippingProfile from 'views/Account/MyShop/Shipping/containers/EditShippingProfile/reducers';
import createEditShippingGeneral from 'views/Account/MyShop/Shipping/containers/CreateEditShippingGeneral/reducers';
import createEditShippingCustom from 'views/Account/MyShop/Shipping/containers/CustomProfiles/reducers';
import snackbar from 'containers/Snackbar/reducers';
import filterChipsBar from 'containers/FilterChipsBar/reducers';

 
// Views
import shopReducer from 'views/Shop/reducer';
import creationsReducer from 'views/Account/MyShop/Creations/reducer';
import interfaceReducer from 'views/reducer';
import imprintReducer from 'views/Account/MyShop/Imprint/reducer';
import shippingReducer from 'views/Account/MyShop/Shipping/reducers';
import shopSetupReducer from 'views/Account/MyShop/ShopSetup/reducer';

// import accountReducer from 'views/Account/reducer'
// import { shopReducer } from 'containers/Shop/'
// import modalReducer from 'containers/Modal/reducers'
import translationsReducer from 'containers/LanguageProvider/reducers'
// import { reducer as creationReducer } from 'components/Card/Creation'
import cropReducer from 'containers/ImageCropper/reducer';
import sortReducer from 'containers/Sort/reducers';
// import accountReducer from 'containers/Account'




const reducers = combineReducers({
	session: sessionReducer,
	form: formReducer,
	interface: interfaceReducer,
	cart: cartReducer,
	filters: filtersReducer,
	shop: shopReducer,
	creations: creationsReducer,
	productTable: productTableReducer,
	//modal: modalReducer,
	//router: routerReducer,
	//creation: creationReducer,
	translations: translationsReducer,
	cropper: cropReducer,
        sort: sortReducer,
	createProduct: createProductReducer,
	//account: accountReducer,
	shopSetup: shopSetupReducer,
	imprint: imprintReducer,
	data: dataReducer,
	shipping: shippingReducer,
	// listShippingZones,
	listShippingProfiles,
	editShippingZone,
	editShippingProfile,
	createEditShippingGeneral,
	createEditShippingCustom,
	snackbar,
	filterChipsBar,
	auto: ()=> {
		return {
			signInModalOpen:true
		}
	}
});

let rootReducer = (state, action) => {
 
	if (action.type == 'USER_SIGN_OUT') {
		cl('root reducer - action type is USER_SIGN_OUT')
	   state = undefined
	}

	return reducers(state, action);
}



export default rootReducer;
