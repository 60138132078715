import { call, put, takeEvery,select } from 'redux-saga/effects';
import * as types from './actionTypes';
import * as actions from './actionCreators';
import api from 'data/api';
import {getTranslationsIfExist} from './selectors';
/**
 * Fetch translations worker saga
 */

function* fetchTranslations(action) {
	console.log('fetchTranslations saga started - fetching');

	const translationsCheck = (yield select()).translations;

	if(Object.keys( translationsCheck) === 0 && translationsCheck.constructor === Object) {

		console.log('translations already exist!')				
	} else {
		console.log('translations DONT exist. Fetching.');

		try {
			const translations = yield call(api.fetchTranslations, action.locale);
			yield put(actions.requestSuccess(translations));
		} catch(e) {
			yield put(actions.requestFailed(e));
		}
	}
	
}

export default function* translationsSaga() {
	yield takeEvery(types.FETCH_TRANSLATIONS, fetchTranslations);
}


	// if(getTranslationsIfExist()){
	// 	alert('translations already exist!')
	// }else{
	// 	alert('translations dnt exist')
	// }
