import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ShippingZones from './containers/ShippingZones';
import CreateEditShippingZone from './containers/CreateEditShippingZone';
import CreateShippingZone from './containers/CreateShippingZone'
import ListShippingProfiles from './containers/ListShippingProfiles';
import EditShippingProfile from './containers/EditShippingProfile';
import CreateEditShippingGeneral from './containers/CreateEditShippingGeneral';
import CreateEditShippingCustom from './containers/CustomProfiles/CreateEditShippingCustom';
import CustomProfiles from './containers/CustomProfiles';
import { actions } from './actions';
import { actions as shippingGeneralActions } from './containers/CreateEditShippingGeneral/actions';
import { actions as createEditShippingZoneactions  } from 'views/Account/Myshop/Shipping/containers/CreateEditShippingZone/actions';

//import Button from 'material-ui-next/Button';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress'

import Tabs from 'components/DaylicacyTabs';
import Alert from 'components/Alert';
import throttle from 'lodash/throttle';
import {cl} from 'utils';
import {selectShippingZonesRequestStatus, selectDefaultShippingProfileId} from  'data/modules/shippingProfiles';
 


const shippingTabs = [
    { label: 'Shipping zones',
        value: 'shipping-zones', 
    },
	{ label: 'Default Shipping Profile', 
        value: 'shipping-general' 
    },
    { label: 'Custom shipping profiles',
    value: 'shipping-custom-profiles'
    }
];

//'status' + 'shippingZoneFormPostedCount' controls if Alert message should be displayed (on save)
 

class Shipping extends Component {

	constructor(props) {
		super(props);
  
		this.state = {
			postStatus: null,
			shippingZoneFormPostedCount: 0
		}

		this.handleOnChange = this.handleOnChange.bind(this);
		this.handleEditShippingZone = this.handleEditShippingZone.bind(this);
		// this.redirectAfterFormPost = this.redirectAfterFormPost.bind(this);
		this.resetPostStatus = this.resetPostStatus.bind(this); 
	}

	//create or update shipping zone
/*	handleSubmitShippingZone((data, dispatch, props)) {
		cl('handleSubmitShippingZone, this is props:')
		cl(props)
		//prepare form data 
		const payload = Object.assign({}, data, {
			countries: Object.keys(data.countries).map(id => parseInt(id)),
		});

		//UPDATE: if shippingZone is set - send form to update the zone
		if (this.props.shippingZoneId) {
			this.props.updateShippingZone(this.props.shippingZoneId, payload);
			
			this.props.viewShippingZones()
			this.setState({
				formPostedCount: this.state.formPostedCount +1
			})
			

		} else {
			//CREATE: if shippingZone is not set - send form to create the zone
			this.props.createShippingZone(payload);

			this.setState({
				postStatus: 
				formPostedCount: this.state.formPostedCount +1
			})
			this.props.viewShippingZones()
			
		}
 
		
	}		*/

	componentDidMount(){
		//fetch general shippingmethods 
		if(this.props.designerId){
			this.props.fetchShippingGeneral(this.props.designerId);
		}
		
	}

    handleOnChange(event, value){ 

		//open the view for the selected tab
		if(value === 'shipping-zones'){
			this.props.viewShippingZones()
			
		}else if(value === 'shipping-general'){

			//switch view and set current profile id to the default one
			this.props.viewEditShippingGeneral(this.props.defaultShippingProfileId);
			
		}else if(value === 'shipping-custom-profiles'){
			this.props.viewEditShippingCustomProfiles()
		}
	}
	
	redirectAfterFormPost = successOrFail => {
	
		//change view 
		this.props.viewShippingZones()
		//activate Alert message
		console.log('after changing view with viewShippingZones - setting state')

		this.setState({
			postStatus: successOrFail,
			shippingZoneFormPostedCount: this.state.shippingZoneFormPostedCount +1
		})
 
		
	}
	
	handleEditShippingZone(shippingZoneId) {
		 
		// props.viewEditShippingProfile(shippingProfile.id)
	}

	handleDeleteShippingZone(shippingZoneId) {
		// alert('handleDeleteShippingZone');

	}

	resetPostStatus = () => {
		// alert('resetPostStatus')
		//reset status (Alert will not show)
		//add some extra time for the fade to complete

		setTimeout(() => {

			this.setState({
				postStatus: null,
				shippingZoneFormPostedCount: 0
			})

		}, 2000 );
 
	}

	//see chosen view in state:  state.shipping
	renderView(view) {

		const {tr, viewEditShippingZone, viewCreateEditShippingCustom, handleDeleteShippingZone} = this.props;

		let statusText = this.state.postStatus ? this.state.postStatus : 'NULL';

		switch (view) {

			case 'edit_shipping_general':
				
					return (
						<div> 
							<CreateEditShippingGeneral
								tr={this.props.tr}
								// onSubmit={() => {}}
							/>
						</div>
					);

			//create/edit methods for a specific custom profile
			case 'create_edit_shipping_custom':
			
				return (
					<div> 
						<CreateEditShippingCustom
							tr={this.props.tr}
							// onSubmit={() => {}}
						/>
					</div>
				);

			// case 'edit_shipping_custom_profile': 

			// 		return (
			// 			<div>
			// 				  <CreateEditShippingCustom 
			// 					tr={this.props.tr}
			// 				/>  
			// 			</div>
			// 		);

			case 'edit_shipping_custom_profiles': 

					return (
						<div>  
							<CustomProfiles
								tr={this.props.tr}			
								onEdit={ customProfileId => viewCreateEditShippingCustom(customProfileId)}  
							/>  
						</div>
					);

			// case 'list_shipping_profiles':		//not in use					
			// 	return (
			// 		<div>
			// 			<h3>Shipping Profiles</h3>
			// 			<Button onClick={() => this.props.viewEditShippingProfile(0)}>
			// 				Create
			// 			</Button>
			// 			<ListShippingProfiles
			// 				tr={this.props.tr}
			// 				onEdit={(shippingProfile) => this.handleEditShippingZone}
			// 			/>
			// 		</div>
			// 	);

			case 'edit_shipping_profile': 
				return (
					<div>
						<EditShippingProfile
							tr={this.props.tr}
							shippingProfileId={this.props.shippingProfileId}
							onSubmit={() => {}}
						/>
					</div>
				);

			case 'shipping_zones':
			 
				default:
					return (
						<div>
								
							{/* <h1>postStatus: {statusText}</h1> */}
							<div className="cx12 cs12">
								<h3>{tr.countries_destination || 'Countries of destination'}</h3>
								<p>
								{this.props.tr.country_groups_info1 || 'You have to choose which country you want to sell to. Your shipment costs will have to be created for those country groups. Countries in your region have to pay the VAT when others may not. The country groups are allowing you to charge another selling price if you want to cover extra costs (see shipping/country profile).'}
								</p>
							</div>
							
							<div className="cx12 shipping-zones-actions">
								
								<Button variant="contained" onClick={() => this.props.viewCreateShippingZone()}>
								Add new country group
								</Button>
							</div>

							<ShippingZones
								tr={this.props.tr}
								className="cx12"
								onEdit={(shippingZone) => viewEditShippingZone(shippingZone.id)} 
							/> 
						</div>
					);
			case 'edit_shipping_zone':
				return (
					<div> 
						 <CreateEditShippingZone
							 // handleSubmitShippingZone={this.handleSubmitShippingZone}
							postStatus={this.state.postStatus}
							tr={this.props.tr}
							shippingZoneId={this.props.shippingZoneId}
							onSubmit={() => {}}
							redirectAfterFormPost={this.redirectAfterFormPost}
							// formPostedCount={this.state.shippingZoneFormPostedCount}
						/> 
					</div>
				);
			
			case 'create_shipping_zone':
				//alert('viewCreateShippingZone');
				return (
					<div> 
						<div> 
							<CreateEditShippingZone
								tr={this.props.tr}
								onSubmit={() => {}}
							/>
						</div>
						{/* <CreateShippingZone
							tr={this.props.tr}
							shippingProfileId={this.props.shippingProfileId}
							onSubmit={() => {}}
						/> */}
					</div>
				);

		}
	}
	 
	render() {

		if(!this.props.defaultShippingProfileId){

			return (
				<div className="mui-loader"><CircularProgress /></div>
			);
		}
		return (
			<div className="content content--padded shipping">
				{/* <h1>{this.state.postStatus}</h1> */}
				<div className="shipping_change_viewbuttons">	
                    <Tabs 
                        className="shop-tabs" 
                        tabs={shippingTabs}
                        onChange={this.handleOnChange}
                    />	
					{/* <Button onClick={() => this.props.viewListShippingZones()}>
							Shipping Zones
					</Button>	
					<Button onClick={() => this.props.viewEditShippingGeneral()}>
							Shipping General
					</Button>
					<Button onClick={() => this.props.viewListShippingProfiles()}>
							Shipping Profiles
					</Button> */}
				</div>
				{ this.state.postStatus === 'failed' &&  
				 	<Alert cssClasses="alertbox alert-error" >{this.props.errorMsg}</Alert>
				}
				{ this.state.postStatus  == 'success' &&		// this.props.requestStatus.requestStatus
				
					<Alert 
						cssClasses="alertbox alert-success"  
						delay={3500}
						formPostedCount={this.state.shippingZoneFormPostedCount}
						resetPostStatus={this.resetPostStatus}
					>
						Saved
					</Alert>
				}
				{this.props.requestStatus.requestStatus == 'failed' &&
					<Alert cssClasses="alertbox alert-error" >{this.props.requestStatus.lastRequestError.message} </Alert>
				 
				}
				{ this.renderView(this.props.view) }
				
				 
			</div>
		);
	}

}

Shipping.propTypes = {
	view: PropTypes.string.isRequired,
	shippingZoneId: PropTypes.number,
	shippingProfileId: PropTypes.number,
};

Shipping = connect(
	state => ({
		tr: state.translations,
		view: state.shipping.view,
		requestStatus: selectShippingZonesRequestStatus(state),
		shippingZoneId: state.shipping.shippingZoneId,
		defaultShippingProfileId: state.createEditShippingGeneral.profileId,
		shippingProfileId: state.shipping.shippingProfileId, 
		designerId: state.session.designerId
	}),
	{
		viewEditShippingGeneral: actions.viewEditShippingGeneral,
		viewEditShippingCustomProfiles: actions.viewEditShippingCustomProfiles,
		viewEditShippingCustomProfile: actions.viewEditShippingCustomProfile,
		viewShippingZones: actions.viewShippingZones,
		viewEditShippingZone: actions.viewEditShippingZone,
		viewCreateShippingZone: actions.viewCreateShippingZone,
		viewListShippingProfiles: actions.viewListShippingProfiles,
		viewEditShippingProfile: actions.viewEditShippingProfile,
		viewCreateEditShippingCustom: actions.viewCreateEditShippingCustom,	 
		updateShippingZone: createEditShippingZoneactions.updateShippingZone,
		createShippingZone: createEditShippingZoneactions.createShippingZone,
		fetchShippingGeneral: shippingGeneralActions.fetchShippingGeneral,
	}
)(Shipping);

export default Shipping;
