import React, { Component } from 'react';
import { connect } from 'react-redux'; 
import LanguagePickerPopover from './components/LanguagePickerPopover';
import LanguagePickerToggle from './components/LanguagePickerToggle'; 
import { changeLanguage, requestData,setLanguagePopoverConfirmed } from 'containers/Session/actionCreators';

import { selectUserLanguage } from 'containers/Session/selectors';
import { selectReactSelectCountries } from 'data/modules/countries';

import { requestProducts } from './../../hoc/product/actionCreators.js';
import { fetchTranslations } from 'containers/LanguageProvider/actionCreators';
import { withRouter } from 'react-router';
import {cl} from 'utils'


class LanguagePicker extends Component {

	constructor(props) {
		super(props);

		const { match: { params } } = this.props;

		//  this.props.changeLanguage(params.lang);
		 this.props.fetchTranslations(params.lang);

		this.state = {
			open: !this.props.languagePopoverConfirmed ? true : false,
		 
			anchorEl: null,
			languages: [{
				code: 'sv',
				name: 'Swedish',
			},{
				code: 'en',
				name: 'English', 
			},{
				code: 'fr',
				name: 'French'
			}],
			currencies: [{
				code: 'EUR',
				name: 'European euro',
			},{
				code: 'SEK',
				name: 'Swedish krona',
			},{
				code: 'USD',
				name: 'American dollar',
			}],
		};

		this.handleTouchTap = this.handleTouchTap.bind(this);
		this.handleRequestClose = this.handleRequestClose.bind(this);
		this.getAnchorEl = this.getAnchorEl.bind(this);
		this.onLanguageChange = this.onLanguageChange.bind(this);
	}
 
	componentDidMount() {
 
		  
	}
	handleTouchTap(event) {
		event.preventDefault();

		this.setState({
			open: true,
		});
	}

	handleRequestClose() {

		if(!this.props.languagePopoverConfirmed) {
			this.props.setLanguagePopoverConfirmed()
		}
		this.setState({
			open: false
		});
	}

	getAnchorEl(el) {
		this.setState({
			anchorEl: el,
		});
	}

	onLanguageChange(e) { 
		var code = e.target.value; 
		console.log('onLanguageChange to ' + code);
	 
		//change current url to chosen country
		//	but first - if language code is SE , change to sv
		if(code == 'SE'){
			
			code = 'sv';
			console.log('exchnaging ' + this.props.language + ' with ' + code)
		}
		//set language in state
		this.props.changeLanguage(code);
		//fetch translations - send language code
		this.props.fetchTranslations(code);

		cl(this.props.location.pathname, 'this.props.location.pathname is ')
		
		console.log('code is currently: ' + code);
		//set url to new path - add '/' if it lacks it
		code = code.charAt(0) !== '/' ? '/' + code : code;
		console.log('after slash check: code is:  ' + code);
		// var newPath = this.props.location.pathname.replace( this.props.language.toLowerCase(), code.toLowerCase());
		cl(code.toLowerCase(), 'changing pathname to')
		this.props.history.push(code.toLowerCase());
	}

 

    

	render() {
	   
		const { tr, language, changeLanguage, countries, deliveryCountry,deliveryCountryCode, userCurrency} = this.props;

		var confirmMessage = !this.props.languagePopoverConfirmed ? 'Hello! We detected your country as <strong>' + this.props.deliveryCountry + 
			'</strong>. You can set <strong>delivery country</strong> to show only products that ship to that country.' : null;
		
		return(
			<div>
				<LanguagePickerToggle
					onClick={this.handleTouchTap}
					getAnchorEl={this.getAnchorEl}
					languageCode={language} 
					deliveryCountryCode={deliveryCountryCode}
					currencyCode= {userCurrency}
				/>
				<LanguagePickerPopover 
					confirmMessage={confirmMessage}
					open={this.state.open}
					anchorEl={this.state.anchorEl}
					onClose={this.handleRequestClose}
					onLanguageChange={this.onLanguageChange}
					changeLanguage={changeLanguage} 
					languages={this.state.languages}
					countries={countries}
					currencies={this.state.currencies}
					userLanguage={language}
					deliveryCountry={deliveryCountry}
					userCurrency={userCurrency}
					tr={tr} 
				/>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({ 
	languagePopoverConfirmed: state.session.languagePopoverConfirmed,
	countries:selectReactSelectCountries(state),
	language: selectUserLanguage(state) == 'sv' ? 'SE' :  selectUserLanguage(state) ,
	deliveryCountry: state.session.delivery_country_name,
	deliveryCountryCode: state.session.delivery_country_code || '',
	userCurrency: state.session.currency,
	tr: state.translations
});

   

LanguagePicker = withRouter(connect(
	mapStateToProps,  
	{ 
		changeLanguage,
		fetchTranslations,
		requestData,
		setLanguagePopoverConfirmed
		
	}
)(LanguagePicker));

export default LanguagePicker;
