import React, {Component} from 'react';

import { connect } from 'react-redux';
import Tabs from 'components/DaylicacyTabs';


class AccountTabs extends Component {

    constructor(props){
        super(props)

        this.state={
            selectedTab : "customer-home"
        }

    }

    handleTabClick = ( event, value) => {
		 
				 //event, value	
		this.setState({ selectedTab: value});
		
		const { history, match } = this.props;
		 
		if(value === 'customer-home') {
			history.push(match.url);
		} else {
			history.push(`${ match.url }/${ value }`);
		}
    }
    
    render() {
        const {isDesigner, onChange, tr,selectedTab} = this.props;

        
    const accountTabs = [
        {
            label: tr.customer_home,
            value: 'customer-home',
        },
        {
            label: tr.my_account,
            value: 'my-account',
        },
        {
            label: tr.my_messages,
            value: 'messages',
        },
        {
            label: tr.my_purchases,
            value: 'my-purchases',
        },
        {
            label: tr.my_shop,
            value: 'my-shop',
        },
        {
            label: tr.my_orders,
            value: 'my-orders',
        },
        {
            label: tr.my_favourites,
            value: 'my-favorites',
        },
        {
            label: tr.my_ratings,
            value: 'my-ratings',
        }
    ];
if(!isDesigner){
    accountTabs.splice(4,1);
}

	return(
		<div className="margin-bottom account_tabs">
			<Tabs 
				tabs={accountTabs}
				onChange={this.handleTabClick}
				theme="flat"
                tr={tr}
                selectedTab={this.state.selectedTab}  
			/>
		</div>
    );
    }
};

AccountTabs = connect(
	state => ({
		isDesigner: state.session.designerId ? true : false,
	})
)(AccountTabs);

export default AccountTabs;

// appPosition="static"