import { ADD_ENTITIES, ADD_MESSAGES, addEntities, requestDataFailure } from 'data/actions';
import { normalize, denormalize } from 'normalizr';
import { createSelector } from 'reselect';
import { call, put } from 'redux-saga/effects';
import { userSchema } from 'data/schemas';
import api from 'data/api';

export const STATE_KEY = 'users';

export default function reducer(state = {}, action) {
	switch (action.type) {
		case ADD_ENTITIES:
			return {
				...state,
				...action.payload.users
			};

        case ADD_MESSAGES:
        	console.log('Inside reducer ADD MESSAGES');

            return {
                ...state,
                ...action.payload.messages
            };
		default:
			return state;
	}
}

export function* getUser(action) {
	try {
		const response = yield call(api.fetchUser, action.payload.args);
		const data = normalize(response.data, userSchema);
		yield put(addEntities(data.entities));
	} catch (e) {
		yield put(requestDataFailure(e.message));
	}
}

/**
 * Selectors
 */

export const selectUserById = (state, id) => {
	return state.data.users[id] || false;
};
