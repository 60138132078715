import { createSelector } from 'reselect';

const getCreations = state => state.creations;

export const getView = createSelector(
	getCreations,
	(creations) => creations.toolbar.view
);

export const getIsLoading = createSelector(
	
	getCreations,
	(creations) => creations.editProduct.isLoading
);

export const getDesignerId = state => state.session.designerId;
