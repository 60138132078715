import React from 'react';
import { connect } from 'react-redux';
import { getFormValues} from 'redux-form';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Paper from 'material-ui-old/Paper';
import DaylicacyButton from 'components/DaylicacyButton';
import { countCartItems } from 'containers/Cart/selectors';
import CircularProgress from '@material-ui/core/CircularProgress';

const createMarkup = (tr) => {
	return {__html: tr.shopping_summary_text }
};


const CartSummary = ({
	handleNext,
	nextButtonLabel,
	numProducts,
	totalPrice,
	numCartItems,
	currentStep,
	formValues,
	tr,
	handleGoBack,
	history,
    loadingProcessedCart,
    dispatch,
	dom
}) => {
	
	return(
		<Paper className="cart-summary">
			<div>
				<h3 style={{ marginBottom: '8px' }}>
					{tr.total_purchase} ({ numProducts } {tr.products})
				</h3>
				<hr style={{ marginBottom: '8px' }} />
				<div 
					className="row between-xs no-gutter" 
					style={{ marginBottom: '8px' }}
				>
					<h3>{tr.total_price}</h3>
					<div>{loadingProcessedCart && <CircularProgress/>} { totalPrice }</div>
				</div>
				<div className="cart-summary-total-price" style={{ marginBottom: '8px' }}>
                   
				</div>
				<br/>
				<Link to="/en"> {tr.back_to_shopping} </Link>

				<p dangerouslySetInnerHTML={createMarkup(tr)} >
					
					{/* { dom.span({dangerouslySetInnerHTML:{__html: tr.shopping_summary_text }}) } */}

				</p>
				<div className="shipping">
					<strong>
						{tr.free_shipping_text}
					</strong>
				</div>
                
				<div>
                    <DaylicacyButton 
                        onClick={handleGoBack}
                        disabled={ currentStep == 1}>
                        <div className="fa fa-arrow-left"> {tr.goback || 'Go back'}</div>
					</DaylicacyButton>
				</div>
			</div>
		</Paper>
	);
};

CartSummary.propTypes = {
	numCartItems: PropTypes.number.isRequired,
	formValues: PropTypes.object,
	currentStep: PropTypes.number.isRequired,
};

export default connect(
	state => ({
		numCartItems: state.cart.numProductsQuantity, //countCartItems(state),
		formValues: getFormValues('checkout')(state),
		tr: state.translations
	})
)(CartSummary);


// { currentStep !== 3 &&
//     <DaylicacyButton 
//         onClick={handleNext}
//         visible={false}
//         disabled={
//             numCartItems <= 0
//             || currentStep === 2
//             && formValues == null
//         }>
//                 {tr.next || "Next "} <div className="fa fa-arrow-right"></div>
//     </DaylicacyButton>
//     }
