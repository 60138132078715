// Sign out
export const USER_SIGN_OUT = 'USER_SIGN_OUT';

// Sign in
export const REQUEST_USER_SIGN_IN = 'REQUEST_USER_SIGN_IN';
export const REQUEST_USER_SIGN_IN_SUCCESS = 'REQUEST_USER_SIGN_IN_SUCCESS';
export const REQUEST_USER_SIGN_IN_FAILED = 'REQUEST_USER_SIGN_IN_FAILED';

//Update user + addresses  
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_ADDRESSES_SUCCESS = 'UPDATE_USER_ADDRESSES_SUCCESS';
export const UPDATE_USER_TOKEN = 'UPDATE_USER_TOKEN';
 

// Language
export const LANGUAGE_CHANGE = 'LANGUAGE_CHANGE';
export const COUNTRY_CHANGE = 'COUNTRY_CHANGE';
export const CURRENCY_CHANGE = 'CURRENCY_CHANGE';
export const REQUEST_DATA = 'REQUEST_DATA';


/**
 * Country
 */
export const CHECK_COUNTRY = 'CHECK_COUNTRY';

//languagePopover
export const SET_LANGUAGE_POPOVER_CONFIRMED = 'SET_LANGUAGE_POPOVER_CONFIRMED';

// Etc
export const USER_BECAME_DESIGNER = 'USER_BECAME_DESIGNER';
