import { types } from './actions';
import {cl} from 'utils'

//state.createEditShippingCustom
const initialState = {
	initialValues: {
		profiles: {}
	},
	isLoading: false,
	status: '',
	requestStatus: '',
	errorMsg: '',
};

const reducer = (state = initialState, action) => {
	switch(action.type) {
		case types.FETCHING:

			if(action.payload.subject === 'createEditShippingCustom'){
				return Object.assign({}, state, {
				 
					requestStatus:'pending'
				 
				});
			}else{
				return state;
			}
			
            
		case types.FETCH_SHIPPING_CUSTOM_PROFILES_SUCCESS:
 
			return Object.assign({}, state, {
 
				requestStatus: 'success'
			});
			
		case types.RESET_CUSTOM_SHIPPING_STATUS:
 
			return {
				...state,
				status: ''
			}

		//add updated shipping method info to state
		//action has: { designerId, formData } 
		// the form data will contain all the default methods
		case types.UPDATE_SHIPPING_CUSTOM:

			console.log('UPDATE_SHIPPING_CUSTOM')
			console.log(action)
			console.log(action.payload.formData);
			return Object.assign({}, state, {
				isLoading: true,
			});

		case types.UPDATE_SHIPPING_CUSTOM_SUCCESS:
			cl('edit shipping custom reducer: UPDATE_SHIPPING_CUSTOM_SUCCESS: ')
			cl(action, 'action: ')

			return Object.assign({}, state, {
				isLoading: false,
				status: 'success',
				initialValues: {		
					methods: action.payload.result.shippingprofile.methods,
					
				},
				profileId: action.payload.result.profileId
			});

		case types.CREATE_CUSTOM_PROFILE_SUCCESS:
			return Object.assign({}, state, {
				isLoading: false,
				status: 'success',
				initialValues: {
					profiles: {
						...action.payload.result
					}
					
				} 
			});

		case types.CREATE_CUSTOM_PROFILE_FAILURE:
			return Object.assign({}, state, {
				isLoading: false,
				status: 'failed',
				errorMsg: action.payload.error.response.data.message
			});
		default:
			return state;
	}
};

export default reducer;
