import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

/* using "material-ui-next" @1.0.0-beta.47 due to problems with popup menu */

// import Menu, { MenuItem } from 'material-ui-next/Menu';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

//import IconButton from 'material-ui-next/IconButton';
// import IconButton from 'material-ui-old/IconButton';
 import IconButton from '@material-ui/core/IconButton';         // <-- test - may fail

//import Button from 'material-ui-next/Button';
import Button from '@material-ui/core/Button';
//import Icon from 'material-ui-next/Icon';
import Icon from '@material-ui/core/Icon';
import OverlayLink from 'components/OverlayLink';

const AccountMenu = (props) => {
	const {
		disabled,
        isDesigner,
        loggedIn,
		tr
	} = props;

    const menuItems = [
        {
            label: tr.customer_home,
            route: '/en/account',
            access: '!designer'
        },
        {
            label: tr.shop_home,
            route: '/en/account',
            access: 'designer'
        },
        {
            label: tr.my_account,
            route: '/en/account/my-account'
        },
        {
            label: tr.my_messages,
            route: '/en/account/messages'
        },
        {
            label: tr.my_purchases,
            route: '/en/account/my-purchases',
        },
        {
            label: tr.my_shop,
            route: '/en/account/my-shop',
            access: 'designer'
        },
        {
            label: tr.my_orders,
            route: '/en/account/my-orders',
            access: 'designer',
        },
        {
            label: tr.my_favourites,
            route: '/en/account/my-favorites'
        },
        {
            label: tr.my_ratings,
            route: '/en/account/my-ratings'
        },
        {
            label: tr.statistics,
            route: '/en/account/statistics',
            access: 'designer',
        },
        {
            label: tr.billing_payments,
            route: '/en/account/billing-payments',
            access: 'designer',
        },
        {
            label: tr.guides_faq_news,
            route: '/en/account/guides',
            access: 'designer',
        },
        {
            label: tr.signout,
            route: '/signout'
        },
    ];
    
    if(!loggedIn && !isDesigner){
        
        menuItems.splice(-1,1);
       //alert(JSON.stringify(menuItems));
    }


	return(
		<div>	
             { (loggedIn || isDesigner) &&
             
           ( <div>
            <Button 
            style={{
                padding: 0, 
                height: 'auto'
            }} 
            onClick={props.onClick}
            >
            
                 <Icon className="fa fa-female" /> 
            </Button>
             
               <Menu className="account-menu"
                    anchorEl={props.anchorEl}
                    open={Boolean(props.anchorEl)}
                    onClose={props.onClose}
                >
                 
                      { menuItems.map((menuItem, i) => {

                        if (menuItem.access === 'designer') {
                            if (!isDesigner) return null;
                        }
                        if (menuItem.access === '!designer') {
                            if (isDesigner) return null;
                        }

                        return (
                            <MenuItem key={i}
                                onClick={props.onClose}
                            >
                                {menuItem.label} 
                                <Link to={menuItem.route}>
                                {/* <OverlayLink to={menuItem.route}>
                                    {menuItem.label}
                                </OverlayLink> */}
                                </Link>
                            </MenuItem> 
                        );
                        }) }  
                </Menu>
                </div> 
           )
            }
		</div>
	);
};

AccountMenu.propTypes = { 
    isDesigner: PropTypes.bool.isRequired,
    tr: PropTypes.object.isRequired
};

export default connect(
	state => ({
        loggedIn: state.session.signedIn ? true : false,
		isDesigner: state.session.designerId ? true : false,
	})
)(AccountMenu);


   //disabled={disabled}