
import { createStore, combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
  
export const REDUX_FORM_TEST = 'REDUX_FORM_TEST';

export const reduxFormTest = (value) => {
	return({
		type: REDUX_FORM_TEST,
		payload: { value },
	});
};

const reducers = { 

  
  form: formReducer.plugin({
       
    createProductForm: (state, action) => {   // <----- 'createProductForm' is name of form given to reduxForm()
      switch(action.type) {
        case REDUX_FORM_TEST:
          return {
            ...state,
            values: {
                ...state.values, 
                images: [
                    // ...state.values.images, 
                    { id: 5,
                        thing: "okay"
                    }
                ]   
            }
            
            // registeredFields: {
            //   ...state.registeredFields,
            //   password: undefined // <----- clear field state, too (touched, etc.)
            // }
          }
        default:
          return state
      }
    },
    
    checkout: (state, action) => {
        
        switch(action.type) {
            
            case 'redux-form/UNREGISTER_FIELD':
            console.log('redux-form reducer: redux-form/UNREGISTER_FIELD');
            
            return state;

        default:
         return state;
         
        }   
    }
  })
}
const reducer = combineReducers(reducers)
const store = createStore(reducer)