import React from 'react';
import { Field, reduxForm } from 'redux-form';

import CircularProgress from '@material-ui/core/CircularProgress';

import DaylicacyButton from 'components/DaylicacyButton';
import { DaylicacyCheckbox } from 'components/Form';
import DaylicacyTextField from 'components/Form/DaylicacyTextField';
 
import { createFormAction } from 'redux-form-saga';
import { requestSignIn } from 'containers/Session/actionCreators';
import * as types from 'containers/Session/actionTypes';
import validate from './validation';
import {TextField} from 'redux-form-material-ui';
import { loginFormAction } from 'containers/Session/formActions';

//See Session/sagas for handling REQUEST_USER_SIGN_IN_SUCCESS


let SignInForm = props => {

	const { error, submitting, handleSubmit, tr, email } = props;

	return(
		<form onSubmit={handleSubmit(loginFormAction)} className="signinform">
		{submitting && <div className="mui-loader">{<CircularProgress/>}</div>}
			 <div>
				<Field
					label={tr.email}
					component={TextField}
					name="email"
					fullWidth={true}
				/>
			</div>
			
			<div style={{ marginBottom: '8px' }}>
				<Field
					name="password"
					type="password"
					label={tr.password}
					component={TextField}
					fullWidth={true}
				/>
			</div> 
			
			<div style={{ margin: '8px 0px' }}>
				<div className="row formoptiontxt1">
					
					<div className="cx6">
						<Field
							type="checkbox"
							name="rememberMe"
							label={tr.remember_me}
							component={DaylicacyCheckbox}
						/>
					</div>
					<div className="cx6" style={{ textAlign: 'right' }}>
						<a href="#">{tr.forgot_password_text}?</a>
					</div>
					
				</div>
			</div>
			{ error && <div className="formalert">{ error }</div> }
			
			<div style={{textAlign:"center"}}>
				<DaylicacyButton 
					type="submit"
					variant="raised" 
					className="mui-button-standard"
					style={{
						width: '30%',
							margin: '0 auto',
					}} 
					disabled={submitting}
				>
				{tr.login}
				</DaylicacyButton>
			</div>
		</form>
	);
};

// SignInForm.propTypes = {
// 	onSubmit: PropTypes.func.isRequired,
// }

const mapStateToProps = state => ({
    errorMsg: state.session.errorMsg,
    tr: state.translations,
    auto: state.auto
});

const getEmailFromUrl = () => {
    let email = null;

    if(window.location.href.includes('email') === true ) {
        var urlParams = new URLSearchParams(window.location.search);
        email = urlParams.get('email');
    }
    return email;
}

SignInForm = reduxForm({
	form: 'signInForm',
	validate,
    initialValues: { email: getEmailFromUrl() }
})(SignInForm);

export default SignInForm;
