import React from 'react';
import DaylicacyTextField from 'components/Form/DaylicacyTextField';
import { reduxForm, Field } from 'redux-form';
class Li extends React.Component {
    render() {
        const {name, label } = this.props;

        return(
            <li className="row middle-xs margin-bottom">
                <div className="cx3">
                    <label className="label label--left">
                        {label}
                    </label>
                </div>
                <div className="cx9">
                    <Field
                        name={name}
                        component={DaylicacyTextField}
                        label={label}
                        type="text"
                    />
                </div>
            </li>
        )
    }
}

export default Li;