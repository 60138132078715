import React from 'react';
// import Popover from 'material-ui-old/Popover';
import Popover from '@material-ui/core/Popover';
import Fashion from './Fashion';
import Typography from '@material-ui/core/Typography';
//import Row from 'components/Row';
//import Col from 'components/Col';

const MegaMenu = ({
	open,
	anchorEl,
	// handleRequestClose,
	handlePopoverClose,
	handleOnMouseEnter, 
	menuIndex,
	tr,
	currentLanguage
}) => {
	const items = menuIndex in window.d_store.navigation ?
		window.d_store.navigation[menuIndex].children : null;
	// console.log('items')
	// console.log(JSON.stringify(items));

	let left = [];
	let middle = [];
	let right = [];

	if(items) {
        left = items.slice(0, 6);
        middle = items.slice(6, 12);
        right = items.slice(12, 16);
    }

	if (!Array.isArray(items)) return null

	

	return(
		 
			<Popover 
				id="mouse-over-popover"
				className="mega-menu-popover"
				open={open}
				anchorEl={anchorEl}
				// useLayerForClickAway={true} 
				onClose={handlePopoverClose}
				anchorOrigin={{"horizontal":"left","vertical":"bottom"}}
				transformOrigin={{"horizontal":"left","vertical":"top"}}
				disableRestoreFocus
			>
				<nav 
					className="k-dropdown mega-menu"
					onMouseEnter={handleOnMouseEnter}
					onMouseLeave={ handlePopoverClose }
					>
				
					<div className="row">

						<Fashion tr={tr} items={left} currentLanguage={currentLanguage}/>
						<Fashion tr={tr} items={middle}  currentLanguage={currentLanguage}/>
						<Fashion tr={tr} items={right}  currentLanguage={currentLanguage}/>

					</div>
				</nav>
			</Popover>
		 
	);

};

export default MegaMenu;
