import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const headerCoverImage = {
	backgroundImage:  `url(${window.d_store.banner})`
}				

const DaylicacyLogo = ({ href }) => {
	return(
		<div className="container">
			<div className="row middle-xs">
				<div className="site-logo cx">
					<Link to={href}>
						<img src={
							window.laroute.url( 
								'img', 
								['daylicacy-logo_woman-name.jpg'] 
							) 
						} />
					</Link>
				</div>
				 
				<div style={headerCoverImage} className="cover-image cx"></div>
			</div>
		</div>
	);
};

DaylicacyLogo.propTypes = {
	href: PropTypes.string.isRequired,
};

export default DaylicacyLogo;