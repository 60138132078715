import React from 'react';
import PropTypes from 'prop-types';
import T, { Table } from 'components/Table';

const ProductShipping = (props) => (
	<div>
		<h2>General shipping conditions</h2>
		<Table>
			<T.Head>
				<T.TR>
					<T.TH>Method</T.TH>
					<T.TH>Supplier</T.TH>
					<T.TH>Delivery time *</T.TH>
					<T.TH>Free shipping for orders over</T.TH>
					<T.TH>Maximum shipping cost</T.TH>
				</T.TR>
			</T.Head>
			<T.Body>
				{props.conditions.map((condition, index) =>
					<T.TR key={index}>
						<T.TD>{condition.method.name}</T.TD>
						<T.TD>{condition.supplier.name}</T.TD>
						<T.TD>{condition.delivery_from} to {condition.delivery_to} business days</T.TD>
						<T.TD>{condition.min_order_cost_free} €</T.TD>
						<T.TD>{condition.max_shipping_cost} €</T.TD>
					</T.TR>
				)}
			</T.Body>
		</Table>

		<h2>Shipping costs for this product</h2>
		<Table>
			<T.Head>
				<T.TR>
					<T.TH>Method</T.TH>
					<T.TH>Cost single</T.TH>
					<T.TH>Cost combined</T.TH>
				</T.TR>
			</T.Head>
			<T.Body>
				{props.product.shipping_profiles[0].methods.map((method, index) =>
					<T.TR key={index}>
						<T.TD>{method.method.name}</T.TD>
						<T.TD>{method.charge_single} €</T.TD>
						<T.TD>{method.charge_combined} €</T.TD>
					</T.TR>
				)}
			</T.Body>
		</Table>
	</div>
);

ProductShipping.propTypes = {
};

export default ProductShipping;
