import * as types from './actionTypes'; 
import {  
	REQUEST_DATA_FAILURE,  
	REQUESTING_START_LOADING, 
	SUCCESS_STOP_LOADING 
} from 'data/actions';

import {cl} from 'utils';

const initialState = {
	filters: {
		madeIn: {},
		madeBy: {},
		terms: {},
		rootTerm: null,
		price: {
			min: 0,
			max: 99999,
		},
	},
	search_filters : window.d_store.search_filters,
	productsCountActive: true,
	 
	rootTerms: window.d_store.root_filters,
	requestStatus: {
        fetching: false,
        requestStatus: null,
        lastRequestError: {
            statusCode:null,
            message: null
        } 
    }
};

const filtersReducer = (state = initialState, action) => {

	let newState;

	switch(action.type) {

		case types.ADD_SHOP_FILTER_TERM:
			console.log(action.payload)

			// newState = state;
			newState = Object.assign({}, state.filters.terms);

			newState[action.payload.id] = {
				key: action.payload.id,
				name: action.payload.name,
				selector: action.payload.selector
			}

			return {
				...state,
				filters: {
					...state.filters,
					terms:{
						...newState
					}
				}
			}
			
			// newState[action.selector][action.id] = action.id;
			 
			
		case types.DEACTIVATE_PRODUCTS_COUNT:
			return {
				...state,
				productsCountActive: false
			}
		case types.ACTIVATE_PRODUCTS_COUNT:
			return {
				...state,
				productsCountActive: true
			}

		 
		//should receive oldRootTermId and rootTermObject - with id, name, selector
		//removes old term, adds the new one 
		case types.REPLACE_FILTER_TERM_OBJECT:
			console.log('REPLACE_FILTER_TERM_OBJECT', action.payload)
			 
			//remove old root term if provided
			if(action.payload.oldRootTermId){
				return {
					...state,
					filters: {
						...state.filters,
						terms:{ 
							...state.filters.filters.terms.slice(0, action.payload.oldRootTermId),
							...state.filters.filters.terms.slice(action.payload.oldRootTermId + 1),
							[action.payload.rootTermObject.id] : {
								key: action.payload.rootTermObject.id,
								name: action.payload.rootTermObject.name,
								selector: action.payload.rootTermObject.selector
							}
						}
					}
				}
			}else{
				return {
					...state,
					filters: {
						...state.filters,
						terms:{ 
							[action.payload.rootTermObject.id] : {
								key: action.payload.rootTermObject.id,
								name: action.payload.rootTermObject.name,
								selector: action.payload.rootTermObject.selector
							}
						}
					}
				}
			}


		case types.REMOVE_SHOP_FILTER_TERM:

			cl('Filters reducer REMOVE_SHOP_FILTER_TERM  - to remove id ' + action.id)

			var stateCopy = Object.assign({}, state.filters.terms);
			 
			if (action.id in stateCopy) {
				delete stateCopy[action.id];
			}
			cl(stateCopy, 'state.filters.terms after deleting one id is ')
			return {
				...state,
				filters: {
					...state.filters,
					terms:{
						...stateCopy
					}
				}
			}
		case types.REMOVE_SHOP_FILTER_TERMS:

			cl(action.idArray, 'Filters reducer REMOVE_SHOP_FILTER_TERMS  - about to remove ids ')

			var stateCopy = Object.assign({}, state.filters.terms);
			 
			action.idArray.filter( termId => {
				if (termId in stateCopy) {
					delete stateCopy[termId];
				}
			})
			
			cl(stateCopy, 'state.filters.terms after deletion: ')
			return {
				...state,
				filters: {
					...state.filters,
					terms:{
						...stateCopy
					}
				}
			}
		case types.CLEAR_FILTERS_TERMS:

		return {
			...state,
			filters: {
				 ...state.filters,
				 terms: {},
				 rootTerm: {}
			}
		}
		case types.ADD_SHOP_ROOT_TERM:

		return {
			...state,
			filters: {
				 ...state.filters,
				 rootTerm: action.rootTerm
				 
			}
		}
		case types.REMOVE_CURRENT_SHOP_ROOT_TERM:

		return {
			...state,
			filters: {
				 ...state.filters,
				 rootTerm: null
				 
			}
		}
		//adds root term name to "rootTerm" property
		case types.FETCH_PRODUCTS_BY_ROOT_FILTER:
		 
			// let newFilters = Object.assign({}, state.filters);
			  
			return {
				...state,
				filters: {
					...state.filters,
					terms: {
						...state.filters.terms,
					 },
					rootTerm: action.params.termSlug,
				},
			}; 


		case types.ADD_FILTER_MENU:
			cl('filtermenu reducer: ADD_FILTER_MENU')
			cl(action.filtermenu);
			return {
				...state,
				search_filters: [
					...action.filterMenu
				],
				productsCountActive: true 
			};
	
		//resets product counts by adding the default filtr menu from d_store
		case types.SET_FILTER_MENU_NO_COUNTS : 
		cl('filtermenu reducer: SET_FILTER_MENU_NO_COUNTS')
		cl(action.filtermenu);
		return {
			...state,
			search_filters: [
				...window.d_store.search_filters,
			],
			productsCountActive: false 
		};

		case REQUESTING_START_LOADING: 
			if(action.payload == 'filters') {
				return {
					...state,
					requestStatus: {
						...state.requestStatus,
						requesting: true,
						requestStatus:  'pending' 
					}
				} 
			}else{
				 return state;
			}

		case SUCCESS_STOP_LOADING: 

            if(action.payload == 'filters') {
                return {
                    ...state,
                    requestStatus: {
                        ...state.requestStatus,
                        requesting: false,
                        requestStatus:'success'
                    }
                    
                }
            }else{
                return state;
			}

		case REQUEST_DATA_FAILURE:
            
            if(action.payload.subject == 'filters') {
                cl(action.payload, 'REQUEST_DATA_FAILURE: ') 
                return {
                    ...state,
                    requestStatus: {
                        requesting: false,
                        requestStatus: 'failed',
                        lastRequestError: {
                            statusCode: action.payload.statusCode || '',
                            message: action.payload.error.message || ''
                        }
                    }
                    
                }
            }else{ 
                return state;
            }

		default:
			return state;

	}

};

export default filtersReducer;
