import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';

import  { selectUserLanguage } from 'containers/Session/selectors'

import PageNotFound from 'views/PageNotFound';
import Logout from 'containers/Logout';
import Master from './Master';
import {cl} from 'utils';

/*
 * APP COMPONENT
 */

class App extends Component {

	constructor(props) {
		super(props);
    }
    

    //TODO(?) redirect to chosen language if language in url is different (for instance '/en' instead of 'fr')
    // componentDidMount(){
    //     this.props.history.listen(this.onRouteChange.bind(this));
    // }

    // onRouteChange(){
    //     alert(this.props.location.pathname, 'App onRouteChange: ')
    // }

    // {`/${this.props.userLanguage}`}
	render() { 
        const {userLanguage} = this.props;
        // var userLang = userLanguage.toLowerCase();
        //If 'se', change to 'sv'
        // userLang = 'se' ? 'sv' : userLang;
        cl(userLanguage)
      
        // <Redirect to={userLang}/>
            return(
                <BrowserRouter>
                    <Switch>                                        {/*     render={ () => <Redirect to="/en" />}     />        */}
                            <Route exact path="/" render={() => <Redirect to={userLanguage}/> }
                            />
                            <Route exact path="/signout"  render={() => <Logout userLanguage={userLanguage}/>}
                            /> 
                            <Route path="/:lang(en|sv|fr)" component={Master} />
                            <Route component={PageNotFound} />
                    </Switch>
                </BrowserRouter>
            );
	}
}

App = connect(
    state => ({
        userLanguage: selectUserLanguage(state)
    })
)(App)
export default App;


