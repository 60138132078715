import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LanguagePicker from 'containers/LanguagePicker';
import SignInModal from 'views/Modals/SignIn';
  
import AccountMenu from './AccountMenu';
import IconButton from 'components/IconButton';
import ToggleCart from './ToggleCart';
import Cart from 'views/Popovers/Cart';
import SearchField from 'containers/SearchField';

import DaylicacyButton from 'components/DaylicacyButton';
import Dialog from 'components/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import SignUpForm from 'components/SignUpForm';
import HelpOpenShop from 'components/HelpOpenShop';
import MyFavorities from 'components/MyFavorities';

import { toggleSearchField } from '../actionCreators';
import { selectUserSignedIn, getSearchField } from '../selectors';
import { Route, Link } from 'react-router-dom';
import Row from 'components/Row';
import Col from 'components/Col';
import Image from 'components/Image';

/**
 * Spline
 */

class Spline extends Component {

	constructor(props) {
		super(props);

		this.state = {
			anchorEl: null,
			signUpDialogOpen: false,
            helpOpenShopDialogOpen: false,
            myFavoritesDialogOpen: false
		};

		this.handleAccountMenuClick = this.handleAccountMenuClick.bind(this);
		// this.handleAccountMenuClose = this.handleAccountMenuClose.bind(this);

		this.handleSignUpClick 		= this.handleSignUpClick.bind(this);
		this.handleSignUpClose 		= this.handleSignUpClose.bind(this);

		this.handleHelpOpenShopClick = this.handleHelpOpenShopClick.bind(this);
		this.handleHelpOpenShopClose = this.handleHelpOpenShopClose.bind(this);

        this.handleMyFavoritesClick = this.handleMyFavoritesClick.bind(this);
        this.handleMyFavoritesClose = this.handleMyFavoritesClose.bind(this);
	}

	handleSubmit(value) {
		console.log('values:', value);
	}

	handleAccountMenuClick(event) {
		 
		 this.setState({ anchorEl: event.currentTarget });
	}

	handleAccountMenuClose = () => {
		this.setState({ anchorEl: null });
	};

	handleSignUpClick() {
		
		this.setState({
			signUpDialogOpen: true,
		});
	}

	handleSignUpClose() {
		this.setState({
			signUpDialogOpen: false,
		});
	}

    handleHelpOpenShopClick() {
        this.setState({
            helpOpenShopDialogOpen: true,
        });
    }
    handleHelpOpenShopClose() {
        this.setState({
            helpOpenShopDialogOpen: false,
        });
    }
    handleMyFavoritesClick() {
        this.setState({
            myFavoritesDialogOpen: true,
        });
	}
    handleMyFavoritesClose() {
        this.setState({
            myFavoritesDialogOpen: false,
        });
	}

	render() {
		const { toggleSearchField, searchField, signedIn, tr } = this.props;

		return(
			<div className="container-fluid"> 
			
				<div className="top-navigation row between-xs middle-xs">
					<Col className="nav no-grow-xs">
						<LanguagePicker/>
					</Col>
					<Col 
						className="no-grow-xs" 
						align="center"
						style={{
							position: 'absolute',
							left: '50%',
							transform: 'translate(-50%)',
						}}>
						<Link to="/">
							<Image 
								style={{
									height: '37px',
								}}
								src={window.d_store.primary_logo} />
						</Link> 
					</Col>
					<Col className="no-grow-xs nav">
						<Row>
							{ !signedIn &&
								<Col>
									<SignInModal open={true} label={tr.login} />
								</Col> }
							{ !signedIn &&
								<Col>
									<DaylicacyButton  
										onClick={this.handleSignUpClick} 
									>
										{tr.signup}
									</DaylicacyButton>

									<Dialog 
										open={this.state.signUpDialogOpen}
										onClose={this.handleSignUpClose} 
                                    >
										
										<DialogContent className="mui-dialog login-dialog" >  
											<SignUpForm 
												tr={tr} 
												onDone={this.handleSignUpClose} 
											/>
										</DialogContent> 
									</Dialog>
								</Col> 
							}

								{ searchField.open &&
									<div className="cx">
										<SearchField onSubmit={this.handleSubmit} /> 
									</div> 
								}
								<div className="cx">
									<IconButton 
										iconClassName="fa fa-search"
										onClick={toggleSearchField} />
								</div>
							
							<Col>
								<Route 
									path="/:lang"
									render={() =>  
										<AccountMenu 
											anchorEl={this.state.anchorEl}
											onClick={this.handleAccountMenuClick}
											onClose={this.handleAccountMenuClose}
											 
											disabled={!signedIn}
											tr={tr}
										/>
									} />
							</Col>
							

							<Col>
								<IconButton
									iconClassName="fa fa-question"
									onClick={this.handleHelpOpenShopClick} />
								<Dialog
									open={this.state.helpOpenShopDialogOpen}
									onRequestClose={this.handleHelpOpenShopClose}>
									<HelpOpenShop tr={tr} onDone={this.handleHelpOpenShopClose} />
								</Dialog>
							</Col>

							<Col>
								<IconButton
									iconClassName="fa fa-heart"
									onClick={this.handleMyFavoritesClick} />
								<Dialog
									open={this.state.myFavoritesDialogOpen}
									onRequestClose={this.handleMyFavoritesClose}>
									<MyFavorities onDone={this.handleMyFavoritesClose} />
								</Dialog>

							</Col>

							<Col>
								<ToggleCart />
							</Col>

						</Row>
					</Col>
				</div>
			</div>
		);
	}

}

Spline.propTypes = {
	searchField: PropTypes.shape({
		open: PropTypes.bool.isRequired,
	}),
	signedIn: PropTypes.bool,
};

const mapStateToProps = state => ({
	signedIn: selectUserSignedIn(state),
	searchField: getSearchField(state),
	tr: state.translations
});

export default connect( 
	mapStateToProps,
	{ toggleSearchField }
)(Spline);
