import * as types from './actionTypes';

export const listClick = () => ({
	type: types.CREATIONS_TOOLBAR_LIST_CLICK,
});

export const editClick = () => ({
	type: types.CREATIONS_TOOLBAR_EDIT_CLICK,
});

export const createClick = () => ({
	type: types.CREATIONS_TOOLBAR_CREATE_CLICK,
});
