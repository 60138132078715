import React from 'react';
import { connect } from 'react-redux'; 
import { Field, reduxForm, formValueSelector } from 'redux-form';

import validate from './validate';

import {TextField} from 'redux-form-material-ui';

//import { withStyles } from 'material-ui-next/styles';
import { withStyles } from '@material-ui/core/styles';


import DaylicacyTextField from 'components/Form/DaylicacyTextField';
//import Button from 'material-ui-next/Button';
import Button from '@material-ui/core/Button';

const styles = theme => ({  
    input: { 
         
        marginTop: '14px',
        border: '1px #E8E6E6 solid', 
    },
    button: { 
        marginTop: '14px', 
    }
});

let ShopProfileForm = props => {
   
    const { tr, classes, validate, handleSubmit, pristine, submitting  } = props;
    
	return(
        <div className="row cx12 shopprofileform">
			<form onSubmit={handleSubmit} className="row cx12 middle-xs margin-bottom">
                    <div className="row cx12">
                        <div className="cx4">
                            <label className={`classes.input`} >Shop description</label>
                        </div>
                        <div className="cx8 textareawrap">
                            <Field 
                                name="shop_description"
                                component={TextField}
                                multiline
                                type="text" 
                                rows="4"
                                rowsMax="4"
                            />
                        </div>
                    </div>
                    <div className="row cx12">
                    <div className="cx4">
                        <label className="label label--left">Shop keywords (in english)</label>
                    </div>
                        <div className="cx8 textareawrap">
                            <Field 
                                name="shopkeywords"
                                component={TextField}
                                multiline
                                type="text" 
                                rows="4"
                                rowsMax="4"
                            />
                        </div> 
                    </div>
                    <div className="cxo4">
                        <Button variant="raised" color="secondary" className="mui-button-standard"> Apply </Button>
                    </div>
                       
            </form>
         </div>   
   
	);
};

ShopProfileForm = reduxForm({
	form: 'shopProfileForm'
})(ShopProfileForm);

  
ShopProfileForm = connect(

    state => {
        return {
            initialValues: state.session.user.designer,
        }
    }
 
)(ShopProfileForm)

export default withStyles(styles)(ShopProfileForm);
 