import React from 'react';
import PropTypes from 'prop-types';

const Content = ({ children }) => (
	<div>{children}</div>
);

Content.defaultProps = {
	children: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cupiditate saepe officia, atque fugit voluptate quas quos autem doloremque sint nam laboriosam iste nobis sapiente, veritatis, error temporibus corporis soluta maiores!'
};

Content.propTypes = {
	children: PropTypes.node.isRequired,
};

export default Content;