import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table, Column, AutoSizer } from 'react-virtualized';
import 'react-virtualized/styles.css';
import { rowClick } from './actionCreators';
import { getVisibleProducts, getIsLoading } from './selectors';
import styled from 'styled-components';
import { selectProducts } from 'data/selectors';

import { withStyles } from '@material-ui/core/styles';

//import CircularProgress from 'material-ui-next/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';

// const styles = theme => ({
// 	progress: {
// 	  marginTop: '1em'
// 	},
//   });

const TableWrapper = styled.div`
	height: 600px;
	background: white;
	padding-top: 16px;
`;


class ProductTable extends Component {

	constructor(props) {
		super(props);

		this.state = {
			selectedIndex: null,
			selectedEl: null,
		};
	  
		
		this.rowGetter = this.rowGetter.bind(this);
		this.handleRowClick = this.handleRowClick.bind(this);
	}

	select(el) {
		el.style.backgroundColor = '#efebf3';
		el.style.color = 'black';
		el.style.outline = 'none';
	}

	deselect(el) {
		el.style.backgroundColor = 'white';
		el.style.color = '#333';
	}

	handleRowClick({ event, index, rowData }) {
		event.stopPropagation();

		const { currentTarget: targetEl } = event;
		const { selectedEl: previousEl } = this.state;

		let newEl, newIndex, newRowData;

		if(previousEl === null) {
			newEl = targetEl;
			newIndex = index;
			newRowData = rowData;
			this.select(newEl);

		} else if(previousEl === targetEl) {
			newEl = null;
			newIndex = null;
			newRowData = null;
			this.deselect(targetEl);
			
		} else if(previousEl !== null && previousEl !== targetEl) {
			newEl = targetEl;
			newIndex = index;
			newRowData = rowData;
			this.deselect(previousEl);
			this.select(newEl);
		}

		this.setState({
			selectedEl: newEl,
			selectedIndex: newIndex,
		});

		const { onRowClick, rowClick } = this.props;
		
		onRowClick({ rowData: newRowData, index: newIndex });
		rowClick({ rowData: newRowData, index: newIndex });
	}

	rowGetter(index,designerProducts) {
		const { products, designer } = this.props;
 
		let product = designerProducts[index];	
		 
		let { id, translations } = product;
		/* console.log(id, translations); */

		return {
			productNumber: id,
			name: translations.en.name,
			description: translations.en.description,
		};
	}

	render() {
		console.log('rendering <ProductTable />');
		console.log(this.props);
		const { designer, designerId,classes, products } = this.props;

	 
		const designerProducts = designer.products;
		 
		
		if (!(designer && designer.products)) {
			return <LinearProgress color="secondary"/>;			
		}
		 
		return(
			<TableWrapper className="creations-table-wrapper">
				<AutoSizer>
					{({ height, width }) => (
						<Table
							className="creations-table"
							height={height}
							rowGetter={({ index }) => this.rowGetter(index, designerProducts)}
							rowHeight={50}
							rowCount={designer.products.length}
							width={width}
							headerHeight={20}
							noRowsRenderer={() => <div style={{marginTop:'10px'}}>No products..</div>}
							rowStyle={{ marginBottom: '1px solid #ccc'}}
							onRowClick={this.handleRowClick}
						>
							<Column 
								label="Product number" 
								dataKey="productNumber" 
								width={150}
							/>
							<Column 
								label="Name" 
								dataKey="name"
								width={100} 
							/>
							<Column 
								label="Description" 
								dataKey="description" 
								width={200} 
							/>
						</Table>
					)}
				</AutoSizer>
			</TableWrapper>
		);
	}

}

ProductTable.defaultProps = {
	products: {},
	productIds: [],
};

ProductTable.propTypes = {
	onRowClick: PropTypes.func,
	products: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.array
	  ]),
	designerId: PropTypes.number.isRequired,
	productIds: PropTypes.array.isRequired,
};

ProductTable = connect(
	(state) => ({
		products: state.data.designers.userDesigner.products,
		isLoading: getIsLoading(state)
		 
	}), 
	{ rowClick }
)(ProductTable);

export default ProductTable;

//linearprogress style:
// style={styles.progress}