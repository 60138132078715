import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { actions } from './actions';
import {
	Table,
	TableBody,
	TableHeader,
	TableHeaderColumn,
	TableRow,
	TableRowColumn,
} from 'material-ui-old/Table';
//import Button from 'material-ui-next/Button';
import Button from '@material-ui/core/Button';

class ListShippingProfiles extends Component {

	constructor(props) {
		super(props);
		
		this.props.fetchShippingProfiles(this.props.designerId);
	}

	render() {
		return (
			<div>
				<Table>
					<TableHeader>
						<TableRow>
							<TableHeaderColumn>Name</TableHeaderColumn>
						</TableRow>
					</TableHeader>
					<TableBody>
						{ this.props.shippingProfiles.map((shippingProfile, index) => (
							<TableRow key={index}>
								<TableRowColumn>{shippingProfile.name}</TableRowColumn>
								<TableRowColumn>
									<Button onClick={() => this.props.onEdit(shippingProfile)} >
										Edit
									</Button>
								</TableRowColumn>
							</TableRow>
						)) }
					</TableBody>
				</Table>
			</div>
		);
	}

}

ListShippingProfiles.propTypes = {
	onEdit: PropTypes.func.isRequired,
	designerId: PropTypes.number.isRequired,
	shippingProfiles: PropTypes.array.isRequired,
};

ListShippingProfiles = connect(
	state => ({
		designerId: state.session.designerId,
		shippingProfiles: state.listShippingProfiles.shippingProfiles,
	}),
	{
		fetchShippingProfiles: actions.fetchShippingProfiles,
	}
)(ListShippingProfiles);

export default ListShippingProfiles;
