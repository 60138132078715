import * as types from './actionTypes';
import api from 'data/api';
import {
	call,
	put,
	takeLatest,
} from 'redux-saga/effects';
import {
	updateImprintSuccess,
	updateImprintFailed,
	fetchImprintSuccess,
	fetchImprintFailed,
} from './actionCreators';

function* updateImprint(action) {
	console.log('updateimprint saga');
	try {
		const result = yield call(api.updateImprint, action.args);
		yield put(updateImprintSuccess(result));
	}catch(error) {
		yield put(updateImprintFailed(error));
	}
}

function* fetchImprint(action) {
	try{
		const result = yield call(api.fetchImprint, action.id);
		yield put(fetchImprintSuccess(result));
	}catch(error) {
		yield put(fetchImprintFailed(error));
	}
}

export default function* listenerSaga() {
	yield takeLatest(types.REQUEST_UPDATE_IMPRINT, updateImprint);
	yield takeLatest(types.REQUEST_FETCH_IMPRINT, fetchImprint);
}
