import * as types from './actionTypes';

/* state.shopSetup */

const initialState = {
	isLoading: false,
	status: null,
	errorMsg: null,
};

const reducer = (state = initialState, action) => {
	switch(action.type) {
		case types.REQUEST_UPDATE_DESIGNER:
			return Object.assign({
				isLoading: true,
			});
		case types.REQUEST_UPDATE_DESIGNER_SUCCESS:
			return Object.assign({}, state, {
				isLoading: false,
				status: 'success',
			});
		case types.REQUEST_UPDATE_DESIGNER_FAILED:
			return Object.assign({}, {
				isLoading: false,
				status: 'failed',
				errorMsg: action.error.response.data.message
			});
		case types.REQUEST_FETCH_DESIGNER_SUCCESS:
			const initialValues = action.result.data.account ? {
				shop_name: action.result.data.shop_name,
				shop_currency_id: action.result.data.shop_currency_id,
				account_owner: action.result.data.account.owner,
				account_country_id: action.result.data.account.country_id,
				account_bank: action.result.data.account.bank,
				account_clearing: action.result.data.account.clearing,
				account_number: action.result.data.account.number,
				account_iban_rib: action.result.data.account.iban_rib,
				account_swift_bic: action.result.data.account.swift_bic,
			} : {};
			
			return Object.assign({}, state, {
				initialValues,
			});
	  
		default:
			return state;
	}
};

export default reducer;

// return Object.assign({}, state, {
// 	initialValues,
// });