import React, { Component } from 'react'; 
import { connect } from 'react-redux';
// import { actions } from '../actions';
// import CreateEditShippingMethodsForm from './components/CreateEditShippingMethodsForm';
import CreateProfilesForm from './components/CreateProfilesForm';
import CircularProgress from '@material-ui/core/CircularProgress'
import {cl, notObjectOrNotEmptyObject} from 'utils'
import {selectNumProductsConnectedToCustomProfile} from 'data/modules/shippingProfiles';
import {selectDenormalizedCustomProfiles} from 'data/modules/shippingProfiles';

import { actions } from './actions';
import { requestData } from 'data/actions';
  
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';


// let id = 0;
// function createData(name, num_methods, num_products) {
//     id += 1;
//     return { id, name,  num_methods, num_products };
//   }

// const rows = [
//     createData('My first custom profile', 2, 5),
//     createData('My second custom profile', 1, 0), 
//  ];



class CustomProfiles extends Component {

    constructor(props) {
		super(props);
 
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    
    componentDidMount() {

        //profiles will end up in state.data.profiles
        this.props.fetchShippingCustomProfiles(this.props.designerId);
        
        // fetch user designer if not already available (contains products needed to see which of them are connected to custom profiles)
		if(!this.props.userDesigner || typeof this.props.userDesigner.id == 'undefined') {
			cl('no user designer - fetch');

			this.props.requestData({ 
				type: 'userDesigner',
				args: {
					id: this.props.designerId
				}
			});
		}else{
			cl('user designer already available')
		}
    }
 

    handleSubmit(values) { 
        // e.preventDefault();
        // alert( JSON.stringify(values));
        // Dispatch action
		this.props.createCustomProfile(values);
    }


    render(){
        const { customShippingProfiles, profileProductCounts, onEdit} = this.props;

        if(!customShippingProfiles){ 
            return <div className="mui-loader"><CircularProgress /></div>
        }
        cl(customShippingProfiles, 'customShippingProfiles: ')
         
        var profilesHaveLength = customShippingProfiles.length ? true : false;
        // return  <p>ok </p>
        return (


            <div className="custom-profiles">
            
                <CreateProfilesForm submitCreateProfilesForm={this.handleSubmit}/>
                 
                <div className="cx12">
                    <h2>Custom shipping profiles</h2>
                    <p className="margin-bottom">Click edit button to view and add methods to a custom profile.</p>
                    <Paper className="">
                        
                        <Table className="cx12">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell align="right">Number of methods</TableCell>
                                    <TableCell align="right">Assigned to number of products</TableCell> 
                                    <TableCell></TableCell> 
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            { profilesHaveLength && customShippingProfiles.map( profile => {

                                return (
                                    <TableRow key={`profile_${profile.id}`}> 
                                        <TableCell>
                                            { profile.name }
                                        </TableCell>
                                        <TableCell align="right">{ profile.methods.length || '' }</TableCell>
                                        <TableCell align="right">{ profileProductCounts[profile.id] }</TableCell> 
                                        <TableCell align="right">
                                            <Button onClick={ () => onEdit(profile.id)}>		
                                                Edit
                                            </Button>
                                        </TableCell> 
                                    </TableRow>
                                );
                            })}
                           
                            </TableBody>
                        </Table>
                    </Paper>
                </div>
            </div>
         )
    }
}
 
 
// const selector = formValueSelector('myAccountForm');

CustomProfiles = connect(
	(state) => ({ 
        view: state.shipping.view,
        designerId: state.session.designerId || null,
        userDesigner: state.data.designers.userDesigner,
        customShippingProfiles: selectDenormalizedCustomProfiles(state),      //from state.data.shippingProfiles.shippingProfiles 
        profileProductCounts: selectNumProductsConnectedToCustomProfile(state)//same^^ , checks products from data.designers.userDesigner
	}),
    {fetchShippingCustomProfiles: actions.fetchShippingCustomProfiles,
        createCustomProfile: actions.createCustomProfile,
        requestData
    }, 
	
)(CustomProfiles);
export default CustomProfiles;