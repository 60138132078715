import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { actions } from './actions';
import EditShippingProfileForm from './components/EditShippingProfileForm';

class EditShippingProfile extends Component {

	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.props.fetchProducts(this.props.designerId);
		if (this.props.shippingProfileId) {
			this.props.fetchShippingProfile(this.props.shippingProfileId);
		}
	}

	handleSubmit(data) {
		const payload = Object.assign({}, data, {
			products: data.products ? Object.keys(data.products).map(id => parseInt(id)) : [],
		});
		if (this.props.shippingProfileId) {
			this.props.updateShippingProfile(this.props.shippingProfileId, payload);
		} else {
			this.props.createShippingProfile(payload);
		}
		this.props.onSubmit();
	}

	render() {
		return (
			<div>
				<EditShippingProfileForm
					initialValues={this.props.initialValues}
					isLoading={this.props.isLoading}
					status={this.props.status}
					onSubmit={this.handleSubmit}
					shippingZones={this.props.shippingZones}
					shippingMethods={window.d_store.shipping_methods}
					designerProducts={this.props.designerProducts}
					errorMsg={this.props.errorMsg}
				/>
			</div>
		);
	}

}

EditShippingProfile.propTypes = {
	onSubmit: PropTypes.func.isRequired,
	designerId: PropTypes.number.isRequired,
	designerProducts: PropTypes.array.isRequired,
	shippingProfileId: PropTypes.number,
};

EditShippingProfile = connect(
	state => ({
		initialValues: state.editShippingProfile.initialValues,
		isLoading: state.editShippingProfile.isLoading,
		status: state.editShippingProfile.status,
		designerId: state.session.designerId || 1,
		designerProducts: state.editShippingProfile.designerProducts,
		shippingZones: state.listShippingZones.shippingZones,
		errorMsg: state.editShippingProfile.errorMsg,
	}),
	{
		fetchShippingProfile: actions.fetchShippingProfile,
		createShippingProfile: actions.createShippingProfile,
		updateShippingProfile: actions.updateShippingProfile,
		fetchProducts: actions.fetchProducts,
	}
)(EditShippingProfile);

export default EditShippingProfile;
