import * as types from './actionTypes';

export const loadData = data => ({
	type: types.EDIT_PRODUCT_LOAD_FORM,
	data
});

export const selectProduct = data => ({
	type: types.VIEW_CREATIONS_SELECT_PRODUCT,
	data
});

export const changeView = view => ({
	type: types.CHANGE_VIEW,
	payload: { view }
});
export const setSubjectKey = subjectKey => ({
	type: types.SET_SUBJECT_KEY,
	subjectKey
});