import localStorage from 'localStorage';
import axios from 'axios';
import qs from 'qs';
import {cl, isObj} from 'utils';
import {updateUserToken} from 'containers/Session/actionCreators';
import store from 'store';
//import { history } from 'ExtendedBrowserRouter';


//var store = importedStore();
//cl('store: ');
//cl(store);

axios.interceptors.request.use(config => {
    
	const session = JSON.parse(localStorage.getItem('session'));
	if (session) {
		config.headers['Authorization'] = 'Bearer ' + session.token.value;
	}
	if (config.method === 'get') {
		config.paramsSerializer = params => (
			qs.stringify(params, {
				encode: false,
			})
		);
	}
	
	return config;
});

axios.interceptors.response.use(response => {
    
    //check if the response contains 'token' - then token has been refreshed
    if( isObj(response) && 'tokenArray' in response.data) {
        
        cl('here is response.data: ')
        cl(JSON.stringify(response.data));
        //if token expired there is a 'logout' property
        if( 'logout' in response.data.tokenArray) {
            
            cl('logout is set and value is: ' + response.data.tokenArray['logout']);
            //since the token is invalid, logout  
            cl('will perform history.push(signout)');
            delete response.data.tokenArray;
            window.location.href = '/signout';          //will cause full refresh  
            
            
            return;
        } 
        var data = {
            value: response.data.tokenArray.token,
            expires: response.data.tokenArray.expires
        }
        cl(data, 'Axios response contained refreshed token. calling updateUserToken with: ')
        store.dispatch(updateUserToken(data));
        delete response.data.tokenArray;
        cl(response.data, 'updateUserToken was called, and tokenArray was deleted. result is: ');
    }
    return response;
    
}, error => {
	console.log('axios response interceptor: error! Will return error promise: ', error);
        if(error.response){
            if (error.response.status === 401) {
		console.log('401: invalid token');
		console.log(error.response.data.message);
            }
        }
	
    return  Promise.reject(error);
});


 

/**
 * Fetch products
 */
 
    export const fetchProducts = (args = {}) => {
	console.log(args, 'fetchProducts. args: ');
//'translations', 
	const params = Object.assign({}, {
		fields: ['designer', 'gallery', 'ratings', 'favorites', 'shipping'],
//                page: args['filters']['page'],
		count: 12,
		countries: args.madeIn || 'all',
		designers: args.madeBy || 'all',
		//country: args.designers ? 'all' : 'se'        // <-- purpose?
	}, args);

	console.log('params: ', params);
	
	return axios({
		method: 'get',
		url: '/api/products',
		params,
	});
};

//fetch by id or slug
export const fetchProduct = ({id, slug=false}) => {
       
	  if (id) {
            console.log('api.js: id is set, sending to product by id');
            return axios.get(`/api/product/${id}`);	
        } else if (slug) {
//            alert('slug is set:' + slug);
            console.log('api.js: fetching product by slug');
            return axios.get(`/api/product/${slug}`);

        } else {
                throw new Error('Slug or id is required.');
        }
};

export const fetchMessages = (id) => {
    
    return axios.get('/api/messages/' + id);
};

/**
 * Fetch designer
 */

export const fetchDesigner = ({ slug, id }) => {
	if (slug) {
		return axios.get(`/api/designer/${slug}`);
	} else if (id) {
		return axios.get(`/api/designer/${id}`);
	} else {
		throw new Error('Slug or id is required.');
	}
};

export const fetchDesigners = args => {
        cl(args, 'fetch designers - args:')
        let params = args;
	return axios.get(`/api/designers`, {
		params
	});
};


export default {

        // Find user country by their IP
        getUserIp: () => {
            cl('getUserIp will fetch IP')
            return axios.get('http://ip-api.com/json');
        },
        
	createProduct: (formData) => {
		return axios.post('/api/products', formData);
	},
	
	fetchProducts,
	fetchProduct,
	fetchMessages,

	updateProduct: (id, data) => {
                cl(id, 'updateproduct - id is: ')
		return axios.post('/api/product/' + id, data);
	},

	fetchProductDetails: () => {
		return axios.get('/api/product/1');
	},

	fetchDesignerList: () => {
		return axios.get('/api/designers');
	},

	updateDesigner: (args) => {
		return axios.post('/api/designer', args);
	},

	fetchImprint: () => {
		return axios.get('/api/imprint');
	},

	updateImprint: (args) => {
		return axios.post('/api/imprint', args);
	},

	sendPayment: (formData) => {
		return axios.post('/api/checkout', formData);
	},
        
//        sendPayment: (formData) => {
//                        return axios.post('/api/checkout', formData);
//                },
                
	requestLogin: (email, password) => {
            cl( 'requestLogin: email is : ' + email + ' , pass is ' + password )
            
		return axios.post('/api/tokens', {
			email,
			password,
		});
	},

	fetchDesigner,

        fetchCountries: () => {
                        return axios.get('/api/countries');
                },
        
	fetchCountriesAndContinents: () => {
		return axios.get('/api/countriescontinents');
	},

	fetchTranslations: (locale) => {
		return axios.get('/api/translations/' + locale);
	},

	fetchTerms: () => {
		return axios.get('/api/terms');
	},

	fetchTerm: (id) => {
		return axios.get('/api/term/' + id);
	},

	fetchShippingZones: (designerId) => {
		return axios.get('/api/designer/' + designerId + '/shipping/zones');
	},

        deleteShippingZone: (data) => {
            cl('delete zone: ')
            cl(data)
            return axios.delete('/api/designer/' + data.designerId + '/shipping/zone/' + data.zone, data);
        },                      
        
	fetchShippingZone: (shippingZoneId) => {
		return axios.get('/api/shipping/zone/' + shippingZoneId);
	},

	createShippingZone: (data) => {
		return axios.post('/api/shipping/zones', data);
	},

	updateShippingZone: (shippingZoneId, data) => {
		return axios.post('/api/shipping/zone/' + shippingZoneId, data);
	},

	fetchShippingProfiles: (designerId) => {
		return axios.get('/api/designer/' + designerId + '/shipping/profiles');
	},

	fetchShippingProfile: (shippingProfileId) => {
		return axios.get('/api/shipping/profile/' + shippingProfileId);
	},

	createShippingProfile: (data) => {
		return axios.post('/api/shipping/profiles', data);
	},
        
        createShippingProfileCustom: (data) => {
		return axios.post('/api/shipping/customprofiles', data);
	},
	
	updateShippingProfile: (shippingProfileId, data) => {
		return axios.post('/api/shipping/profile/' + shippingProfileId, data);
	},
	
	fetchShippingGeneral: (designerId) => {
		return axios.get('/api/designer/' + designerId + '/shipping/general');
	},
        fetchShippingCustom: (designerId) => {
		return axios.get('/api/designer/' + designerId + '/shipping/custom');
	},
        
	updateShippingGeneral: (designerId, data) => {
		return axios.post('/api/designer/' + designerId + '/shipping/general', data);
	},
        updateShippingCustom: (designerId, data) => {
                return axios.post('/api/designer/' + designerId + '/shipping/custom', data);
        },      
	prepareCheckout: (cart) => {
                console.log('prepareCheckout',cart);
                
		return axios.get('/api/checkout', {
			params: {
				cart: cart,
			},
		});
	},
        fetchFilterMenu: filterObject => {
            cl('fetchFilterMenu');
            
            return axios({
		method: 'get',
		url: '/api/filters',
		params: filterObject,
            }); 
        },
        
	fetchDesignerOrders: (designerId) => {
		return axios.get('/api/designer/' + designerId + '/orders');
	},

	fetchCustomerOrders: (customerId) => {
		return axios.get('/api/user/' + customerId + '/orders');
	},

	updateOrder: (orderId, data) => {
		return axios.post('/api/order/' + orderId, data);
	},

	fetchUser: ({ id }) => {
		return axios.get(`/api/user/${id}`);
	},
	updateUser: (userId, data) => {
		return axios.post(`/api/user/${userId}`, data);
	},
        updateUserAddresses: (userId, data) => {
                return axios.post(`/api/useraddresses/${userId}`, data);
        },
	updateDesigner: (args) => {
		return axios.post('/api/designer', args);
	},
        updateBankAccount: (userId, data) => {
                return axios.post(`/api/designer/${userId}/account`, data);
	},

};
