import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, getFormValues, SubmissionError, isValid, FormSection,formValueSelector,submit } from 'redux-form';
import { DaylicacyCheckbox } from 'components/Form';
import {Select} from 'redux-form-material-ui';
// import DaylicacyTextField from 'components/Form/DaylicacyTextField';

import { TextField } from 'redux-form-material-ui';
import Paper from 'material-ui-old/Paper';

import DaylicacyButton from 'components/DaylicacyButton';
//import { MenuItem } from 'material-ui-next/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { selectHydratedAll as selectCountries, selectCountryNameById } from 'data/modules/countries';
import {selectUserAddresses,selectUserDeliveryAddress} from 'containers/Session/selectors';  
  

import {Link} from 'react-router-dom';
import { select } from 'redux-saga/effects';

const paperStyle = {
	padding: '16px', 
	marginBottom: '16px'
};
const required = value => (value ? undefined : 'Required')
 
let Delivery = (props) => {

    const { invalid , pristine , submitting, formValues  } = props;
    // const deliveryCountryId = formValues && 'shipping_destination' in formValues 
    //             ? formValues.shipping_destination : null
            

    return(
	<div className="cx12 cs8 checkout-delivery"> 
        <form onSubmit={props.handleSubmit}>
            
            <Paper style={paperStyle} className="checkout-delivery-delivery">
                
                <h3>Delivery address</h3>
                <div className="margin-bottom1">
                    <Field
                        name="delivery_title"
                        component={TextField}
                        validate={[required]}
                        label="Title"
                    />
                </div>
                <div className="margin-bottom1">
                    <Field
                        name="delivery_first_name"
                        label="First Name"
                        validate={[required]}
                        component={TextField}
                    />
                </div>
                <div className="margin-bottom1">
                    <Field
                        name="delivery_last_name"
                        label="Last Name"
                        validate={[required]}
                        component={TextField}
                    />
                </div>
                <div className="margin-bottom1">
                    <Field
                        name="delivery_company"
                        label="Company" 
                        component={TextField}
                    />
                </div>
                <div className="margin-bottom1">
                    <Field
                        name="delivery_address"
                        label="Address"
                        validate={[required]}
                        component={TextField}
                    />
                </div>
                <div className="margin-bottom1">
                    <Field
                        name="delivery_zip_code"
                        label="Zip Code"
                        validate={[required]}
                        component={TextField}
                    />
                </div>
                <div className="margin-bottom1">
                    <Field
                        name="delivery_city"
                        label="City"
                        validate={[required]}
                        component={TextField}
                    />
                </div>
                <div className="margin-bottom1 input-imitation">
                    <div>                 
                        <label className="disabled">{props.deliveryCountryName}</label>
                        {/* <Field 
                            className="mui-select-input delivery-country-id"
                            name="delivery_country_id" 
                            component={Select}
                            validate={[required]}
                            label="Country"
                        > */}
                            {/* {props.countries.map((country, index) => 
                                <MenuItem
                                    key={`country_${country.id}`}
                                    value={country.id} 
                                >
                                {country.name}
                                </MenuItem>
                            )} */}
                        {/* </Field> */}
                        <div></div>
                        
                    </div>
                    <div className="notice1"><p>Make sure that the country you chose is correct for this address.</p>
                        <p>(To change country, go back to previous step.)</p></div>
                </div>
                <div className="margin-bottom1">
                    <Field
                        label="Email"
                        name="email"
                        validate={[required]}
                        component={TextField}
                    />
                </div>
                <div className="margin-bottom1">
                    <Field
                        label="Repeat Email"
                        name="repeatEmail"
                        validate={[required]}
                        component={TextField}
                    />
                </div>
                <div className="margin-bottom1">
                    <Field
                        label="Phone"
                        name="phone" 
                        component={TextField}
                    />
                </div>
            </Paper>
            <Paper style={paperStyle} className="checkout-delivery-billing-address">
                <h3 style={{ marginBottom: '16px' }}>
                    Billing address
                </h3>
                <div className="margin-bottom1">
                    <Field
                        label="Same as delivery address"
                        name="billingAddress"
                        component={DaylicacyCheckbox}
                    />
                </div>
                
            </Paper>
            <div className="row right-button-ctr margin-bottom1">
                <DaylicacyButton 
                    type={'submit'} 
                    disabled={invalid || pristine || submitting}
                >
                    {props.tr.next || "Next "} <div className="fa fa-arrow-right"></div>
                </DaylicacyButton>
			</div>
           
        </form>           
	</div>
    )
};

Delivery = reduxForm({
    form: 'checkout',
    // enableReinitialize:true,
	keepDirtyOnReinitialize: true,
	destroyOnUnmount: false,
    forceUnregisterOnUnmount: false
})(Delivery);
  

const selector = formValueSelector('checkout'); 


Delivery = connect(
	(state, ownProps) => {
        var selectedDeliveryCountryId = selector(state,'shipping_destination');

        // var userDeliveryAddress = state.session.signedIn ? selectUserDeliveryAddress(state) || '' : '';  

        return {
            // initialValues: {...userDeliveryAddress},
            deliveryCountryName: selectCountryNameById(state, selectedDeliveryCountryId),
            formValues: getFormValues('checkout')(state),
            userAddresses: ownProps.loggedIn ? selectUserAddresses(state) : '', 
            countries: selectCountries(state),
            tr: state.translations
        }
    })(Delivery);

export default Delivery;
