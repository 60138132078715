import React from 'react';
import PropTypes from 'prop-types';
import { SortableContainer } from 'components/Sortable';

const SortableGrid = (props) => {
	const { onSortEnd, children } = props;

	return(
		<SortableContainer
			axis="xy"
			transitionDuration={200}
			onSortEnd={onSortEnd}
		>
			{ children }
		</SortableContainer>
	);
};

SortableGrid.propTypes = {
	children: PropTypes.node.isRequired,
	onSortEnd: PropTypes.func.isRequired,
};

export default SortableGrid;