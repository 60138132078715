import React from 'react';

// Components
import FooterWidget from './FooterWidget';
import FooterPopover from './FooterPopover';


class SecurePayments extends React.Component {

	constructor() {
		super();

		this.state = {
			open: false,
		};
	}

	handleMouseEnter(e) {
		this.setState({
			open: true,
			anchorEl: e.currentTarget,
			width: window.getComputedStyle(e.currentTarget).width,
		});
	}

	handleMouseLeave() {
		this.setState({
			open: false,
		});
	}

	paypalInfo(text){
		return {__html: text};
	}
	render() {
		const d = React.DOM;
		const securePaypalText =  this.props.tr.secure_payment_with_paypal_text;
		return(
				<FooterWidget
					icon="fa-lock"
					label={this.props.tr.secure_payments}
					handleMouseEnter={this.handleMouseEnter.bind(this)}
					handleMouseLeave={this.handleMouseLeave.bind(this)}
				>
					<FooterPopover 
						open={this.state.open}
						anchorEl={this.state.anchorEl}
						width={this.state.width}
					>
						<div>
							<h2>{this.props.tr.secure_payment_with_paypal}</h2>
							<span>dangerouslySetInnerHTML={this.paypalInfo(securePaypalText) }</span>
							{/* { d.span({dangerouslySetInnerHTML:{__html: this.props.tr.secure_payment_with_paypal_text }}) } */}
							<div className="k-dropdown__close">x</div>
						</div>
					</FooterPopover>
			</FooterWidget>
		);
	}
}

export default SecurePayments;