import styled from 'styled-components';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: stretch;
	margin-bottom: 10px;
	color: #333;
	padding: 10px;
`;

export const DividerContentBefore = styled.div`
	height: 1px;
	background: #ccc;
	flex: 1;
	margin-right: 10px;
`;


export const DividerContent = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
`;

export const DividerContentAfter = styled.div`
	height: 1px;
	background: #ccc;
	flex: 1;
	margin-left: 10px;
`;