import 'babel-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
//import { ConnectedRouter } from 'react-router-redux';             //  alternative: connected-react-router  
//import { MuiThemeProvider as NewMuiThemeProvider, createMuiTheme } from 'material-ui-next/styles';
import { MuiThemeProvider as NewMuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import MuiThemeProvider from 'material-ui-old/styles/MuiThemeProvider';

//import { createBrowserHistory } from 'history'      //  connected-react-router  
//import createHistory from 'history/createBrowserHistory';
import injectTapEventPlugin from 'react-tap-event-plugin';
import LanguageProvider from 'containers/LanguageProvider';
import moment from 'moment';
import 'moment/locale/en-gb';
import store from './store';
import App from './views';
import { AppContainer } from 'react-hot-loader';
 
import ExtendedBrowserRouter from './ExtendedBrowserRouter';

import blue from '@material-ui/core/colors/blue';

//IE11 polyfill
var URLSearchParams = require('url-search-params');


/**
 * Stylesheets
 */

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import './sass/index.scss';
 
moment.locale('en-gb');

// History used by react-router    

//const history = createBrowserHistory();     //connected-react-router
//const history = createHistory();

// Required by @material-ui/core
// injectTapEventPlugin();            //temporarily disabled - causes error

//Muitheme

const theme = createMuiTheme({
    
        something: {
            backgroundColor: '#EA28EE'
        },
        button: {
            backgroundColor: '#EA28EE'
        },
	palette: {
                primary: {
                         // "light/dark : will be calculated from palette.primary.main"
                        light: '#757ce8',
                        main: 'rgb(211,207,218)',
                        dark: '#002884',
                        contrastText: '#fff',
                },
                secondary: {
                        light: '#ff7961',
                        main: 'rgb(255, 64, 129)',
                        dark: '#ba000d',
                        contrastText: '#fff',
                } 
        },
        overrides: {
                raisedSecondary: {
                        
                }    
        },
	status: {
		danger: 'orange',
        },
});

/**
 * render(Component)
 *
 * Renders the app with HMR support
 */

const render = Component => {
	ReactDOM.render(
            <Provider store={store}> 
                    <LanguageProvider>
                                {/* <ConnectedRouter history={history}> */}
                                <NewMuiThemeProvider theme={theme}>
                                <MuiThemeProvider>
                                        <AppContainer>
                                                <Component />
                                        </AppContainer>
                                </MuiThemeProvider>
                                </NewMuiThemeProvider>
                          {/* </ConnectedRouter> */}
                    </LanguageProvider> 
            </Provider>,
            document.getElementById('root')
	);
};

render(App);

// Hot module replacement
if (module.hot) {
	module.hot.accept('./views', () => render(App));
}