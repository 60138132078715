import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import { PropTypes } from 'prop-types';

//import CircularProgress from 'material-ui-next/CircularProgress';
import CircularProgress from '@material-ui/core/CircularProgress';

import DesignerTabs from './components/DesignerTabs';
import Profile from './components/Profile';
import DesignerCard from 'containers/DesignerCard';
import Alert from 'components/Alert';
import Banner from 'components/Banner';
import { requestData } from 'data/actions';
import { selectDesigners } from 'data/selectors';
import DesignerProductList from './components/DesignerProductList';
import Content from 'components/Content';
import Collections from 'containers/Collections';
import { getDesignerNotice,selectDesignerBySlug, selectDesignerById } from 'data/modules/designers';
import { selectUserById } from 'data/modules/users';
import { throttle } from 'lodash';
import PageNotFound from 'views/PageNotFound';
 
import {cl} from 'utils';

/* if designer is not available, will be fetched :
		requestData - 'designer', triggers saga worker "fetchDesigner()" in designers module
		api call function: fetchDesigner
		backend endpoint: 'designer'
		function: 'getDesignerById' or 'getDesignerBySlug' 

*/
class DesignerProfile extends Component {

	constructor(props) {
		super(props);

		const { requestData, match: { params } } = this.props;
		this.slug = params.slug;
 
 
		this.handleTabChange = this.handleTabChange.bind(this);
	}

	componentWillMount(){

        // check if designer has 'products' and 'shippingProfiles' (if not fetching and request did not fail)
		if(!this.props.requestStatus.fetching && this.props.requestStatus.requestStatus !== 'failed' &&
		(!this.props.designer || !('products' in this.props.designer) || !('shippingProfiles' in this.props.designer.products))) {
            console.log('Data missing - try fetching designer'); 
                        
            this.props.requestData({
                type: 'designer',
                args: {
                    id: '',
                    slug: this.props.match.params.slug,
                    fields: [
                        'shop_banner'
                    ]
                }
            }); 
        }else{
			cl('componentWillMount - no action')
		}
		  
	}
	// componentWillUpdate(){
	// 	console.log('componentWillUpdate. ');

	// 	console.log( !this.props.designer || !this.props.designer.products ? "no designer or prods" : "designer or prods");
	// }
 
	handleTabChange(Tab) {
		const value = Tab.props.value;

		if (value) {
			cl(value, 'tab : ',)
			const { history, match }	= this.props;
			history.push(`${match.url}/${value}`);
		}
	}

	render() {
		let banner;
		const { match, designer,requestStatus} = this.props;		//designers

		const isActive = match.url;

		const className = isActive ? 'active' : 'hello';
  
		if(requestStatus.requestStatus == 'failed' && requestStatus.lastRequestError.statusCode == 404){
			return <PageNotFound />
		} 
		
		if(requestStatus.requestStatus){
			cl(requestStatus.requestStatus, 'requestStatus: ')
		}
		// return <p>ok</p>
		if (!designer || !designer.products) {

			return (
				<div className="mui-circular-container"><CircularProgress /></div>
			);
		}
		// 

		// if(!this.props.location.state.designerId){
		// 	return (
		// 		<div>no designer id</div>
		// 	);
		// }
	 
		if (designer.shop_banner != null) {
			banner = designer.shop_banner.url;
		} else {
			banner = '/img/placeholders/banner_958x320.jpg';
		}
								
		return (
			<div className="container-fluid padded-top margin-bottom">
				<div className="profile">
				
					<Banner
						className="banner"
						background={banner} />
					{ designer.shop_notice && 
						<Alert cssClasses="alertbox alert-notice" >{designer.shop_notice}</Alert> 
					} 
					<div className="row">
						<div className="cx2">
							 <DesignerCard 
								mode="portrait"
								slug={designer.shop_slug || ''}
								userId={designer.user_id || ''}
							/> 
							 { designer.shop_notice && <Alert cssClasses="alertbox alert-notice" >{designer.shop_notice}</Alert> }
						</div>
						<div className="cx10">
							<div className="tabs margin-bottom">
								<DesignerTabs 
									onChange={this.handleTabChange}
									className={this.className}
								/>
							</div>
							<Switch>
                               
                                <Route
                                        exact
                                        path={`${match.url}`}
                                        render={() => 
											<DesignerProductList 
												designer={designer}
                                                currency={designer.shop_currency.code.toLowerCase()} 
                                                // products={designer.products} 
                                                slug={this.slug}
                                         />
                                        } />   
                                    <Route
                                        path={`${match.url}/creations`}
                                        render={() => 
                                            <DesignerProductList 
												currency={designer.shop_currency.code.toLowerCase()} 
												designer={designer}
                                                // products={designer.products} 
                                                slug={this.slug} />
                                        } /> 
								<Route 
									path={`${match.url}/collections`} 
									component={Collections} />
								   <Route
									path={`${match.url}/profile`}
									render={() =>
										<Profile slug={this.slug} designer={designer} />
									} />   
								<Route
									path={`${match.url}/imprint`}
									render={() => 
										<Content>Lorem ipsum</Content>
									} />
								<Route
									path={`${match.url}/faq`}
									render={() => 
										<Content>Faq</Content>
									} />
								<Route
									path={`${match.url}/terms-and-conditions`}
									render={() => <Content>Toc</Content>} />
								<Route
									path={`${match.url}/payment-and-shipping`}
									render={() => <Content>PaymentAndShipping</Content>} />
							</Switch>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

DesignerProfile.propTypes = {
	match: PropTypes.object.isRequired,
};

export default connect(
	(state, ownProps) => {
		cl(ownProps.match.params.slug, 'ownProps.match.params.slug is :')
		
		return {
			requestStatus: state.data.designers.requestStatus,
			designer: selectDesignerBySlug(state, ownProps.match.params.slug),
		
			//designer: selectDesignerById(state, ownProps.location.state ? ownProps.location.state.designerId : ''),
		}
	},
	{ requestData }
)(DesignerProfile);

// { designer.products && 
//     <div>
        
//     </div>
// }