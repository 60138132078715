import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import { actions } from './actions';
// import CreateEditShippingMethodsForm from './components/CreateEditShippingMethodsForm';

class CreateShippingZone extends Component {

	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.props.fetchShippingGeneral(this.props.designerId);
	}

	handleSubmit(data) {
		 alert('ok');
	}

	render() {
		return (
			<div>
				{/* <SomeForm
					initialValues={this.props.initialValues}
					isLoading={this.props.isLoading}
					status={this.props.status}
					onSubmit={this.handleSubmit}
					shippingMethods={window.d_store.shipping_methods}
					shippingSuppliers={window.d_store.shipping_suppliers}
					shippingZones={this.props.shippingZones}
					errorMsg={this.props.errorMsg}
				/> */}
			</div>
		);
	}

}

CreateShippingZone.propTypes = {
	// onSubmit: PropTypes.func.isRequired,
	// designerId: PropTypes.number.isRequired,
};

CreateShippingZone = connect(
	state => ({ 
	}),
	{
		// fetchShippingGeneral: actions.fetchShippingGeneral,
		// updateShippingGeneral: actions.updateShippingGeneral,
	}
)(CreateShippingZone);

export default CreateShippingZone;