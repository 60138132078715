import React from 'react';
import { Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn } from 'material-ui-old/Table';

const renderRow = (product, index) => {
	const {
		product_number,
		name,
		price,
	} = product;

	return(
		<TableRow key={index}>
			<TableRowColumn>{ product_number }</TableRowColumn>
			<TableRowColumn>{ name }</TableRowColumn>
			<TableRowColumn>Dress</TableRowColumn>
			<TableRowColumn>lorem</TableRowColumn>
			<TableRowColumn>summer_2016</TableRowColumn>
			<TableRowColumn>ship_profile_dress</TableRowColumn>
			<TableRowColumn>Not valid</TableRowColumn>
			<TableRowColumn>Active</TableRowColumn>
			<TableRowColumn>{ price }</TableRowColumn>
			<TableRowColumn>2</TableRowColumn>
		</TableRow>
	);
};

const DaylicacyProductTable = ({ products }) => {
	return(
		<Table>
			<TableHeader>
				<TableRow>
					<TableHeaderColumn>Product number</TableHeaderColumn>
					<TableHeaderColumn>Product Description</TableHeaderColumn>
					<TableHeaderColumn>Category</TableHeaderColumn>
					<TableHeaderColumn>Personal Category</TableHeaderColumn>
					<TableHeaderColumn>Collection</TableHeaderColumn>
					<TableHeaderColumn>Shipping profile</TableHeaderColumn>
					<TableHeaderColumn>Gift ideavalid until</TableHeaderColumn>
					<TableHeaderColumn>Status</TableHeaderColumn>
					<TableHeaderColumn>Price (EUR)</TableHeaderColumn>
					<TableHeaderColumn>Quantity</TableHeaderColumn>
				</TableRow>
			</TableHeader>
			<TableBody>
				{ products.map(renderRow) }
			</TableBody>
		</Table>
	);
};

export default DaylicacyProductTable;