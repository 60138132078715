import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import { CardActions } from 'material-ui-old/Card';

import CardActions from '@material-ui/core/CardActions';
import Badge from 'components/Badge';
import IconButton from 'components/IconButton';
import { prepareAddToCart } from 'containers/Cart/actionCreators';
import { openCart, closeCart } from 'containers/Cart/actions';
import { openSnackbar } from 'containers/Snackbar/actions';
import {stall} from 'utils';

const styles = {
	textAlign: 'center',
};


class CreationControls extends Component {

	constructor(props) {
		super(props);
	}

	prepareAddToCart = async(product) => {
		console.log('creationControls: prepareAddToCart ');
		const { prepareAddToCart, openCart, closeCart } = this.props;
		console.log('product id :' + prepareAddToCart + ' added to cart');

		//Todo - needs an anchor position (maybe add to redux state, then retrieve in ToggleCart)
		//or upgrdae to Popup mui v1 and use 'anchorReference'
		
		openCart();
		//alert('msg before stall');

		await stall(1500);
		// alert('msg after stall');
		prepareAddToCart(product);
		closeCart.bind(this);
         

	}
 
	render() {
		const { 
			product,
			productId, 
			openSnackbar,
		} = this.props;

		return(
			<CardActions>
				<div className="row">
					<div className="cx4" style={styles}>
						<Badge 
							value={22}
							position="bottom">
							<IconButton
								tooltip="Favorite designer"
								iconClassName="fa fa-archive"
								onClick={() => console.log('click')}
							/>
						</Badge>
					</div>
					<div className="cx4" style={styles}>
						<Badge 
							value={10}
							position="bottom">
							<IconButton
								tooltip="Favorite product"
								iconClassName="fa fa-heart"
							/>
						</Badge>
					</div>
					<div className="cx4" style={styles}>
						<IconButton
							tooltip="Add to cart"
							iconClassName="fa fa-shopping-bag"
							onClick={() => {
								this.prepareAddToCart(product);
								openSnackbar({
									message: 'Product has been placed in cart',
								});
							}}
						/>
					</div>
				</div>
			</CardActions>
		);
	}
}

CreationControls.propTypes = {
	prepareAddToCart: PropTypes.func.isRequired,
	productId: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number
	]),
	openSnackbar: PropTypes.func.isRequired,
};

export default connect(
	null,
	{ prepareAddToCart, openSnackbar, openCart, closeCart }
)(CreationControls);
