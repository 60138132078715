import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import { reduxForm, Field, getFormValues } from 'redux-form';

import {
	LOGINFORM_SUBMIT,
	LOGINFORM_SUCCESS,
	LOGINFORM_FAILURE
} from '../../../actionTypes';

import {Checkbox, Select, TextField} from 'redux-form-material-ui';

import MuiCheckbox from '@material-ui/core/Checkbox';
//import { MenuItem } from 'material-ui-next/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress'
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Alert from 'components/Alert';
import DaylicacyButton from 'components/DaylicacyButton';
import { DaylicacyCheckbox } from 'components/Form';
// import SelectField from 'components/SelectField';
// import DaylicacyTextField from 'components/Form/DaylicacyTextField';
import FileField from 'components/FileField';
import TabControl from './TabControl';
import { DaylicacyFlagIcon } from 'components/Icon';

import Continent from './Continent'; 
import {cl} from 'utils'
import { throttle } from 'lodash';





/*	Use both for create and update.
	
	Handlesubmit (in parent) calls api method "updateShippingZone" or createShippingZone,
		if edit: 
			sends to : /api/shipping/zone/' + shippingZoneId
			function: postShippingZones
 
	Components:
		euSelections:			Checkbox functions for Europe to select eu or non-eu countries
		CheckBoxField:			Redux Form checkbox field for each country
		ContinentColumn: 		Countries are displayed in columns
		EditShippingZoneForm: 	form to select countries from continents
*/

const euSelections = [
	{
		code: '',
		name: 'Select All Europe',
		value: 'euChecked1'
	},
	{
		code: 'EU',
		name: 'Select EU Countries',
		value: 'euChecked2'
	},
	{
		code: '',
		name: 'Select All except EU countries',
		value: 'euChecked3'
	}

]
	 

class EditShippingZoneForm extends Component {

	constructor(props){
		super(props)

		this.state = { 

			euChecked1 : false,
			euChecked2 : false,
			euChecked3 : false,
		}
	}
	  
	handleEUOnchange = name => event =>  {

		const {otherZoneCountryIds, eucountries,continents} = this.props;
 
		// console.log(Object.keys(continents[0].Europe));

		console.log( 'setting ' + name + ' to ' + event.target.checked);
		 this.setState({ [name] : event.target.checked })
 
		//  var some = 29;
		//  this.props.change(`countries[${some}]`, event.target.checked);

		if(name == 'euChecked1') {
			//all europe
			(continents[0].Europe).map( europeCountry => {

				let skip = false;
				//skip countries taken in other zone
				otherZoneCountryIds && otherZoneCountryIds.map( countryId => {
					if(countryId == europeCountry.id){
						console.log('skipping ' + countryId + ' because otherZoneCountry')
						skip = true;
					} 
				})

				if (!skip){
					return this.props.change(`countries[${europeCountry.id}]`, event.target.checked);
				}
			
				
			})
		}else if(name == 'euChecked2'){
		 
			//all eu countries
			Object.values(this.props.eucountries).map( eucountry => {
				
				let skip = false;
				//skip countries taken in other zone
				otherZoneCountryIds && otherZoneCountryIds.map( countryId => {
 
					if(countryId == eucountry.id){
						console.log('skipping ' + countryId + ' because otherZoneCountry')
						skip = true;
					} 
				})

				if (!skip){
					return this.props.change(`countries[${eucountry.id}]`, event.target.checked);
				}
				

				
			})

 
		}else if(name == 'euChecked3') {
 
			/* all except eu countries */
			//create id array of both otherZoneCountryIds and eucountries
			var exceptionCountryIds = [];

			if(otherZoneCountryIds){
				//add country ids from existing zones
				exceptionCountryIds = otherZoneCountryIds.map( countryId => countryId);
 
			}
			//add eu countries
			Object.values(eucountries).forEach( euc => 
				exceptionCountryIds.push(euc.id)
		   	)

			Object.values(this.props.continents[0].Europe).map( eucountry => {

				let skip = false;

				//skip exceptionCountryIds 
				 exceptionCountryIds.map( exceptionCountryId => {
					if(exceptionCountryId == eucountry.id){
						console.log('skipping ' + exceptionCountryId + ' because otherZoneCountry')
						skip = true;
					} 
				}) 
				if (!skip){
					return this.props.change(`countries[${eucountry.id}]`, event.target.checked);
				}
				

			});

 

		}
		
		
	}

	
	componentDidUpdate(prevProps, prevState){
  
		 if(prevProps.submitSucceeded == this.props.submitSucceeded) {
 	 
			 console.log('prevProps.submitSucceeded is identical to this.props.submitSucceeded');
			
		 }else{
			console.log('componentDidUpdate , prevProps.submitSucceeded is ' + prevProps.submitSucceeded)
			console.log('componentDidUpdate , this.props.submitSucceeded is ' + this.props.submitSucceeded)
			 //Redirect after form post if status have changed to success  
			if(this.props.submitSucceeded) {
				console.log('prevProps.submitSucceeded: ' + prevProps.submitSucceeded + ' , this.props.submitSucceeded: ' + this.props.editShippingZoneStatus +
				' - run redirectAfterFormPost');

				//call redirectAfterFormPost in Shipping. (make sure it is set first)
				 
				if(typeof this.props.redirectAfterFormPost === 'function'){
					cl('this.props.redirectAfterFormPost is a function - run it')
					this.props.redirectAfterFormPost('success')
				}else{
					cl('this.props.redirectAfterFormPost is not set - no view change - fallback alert will show instead');
				}
					
			}
		} 
	}
 
	render() {
 
		const {
			initialValues, 
			selectedContinent, 
			invalid, pristine, 
			submitting, 
			formPostedCount, 
			editShippingZoneStatus, 
			submitSucceeded,
			editShippingZoneErrorMsg
		} = this.props;

		const {euChecked1, euChecked2, euChecked3} = this.state;

		var continents = (this.props.continents && this.props.continents[0]) ? this.props.continents[0] : undefined;
		var continentNames = [ 'Europe','Asia','Africa','North America','Central America','South America', 'Oceania','Other'];
		var selectedContinentName = continentNames[selectedContinent];
		
		if(continents && (selectedContinent === 0 || selectedContinent > 0)) {
			
			//4 columns: continent's countries divided by 4 for each column 
			var totalContinentCountries = continents[selectedContinentName].length;
			var continentColumnCount = Math.ceil(totalContinentCountries / 4);
			// console.log('continentColumnCount is ' + continentColumnCount);
 
		} 
	  

		return (
		<form 
			className="edit-shipping-zone-form"  
			onSubmit={ this.props.handleSubmit } 
		>
		{/* <h2>editShippingZoneStatus: {editShippingZoneStatus}</h2> */}

			<div className="row">
				<div className="cx3">
					<label>Name</label>
				</div>
				<div className="cx9">
					<Field 
						className="mui-text-input-min-width mui-bottom-border"  
						type="text" 
						name="name"
						placeholder="Name" 
						component={TextField}
					/>
				 
				</div>
			</div>
			<div className="row">
				<div className="cx3">
					<label>Apply VAT</label>
				</div>
				<div className="cx9">
					<Field 
						className="mui-select-input"
						name="vat"
						label="Apply VAT"
						component={Select}
					>
						{ [{ value: 0, label: 'No' },{ value: 1, label: 'Yes' }].map((option, index) => (
							<MenuItem
								key={'vat_' + index}
								value={option.value} 
							>
								{option.label}
							</MenuItem>
						)) }
					</Field>
				</div>
			</div>
			<div> 
				{!continents && <div className="mui-circular-container"><CircularProgress/></div>}

				{continents && (
	
					<div>
						
						<TabControl 
							selectedContinent={selectedContinent}
							onChange={this.props.onContinentChange}  
						>
						{
							Object.keys(continents).map( (continent, index) => (
								
							<li key={index} className="cx12 cxs6 cs1 md1">
								{continent}
								<span className="fa fa-sort-down"></span>
							</li> 
							))
							} 
						</TabControl>
					</div>
				)}
			
					{continents && selectedContinent >= 0 &&
						(
							<div className="continent-form-part">
							<div className="country-columns-container">
							{ selectedContinentName in continents &&
									<div>
										{ selectedContinentName === 'Europe' &&
											<div className="row eu-country-selection cx12 ">
												{ euSelections.map( (euSelection, index) => {

													// console.log('euSelection index is ' + index);
													let checkedValue=false;
													if(index === 0){
														checkedValue = euChecked1
													}else if(index===1){
														checkedValue = euChecked2
													}else if(index===2){
														checkedValue = euChecked3
													}
													let className = (index === 2) ? "cxs6 cs6" : "cxs6 cs3";
													
													return(
													<div key={index} className={className}>
													
														<MuiCheckbox
															//className="redux-form-input"
															item={euSelection}
															checked={checkedValue}
															onChange={this.handleEUOnchange(euSelection.value)}
															value={euSelection.value}
														/> 
														<span className="fa-icon-span">
														<DaylicacyFlagIcon 
															countryCode={euSelection.code} 
															countryName={euSelection.name}
														/>
														</span>
														<span className="country-name">{euSelection.name}</span>
													
													</div>
													)
												}
												)}
											</div>
										}
										
										<Continent  
											euChecked1={euChecked1}
											euChecked2={euChecked2}
											euChecked3={euChecked3} 
											continentColumnCount={continentColumnCount}
											totalContinentCountries={totalContinentCountries}
											continents={continents}
											selectedContinentName= {selectedContinentName}
											initialValues={initialValues}
										/> 

											
									</div>
							}	
							</div>

							</div>
						)
					}
	
			</div>
			
			<div className="row">
				<div className="cx">
					<DaylicacyButton 
						type="submit"
						variant="raised" 
						className="mui-button-standard"  
						disabled={invalid || pristine || submitting}
					>
						{this.props.tr.save || 'Save'}
					</DaylicacyButton>
				</div>
			</div>

			{ this.props.postStatus === 'failed' && 
				<Alert cssClasses="alertbox alert-error">{editShippingZoneErrorMsg}</Alert>
			}

			{/* fallback success alert:  (doesn't work - will show when re-entering zone form) */}
		
			 { this.props.postStatus  == 'success' &&		 
				<Alert 
					cssClasses="alertbox alert-success"  
					delay={3500}  
				>
					Saved
				</Alert>
			} 
			 
		</form>

		)
	}
}
	{/* call function in "Shipping" to do redirect and display message  */}
EditShippingZoneForm.propTypes = {
	isLoading: PropTypes.bool.isRequired,
	status: PropTypes.string.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,
	countries: PropTypes.array.isRequired,
};

const validate = values => {
	let errors = {};

	const required = ['name', 'vat', 'countries'];

	required.forEach(field => {
		if (!(field in values)) {
			errors[field] = 'Required';
		}
	});

	if(values.countries) {
        for (var key in values.countries) {
            if (values.countries[key] !== true) {
                delete values.countries[key];
            }
        }
    }

	return errors;
};

EditShippingZoneForm = reduxForm({
	form: 'editShippingZoneForm',
	enableReinitialize:true,
	// keepDirtyOnReinitialize: true,
	// destroyOnUnmount: false,
    // forceUnregisterOnUnmount: false,
	validate,
})(EditShippingZoneForm);
 

export default EditShippingZoneForm;
 



	 