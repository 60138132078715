import React from 'react';
import PropTypes from 'prop-types';
import Badge from 'material-ui-old/Badge';

const initialStyles = {
	badgeStyle: {
		padding: 0,
		backgroundColor: 'rgb(255, 64, 129)',
		color: '#fff',
		zIndex: 100,
	},
	rootStyle: {
		padding: 0,
//		padding: '5px',
	}
};

const DaylicacyBadge = ({ 
	value, 
	children, 
	position 
}) => {

	let badgeStyle, rootStyle;

	rootStyle = initialStyles.rootStyle;

	if(position === 'bottom') {
		badgeStyle = Object.assign({}, initialStyles.badgeStyle, {
			top: 'auto',
			bottom: '-6px',
			right: '-10px',
			left: 'auto',
		});
	} else {
		badgeStyle = initialStyles.badgeStyle;
	}

	return(
		<Badge
			badgeContent={value || 0}
			badgeStyle={badgeStyle}
			style={rootStyle}
		>
			{ children }
		</Badge>
	);
};

DaylicacyBadge.propTypes = {
	position: PropTypes.string,
	children: PropTypes.node.isRequired,
};

export default DaylicacyBadge;