import * as types from './actionTypes'

const translationsReducer = ( state = {}, action ) => {

	switch( action.type ) {

	case types.FETCH_TRANSLATIONS_SUCCESS:
			return Object.assign({}, state, {
				...action.result.data
			});

		default:
			return state
	}
}

export default translationsReducer;
