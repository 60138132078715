export const STATE_KEY = 'profile';

const SET_PROFILE = 'SET_PROFILE';

export default function reducer(state = {}, action) {
	switch (action.type) {
		case SET_PROFILE:
			return {
				...state,
				...action.payload
			};
		default:
			return state;
	}
}

export const setProfile = slug => ({
	type: SET_PROFILE,
	payload: { slug },
});

export const selectProfile = state => state[STATE_KEY].profile;