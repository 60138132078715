//actionCreatorsBoilerPlate

import * as types from './actionTypes';

export const setShopProductsSortOption = (pageType, value) => {
	return({
		type: types.SET_SORT_OPTION,
		payload: { pageType, value },
	});
};

// export const actionCreator2 = () => {
// 	return({
// 		type: types.SOMETHING2,
// 	});
// };