import { combineReducers } from 'redux';
import headerReducer from './Header/reducers';
import footerReducer from './Footer/reducer';
import filterMenuReducer from 'containers/filters/components/reducer';

//state/interface
export default combineReducers({
	header: headerReducer,
	footer: footerReducer,
	filtermenu: filterMenuReducer
});