import * as types from './actionTypes';
import api from 'data/api';
import {
	call,
	put,
	takeLatest,
} from 'redux-saga/effects';
import {
	createProductSuccess,
	createProductFailed,
	fetchTermsSuccess,
	fetchTermsFailed,
} from './actionCreators';

import {  
	requestDataFailure,
	requestingStartLoading,
	successStopLoading,
} from 'data/actions';

import {cl} from 'utils';

import {addUserDesignerProduct} from 'data/modules/designers';

/**
 * worker saga - Create product 
 */

var subject = 'createProduct';

function* createProduct(action) {
	const { formData } = action; 
	try{
		yield put(requestingStartLoading('createProduct'));
		const result = yield call(api.createProduct, formData);
		cl(result, 'result: ');

		if(result.status === 401) {
			// Login pop
			console.log('status 401');
			//document.getElementById('sign_in').click();

		}else if('message' in result) {
			console.log( 'error message: ' + result.message);
		}
		yield put(successStopLoading('createProduct')); 
		yield put(addUserDesignerProduct(result.data))
		yield put(createProductSuccess(result));

	} catch(e) {
		console.log('createProduct error: ' + e);
		yield put(requestDataFailure(subject, e, e.response.status));
	}
}

function* fetchTerms(action) {
	try{
		const result = yield call(api.fetchTerms, action.id);
		yield put(fetchTermsSuccess(result));

	} catch(e) {
		yield put(fetchTermsFailed(e));
	}
}

export default function* listenerSaga() {
	yield takeLatest(types.REQUEST_CREATE_PRODUCT, createProduct);
	yield takeLatest(types.FETCH_TERMS, fetchTerms);
}
