import React from 'react';

const DaylicacyHamburgerButton = ({
	handleClick,
	active,
	hasClicked
}) => {

	let className;

	if(hasClicked) className = active ? 'on' : 'off';

	return(
		<button 
			id="toggle" 
			onClick={handleClick}
			className={className}
		>
			<span className="bar"></span>
			<span className="bar"></span>
			<span className="bar"></span>
		</button>
	);
};

export default DaylicacyHamburgerButton;