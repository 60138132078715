import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Step, Stepper, StepLabel } from 'material-ui-old/Stepper';

class CheckoutSteps extends Component {

	constructor(props) {
		super(props);
	}

	render() {
		return(
			<Stepper activeStep={this.props.stepIndex}>
				<Step>
					<StepLabel>Shopping basket</StepLabel>
				</Step>
				<Step>
					<StepLabel>Delivery</StepLabel>
				</Step>
				<Step>
					<StepLabel>Payment</StepLabel>
				</Step>
			</Stepper>
		);
	}

}

CheckoutSteps.propTypes = {
	stepIndex: PropTypes.number.isRequired,
};

export default CheckoutSteps;