import { combineReducers } from 'redux';

import * as types from './actionTypes';
import {cl} from 'utils'
//state.shop
const initialState = {
	
	creations: {
		currentPage: 1,
		currentQueryTotalSet: null,
		numPages: null,
		from: null,
		to: null
	},
	designers: {
		currentPage: 1,
		currentQueryTotalSet: null,
		numPages: null,
		from: null,
		to: null
	}			
};

const shopGeneralReducer = (state = initialState.shop, action) => {

	switch(action.type) {
		case types.SET_SHOP_PAGESLUG:

		return {
			...state,
			lastShopPageSlug: action.pageSlug
		}
		default:
			return state;
	}
	

}
const creationsReducer = (state = initialState.creations, action) => {
	switch(action.type) {
		case types.PAGINATION_NEXT:
			return state;
		case types.PAGINATION_PREV:
			return state;
			
		case types.ADD_PAGE_QUERY_DETAILS: 

			if(action.pageType == 'products'){

				return Object.assign({}, state, {
					currentPage: action.payload.currentSet,
					currentQueryTotalSet: action.payload.currentQueryTotalSet,
					numPages: action.payload.numPages,
					from: action.payload.from,
					to: action.payload.to
				});

			}else{
				return state;
			}
			
			
		case types.ADD_SHOP_NUM_PAGES: 

			return Object.assign({}, state, { 
				numPages: action.numPages
			});

		case types.CLEAR_PAGE_QUERY_DETAILS:

			if(action.pageType == 'creations'){
				cl('CLEAR_PAGE_QUERY_DETAILS - creations')
				return {
					currentPage: 1,
					currentQueryTotalSet: null,
					numPages: null,
					from: null,
					to: null
				}
			}
		default:
			return state;
	}
};

const designersReducer = (state = initialState.designers, action) => {
	switch(action.type) {
		case types.PAGINATION_NEXT:
			return state;
		case types.PAGINATION_PREV:
			return state;
			
		case types.ADD_PAGE_QUERY_DETAILS: 

			if(action.pageType == 'designers'){

				cl(action.payload, 'shop designers reducer, ADD_PAGE_QUERY_DETAILS ')

				return Object.assign({}, state, {
					currentPage: action.payload.currentSet,
					currentQueryTotalSet: action.payload.currentQueryTotalSet,
					numPages: action.payload.numPages,
					from: action.payload.from,
					to: action.payload.to
				});
				
			}else{
				return state;
			}
			
		case types.ADD_SHOP_NUM_PAGES: 

			return Object.assign({}, state, { 
				numPages: action.numPages
			});

		case types.CLEAR_PAGE_QUERY_DETAILS:

			if(action.pageType == 'designers'){

				cl('CLEAR_PAGE_QUERY_DETAILS - designers')
				return {
					currentPage: 1,
					currentQueryTotalSet: null,
					numPages: null,
					from: null,
					to: null
				}
			}
		default:
			return state;
	}
};

const shopReducer = combineReducers({ 

	creations: creationsReducer,
	designers: designersReducer,
});

export default shopReducer;
