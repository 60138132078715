import * as types from './actionTypes';
import { OPEN_CART, CLOSE_CART } from 'containers/Cart/actions';
import {  
	REQUESTING_START_LOADING,
	SUCCESS_STOP_LOADING,
	REQUEST_DATA_FAILURE
}from 'data/actions'
import {cl} from 'utils'

//state/cart
const initialState = {
	open: false,
	anchorEl: null,
	items:{},
	numProductsQuantity: 0,
	requestStatus: {
		fetching: false,
		requestStatus: null,
		lastRequestError: {
			statusCode:null,
			message: null
		} 
	}
};


const reducer = (state = initialState, action) => {
	switch(action.type) {
		case OPEN_CART:
			return Object.assign({},
				state,
				{ open: true }
			);
		case CLOSE_CART:
			return Object.assign({}, 
				state,
				{ open: false }
			);

		case types.ADD_TO_CART:

			const productId = action.payload.product.id;

			console.log('ADD_TO_CART - prod id: ' + productId);
			//check if item exist
			const existingItem = Object.entries(state.items).filter(item => {
				 
				if(item[0] == productId){
					return item[1];
				}
			});
			
             
			if(existingItem.length) {
                /* 	New quantity is +1 of existing item.
				 	Replace existing item with new one (because it may have updated data) 
				 */
				console.log('existing item: ',existingItem); 

				var quantity = existingItem[0][1].quantity += 1;

				//set quantity on new object 
				action.payload.product.quantity = quantity;

				/* 	if shipping profile is "default", delete any "shipping profile"
					property from the existing item	*/

				if(action.payload.product.shipping_profile_or_default === 'default'){
					console.log('shipping_profile is default - deleting shipping_profiles property ');
					delete  existingItem[0][1].shipping_profiles;
					console.log('after delete: ' , existingItem);
				}
				
				var cartItems = { 
						
					...state.items,
					[action.payload.product.id] :existingItem,
					[action.payload.product.id] : action.payload.product
					
				}
			}else { 
				//add new product
				console.log('adding new cart item');
				action.payload.product.quantity = 1; 

				var cartItems = { 
						
					...state.items,
					 [action.payload.product.id] : action.payload.product 
					
				}
				// console.log('cartItems: ',cartItems);
				// localStorage.setItem('cart', JSON.stringify(cartItems));
 
			}

			
			return Object.assign({}, state, {
				numProductsQuantity: state.numProductsQuantity +1,
				items: cartItems
					
			});
  
		case types.CART_REMOVE:

			console.log('CART REDUCER: \'CART_REMOVE\':  state items before remove:');
			cl(state.items)
			cl(action.payload.id, 'to remove action.payload.id: ')

            if(! action.payload.id in state.items){
				return state;
			}
			let index = action.payload.id;

			let stateCopy = Object.assign({}, state.items);
			
			delete stateCopy[index];

			let itemsQuantity = Object.keys(stateCopy).reduce((accumulator, item) => {
				return accumulator + stateCopy[item].quantity;
				
			},0);
			console.log('updated number of items (including quantity of each) in cart: ' + itemsQuantity);
			console.log('stateCopy with index removed: ',stateCopy);
            const newState =
            {
				...state,
				numProductsQuantity: parseInt(itemsQuantity),
                items: { 
                    ...stateCopy
				},
				
				
            };

			console.log('New state items: ', newState.items);
			
            return newState;
           

		case types.CART_REMOVE_ALL:
			return Object.assign({}, state, {

				items:{},
				numProductsQuantity: 0
			});

		case REQUESTING_START_LOADING: 
		
			if(action.payload == 'cart') {
				cl('reducer: REQUESTING_START_LOADING, action.payload: cart')
				return {
					...state,
					requestStatus: {
						...state.requestStatus,
						requesting: true,
						requestStatus:  'pending' 
					}
				} 
			}else{
				 return state;
			}
		 

		case SUCCESS_STOP_LOADING: 

			if(action.payload == 'cart') {
				return {
					...state,
					requestStatus: {
						...state.requestStatus,
						requesting: false,
						requestStatus:'success'
					}
					 
				}
			}else{
				return state;
			}
		 
		
		case REQUEST_DATA_FAILURE:
			
			if(action.payload.subject == 'cart'){
				cl(action.payload, 'REQUEST_DATA_FAILURE: cart: ') 
				return {
					...state,
					requestStatus: {
						requesting: false,
						requestStatus: 'failed',
						lastRequestError: {
							statusCode: action.payload.statusCode || '',
							message: action.payload.error.message || ''
						}
					}
					
				}
			}else{ 
				return state;
			}

		default:
			return state;
	}
};

export default reducer;
