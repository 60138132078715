import * as types from './actionTypes';

export const createProduct = (formData) => {
	return({
		type: types.REQUEST_CREATE_PRODUCT,
		formData
	});
};

export const createProductSuccess = (result) => ({
	type: types.REQUEST_CREATE_PRODUCT_SUCCESS, 
	result,
});

export const createProductFailed = error => ({ 
	type: types.REQUEST_CREATE_PRODUCT_FAILED, 
	error,
});

export const fetchTerms = () => ({
	type: types.FETCH_TERMS,
});

export const fetchTermsSuccess = (result) => ({
	type: types.FETCH_TERMS_SUCCESS,
	result,
});

export const fetchTermsFailed = (error) => ({
	type: types.FETCH_TERMS_FAILED,
	error,
});
