import React from 'react';
import PropTypes from 'prop-types';

const getStyles = props => {
	const styles = {};

	if (props.gutter) {
		styles.marginLeft = `-${props.gutter}em`;
		styles.marginRight = `-${props.gutter}em`;
	}

	return styles;
};

const Row = props => {
	return (
		<div 
			className={`row ${props.className}`}
			style={Object.assign({}, getStyles(props), props.style)}>
			{props.children}
		</div>
	);
};

Row.propTypes = {
	gutter: PropTypes.number,
};

export default Row;
