import React from 'react';
import { Wrapper, DividerContentBefore, DividerContentAfter, DividerContent } from './components/DividerContent';

const Divider = () => 
<Wrapper>
	<DividerContentBefore />
	<DividerContent>or</DividerContent>
	<DividerContentAfter />
</Wrapper>;

export default Divider;