import React from 'react';
import PropTypes from 'prop-types';
import Tabs from 'components/DaylicacyTabs';

const ShopTabs = ({
	numCreations, 
	onChange,
	selectedTab,
	fetchingProducts,
	requestStatus,
	currentDesignersQueryTotalSet,
	currentCreationsQueryTotalSet
}) => {
	const shopTabs = [
		{
			label: `Creations (${currentCreationsQueryTotalSet ? currentCreationsQueryTotalSet : 0})`,
			value: 'creations',
			loading: {fetchingProducts}
		},
		{
			label: `Designers (${currentDesignersQueryTotalSet ? currentDesignersQueryTotalSet : 0})`,
			value: 'designers',
			loading: null
		},
	];
	
	return (
		<Tabs 
			className="shop-tabs"
			selectedTab={selectedTab}
			tabs={shopTabs}
			onChange={onChange}
			theme="rounded" 

		/>
	);
};

ShopTabs.propTypes = {
	numCreations: PropTypes.number,
	numDesigners: PropTypes.number,
	onActive: PropTypes.func.isRequired,
};

export default ShopTabs;