import React from 'react';

class Profile extends React.Component {

    render() {

        return (
            <div className="margin-bottom">
                <p>Designer Profile</p>
                <p>Shop Name: {this.props.designer.shop_name} </p>
                <p>Shop Owner: {this.props.designer.user_id} </p>
            </div>
        );
    }
};

export default Profile;
