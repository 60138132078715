import { put, call } from 'redux-saga/effects';
import { ADD_ENTITIES, addEntities, requestDataFailure } from '../actions';
import api from '../api';
import axios from 'axios';


export const STATE_KEY = 'checkout';
 
export const LOADING_PROCESSED_CART = 'LOADING_PROCESSED_CART';
export const STOP_LOADING_PROCESSED_CART = 'STOP_LOADING_PROCESSED_CART';
export const ADD_PROCESSED_CART = 'ADD_PROCESSED_CART';

export const addProcessedCart = data => ({
	type: ADD_PROCESSED_CART,
	payload: data
})

export const loadingProcessedCart = data => ({
	type: LOADING_PROCESSED_CART,
	payload: data
})
export const stopLoadingProcessedCart = data => ({
	type: STOP_LOADING_PROCESSED_CART,
	payload: data
})

//state.data.checkout
const initialState = {
	shippingOptions: null,
	delivery: null,
	processedCart: null,
	loadingProcessedCart: false
};

export default function reducer(state = initialState, action) {
	
	switch (action.type) {
		case ADD_ENTITIES:
					console.log('modules-checkout reducer: ADD_ENTITIES');
					
			return {
				...state,
				...action.payload.checkout,
			};
		
		case ADD_PROCESSED_CART:
			console.log('checkout module reducer: ADD_PROCESSED_CART')
				return {
				...state,
				processedCart:{
					...state.processedCart,
					...action.payload,
				} 
			};
		
		case LOADING_PROCESSED_CART: {

			return {
				...state,
				loadingProcessedCart: true
			}
			
		}

		case STOP_LOADING_PROCESSED_CART: {

			return {
				...state,
				loadingProcessedCart: false
			}
		}

		default:
			return state;
	}
}

export function* prepareCheckout(action) {
	
	console.log('modues-checkout: prepareCheckout, this is the payload:')
	console.log(action.payload.args);
	try {
		//loadingFinalCart:true
		yield(put(loadingProcessedCart('loading')));

		const response = yield call(api.prepareCheckout, action.payload.args);

			console.log('preparecheckout response: ', response);
		
		//Response: Add cart contents to state
		 yield put(addProcessedCart(response.data));
		

		yield(put(stopLoadingProcessedCart('stop')));
			console.log('the api call is finished - setting state');
			console.log('response', response.data);

		// this.setState({
		// 	costs: response.data,
		// 	loadingFinalCart:false
		// });
 
	} catch (e) {
		console.log('REDUX SAGA HAS FAULED');
		yield put(requestDataFailure(e.message));
	}

 
}

export const selectAll = state => state.data.checkout;

