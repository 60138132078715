import { email as isEmail } from 'is_js';

const validate = values => {
	const errors = {};
	if(!values.email) {
		errors.email = 'Required';
	} else if(!isEmail(values.email)) {
		errors.email = 'Invalid email';
	}
	if(!values.password) {
		errors.password = 'Required';
	}
	
	return errors;
};

export default validate;
