import React from 'react';
import PropTypes from 'prop-types';
import Row from 'components/Row';
import Col from 'components/Col';
import styled from 'styled-components';

const SummaryList = styled.ul`
	fontSize: 0.7em;
	fontStyle: italic;
`;

const PurchaseSummary = props => {
	return (
		<div>
			<h3>
				Total purchase ({props.totalProducts} products)
			</h3>
			<Row>
				<Col xs={6}>
					Total for this designer
				</Col>
				<Col xs={6} align="right">
					{props.totalPrice}
				</Col>
			</Row>
			<SummaryList>
				<li>* including shipping costs</li>
				<li>* VAT included (where applicable). Tax fees may apply depending on the delivery country</li>
				<li>* The price is rounded to 2 decimals</li>
			</SummaryList>
		</div>
	);
};

PurchaseSummary.propTypes = {
	totalProducts: PropTypes.number.isRequired,
	totalPrice: PropTypes.string.isRequired,
};

export default PurchaseSummary;