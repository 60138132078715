import React, { Component } from 'react';
import PropTypes from 'prop-types';
import T, { Table } from 'components/Table';
import { connect } from 'react-redux';
import { selectSales } from 'data/modules/sales';

class Sales extends Component {

	constructor(props) {
		super(props);
	}

	render() {
		return (
			<Table>
				<T.Head>
					<T.TR>
						<T.TH>Sales %</T.TH>
						<T.TH>Sales name</T.TH>
						<T.TH>Sales from</T.TH>
						<T.TH>Sales to</T.TH>
						<T.TH>Sales applied to</T.TH>
					</T.TR>
				</T.Head>
				<T.Body>
					{this.props.sales.map(sale => {
						const keys = Object.keys(sale);

						return (
							<T.TR key={sale.id}>
								{keys.map(key => (
									<T.TD>{sale[key]}</T.TD>
								))}
							</T.TR>
						);
					})}
				</T.Body>
			</Table>
		);
	}

}

Sales.propTypes = {
	sales: PropTypes.array.isRequired,
};

export default connect(
	state => ({
		sales: selectSales(state),
	})
)(Sales);